import { Component, OnInit, Input } from '@angular/core';
import { BreadcrumbsService, IBreadcrumbRoute } from '../../core/breadcrumbs.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit {
    @Input() title: string;
    @Input() dropdownId: string;
  constructor(
  ) { }

  ngOnInit(): void {
  }

  dropDownClick() {
    const dropdowns = document.querySelector("#"+this.dropdownId);;
    const select = dropdowns.querySelector(".dropdown-select");
    const menu = dropdowns.querySelector(".dropdown-menu");
    select.classList.toggle("select-clicked");
    menu.classList.toggle("dropdown-menu-open");
  }
}
