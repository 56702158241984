import { IContentElement } from "../models";

export interface IContentElementTable extends IContentElement {
    grid: Array<Array<IContentElementTableCell>>;
    isHeaderRow: boolean;
    isHeaderCol: boolean;
    isColWidthConst: boolean;
    isTableOfValues?: boolean;
    colWidthConst: number;
  }
  export interface IContentElementTableCell extends IContentElement{
    val: string;
    align?: string;
  }

  export const tableTextEditInfo = {
    editExcludeFields: [],
    editTextFields: ['val']
  }


  export const tableEditInfo = {
    editExcludeFields: []
  }