<!-- <input class="input is-fullwidth" [formControl]="content" type="text" placeholder="Option"> -->
<fieldset [disabled]="isReadOnly()">
    <textarea 
        [formControl]="content" 
        cdkTextareaAutosize 
        [cdkTextareaAutosize]="true" 
        [cdkAutosizeMinRows]="2"
        class="textarea is-fullwidth" 
        type="text" 
        placeholder="Option"
    ></textarea>
</fieldset>