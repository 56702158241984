import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";

export class Polygon extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  polygon: PIXI.Graphics;
  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.element = element;
    this.spriteLoader = new SpriteLoader();
    
    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.drawPolygons(this.element.polygonConfig);

    // Render scene
    this.render();
  }

  drawPolygons(json: string) {
    if(!json || json.length == 0) {
      return;
    }
    
    try {
      const polygons = JSON.parse(json);

      if(Array.isArray(polygons)) {
        polygons.map((polygon) => {
          const {baseX, baseY, fill, points, isInteractive} = polygon;
          const newPolygon = this.drawPolygon(baseX, baseY, fill, points, isInteractive);
          this.addGraphic(newPolygon);
        })
      } else {
        const {baseX, baseY, fill, points, isInteractive} = polygons;
        const newPolygon = this.drawPolygon(baseX, baseY, fill, points, isInteractive)
        this.addGraphic(newPolygon);
      }
    } catch(err) {
      console.error("Invalid JSON", err);
    }
  }

  loadAssets() {
      return null;
  }
}
