import { IContentElement, IEntryStateScored } from "../models";

export interface IContentElementGraphing extends IContentElement {
    availableTools: { [key: string]: boolean };
}

export interface IEntryStateGraphing extends IEntryStateScored {
    state: any;
}

export const graphingEditInfo = {
    editExcludeFields: []
}

