import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'documents-display',
  templateUrl: './documents-display.component.html',
  styleUrls: ['./documents-display.component.scss']
})
export class DocumentsDisplayComponent implements OnInit {

  constructor() { }

  @Output() toggleDocuments = new EventEmitter();
  @Output() selectDocumentView = new EventEmitter();
  @Input() showCloseButton;
  @Input() documentViews;
  @Input() selectedDocumentId;
  @Input() activeDocument;
  @Input() containingWidth;
  @Input() containingWidthSpill;
  @Input() allowSubtitles;
  @Input() allowTranscripts;
  @Input() allowAudioPlaybackSpeed;
  @Input() allowVideoPlaybackSpeed;
  @Input() addPadding;

  ngOnInit(): void {
  }


}
