export const sanitizeAccessCodeStudentNumber = (accessCode:string | number, studentNumber?:string | number, options?: {isStudentNumberEmail?:boolean}) => {
    return {
        accessCode: (''+(accessCode || '')).replace(/[\W_]+/g, ""), // not case sensitive
        studentNumber: processStudentLoginId(studentNumber, options?.isStudentNumberEmail),
    }
} 


export const processStudentLoginId = ( studenLoginId:string | number, isEmail?:boolean) => {
    const loginId = (''+(studenLoginId || '')).trim()
    if(isEmail) return loginId;
    return loginId.replace(/[\W_]+/g, "");
}
