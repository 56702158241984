import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { imageEditInfo } from 'src/app/ui-testrunner/element-render-image/model';
import { mathEditInfo } from 'src/app/ui-testrunner/element-render-math/model';
import { IContentElementTable, IContentElementTableCell, tableTextEditInfo } from 'src/app/ui-testrunner/element-render-table/model';
import { textEditInfo } from 'src/app/ui-testrunner/element-render-text/model';
import { ElementType, IElementTypeDef } from 'src/app/ui-testrunner/models';
import { EditingDisabledService } from '../editing-disabled.service';
import { applyDefaultElementProps, createDefaultElement } from '../item-set-editor/models';
import { bindFormControls } from '../services/data-bind';

enum SelectionType {
  ROW = 'row',
  COL = 'column',
}

@Component({
  selector: 'element-config-table',
  templateUrl: './element-config-table.component.html',
  styleUrls: ['./element-config-table.component.scss']
})
export class ElementConfigTableComponent implements OnInit {

  @Input() element:IContentElementTable;

  public isShowAdvancedOptions = new FormControl(false);
  public isTableOfValues = new FormControl(false);
  public isHeaderRow = new FormControl(false);
  public isHeaderCol = new FormControl(false);
  public isColWidthConst = new FormControl(false);
  public colWidthConst = new FormControl();
  public grid = [];
  public elementTypes:IElementTypeDef[] = [
    {id: ElementType.TABLE_TEXT, caption:'Text',      icon:'fa-font', editingInfo: tableTextEditInfo},
    {id: ElementType.TEXT,    caption:'Adv. Text', icon:'fa-font', editingInfo: textEditInfo},
    {id: ElementType.MATH,    caption:'Math',      icon:'fa-pencil-alt', editingInfo: mathEditInfo},
    {id: ElementType.IMAGE,   caption:'Image',     icon:'fa-image', editingInfo: imageEditInfo},
  ];
  public allowedSubElements = {'':true, 'math': true, 'image': true}
  public alignments = [
    {id:'left', icon:'fa-align-left'},
    {id:'center', icon:'fa-align-center'},
    {id:'right', icon:'fa-align-right'},
  ]
  public isImportExportOpen:boolean = false;
  public importExportStr = new FormControl();

  // SelectionType = SelectionType;
  // public selection:{type:SelectionType, index:number};

  constructor(private editingDisabled: EditingDisabledService) { }

  ngOnInit() {

    const formControls = [
      {f: this.isTableOfValues, p:'isTableOfValues'},
      {f: this.isHeaderRow, p:'isHeaderRow'},
      {f: this.isHeaderCol, p:'isHeaderCol'},
      {f: this.isColWidthConst, p:'isColWidthConst'},
      {f: this.colWidthConst, p:'colWidthConst'},
      {f: this.isShowAdvancedOptions, p:'isShowAdvancedOptions'},
    ];

    bindFormControls(this.element, formControls);
    this.initGrid();
  }

  initGrid(){
    // console.log('table element', this.element);
    this.grid = [];
    if (!this.element.grid){
      const defaultRows = 3;
      const defaultCols = 2;
      for(let r = 0; r < defaultRows; r++) {
        const cols = [];
        for(let c = 0; c < defaultCols; c++) {
          const el = createDefaultElement(ElementType.TABLE_TEXT)
          cols.push(el)
        }
        this.element.grid.push(cols);
      }
    }
    this.element.grid.forEach(row =>{
      const inputRow = [];
      row.forEach(cell => {
        const inputCell = this.initCell(cell);
        inputRow.push(inputCell)
      });
      this.grid.push(inputRow);
    })
  }

  openImport(){
    if (this.isImportExportOpen){
      this.closeImport();
    }
    else {
      this.isImportExportOpen = true;
      this.importExportStr.setValue(JSON.stringify(this.element))
    }
  }
  closeImport(){
    this.isImportExportOpen = false;
  }
  importGrid(){
    const newEl = JSON.parse(this.importExportStr.value);
    Object.keys(newEl).forEach(key => {
      this.element[key] = newEl[key];
    });
    this.initGrid()
    this.closeImport();
  }

  checkHeaderByIndex(i:number){
    if (this.isHeaderRow.value && i === 0){
      return true;
    }
  }
  checkColHeaderByIndex(i:number){
    if (this.isHeaderCol.value && i === 0){
      return true;
    }
  }

  getCell(row_i:number, col_i:number){
    return this.element.grid[row_i][col_i];
  }
  isCellElementType(row_i:number, col_i:number, typeId:string){
    const cell = this.getCell(row_i, col_i);
    if (!cell.elementType){
      if (typeId === ''){
        return true
      }
    }
    else{
      return (cell.elementType === typeId);
    }
  }
  
  getCellElementType(row_i:number, col_i:number){
    const cell = this.getCell(row_i, col_i);
    return cell.elementType;
  }
  setCellElementType(row_i:number, col_i:number, typeId:string){
    const cell = this.getCell(row_i, col_i);
    cell.elementType = typeId;
    applyDefaultElementProps(cell, typeId);
  }

  isCellAlignment(row_i:number, col_i:number, alignId:string){
    const cell = this.getCell(row_i, col_i);
    if (cell.align){
      if (cell.align === alignId){
        return true
      }
    }
  }
  setCellAlignment(row_i:number, col_i:number, alignId:string){
    const cell = this.getCell(row_i, col_i);
    cell.align = alignId;
  }
  setColAlignment(col_i:number, alignId:string){
    this.element.grid.forEach(row => {
      const cell = row[col_i];
      cell.align = alignId;
    })
  }

  ensureAtLeastOneRowAndColumn(){
    if (this.grid.length < 1){
      this.createNewRow();
    }
    if (this.grid[0].length < 1){
      this.createNewColumn();
    }
  }

  deleteRow(index:number){
    if (confirm('Are you sure you want to remove the row?')){
      this.grid.splice(index, 1);
      this.element.grid.splice(index, 1);
    }
    this.ensureAtLeastOneRowAndColumn();
  }

  

  deleteColumn(index:number, isDisabled:boolean=false){
    if (isDisabled){ return; }
    if (confirm('Are you sure you want to remove the column?')){
      this.grid.forEach((row, i) => {
        row.splice(index, 1);
        this.element.grid[i].splice(index, 1);
      })
    }
    this.ensureAtLeastOneRowAndColumn();
  }

  createNewRow(){
    const numCols = this.grid[0].length;
    const inputRow = [];
    const modelRow = [];
    for (let i=0; i<numCols; i++){
      const cell:IContentElementTableCell = <IContentElementTableCell>createDefaultElement(ElementType.TABLE_TEXT)
      const inputCell = this.initCell(cell);
      modelRow.push(cell);
      inputRow.push(inputCell)
    }
    this.element.grid.push(modelRow);
    this.grid.push(inputRow);
  }

  createNewColumn(){
    this.grid.forEach((inputRow, i) => {
      const cell:IContentElementTableCell = <IContentElementTableCell>createDefaultElement(ElementType.TABLE_TEXT)
      const inputCell = this.initCell(cell);
      this.element.grid[i].push(cell);
      inputRow.push(inputCell)
    })
  }

  initCell(cell:IContentElementTableCell){
    const input = new FormControl(cell.val);
    
    input.valueChanges.subscribe(obs => {
      cell.val = input.value;
    })
    return input;
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }
}
