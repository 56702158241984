<div>

    <!-- <div class="field" *ngIf="isShowAdvancedOptions.value">
      <div class="control">
        <div class="select is-fullwidth">
          <select [formControl]="paragraphStyle">
            <option *ngFor="let option of paragraphStyles" [value]="option.id">{{option.caption}}</option>
          </select>
        </div>
      </div>
    </div> -->
  
    <div style="overflow:auto;">
      <table *ngIf="grid">
        <tr>
          <td></td>
          <td *ngFor="let cell of grid[0]; let col_i = index;" [ngClass]="{'no-pointer-events':isReadOnly()}" class="min-col-width selectable-cell" (click)="deleteColumn(col_i, isShowAdvancedOptions.value)">
            <fieldset [disabled]="isReadOnly()">
              <i *ngIf="!isShowAdvancedOptions.value" class="fa fa-trash" aria-hidden="true"></i>
              <div *ngIf="isShowAdvancedOptions.value">
                <div>
                  <button [disabled]="isReadOnly()" class="button is-small is-light" (click)="deleteColumn(col_i)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                  <div  class="buttons is-no-wrap" style="margin-top:0.5em;">
                    <button *ngFor="let align of alignments" [disabled]="isReadOnly()" class="button is-small"  (click)="setColAlignment(col_i, align.id)">
                      <i class="fa {{align.icon}}"></i>
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
          </td>

        </tr>
        <tr *ngFor="let row of grid; let row_i = index;" [class.header-row]="checkHeaderByIndex(row_i)"> 
          <td [class.no-pointer-events]="isReadOnly()" class="selectable-cell" (click)="deleteRow(row_i)">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </td>
          <td *ngFor="let cell of row; let col_i = index;" [class.header-col]="checkColHeaderByIndex(col_i)">
            <div style="display:flex; flex-direction:row;">
              <div [ngSwitch]="getCellElementType(row_i, col_i)" style="flex-grow:1">
                <div *ngSwitchCase="'text'" style="min-width: 15em;"><element-config-text [element]="getCell(row_i, col_i)" ></element-config-text></div>
                <div *ngSwitchCase="'math'" style="min-width: 15em;"><element-config-math [element]="getCell(row_i, col_i)" ></element-config-math></div>
                <div *ngSwitchCase="'image'"><element-config-image [hasStates]="true" [element]="getCell(row_i, col_i)" ></element-config-image></div>
                <fieldset [disabled]="isReadOnly()">
                  <div *ngSwitchDefault>
                    <!-- <input type="text" class="input is-small" style="text-align:center;" [formControl]="cell"> -->
                    <textarea 
                      [formControl]="cell"
                      style="min-width: 10em;" 
                      class="textarea is-small" 
                      cdkTextareaAutosize 
                      [cdkTextareaAutosize]="true" 
                      [cdkAutosizeMinRows]="2"
                    ></textarea>
                  </div>
                </fieldset>
              </div>
              <fieldset [disabled]="isReadOnly()">
                <button *ngIf="isShowAdvancedOptions.value" class="button is-small" [class.is-dark]="cell.isSettingsOpen" (click)="cell.isSettingsOpen = !cell.isSettingsOpen">
                  <i class="fas fa-cog"></i>
                </button>
              </fieldset>
            </div>
            <fieldset [disabled]="isReadOnly()">
              <div  *ngIf="isShowAdvancedOptions.value && cell.isSettingsOpen" style="margin-top:0.5em;">
                <div class="buttons">
                  <button *ngFor="let align of alignments" [disabled]="isReadOnly()" class="button is-small" [class.is-info]="isCellAlignment(row_i, col_i, align.id)" (click)="setCellAlignment(row_i, col_i, align.id)">
                    <i class="fa {{align.icon}}"></i>
                  </button>
                </div>
                <div>
                  <button *ngFor="let elementType of elementTypes" [disabled]="isReadOnly()" class="button is-small is-fullwidth has-icon" [class.is-info]="isCellElementType(row_i, col_i, elementType.id)" (click)="setCellElementType(row_i, col_i, elementType.id)">
                    <span class="icon"><i class="fa {{elementType.icon}}"></i></span>
                    <span>{{elementType.caption}}</span>
                  </button>
                </div>
              </div>
            </fieldset>
          </td>
        </tr>
      </table>
    </div>

  
    <hr />

    <fieldset [disabled]="isReadOnly()">
      <button [disabled]="isReadOnly()" (click)="createNewRow()" class="button is-small has-icon">
        <span class="icon"><i class="fa fa-plus" aria-hidden="true"></i></span>
        <span>New Row</span>
      </button>
      <button [disabled]="isReadOnly()" (click)="createNewColumn()" class="button is-small has-icon">
        <span class="icon"><i class="fa fa-plus" aria-hidden="true"></i></span>
        <span>New Column</span>
      </button>
    
      <hr />
  
      <div>
        <label class="checkbox">
          <input type="checkbox" [formControl]="isHeaderRow">
          <tra slug="auth_row_header"></tra>
        </label>
      </div>
      <div>
        <label class="checkbox">
          <input type="checkbox" [formControl]="isHeaderCol">
          <tra slug="auth_column_header"></tra>
        </label>
      </div>
      <div>
        <label class="checkbox">
          <input type="checkbox" [formControl]="isColWidthConst">
          <tra slug="auth_table_column_width"></tra>
        </label>
        <div style="padding-left:2em;" *ngIf="isColWidthConst.value">
          <tra slug="auth_width"></tra> 
          <input type="number" [formControl]="colWidthConst" style="width:5em;">
        </div>
      </div>
      <div *ngIf="isShowAdvancedOptions.value">
        <label class="checkbox">
          <input type="checkbox" [formControl]="isTableOfValues">
          <tra slug="auth_table_of_val"></tra>
        </label>
      </div>
    </fieldset>

    <hr />

    <div *ngIf="isShowAdvancedOptions.value">
      <button class="button has-icon is-light" (click)="openImport()">
        <span class="icon"><i class="fa fa-upload" aria-hidden="true"></i></span>
        <span>Import/Export</span>
      </button>
      <div *ngIf="isImportExportOpen" style="margin-top:0.3em;">
        <textarea class="textarea" [formControl]="importExportStr" rows=3></textarea>
        <div class="buttons" style="margin-top:0.3em;">
          <div [class.no-pointer-events]="isReadOnly()" (click)="closeImport()" class="button is-danger">Close</div>
          <div [class.no-pointer-events]="isReadOnly()" (click)="importGrid()" class="button">Import</div>
        </div>
      </div>
      <hr />
    </div>

    <div>
      <label class="checkbox">
        <input type="checkbox" [formControl]="isShowAdvancedOptions">
        <tra slug="auth_show_advanced_options"></tra>
      </label>
    </div>

  </div>