import { Component, Input, OnInit } from '@angular/core';

export enum ExpansionPanelContentType {
  JSON_VIEW = 'JsonView',
  TEXT_AREA = 'TextArea'
}
export interface IExpansionPanelContent {
  type: ExpansionPanelContentType;
  label?: string;
  data: any;
}
@Component({
  selector: 'expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {

  @Input() title : string;
  @Input() subTitle: string
  @Input() description: string
  @Input() content: IExpansionPanelContent[];
  @Input() style: any;

  constructor() { }

  ngOnInit(): void {
  }

  getBorderColor(){
    if(this.style && this.style['borderColor']){
      return this.style['borderColor']
    }
    return '#d3d3d3'
  }

}
