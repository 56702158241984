import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LangService } from 'src/app/core/lang.service';

const DEFAULT_PROMPT = 'bc_upload_widget_default_prompt';
const DEFAULT_FILE_DESCRIPTION = 'bc_upload_widget_default_file_description';

@Component({
  selector: 'bc-upload-widget',
  templateUrl: './bc-upload-widget.component.html',
  styleUrls: ['./bc-upload-widget.component.scss']
})
export class BcUploadWidgetComponent implements OnInit {

  @ViewChild('importInput', { static: false }) importInput: ElementRef;

  @Input() prompt: string = DEFAULT_PROMPT;
  @Input() fileDescription: string = DEFAULT_FILE_DESCRIPTION;
  @Input() error: string = null;
  @Input() disabled: boolean = false;

  @Output() file = new EventEmitter();

  dragAndDropText: string;

  fileToUpload: File;
  files: FileList;

  constructor(
    private lang: LangService,
  ) { }

  ngOnInit(): void {
    this.dragAndDropText =
      this.lang.tra('bc_upload_widget_drag_or_drop_1')
      + ' '
      + this.lang.tra(this.fileDescription)
      + ' '
      + this.lang.tra('bc_upload_widget_drag_or_drop_2');
  }

  async onImportFileChange(files: FileList) {
    this.files = files;
    this.fileToUpload = this.files.item(0);
    this.file.emit(files);
  }

}
