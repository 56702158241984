import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { EStyleProfile } from 'src/app/core/styleprofile.service';
import { LangService } from 'src/app/core/lang.service';
import { AuthService } from 'src/app/api/auth.service';
import { Router } from '@angular/router';
import { ISsAttemptSlugMap } from '../types';

export interface IMapElement {
  url: string | {[key:string]: string},
  animation?: IAnimationConfig,
  anchor?: {x?: number, y?: number}, //0.100 percent values - used to place elements, also used to to place module status on elements.
  rotation?: number,
  pos?: IPosConfig,
  styleConfig?: any,
  style?: any,
  animStyle?: any,
  subSessionSlug?: string,
  subSessionSlugRef?: string,
  audioSlug?: string,
  subElements?: IMapElement[],
  isStatusEl?: boolean
}

export enum EMgScaleMode {
  FILL_WIDTH = 'FILL_WIDTH',
  FILL_HEIGHT = 'FILL_HEIGHT',
  AUTO = 'AUTO'
}
export interface IMapMgConfig {
  imgUrl: string,
  scaleMode?: EMgScaleMode
}

export interface IMapBgConfig {
  imgUrl: string,
  style?: any
}

export interface IModStatusElConfig {
  [key:string]: IMapElement
}

export interface IMapBtnConfig {
  captionSlug: string,
  faClass?: string,
  imgUrl?: string,
  style?: any,
  invertOnHover?: boolean
}

export interface IMapConfig {
  finishBtnConfig: IMapBtnConfig,
  homeBtnConfig: IMapBtnConfig,
  mgConfig: IMapMgConfig, 
  bgConfig: IMapBgConfig,
  modStatusEls: IModStatusElConfig,
  contents: IMapElement[]
}

export enum EAnimTrigger {
  ON_SS_COMPLETE = 'ON_SS_COMPLETE'
}

export interface IAnimationConfig {
  name: string,
  duration: string,
  iterations: string | number,
  trigger?: EAnimTrigger,
  timingFn?: string,
  delay?: string,
  disableOnUnclickable?: boolean
}

interface IPosConfig {
    x?: number,
    y?: number,
    size?: number
}

export interface ISubSessionAttemptDef {
  id?: number,
  sections?: number[],
  slug: string,
  twtdar_order?: number,
  caption?: string,
  order?: number,
  is_submitted?: number,
  styleProfile?: EStyleProfile,
  active_sub_session_id?: number
}

export enum ESubSessionStatus {
  COMPLETED = 'COMPLETED',
  UNLOCKED = 'UNLOCKED',
  LOCKED = 'LOCKED'
}

export type AnimTriggerEventMap = Map<EAnimTrigger, Set<any>>;

@Component({
  selector: 'pj-map',
  templateUrl: './pj-map.component.html',
  styleUrls: ['./pj-map.component.scss']
})
export class PjMapComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() ssAttemptSlugMap: ISsAttemptSlugMap;
  @Input() map: IMapConfig;
  @Input() isPublic: boolean;
  @Input() activeSubSessionId: number;
  @Input() refreshSubElTrigger: boolean;
  @Input() animTriggerEvents: AnimTriggerEventMap;
  @Input() isG6;
  @Output() goHome = new EventEmitter();
  @Output() enterSubSession = new EventEmitter();
  @Output() goToCompletion = new EventEmitter();
  @ViewChild('bgDiv') bgDiv: ElementRef;
  @ViewChild('mgImg') mgImg: ElementRef;
  constructor(
    public lang:LangService,
    private auth: AuthService, 
    private router: Router,
  ) { }

  displayName:string;
  mgStyle;
  mgRatio; //w/h - stored on image load for firefox since we don't have access to naturalwidth / naturalheight for SVGs there.

  ESubSessionStatus = ESubSessionStatus;

  refreshStyleInterval;

  mapElements: IMapElement[] = [];
  
  ngOnInit(): void {
    this.mapElements = _.cloneDeep(this.map.contents); //Ensure that module sub-elements are not added multiple times.
    this.refreshSubElements();
    this.reorderJuniorOPSubElements();
    window.addEventListener('resize', this.refreshStyles);
    this.auth.user().subscribe((userInfo:any) => {
      if (userInfo){
          this.displayName = [userInfo.first_name, userInfo.last_name].join(' ')
      }
    });     
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.refreshSubElTrigger && !changes.refreshSubElTrigger.isFirstChange()) {
      this.refreshSubElements();
      this.refreshElementStyles(this.mapElements);
    }
  }

  refreshSubElements() {
    for(const el of this.mapElements) {
      el.subElements = el.subElements?.filter( el => !el.isStatusEl) || [];
      if(el.subSessionSlug) {
        el.subElements.push(this.getSubSessionStatusEl(el.subSessionSlug));
      }
    }
  }

  // A sort function to ensure the map elements for language and math sessions/stages are in order
  // so that the tab sequence is correct.
  reorderJuniorOPSubElements(){
    let juniorLangSessions = [];
    let juniorMathSessions = [];

    // Get all language sessions and math stages, store them in separate arrays
    this.mapElements.forEach(el => {
      if (el.subSessionSlug){
        if (el.subSessionSlug.match(/lang_session_([a-z])/g)){
          juniorLangSessions.push(el);
        }
        else if (el.subSessionSlug.match(/math_stage_([0-9])/g)){
          juniorMathSessions.push(el);
        }
      }
    })

    // Sort each of the arrays by the last character in the sub session slug, language is [a-z] and math is [1-9]
    function cmp(x, y) {
      return x.subSessionSlug.slice(-1) > y.subSessionSlug.slice(-1) ? 1 : (x.subSessionSlug.slice(-1) < y.subSessionSlug.slice(-1) ? -1 : 0);
    }
    juniorLangSessions.sort(cmp);
    juniorMathSessions.sort(cmp);
    
    // Remove the unsorted language sessions and math stages from the this.mapElements
    let sortedMapElements = juniorLangSessions.concat(juniorMathSessions)

    this.mapElements = this.mapElements.filter( function( el ) {
      return !sortedMapElements.includes( el );
    } );

    // Find the index of the language/math title element, append the sorted sessions/stages right after the title element
    let langIndex = this.mapElements.indexOf(this.mapElements.find(el => el.audioSlug === 'pj_lang'));
    this.mapElements = this.mapElements.slice(0, langIndex + 1).concat(juniorLangSessions, this.mapElements.slice(langIndex + 1));
    let mathIndex = this.mapElements.indexOf(this.mapElements.find(el => el.audioSlug === 'pj_math'));
    this.mapElements = this.mapElements.slice(0, mathIndex + 1).concat(juniorMathSessions, this.mapElements.slice(mathIndex + 1));
  }

  ngAfterViewInit(): void {
    this.mgImg.nativeElement.addEventListener('load', this.onMgLoaded)
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.refreshStyles);
    this.mgImg.nativeElement.removeEventListener('load', this.refreshStyles);
    // if(this.refreshStyleInterval) {
    //   clearInterval(this.refreshStyleInterval);
    // }
  }

  isUnlocked = (subSession: ISubSessionAttemptDef) => {
    if(this.isPublic) {
      return true;
    } else {
      return subSession.id === this.activeSubSessionId
    }
  }
  
  isElClickable = (element: IMapElement) => {
    const status = this.getSubSessionStatus(element.subSessionSlug);
  
    return status === ESubSessionStatus.UNLOCKED; 
  }
  
  getSubSessionStatus = (subSessionSlug: string) : ESubSessionStatus => {
    const subSession = this.ssAttemptSlugMap[subSessionSlug];
    
    if(!subSession) {
      return undefined;
    }
    
    if(subSession.is_submitted) {
      return ESubSessionStatus.COMPLETED;
    } else if(this.isUnlocked(subSession)) {
      return ESubSessionStatus.UNLOCKED;
    } 
    return ESubSessionStatus.LOCKED;
  }

  refreshElementStyles(elements: IMapElement[], parentEl?: IMapElement) {
    for(const el of elements) {
      el.style = this.getElementStyle(el, parentEl);

      el.animStyle = {};
      if(!el.animation?.disableOnUnclickable || (this.isElClickable(el) && el.animation?.disableOnUnclickable)) {
        el.animStyle = this.getAnimStyle(el);
      }

      this.refreshElementStyles(el.subElements || [], el);
    }
  }

  onMgLoaded = () => {
    this.mgRatio = this.getMgRatio();
    this.refreshStyles();

    //Commenting this out since this conflicts with the "onceOnly" animation logic - cuts animations midway through if their styles are updated.
    //Need to update that logic too if we need this
    // if(!this.refreshStyleInterval) {
    //   this.refreshStyleInterval = setInterval(() => {
    //     this.refreshStyles();
    //   }, 1000);
    // }
  }

  refreshStyles = () => {
    this.refreshMgStyle();
    this.refreshElementStyles(this.mapElements);//relies on mgStyles being calculated first
    this.animTriggerEvents.clear(); //do not use these events to trigger any more animations
    // this.refreshStatusStyles(); 
  }

  refreshMgStyle() {
    this.mgStyle = this.getMgStyle();
  }

  // refreshStatusStyles() {
  //   for(const el of this.mapElements) {
  //     if(el.module) {
  //       el.modStatusStyle = this.getModStatusStyle(el);
  //       el.modStatusAnimStyle = this.getModStatusAnimStyle(el);
  //     }
  //   }
  // }


  /*
    getModStatusStyle(fgElement: IMapElement) {
    const status = this.getModuleStatusEl(fgElement.module);

    // const statusHeightP = 0.17; //percentage of the midground layer for the status size
    const statusWidthP = status.pos?.size;


    //Calculate the height in pixels for this status element so that it is relative to the midground layer.
    //It can then be positioned relative to the element it is within, but be sized independent of the element it is within and relative to the grandparent instead.
    const {bgDivW,bgDivH} = this.getBgDimensions();

    let mgHeightPx;
    let mgWidthPx;
    if(this.mgStyle['width.%']) {
      const mgWidthP = this.mgStyle['width.%'];
      mgWidthPx = ((mgWidthP/100.0)*bgDivW)
      // mgHeightPx = mgWidthPx*(1/this.mgRatio);
    } else if(this.mgStyle['height.%']) {
      const mgHeightP = this.mgStyle['height.%'];
      mgHeightPx = ((mgHeightP/100.0)*bgDivH);
      mgWidthPx = mgHeightPx*this.mgRatio;
    }

    // const heightPx = mgHeightPx*statusHeightP;
    const widthPx = mgWidthPx*statusWidthP;

    const statAnchorPos = this.getAnchorPos(status);

    const fgAnchorPos = this.getAnchorPos(fgElement);

    let style: any = {
      'top.%': fgAnchorPos.y,
      'left.%': fgAnchorPos.x,
      'transform': `translate(-${statAnchorPos.x}%, -${statAnchorPos.y}%)`,
      // 'height.px': heightPx
      'width.px': widthPx //use width since we use width.% to denote size for fg elements -- height.% does not work properly for firefox
    };
    return style;
  }
  */

  getElementStyle(element: IMapElement, parentEl?: IMapElement) {
    const widthP = element.pos?.size;

    //Calculate the height in pixels for this status element so that it is relative to the midground layer.
    //It can then be positioned relative to the element it is within, but be sized independent of the element it is within and relative to the grandparent instead.
    const {bgDivW,bgDivH} = this.getBgDimensions();

    let mgHeightPx;
    let mgWidthPx;
    if(this.mgStyle['width.%']) {
      const mgWidthP = this.mgStyle['width.%'];
      mgWidthPx = ((mgWidthP/100.0)*bgDivW)
      // mgHeightPx = mgWidthPx*(1/this.mgRatio);
    } else if(this.mgStyle['height.%']) {
      const mgHeightP = this.mgStyle['height.%'];
      mgHeightPx = ((mgHeightP/100.0)*bgDivH);
      mgWidthPx = mgHeightPx*this.mgRatio;
    }

    // const heightPx = mgHeightPx*statusHeightP;
    const widthPx = mgWidthPx*(widthP/100.0);

    const elAnchorPos = this.getAnchorPos(element);

    let coords = {x: undefined, y: undefined};
    
    coords = this.getAnchorPos(parentEl);

    for(const dimension of Object.keys(coords)) {
      coords[dimension] = element.pos[dimension] ?? coords[dimension]; //Fall back on parent's anchor position if no coordinates set. Falls back on center if no parent. 
    }

    const transform = `translate(-${elAnchorPos.x}%, -${elAnchorPos.y}%) rotate(${element?.rotation || 0}deg)`

    const styleConfig = element.styleConfig || {};
    let style: any = {
      ...styleConfig,
      'top.%': coords.y,
      'left.%': coords.x,
      'transform': transform,
      // 'height.px': heightPx
      'width.px': widthPx //use width since we use width.% to denote size for fg elements -- height.% does not work properly for Firefox
    };


    return style;
  }

  getMgSrc() {
    return this.map.mgConfig.imgUrl;
  }

  getBgDimensions() {
    const bgDiv = this.bgDiv?.nativeElement;
    let bgDivW = bgDiv?.offsetWidth;
    let bgDivH = bgDiv?.offsetHeight;

    if(!bgDivW || !bgDivH) {
      return {};
    }

    const computedStyle = getComputedStyle(bgDiv);

    bgDivW -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    bgDivH -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);

    return {bgDivW, bgDivH}
  }

  getMgRatio() {
      const w = this.mgImg?.nativeElement?.width;
      const h = this.mgImg?.nativeElement?.height;
      if(!w || !h) {
        return 0;
      }
      return w/h;
  }

  getInitMgStyle() {
    return {
      'visibility': 'hidden',
      'width.%': 100 //Firefox requires width or height to be set to be able to calculate an aspect ratio for SVGs - is overwritten once we can calculate that.
    }
  }

  getBgStyle() {
    return {
      'background-image': `url(${this.map.bgConfig.imgUrl})`,
      ...this.map.bgConfig.style
    }
  }

  getMgStyle() {
    switch(this.map.mgConfig.scaleMode) {
      case EMgScaleMode.FILL_WIDTH: 
        return {
          position: 'relative',
          'width.%': 100
        }
      case EMgScaleMode.FILL_HEIGHT:
        return {
          position: 'relative',
          'height.%': 100
        }
      case EMgScaleMode.AUTO:
      default:
        const {bgDivW, bgDivH} = this.getBgDimensions(); 
    
        if(!this.mgRatio || !bgDivW || !bgDivH) {
          return undefined;
        }
        
        const bgRatio = bgDivW/bgDivH;
    
        const maxWH = 100;
    
        let style:any = {
          position: 'relative'
        };
        if(bgRatio >= this.mgRatio) {
          style['height.%'] = maxWH;
          style['width'] = 'fit-content';
        }
    
        if(bgRatio <= this.mgRatio) {
          style['width.%'] = maxWH;
        }
    
        return style;
    }
  }

  isAnimTriggered(element: IMapElement, trigger:EAnimTrigger) {
    switch(trigger) {
      case EAnimTrigger.ON_SS_COMPLETE:
        const subSessionSlug = element.subSessionSlug || element.subSessionSlugRef;
        return !!this.animTriggerEvents.get(trigger)?.has(subSessionSlug);
      default:
        break;
    }

    return false;
  }

  getAnimStyle(element: IMapElement) {
    if(!element.animation) {
      return {};
    }
    const {name, duration, iterations, timingFn, delay, trigger} = element.animation;

    if(trigger) {
      if(!this.isAnimTriggered(element, trigger)) {
        return {};
      }
    }
    
    return {
      'animation-name': name,
      'animation-duration': duration,
      'animation-iteration-count': iterations,
      'animation-timing-function': timingFn,
      'animation-delay': delay
    }
  }

  getSubSessionStatusEl(subSessionSlug: string) : IMapElement{
    const status = this.getSubSessionStatus(subSessionSlug);
    return {
      ... this.map.modStatusEls[status],
      subSessionSlugRef: subSessionSlug,
      isStatusEl: true //reference to module that this is a status of
    };
  }

  getAnchorPos(element: IMapElement) {
    if(!element?.anchor) {
      return {x: 50, y: 50};
    }

    let {x, y} = element.anchor; 
    x = x || 50;
    y = y || 50;
    return {x, y};
  }

  selectElement(element: IMapElement) {
    this.enterSubSession.emit(element.subSessionSlug);
  }

  allModulesComplete() {
    for(const slug of Object.keys(this.ssAttemptSlugMap)) {
      const status = this.getSubSessionStatus(slug);
      if(status !== ESubSessionStatus.COMPLETED) {
        return false;
      }
    }
    return true;
  }

  getHomeSlug() {
    return this.map.homeBtnConfig.captionSlug
  }

  getMapElInput() {
    return {
      isElClickable: this.isElClickable,
      getSubSessionStatus: this.getSubSessionStatus
    }
  }

  async logout(){
    await this.auth.logout();
    this.router.navigateByUrl(`/${this.lang.c()}/login-student`);
}
}
