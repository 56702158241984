import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';
import { AuthService } from '../../api/auth.service';

@Component({
  selector: 'ens-certificate',
  templateUrl: './ens-certificate.component.html',
  styleUrls: ['./ens-certificate.component.scss']
})
export class EnsCertificateComponent implements OnInit, AfterViewInit {
  
  @Input() name : string;
  @Input() email : string;
  @Output() certificateEnd = new EventEmitter();
  @ViewChild('canvasRef') canvasElementRef: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;
  private image = new Image();
  certificateWidth = 2000;
  certificateHeight = 1413;

  constructor(
    private auth: AuthService,
  ) { }

  ngAfterViewInit(): void {
    this.ctx = this.canvasElementRef.nativeElement.getContext('2d');
    this.image.src = "assets/ens/certificate-template.jpg";
    this.image.onload = () => {
      this.ctx.drawImage(this.image, 0,0);
      const nameSize = 37*(this.certificateWidth/500);
      this.ctx.font = `${nameSize}px Calibri`;
      this.ctx.textAlign = "center";
      this.ctx.fillText(this.name, this.certificateWidth/2.0,0.67*this.certificateHeight);
      const dateSize = 10*(this.certificateWidth/500);
      this.ctx.font = `${dateSize}px Calibri`;
      const today = new Date();
      const dateString = today.toLocaleDateString('default', {month: 'long', day: 'numeric', year: 'numeric'});
      this.ctx.fillText(dateString, this.certificateWidth/2.0, 0.84*this.certificateHeight);
      this.canvasElementRef.nativeElement.toBlob((blob)=> {
        const filename = this.email.split('.').join('-') + '.png';
        return this.auth
          .uploadFile(blob, filename, 'vea', true)
          .then(uploadRes => {
            const certUrl = 'https://uploadtovretta.s3.amazonaws.com/' + uploadRes.filePath;
            return this.auth.apiCreate('public/whitelabel/vretta-ens/email-cert', {
              name: this.name,
              email: this.email,
              certUrl,
            });
          })
      });
    };    
  }

  ngOnInit(): void {
  }

  openSocial(target:string){
    const baseUrl = 'https://eassessment.vretta.com/#/vretta-ens/home';
    const shareUrl = encodeURIComponent(baseUrl);
    const message = encodeURIComponent('Seneca Helix Entrepreunerial Numeracy Skills');
    const popupUrl = (url:string) => window.open(url, '_blank');
    switch(target){
      case 'fb':        popupUrl(`https://www.facebook.com/sharer.php?u=${shareUrl}`)
      case 'twitter':   popupUrl(`https://twitter.com/share?url=${shareUrl}&text=&hashtags=numeracy,vretta`) 
      case 'linkedin':  popupUrl(`https://www.linkedin.com/`) 
      case 'ig':        popupUrl(`https://www.instagram.com`)
    }
  }

}
