import { ASSESSMENT, SCHEDULER } from "../../ui-teacher/data/types";

// TODO: move it DB twtar or twtt
export const getTwtarAsmtslug = (title: string): string => {
    switch (title) {
      case ASSESSMENT.PRIMARY_SAMPLE: return "primary_sample_test";
      case ASSESSMENT.PRIMARY_OPERATIONAL: return "primary_operational_test";
      
      case ASSESSMENT.JUNIOR_SAMPLE: return "junior_sample_test";
      case ASSESSMENT.JUNIOR_OPERATIONAL: return "junior_operational_test";
     
      case ASSESSMENT.G9_OPERATIONAL: return "g9_operational_test";
      //case ASSESSMENT.G9_SAMPLE: return 'Sample Assessment (G9 Math'
      case ASSESSMENT.G9_SAMPLE: return 'lbl_sample_g9';
      
      case ASSESSMENT.OSSLT_OPERATIONAL: return 'lbl_osslt_test';
      //case ASSESSMENT.OSSLT_SAMPLE: return 'lbl_sample_assessment';
      case ASSESSMENT.OSSLT_SAMPLE: return 'lbl_sample_test_osslt';

      case ASSESSMENT.TCLE_OPERATIONAL : return 'TCLE OPERATIONAL' //cretae label 
      case ASSESSMENT.TCLE_SAMPLE : return 'TCLE SAMPLE' // create label

      case ASSESSMENT.TCN_OPERATIONAL : return 'TCN OPERATIONAL'
      case ASSESSMENT.TCN_SAMPLE : return 'TCN SAMPLE'

      case ASSESSMENT.SCIENCES8_OPERATIONAL : return 'SCIENCES8 OPERATIONAL'
      case ASSESSMENT.SCIENCES8_SAMPLE : return 'SCIENCES8 SAMPLE'

      case ASSESSMENT.LECTURE2_OPERATIONAL: return 'lbl_lecture2_operational'
      
      case ASSESSMENT.LECTURE7_OPERATIONAL: return 'lbl_lecture7_operational'

      case ASSESSMENT.MATH8_SAMPLE : return 'lbl_math8_sample'
      case ASSESSMENT.MATH8_OPERATIONAL : return 'lbl_math8_operational'

      case ASSESSMENT.MATH6_OPERATIONAL : return 'lbl_math6_operational'

      case ASSESSMENT.ELPA_OPERATIONAL: return 'lbl_elpa_operational'
      case ASSESSMENT.ELPA_SAMPLE: return 'lbl_elpa_sample'

      case ASSESSMENT.DEMO_SAMPLE: return 'lbl_demo_sample'

      case ASSESSMENT.NBED_ANGLO_EN4_OPERATIONAL : return 'lbl_anglo_en4_operational';
      case ASSESSMENT.NBED_ANGLO_EN4_SAMPLE : return 'lbl_anglo_en4_sample';

      case ASSESSMENT.NBED_ANGLO_EN6_OPERATIONAL : return 'lbl_anglo_en6_operational'
      case ASSESSMENT.NBED_ANGLO_EN6_SAMPLE : return 'lbl_anglo_en6_sample';

      case ASSESSMENT.NBED_ANGLO_FI_READ10_OPERATIONAL : return 'lbl_anglo_fi_read10_operational';
      case ASSESSMENT.NBED_ANGLO_FI_READ10_SAMPLE : return 'lbl_anglo_fi_read10_sample';

      case ASSESSMENT.NBED_ANGLO_FI_READ7_OPERATIONAL : return 'lbl_anglo_fi_read7_operational';
      case ASSESSMENT.NBED_ANGLO_FI_READ7_SAMPLE : return 'lbl_anglo_fi_read7_sample';

      case ASSESSMENT.NBED_ANGLO_FR12_OPERATIONAL :  return 'lbl_anglo_fr12_operational';
      case ASSESSMENT.NBED_ANGLO_FR12_SAMPLE :  return 'lbl_anglo_fr12_sample';

      case ASSESSMENT.NBED_ANGLO_FR5_OPERATIONAL : return 'lbl_anglo_fr5_operational'
      case ASSESSMENT.NBED_ANGLO_FR5_SAMPLE : return 'lbl_anglo_fr5_sample';

      case ASSESSMENT.NBED_ANGLO_MATH7_OPERATIONAL : return 'lbl_anglo_math7_operational';
      case ASSESSMENT.NBED_ANGLO_MATH7_SAMPLE : return 'lbl_anglo_math7_sample';

      case ASSESSMENT.NBED_ANGLO_SCI4_OPERATIONAL : return 'lbl_anglo_sci4_operational';
      case ASSESSMENT.NBED_ANGLO_SCI4_SAMPLE : return 'lbl_anglo_sci4_sample';

      case ASSESSMENT.NBED_ANGLO_SCI6_OPERATIONAL : return 'lbl_anglo_sci6_operational';
      case ASSESSMENT.NBED_ANGLO_SCI6_SAMPLE : return 'lbl_anglo_sci6_sample';

      case ASSESSMENT.NBED_ANGLO_SCI8_OPERATIONAL : return 'lbl_anglo_sci8_operational';
      case ASSESSMENT.NBED_ANGLO_SCI8_SAMPLE : return 'lbl_anglo_sci8_sample';

      case ASSESSMENT.NBED_ANGLO_MATH5_OPERATIONAL: return 'lbl_anglo_math5_operational'
      case ASSESSMENT.NBED_ANGLO_MATH5_SAMPLE: return 'lbl_anglo_math5_sample';

      case ASSESSMENT.NBED_ANGLO_FI_READ5_OPERATIONAL: return 'lbl_anglo_fi_read5_operational'
      case ASSESSMENT.NBED_ANGLO_FI_READ5_SAMPLE: return 'lbl_anglo_fi_read5_sample'

      case ASSESSMENT.NBED_ANGLO_PIF_READ7_OPERATIONAL: return 'lbl_anglo_pif_read7_operational'
      case ASSESSMENT.NBED_ANGLO_PIF_READ7_SAMPLE: return 'lbl_anglo_pif_read7_sample';

      case ASSESSMENT.NBED_ANGLO_PIF_READ10_OPERATIONAL: return 'lbl_anglo_pif_read10_operational'      
      case ASSESSMENT.NBED_ANGLO_PIF_READ10_SAMPLE: return 'lbl_anglo_pif_read10_sample';

      case ASSESSMENT.NBED_ECRITURE7_OPERATIONAL: return 'lbl_nbed_ecriture7_operational';
      case ASSESSMENT.NBED_ECRITURE7_SAMPLE: return 'lbl_nbed_ecriture7_sample';

      case ASSESSMENT.NBED_FRANCO_DEMO_OPERATIONAL: return 'lbl_nbed_franco_demo_operational';
      case ASSESSMENT.NBED_FRANCO_DEMO_SAMPLE: return 'lbl_nbed_franco_demo_sample';

      case ASSESSMENT.MBED_SAMPLE : return 'MBED SAMPLE'

      case ASSESSMENT.SMCS_EN_G7 : return 'SMCS_EN_G7'
      
      case SCHEDULER.NOW: return 'lbl_now';
      case SCHEDULER.LATER: return 'lbl_sess_scheduled';  // 'lbl_later'; - TODO: whitelable based
    }
  }