import * as _ from 'lodash';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FilterSettingMode,
  IFilterSetting,
  IFilterSettingConfigValue
} from "../capture-filter-range/capture-filter-range.component";
import {MemDataPaginated} from "../paginator/helpers/mem-data-paginated";

export enum IColumnFilterType {
  BOOLEAN_LIST = 'boolean_list'
}

export interface IColumnFilter {
  type: IColumnFilterType,
  options: any
}

@Component({
  selector: 'custom-column-filter',
  templateUrl: './custom-column-filter.component.html',
  styleUrls: ['./custom-column-filter.component.scss']
})
export class CustomColumnFilterComponent implements OnInit {
  @Input() id: string;
  @Input() filter: IColumnFilter;
  @Input() ctrl: MemDataPaginated<any>;
  @Output() emitter = new EventEmitter();
  IColumnFilterType = IColumnFilterType;
  
  constructor() { }

  ngOnInit(): void {
    this.ctrl.activeFilters[this.id] = {
      mode: FilterSettingMode.VALUE,
      config: { value: null }
    }
  }

  updateFilter = _.debounce(() => {
    if (this.ctrl && this.id) {
      let filterObj: IFilterSetting;
      filterObj = this.ctrl.activeFilters[this.id];
      if (!filterObj) {
        filterObj = {
          mode: FilterSettingMode.VALUE,
          config: {value: this.filter.options}
        } as IFilterSetting;
        this.ctrl.activeFilters[this.id] = filterObj;
      }
      if (!filterObj.config) {
        filterObj.config = {value: this.filter.options}
      }
      ;
      (filterObj.config as IFilterSettingConfigValue).value = this.filter.options;
      this.ctrl.refreshFilters();
      this.emitter.emit();
    }
  }, 500, {leading: true});

}
