import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { LangService } from '../../core/lang.service';

export interface IEditSaveCtrl {
  data?: any,
  isEditing?:boolean,
  isSaving?:boolean,
}

export interface IMatrixDef {
  rows: string[],
  cols: string[],
}

type PropContainer = {[key:string]: any};
export const applyPropPatches = (patch:PropContainer, target:PropContainer) => {
  Object.keys(patch).forEach(key => {
    target[key] = patch[key];
  })
}

export const renderMatrixFCKey = (rowId:string, colId:string) => [rowId, colId].join('/');
export const generateMatrixFormControls = (matrixDef:IMatrixDef) => {
  const matrixFormControls:{[key:string]:FormControl} = {};
    matrixDef.rows.forEach(rowId => {
      matrixDef.cols.forEach(colId => {
        const key = renderMatrixFCKey(rowId, colId);
        matrixFormControls[key] = new FormControl();
      })
      
    });
    return matrixFormControls;
}

export class EditSaveFormlet {
  
  buttonCtrl: IEditSaveCtrl = {};
  _changes:Subject<any> = new Subject();  
  changeSubscriptions
  private formValuesBackup: {[key:string]:any};
  
  constructor(
    private form:{ [key:string]:FormControl },
    public save:() => Promise<any>,
  ){}

  public get changes() : Subject<any> {
    return this._changes;
  }

  destroy(){

  }

  getFC(fcKey:string){
    return this.form[fcKey];
  }
  isEditing(){
    return !!this.buttonCtrl.isEditing;
  }
  loadValues(data:{[key:string]:any}){

  }
  editStart(){
    this.formValuesBackup = {};
    Object.keys(this.form).forEach(prop => {
      this.formValuesBackup[prop] = this.form[prop].value;
    })
  }
  editCancel(){
    if (this.formValuesBackup){
      Object.keys(this.formValuesBackup).forEach(prop => {
        this.form[prop].setValue(this.formValuesBackup[prop]);
      })
    }
  }
  restore(){

  }
}

@Component({
  selector: 'edit-save',
  templateUrl: './edit-save.component.html',
  styleUrls: ['./edit-save.component.scss']
})
export class EditSaveComponent implements OnInit {

  @Input() formlet:EditSaveFormlet;
  @Input() model:IEditSaveCtrl;
  @Input() caption:string = this.lang.tra('edit_btn');
  @Input() isInline:boolean;
  @Input() initEditing:boolean = false;
  @Input() isDisabled:boolean;
  @Input() save: (data?:any) => Promise<any>;
  @Input() isSmall: boolean = false;
  @Output() edit = new EventEmitter();

  constructor(
      private lang: LangService
  ) { }

  ngOnInit(): void {
    if (this.formlet){
      this.model = this.formlet.buttonCtrl;
    }
    if (!this.model){
      this.model = {};
    }

    if(this.initEditing) {
      this.editStart();
    }
  }

  editStart(){
    this.model.isEditing = true;
    if (this.formlet){ this.formlet.editStart(); }
    this.edit.emit();
  }
  editSave(){
    let save = this.save;
    if (this.formlet){
      save = this.formlet.save;
    }
    try {
      this.model.isSaving = true;
      save(this.model.data).then(() => {
        this.model.isEditing = false;  
        this.model.isSaving = false;
      })
      .catch(e => {
        this.model.isSaving = false;
      })
    }
    catch(e){
      console.log(e);
      console.error('Save function is not configured');
    }
  } 
  editCancel(){
    this.model.isEditing = false;
    if (this.formlet){ this.formlet.editCancel(); }
  }

}
