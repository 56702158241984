import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { state } from '@angular/animations';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() state:boolean;
  @Input() caption:string;
  @Output() toggle = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {

  }

}
