import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'table-row-selector',
  templateUrl: './table-row-selector.component.html',
  styleUrls: ['./table-row-selector.component.scss']
})
export class TableRowSelectorComponent implements OnInit {

  @Input() entry:any;
  @Input() prop:string = 'isSelected';
  @Input() isInverted:boolean = false;
  @Input() isDisabled:boolean = false;
  @Input() singleSelect:boolean = false;
  @Output() toggle = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (!this.entry){
      this.entry = {};
    }
  }

  isDisplaySelected(){
    if (this.isInverted){
      return ! this.isSelected();
    }
    else{
      return this.isSelected();
    }
  }

  isSelected(){
    return !! this.entry[this.prop];
  }

  _toggle(){
    this.entry[this.prop] = !this.entry[this.prop];
    if (this.entry[this.prop] && this.singleSelect) {
      Object.keys(this.entry).forEach(prop => {
        if (prop.toString() !== this.prop.toString()) {
          this.entry[prop] = false;                                
        }                
      });
    }
    this.toggle.emit(this.entry);
  }

}
