export const indexOf = (arr:any[], t:any) => {
    let i = -1;
    arr.forEach((_t, _i) => {
      if (_t === t){
        i = _i;
      }
    });
    return i;
  }
  

export const mapToJson = (map) =>  {
    return JSON.stringify([...map]);
}

export const isOldFirefoxBrowser = () => {
  const agent = navigator.userAgent
  const index = agent.indexOf("Firefox/")
  if (index !=-1) {
    let substr = agent.substr(index+8)
    const version = Number(substr)
    if (version && version<60) {
      return true
    }
  }
  return false
}