import { WarningModes } from "../../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component";
import { ApiFailCode } from "../../../api/constants/access-controls";


export const parseAttemptLoadErrorMessage = (message: string): {mode:WarningModes | string, isUnknown?:boolean} =>  {
  switch (message) {

    case WarningModes.INVALID_SEB:
    case 'SEB header used but no matching available SEB headers found for this institution':
    case 'SEB header hash matched but is revoked':
    case 'SEB header does not match expected hash':
    case 'Missing SEB header':
      return {mode: WarningModes.INVALID_SEB, isUnknown:true};

    case ApiFailCode.NOT_AUTHENTICATED:
      return {mode: WarningModes.LOGIN};
    
    case 'NO_SECURITY':
      return {mode: 'student_warning_secure'};

    case 'NO_SECURITY_PRIV':
      return {mode: 'student_warning_secure_private'};

    case 'STUDENT_NOT_PAID':
      return {mode: 'eqao_student_not_paid'};

    case 'UNREGISTERED_IP_ADDR':
      return {mode: 'student_warning_unregistered_ip'};
    
    case 'SESSION_CLOSED':
      return {mode: 'msg_need_session_closed'};
    case 'SESSION_ENDED': 
      return {mode: 'msg_session_ended'};

    case 'MISSING_FRAMEWORK': 
      return {mode: 'msg_missing_framework'};
    case ApiFailCode.GROUP_ROLE_REQ_FOR_ROUTE:
      return {mode: WarningModes.NOT_BOOKED_APPL};

    // to do: ideally every 
    case WarningModes.NO_QUESTIONS_FOUND:
    case WarningModes.SELECT_LANG:
    case WarningModes.ACCEPT_ATTEST:
      return {mode: message};

    case 'OUTSIDE_DAILY_ALLOWED_TIME_WINDOW':
      return {mode: 'msg_outside_allowed_daily_time'};
    case 'ATTEMPT_PAUSED':
      return {mode: 'msg_test_attempt_paused'}

    case 'Missing Institution ID':
    default :
      return {mode: WarningModes.UNKNOWN, isUnknown:true};
  }
}