import { IContentElement, IScoredResponse } from "../models";

export interface IContentElementDnd extends IContentElement, IScoredResponse {
    targetType: DndTargetType;
    width?: number;
    height?: number;
    customTargetDim?: boolean;
    defaultTargetStyle: IContentElementDndSub;
    backgroundElements: Array<IContentElementDndBackground>;
    draggables: Array<IContentElementDndDraggable>;
    targets: Array<IContentElementDndTarget>;
    draggableCounter: number;
    targetCounter: number;
    groups: IDndGroup[];
  }
  export interface IDndGroup {
    id: number;
    caption: string;
  }
  export enum DndTargetType {
    DRAGGABLE = "DRAGGABLE",
    TARGET = "TARGET",
    GROUP = "GROUP"
  }
  export interface IContentElementDndSub {
    __uniqueId?: string,
    key_id?: string;
    id?: number;
    x: number;
    y: number;
    width?: number;
    height?: number;
    backgroundColor?: string;
    borderColor?: string;
    borderThickness?: string;
    borderRadius?: number;
    padding?: number;
    verticalAlignment?: string;
    horizontalAlignment?: string;
    voiceover?: any;
    targetId?: number;
  }
  export interface IContentElementDndBackground extends IContentElementDndSub {
    element: IContentElement;
  }
  export interface IContentElementDndDraggable extends IContentElementDndSub {
    element: IContentElement;
    targetId?: number;
    key_id?: string;
    groupId?: number;
    voiceover?: any;
    containerBackgroundColor?: string;
    exactDraggableRequired?: number;
    minRequired?: number;
    maxRequired?: number;
    targetID2Amount?: any;
    label?: string;
    sortOrder?: number;
    partialWeight?: number;
  }
  export interface IContentElementDndTarget extends IContentElementDndSub {
    draggableId?: number;
    groupId?: number;
    key_id?:string,
    targetId?:number;
  }

  export interface IElementPos {
    ref: IContentElementDndSub;
    originalX: number;
    originalY: number;
    isTarget?: boolean;
    isDraggable?: boolean;
    style: {
      [key: string]: string | number
    };
  }
  export interface KeyboardDrop{
    lastSrcIndex: number | null,
    sourceElement: IElementPos | {},
    source: IElementPos[],
    srcFromHome?: boolean | null
  }

  export const dndEditInfo = {
    editExcludeFields: ['defaultTargetStyle', 'backgroundElements', 'draggables', 'targets']
  }