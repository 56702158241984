import * as moment from 'moment-timezone';
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { AuthService, IUserInfo } from '../api/auth.service';
import { BehaviorSubject } from 'rxjs';
import { LoginGuardService } from '../api/login-guard.service';
import { RoutesService } from '../api/routes.service';
import { Router } from '@angular/router';
import { AccountType } from '../constants/account-types';
import { LangService } from '../core/lang.service';
import { EDeliveryOption } from '../api/models/db/test-sessions.schema';
import { ITestSessionAvailTT } from '../api/models/db/test-session-avail-tt.schema';
import { SafeResourceUrl } from '@angular/platform-browser';
import { WhitelabelService } from '../domain/whitelabel.service';

export interface IMyApplAcct {
  uid: number,
  name: string,
  capId: string,
}
export enum InvigLang {
  FRENCH_INVIG = 'French Invigilation',
  ENGLISH_INVIG = 'English Invigilation',
  BILINGUAL_INVIG = 'Bilingual Invigilation (English and French) '
}

export interface ISessionTT {
  m: moment.Moment;
  id:number,
  sessionNum:number,
  instGroupId:number,
  testWindowTitle: string,
  dateDisplay?: string,
  timeFrom: string,
  institutionName?: string,
  testLocation?: string,
  hasAccessCode: boolean, 
  status: string,
  timeZone: string,
  isByod: boolean,
  isFull: boolean,
  isExpired?: boolean,
  isFilteredOut?:boolean,
  test_attempt_id?:number,
  delivery_format?:string,
  videostream_link?:string,
  videostream_password?:string,
  //
  videostreamSafeUrl?:SafeResourceUrl, // tacked on in the client
}

@Injectable({
  providedIn: 'root'
})
export class MyApplAccountService {

  constructor(
    private auth: AuthService,
    private loginGuard: LoginGuardService,
    private routes: RoutesService,
    private router: Router,
    private lang: LangService,
    private whiteLabel: WhitelabelService,
  ) { 
    this.init();
  }

  private info:BehaviorSubject<IMyApplAcct> = new BehaviorSubject(null);

  private init(){
    this.auth.user().subscribe(userInfo => {
      if(userInfo){
        this.info.next({
          uid: userInfo.uid,
          name: userInfo.firstName + ' ' + userInfo.lastName,
          capId: '224466',
        });
      }
      else{
        this.info.next(null);
      }
    })
  }

  public sub(){
    return this.info;
  }

  public getDisplayName(){
    return this.info.getValue().name
  }

  public async sendStripeSessionForAppeal() {
    const lang_code = this.lang.c();
    const currentDomain = window.location.href;
    let domainSplit = currentDomain.split('/');
    const attemptId = parseInt(domainSplit[domainSplit.length - 1]);
    const type = 'APPEAL';
    const body = {type, attemptId, lang_code};
    const stripeCheckout = await this.auth.apiCreate(
      this.routes.TEST_TAKER_TEST_SESSIONS_STRIPE_CHECKOUT,
      body
    );
    location.assign(stripeCheckout.url);
  }

  public async sendStripeSessionForCreditPurchase(test_session_id: number, access_code: string, lang: string, isAccommReq: boolean) {
    const lang_code = lang;
    const isAccomm = isAccommReq ? 1 : 0;
    const type = 'CREDIT_PURCHASE';
    const body = {test_session_id, type, lang_code, isAccomm}
    const stripeCheckout = await this.auth.apiCreate(
      this.routes.TEST_TAKER_TEST_SESSIONS_STRIPE_CHECKOUT,
      body
    );
    location.assign(stripeCheckout.url);
  }
  
  bookSession(test_session_id:number, access_code: string){
    return this.auth.apiCreate(
      this.routes.TEST_TAKER_TEST_SESSIONS_BOOKING,
      { test_session_id, access_code }
    )
    .then( () => { this.gotoDashboard() })
    .catch( e => {
      if (e.message === 'INVALID_ACCESS_CODE'){
        const caption = 'You have provided an invalid access code for this test session.'
        this.loginGuard.confirmationReqActivate({caption, confirm: ()=>{} })
      }
      else if (e.message === 'TEST_SESSION_FULL'){
        const caption = 'The test session has become full since the time you loaded this page. You may refresh this page to see updated availability or select another session.';
        this.loginGuard.confirmationReqActivate({caption, confirm: ()=>{} })
      }
      else if (e.message === 'TEST_SESSION_BUFFER_CLOSED'){
        const caption = 'This session is closed for bookings. Please contact the test centre if you believe that the bookings have been erroneously closed early.';
        this.loginGuard.confirmationReqActivate({caption, confirm: ()=>{} })
      }
      else{
        const caption = 'We are unable to register you to the test session at this time. Please verify that you are not already registered for a test session and try again (press "OK" to be redirected to the dashboard). If the problem persists, please contact support.';
        this.loginGuard.confirmationReqActivate({caption, confirm: this.gotoDashboard })
      }
      throw e;
    })
  }

  waitlistSession(test_session_id:number, access_code: string){
    return this.auth.apiCreate(
      this.routes.TEST_TAKER_TEST_SESSIONS_WAITLIST,
      { test_session_id, access_code }
    )
    .then( () => {
      this.gotoDashboard()
    })
    .catch( e => {
      if (e.message === 'INVALID_ACCESS_CODE'){
        const caption = 'You have provided an invalid access code for this test session.'
        this.loginGuard.confirmationReqActivate({caption, confirm: ()=>{} })
      }
      else{
        const caption = 'We are unable to add you to the test session waitlist at this time. Please verify that you are not already registered for a test session and try again (press "OK" to be redirected to the dashboard). If the problem persists, please contact support.';
        this.loginGuard.confirmationReqActivate({caption, confirm: this.gotoDashboard })
      }
      throw e;
    })
  }

  gotoDashboard = () => {
    this.router.navigate([`/${this.lang.c()}/${AccountType.TEST_TAKER}/dashboard`]);
  }

  checkIfCreditsEnabled = () => {
    return this.auth.apiFind(this.routes.CREDITS_CREDIT_SYSTEM);
  }

  getUserCreditInfo = () => {
    return this.auth.apiFind(this.routes.TEST_TAKER_CREDIT_DETAILS);
  }

  checkIfPaymentsEnabled = () => {
    return this.auth.apiFind(this.routes.CREDITS_PAYMENT_SYSTEM);
  }

  sanitizeSessionInfo(session:ITestSessionAvailTT, institutionName:string = '') : ISessionTT {
    const timezone = moment.tz.guess();
    const m = moment.tz(session.date_time_start, timezone);
    // '3:00pm, Sept. 16, 2020 (Wednesday)'
    const FMT_DATE_DAY_DISPLAY = this.lang.tra('datefmt_day_month_year_dow');
    const FMT_DATE_TIME_DISPLAY = this.lang.tra('datefmt_time');
    let testWindowTitle;
    try {
      const titles = JSON.parse(session.test_window_title);
      testWindowTitle = titles[this.lang.c()];
    }
    catch(e){}
    // window['moment'] = moment;
    // console.log('guess', moment.tz.guess())
    return {
        m,
        id:session.id,
        delivery_format: session.delivery_format,
        videostream_link: session.videostream_link,
        videostream_password: session.videostream_password,
        sessionNum:0,
        instGroupId:session.instit_group_id,
        dateDisplay: m.format(FMT_DATE_DAY_DISPLAY),
        timeFrom: m.format(FMT_DATE_TIME_DISPLAY),
        timeZone: moment.tz.guess(),
        institutionName,
        testWindowTitle,
        status: session.status,
        testLocation: [
          `${this.lang.tra('cts_location_room_lbl')} ${session.room}, ${session.campus_building}`,
          `${session.address}`,
          `${session.city} ${session.province} ${session.postal_code}`,
          `${session.phone_number}`,
        ].join('\n'),
        hasAccessCode: session.is_access_code_enabled === 1, 
        isByod: session.delivery_format === EDeliveryOption.BYOD,
        isFull: session.booked ? session.booked >= session.capacity : false,
    }
  }

}
