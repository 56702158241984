export const indexOf = (arr:any[], t:any) => {
    let i = -1;
    arr.forEach((_t, _i) => {
      if (_t === t){
        i = _i;
      }
    });
    return i;
  }
  

export const mapToJson = (map) =>  {
    return JSON.stringify([...map]);
}


export const compareArrElPropOutSync = <T, P extends keyof T>(arr1: T[], arr2: T[], prop: P) => {                        
  return arr1.some((arr1El, index) => arr1El[prop] !== arr2[index][prop]) 
}