import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';


export interface PageSwitch {
  canvasId?: string,
  pageNum?: number
}

@Injectable({
  providedIn: 'root'
})
export class CanvasService {
  
    private  bumpStore
    public canvasPageNumChanged:BehaviorSubject<PageSwitch> = new BehaviorSubject<PageSwitch>({});
  
    constructor() {
      this.canvasPageNumChanged.subscribe(data => this.bumpStore = data)
    }

    public bump(){
      this.canvasPageNumChanged.next(this.bumpStore);
    }
}