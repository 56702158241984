import { Moment } from "moment";
import { FsaDataLoad, PaginatedRows } from "../bc-accounts/bc-accounts.service";


export enum ReportType {
    SCHOOL_COMPLETION = 'school completion',
    DISTRICT_COMPLETION = 'district completion',
    LOGIN_BY_STUDENT = 'login by student',
    LOGIN_BY_SCHOOL = 'login by school',
    LOGIN_BY_DISTRICT = 'login by district',
    LOGIN_BY_PROVINCE = 'login by province',
    REGISTRATION_BY_SCHOOL = 'registration by school',
    REGISTRATION_BY_DISTRICT = 'registration by district',
    REGISTRATION_BY_PROVINCE = 'registration by province',
    SUBMISSION_BY_SCHOOL = 'submission by school',
    SUBMISSION_BY_DISTRICT = 'submission by district',
    SUBMISSION_BY_PROVINCE = 'submission by province',
    FILTERED_BY_STUDENT = 'filtered by student',
    REGISTERED_STUDENTS = 'registered students',
    LOGGED_IN_STUDENTS = 'logged in students',
    SUBMITTED_STUDENTS = 'submitted students',
    STAGE_ONE = 'stage one',
    STAGE_TWO = 'stage two',
    STAGE_THREE = 'stage three',
    STAGE_FOUR = 'stage four',
    STUDENT_ASSESSMENT_RESULT = 'student assessment result',
    SCHOOL_LEVEL_DATA_REPORT = 'school_level_data_report',
    INDIVIDUAL_RAW_SCORES = 'individual raw scores',
    INDIVIDUAL_SCALED_SCORES = 'individual scaled scores',
    COGNITIVE_LEVEL_SCORES = 'cognitive level scores',
    STUDENT_SELF_REFLECTION = 'student self reflection',
    ITEM_DATE_REPORT = 'item data report',
    DAILY_SUBMISSION_SUMMARY = 'daily submission summary',
    DISTRICT_ROLL_UP = 'district roll up report',
    ENROLLMENT_EXTRACT_1300_C = 'enrollment extract 1300 c',
    ENROLLMENT_EXTRACT_1300_D = 'enrollment extract 1300 d',
    SCHOOLS_NOT_STARTED = 'schools not started',
    STUDENT_LOGIN = 'student login',
    STUDENT_RESPONSE_TIME = 'student response times',
    DAILY_DISTRICT_SUBMISSION = 'daily district submission',
    CONFIRMATION_CODEs = 'confirmation codes',
}

export interface DailySubmissionSummaryReportRow {
    district_group_id: number,
    district_name: string,
    district_foreign_id: number,
    total_submissions: number,
    total_logins: number,
}

export interface DailySubmissionSummaryReport {
    other_districts: PaginatedRows<DailySubmissionSummaryReportRow>,
    all_districts: DailySubmissionSummaryReportRow,
}

export interface DailySubmissionSummaryReportParams {
    start_date: Moment
    end_date: Moment
}

export interface RollUpReportRow {
    grade: number,
    enrollment: number,
    sr_literacy: number,
    sr_numeracy: number,
    cr_score_entry: number,
}

export interface DistrictRollUpReportRow extends RollUpReportRow {
    district_group_id: number,
    district_name: string,
    district_foreign_id: number,
    _isInfo?: any,
    _isOdd?: any,
}

export interface DistrictRollUpReport {
    districts: PaginatedRows<DistrictRollUpReportRow>,
    provincial: { [key: string]: RollUpReportRow },
}

export interface DistrictRollUpReportParams {
    test_window_id: number,
    grades: (4 | 7)[],
}

export interface EnrollmentExtract1300CReportRow {
    school_type: string,
    facility_type: string,
    english_g4: number,
    english_g7: number,
    french_g4: number,
    french_g7: number,
    total_schools: number,
    participating_schools: number,
}

export interface EnrollmentExtract1300CReportParams {
    load: 'sept' | 'feb',
}

export interface EnrollmentExtract1300DReportRow {
    district_group_id: number,
    district_foreign_id: number,

    school_group_id: number,
    school_name: string,
    school_foreign_id: number,
    is_participating: boolean,

    english_g4: number,
    english_g7: number,

    french_g4: number,
    french_g7: number,

    total_g4: number,
    total_g7: number,
}

export interface EnrollmentExtract1300DReportParams {
    load: FsaDataLoad,
    district_group_id: number,
}

export interface EnrollmentExtract1300DReportExportParams {
    district_group_id: number,
    district_name: string,
}


export interface CompletionReportRow {
    grade: number,
    enrollment: number,
    sr_literacy: number,
    sr_numeracy: number,
    cr_literacy: number,
    cr_numeracy: number,
}

export interface SchoolCompletionReportRow extends CompletionReportRow {
    school_group_id: number,
    school_foreign_id: number,
    school_name: string,
    school_type: string,
    _isInfo?: any,
    _isOdd?: any,
}

export interface SchoolCompletionReport {
    schools: PaginatedRows<SchoolCompletionReportRow>,
    district: { [key: string]: CompletionReportRow }
}

export interface SchoolCompletionReportParams {
    district_group_id: number,
    school_group_id?: number,
    test_window_id: number,
    grades: (4 | 7)[],
}

export interface SchoolCompletionReportExportParams {
    district_group_id: number,
    district_name: string,
    test_window_id: number,
}

export interface SchoolCompletionReport {
    schools: PaginatedRows<SchoolCompletionReportRow>,
    district: { [key: string]: CompletionReportRow }
}

export interface SchoolsNotStartedReportRow {
    school_group_id: number,
    school_foreign_id: number,
    school_name: string,
    school_type: string,
    grade: number,
    enrollment: number,
    sr_literacy: number,
    sr_numeracy: number,
    cr_score_entry: number,
    _isInfo?: any,
    _isOdd?: any,
}

export interface SchoolsNotStartedReportExportParams {
    district_group_id: number,
    district_name: string,
    test_window_id: number,
}

export interface SchoolsNotStartedReportParams {
    grades: (4 | 7)[],
    district_group_id: number,
    school_group_id?: number,
    test_window_id: number,
}


export enum IndividualRawScoresProficiencyLevel {
    PLU = '-',
    EMERGING = 'EM',
    ON_TRACK = 'OT',
    EXTENDING = 'EX',
}

export interface IndividualRawScoresRow {
    last_name: string,
    first_name: string,
    pen: string,
    grade: number,

    li_sr_attempted: number,
    li_sr_score: number,
    li_1_score: number,
    li_2_score: number,
    li_3_score: number,
    li_proficiency_level: IndividualRawScoresProficiencyLevel,

    nu_sr_attempted: number,
    nu_sr_score: number,
    nu_1_score: number,
    nu_2_score: number,
    nu_3_score: number,
    nu_proficiency_level: IndividualRawScoresProficiencyLevel,
}

export interface IndividualRawScoresReport {
    school_group_id: number,
    school_code: number,
    school_name: string,
    is_paginated: boolean,
    data: PaginatedRows<IndividualRawScoresRow>,
}

export interface IndividualRawScoresReportParams {
    school_group_id: number,
    test_window_id: number,
    school_name: string,
    school_code: number,
}

export interface IndividualScaledScoresReportParams {
    school_group_id: number,
    test_window_id: number,
    school_name: string,
    school_code: number,
}


export interface CognitiveLevelScoresRow {
    uid: number,
    last_name: string,
    first_name: string,
    pen: string,
    grade: number,

    l1_score: number,
    l1_weight: number,
    l2_score: number,
    l2_weight: number
    l3_score: number,
    l3_weight: number
    n1_score: number,
    n1_weight: number
    n2_score: number,
    n2_weight: number,
    n3_score: number,
    n3_weight: number
}

export interface CognitiveLevelScoresReport {
    school_group_id: number,
    school_code: number,
    school_name: string,
    is_paginated: boolean,
    data: PaginatedRows<CognitiveLevelScoresRow>,
}

export interface CognitiveLevelScoresReportParams {
    school_group_id: number,
    test_window_id: number,
    school_name: string,
    school_code: number,
}


export interface StudentSelfReflectionRow {
    uid: number,
    last_name: string,
    first_name: string,
    pen: string,
    grade: number,

    literacy: string,
    numeracy: string,
}

export interface StudentSelfReflectionReport {
    school_group_id: number,
    school_code: number,
    school_name: string,
    is_paginated: boolean,
    data: PaginatedRows<StudentSelfReflectionRow>,
}

export interface StudentSelfReflectionReportParams {
    school_group_id: number,
    test_window_id: number,
    school_name: string,
    school_code: number,
}

export interface StudentLoginReportRow {
    district_group_id: number,
    school_group_id: number,
    school_foreign_id: number,
    school_name: string,

    uid: number,
    first_name: string,
    last_name: string,
    pen: string,
    test_session_slug: string,

    sr_attempted: number,
    sr_completed: number,
    is_submitted: number,
    started_on: string,
    completed_on: string

    confirmation_code?: string,
    has_unsubmissions: number,
}

export interface StudentLoginReportParams {
    district_group_id: number,
    school_group_id?: number,
    test_window_id: number
}

export interface StudentLoginReportExportParams {
    test_window_id: number,
}

export interface StudentResponseTimesReportRow {
    district_group_id: number,
    school_group_id: number,
    school_foreign_id: number,
    school_name: string,

    uid: number,
    first_name: string,
    last_name: string,
    pen: string,
    test_session_slug: string,
}

export interface StudentResponseTimesReportParams {
    district_group_id: number,
    school_group_id?: number,
    test_window_id: number
}

export interface StudentResponseTimesReportExportParams {
    test_window_id: number
}

export interface DailySubmissionReportRow {
    enrollment: number,
    expected_tests: number,
    submissions: number,
}

export interface DailyDistrictSubmissionReportRow extends DailySubmissionReportRow {
    district_group_id: number,
    district_foreign_id: number,
    district_name: string,
}

export interface DailyDistrictSubmissionReport {
    districts: PaginatedRows<DailyDistrictSubmissionReportRow>,
    public: DailySubmissionReportRow,
    independent: DailySubmissionReportRow,
}

export interface DailyDistrictSubmissionReportParams {
    test_window_id: number,
    start_date: string,
    end_date: string,
}

export interface ConfirmationCodeReportRow {
    district_group_id: number,
    district_foreign_id: number,
    school_group_id: number,
    school_foreign_id: number,
    school_name: string,

    uid: number,
    last_name: string,
    first_name: string,
    pen: string,
    grade: number,
    slug: string,
    confirmation_code: string,
    created_on: string,
    has_unsubmissions: number,

}

export interface ConfirmationCodeReportParams {
    test_window_id: number,
    district_group_id?: number,
    school_group_id?: number,
}