import { API_ADDRESS_LOCAL, API_ADDRESS_LOCALB } from "../../api/api-addr/l";
import { IContextData } from "./common";

export const ANON:IContextData = {
  id: 'ANON',
  homepageRoute: '/',
  brandName: 'Vretta eAssessment',
  logo: '',
  footer: null,
  apiAddress: (hostname:string) => {
    switch(hostname){
      case 'localhost': 
        return 'https://nbed-api.vretta.com';
        // return 'https://eassessment-qc-api.vretta.com'
        // return API_ADDRESS_LOCALB;
      case 'nbed-qc.vretta.com':
        return "https://nbed-qc-api.vretta.com"
      case 'nbed-sso.vretta.com':
        return "https://nbed-sso-api.vretta.com"
      case 'nbed-elpa.vretta.com':
        return "https://nbed-elpa-api.vretta.com"  
      default: 
        return 'https://nbed-api.vretta.com';
    }
  },
  ssoAddress: (hostname:string)=>{
    switch(hostname){
      case 'localhost':
        return 'http://localhost:3000/auth';
      default:
        return 'https://vea-sso-nbed.vretta.com/auth';
    }
  },
  siteFlags: {
    'IS_VEA': true,
    'IS_NBED': true, // until distinct whitelabel is created
    'INST_MNG_TT': true,
    'TEST_CTRL_REPORT_EXPLR': true,
    'TEST_CTRL_DATA_QUERY': true,
    'IS_TC_SUMM': true,
    'IS_SMCS': true,  // for St.Michael's College School
    'IS_TTS_SOMETIMES' : true,
    'IS_LANG_HEADER_SWITCH': true,
    'IS_ROLE_BASED_ACCESS': true,
    'IS_STU_WORDCOUNT_TOGGLE': true, // todo: currently only works with IS_STU_WORDCOUNT_SIMPLE
    'IS_STU_WORDCOUNT_SIMPLE': true,
    'IS_STU_ESSAY_SPACING': true,
    'IS_STU_ESSAY_PREVIEW': true,
  },
  siteText: {
    copyright: ' © Vretta Inc. 2024',
    supportEmail: 'support@vretta.com',
    supportPhone: '',
    my_inst: 'My School',
    my_inst_admin: 'You are the **administrator** of this school.',
    my_inst_manage_acct: 'Manage Teachers+',
    currentLogo: 'https://www.vretta.com/logo.svg',
    STU_ACCOMM_TTS_SLUGS: 'accomm_text_to_speech',
    st_michaels_logo: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/392800/logo_vretta.png'
  },
  siteData: {
    'TEACHER_SUPPORT': {
      text: 'txt_support_popup_nbed',
      email: '',
      phone:'',
    }
  },
  hosts: [
      'd29ysxw1a93y08.cloudfront.net', // prod
      'd3cbjew6bdqtwz.cloudfront.net', // qc
  ],
  defaultTimezone: 'America/Vancouver',
}
