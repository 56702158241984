<div class="translation-w" cdkDrag>
    <div class="translation-w-container">
        <div class="translation-w-header" cdkDragHandle>
            <div class="header-box">
                <i class="fas fa-globe"></i>
                <span style="margin-left: 0.3em;"> {{translationWidgetTitle}} </span>                
            </div>            
        </div>
        <div class="translation-w-main" *ngIf="!getMetaControlsVal('isInitializing')">
            <div class="translation-w-box" *ngIf="isToTranslateTextBoxVisible">
                <div class="box-title">
                    <span style="padding: 0.5em;">{{sourceLangLabel}}</span>
                </div>
                <textarea 
                    name="translate-from"                     
                    class="box-textarea"
                    [placeholder]="translateFromPlaceholder"
                    [(ngModel)]="sourceText"
                ></textarea>
            </div>
            <button 
                *ngIf="isTranslateBtnVisible"
                class="button is-small translate-button"
                (click)="handleTranslateBtn()"
                [disabled]="getMetaControlsVal('isTranslating')"
            >
                {{translateBtnLabel}}
            </button>
            <div class="translation-w-box">
                <div class="box-title select" *ngIf="!showTranslatedFullScreen">
                    <select [(ngModel)]="targetLanguageCode" [class.is-default]="isLangSelectPlaceholder">
                        <!--Placeholder Option-->
                        <option [ngValue]="undefined"  disabled selected> {{ selectLanguageLabel }}... </option>
                        <option *ngFor="let lang of languages" [value]="lang.lang_code"> 
                            {{ lang.language }}
                        </option>
                    </select>
                </div>
                <div class="translated-w-box">                    
                    <textarea
                        name="translate-to"                        
                        class="translated-box-textarea"
                        [placeholder]="translateToPlaceholder"
                        [ngModel]="translatedText"
                    ></textarea>
                    <div class="box-fullscreen-btn">
                        <div *ngIf="showTranslatedFullScreen" (click)="toggleTranslatedFullScreen()">
                            <img
                                src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/UI_Elements/translation-widget/minimize.svg">
                        </div>
                        <div *ngIf="!showTranslatedFullScreen" (click)="toggleTranslatedFullScreen()">
                            <img
                                src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/UI_Elements/translation-widget/expand.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>