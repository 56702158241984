import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BcAccountsService, DistrictDetail, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcReportsService } from 'src/app/bc-reports/bc-reports.service';
import { CognitiveLevelScoresReport, IndividualRawScoresProficiencyLevel, IndividualRawScoresReport, StudentSelfReflectionReport } from 'src/app/bc-reports/types';
import { AccountType } from 'src/app/constants/account-types';

enum Sheet {
  INDIVIDUAL_RAW_SCORES = 'sa_fr_issr_raw_scores',
  COGNITIVE_LEVEL_SCORES = 'sa_fr_issr_level_scores',
  STUDENT_SELF_REFLECTION = 'sa_fr_issr_self_reflection',
}

const INDIVIDUAL_SCALED_SCORES_STR = 'Individual Scaled Scores';

@Component({
  selector: 'school-level-data-report',
  templateUrl: './school-level-data-report.component.html',
  styleUrls: ['./school-level-data-report.component.scss']
})
export class SchoolLevelDataReportComponent implements OnInit {
  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;
  @Input() accountType: AccountType = AccountType.BC_FSA_SCHOOL_ADMIN;

  isScaled: boolean = false;

  pagination: Pagination;
  individualRawScoresReport: IndividualRawScoresReport;
  cognitiveLevelScoresReport: CognitiveLevelScoresReport;
  studentSelfReflectionReport: StudentSelfReflectionReport;

  Sheet = Sheet;
  sheet: Sheet = Sheet.INDIVIDUAL_RAW_SCORES;

  isLoading: boolean = false;

  constructor(
    private bcAccounts: BcAccountsService,
    private bcReports: BcReportsService,
  ) { }

  ngOnInit(): void {
    this.pagination = this.getInitialPagination();
    this.updateTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.schoolDetail || changes.testWindow) {
      this.updateTable();
    }
  }

  async updateTable() {
    this.isLoading = true;

    if (this.schoolDetail) {
      if (this.sheet === Sheet.INDIVIDUAL_RAW_SCORES) {
        this.individualRawScoresReport = await this.bcReports.getIndividualRawScoresReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.individualRawScoresReport.data.count;
      } else if (this.sheet === Sheet.COGNITIVE_LEVEL_SCORES) {
        this.cognitiveLevelScoresReport = await this.bcReports.getCognitiveLevelScoresReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.cognitiveLevelScoresReport.data.count;
      } else if (this.sheet === Sheet.STUDENT_SELF_REFLECTION) {
        this.studentSelfReflectionReport = await this.bcReports.getStudentSelfReflectionReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.studentSelfReflectionReport.data.count;
      }
    }

    this.isLoading = false;
  }

  selectSheet(sheet: Sheet) {
    this.sheet = sheet;
    this.pagination = this.bcAccounts.getInitialPagination();
    this.updateTable();
  }

  getSheetName(sheet: Sheet) {
    if (this.isScaled && sheet == Sheet.INDIVIDUAL_RAW_SCORES) {
      return INDIVIDUAL_SCALED_SCORES_STR;
    }
    else {
      return sheet;
    }
  }

  sumScore(srScore: string | number, crScore1: string | number, crScore2: string | number, crScore3: string | number): string {
    if (srScore == 'NR' && crScore1 == 'NR' && crScore2 == 'NR' && crScore3 == "NR") {
      return 'NR';
    } else {
      return [srScore, crScore1, crScore2, crScore3].filter(score => score != 'NR').reduce((sum: number, score: string | number) => {
        return sum + parseInt(score.toString());
      }, 0).toString();
    }
  }

  proficiencyLevelRange = {
    'English': {
      4: {
        'literacy': [0, 17, 31],
        'numeracy': [0, 16, 34],
      },
      7: {
        'literacy': [0, 16, 34],
        'numeracy': [0, 16, 32],
      },
    },
    'French': {
      4: {
        'literacy': [0, 15, 27],
        'numeracy': [0, 16, 34],
      },
      7: {
        'literacy': [0, 16, 29],
        'numeracy': [0, 16, 32],
      },
    },
  }

  proficiencyLevels = [
    IndividualRawScoresProficiencyLevel.PLU,
    IndividualRawScoresProficiencyLevel.EMERGING,
    IndividualRawScoresProficiencyLevel.ON_TRACK,
    IndividualRawScoresProficiencyLevel.EXTENDING,
  ];

  getProficiencyLevel(language: 'English' | 'French', grade: 4 | 7, component: 'literacy' | 'numeracy', totalScore: string | number) {
    if (totalScore == 'NR') return this.proficiencyLevels[0];
    const _totalScore = parseInt(totalScore.toString());

    let languageRange = this.proficiencyLevelRange[language];
    if (!languageRange) throw new Error;
    let gradeRange = languageRange[grade];
    if (!gradeRange) throw new Error;
    let range = gradeRange[component];
    if (!range) throw new Error;

    let level = this.proficiencyLevels[this.proficiencyLevels.length - 1]; // EX
    for (let i = 0; i < range.length - 1; i++) {
      if (_totalScore >= range[i] && _totalScore < range[i + 1]) {
        level = this.proficiencyLevels[i + 1];
        break;
      }
    }
    return level;
  }

  async export() {
    if (!this.schoolDetail) return;
    await this.bcAccounts.exportSchoolLevelDataReport(this.schoolDetail.groupId, this.testWindow, this.isScaled);
  }

  private getInitialPagination(): Pagination {
    let pagination = this.bcAccounts.getInitialPagination();
    if (this.sheet === Sheet.INDIVIDUAL_RAW_SCORES) {
      pagination.size = 100;
    }
    return pagination;
  }

}
