import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription, SubscriptionLike } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { BcAccountsService, DistrictDetail, School, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { MyBoardService } from 'src/app/ui-dist-admin/my-board.service';
import { MySchoolService } from 'src/app/ui-schooladmin/my-school.service';

export interface ButtonConfig {
  title?: string,
  tra?: string,
  style?: { [key: string]: string },
  buttonId: string,
}

@Component({
  selector: 'bc-header-layout',
  templateUrl: './bc-header-layout.component.html',
  styleUrls: ['./bc-header-layout.component.scss']
})
export class BcHeaderLayoutComponent implements OnInit {


  @Input() title: string;
  @Input() tra: string;
  @Input() imgSrc: string;
  @Input() accountType: AccountType = AccountType.BC_FSA_SCHOOL_ADMIN;
  @Input() phantom: boolean = false;

  @Input() buttons: ButtonConfig[] = [];
  @Input() showInactiveWindows: boolean = false;

  @Output() school = new EventEmitter();
  @Output() district = new EventEmitter();
  @Output() testWindow = new EventEmitter();
  @Output() button = new EventEmitter();

  schools: School[];
  selectedSchool: School;

  testWindows: TestWindow[];
  selectedTestWindow: TestWindow;

  isLoading: boolean = false;

  mySchoolSubscription: Subscription;
  myBoardSubscription: Subscription;

  disableAssessmentSessions = false; // deprecated
  disableSchoolSelections = false; // deprecated

  routeSubcription: Subscription;

  schoolDetail: SchoolDetail;
  districtDetail: DistrictDetail;

  constructor(
    private auth: AuthService,
    private bcAccounts: BcAccountsService,
    private bcAssessments: BcAssessmentsService,
    private mySchool: MySchoolService,
    private myBoard: MyBoardService,
    private lang: LangService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {

    const url = this.route.snapshot.url.toString();

    // if(this.isDistrictAdmin() || (this.isSchoolAdmin() && url.includes('final_results'))){
    //   this.disableSchoolSelections = false;
    // }
    //     testWindows.forEach(tw => {
    //       if (tw.title.en !== "2020-2021 Foundation Skills Assessment Session") {
    //         testWindowResults.push(tw);
    //       }
    //     });


    this.loadDistrictAndSchools();

    // if (url.includes("final_results")) {
    //   this.bcAssessments.findAllTestWindows(AssessmentType.FSA, false, false).then(testWindows => {
    //     this.testWindows = testWindows;
    //     this.selectedTestWindow = this.testWindows.find(tw => tw.is_active);
    //     if (!this.selectedTestWindow) {
    //       this.selectedTestWindow = this.testWindows[this.testWindows.length - 1];
    //     }
    //     this.disableAssessmentSessions = false;
    //     this.onSelectedTestWindowChange();
    //   })
    // } else {
      this.bcAssessments.findAllTestWindows(AssessmentType.FSA, false, false).then(testWindows => {
        const activeTestWindow = testWindows.find(tw => tw.is_active);
        this.selectedTestWindow = activeTestWindow;

        if (this.showInactiveWindows){
          this.testWindows = testWindows;  
          if (!this.selectedTestWindow) {
            this.selectedTestWindow = testWindows[testWindows.length - 1];
          }
        }
        else{
          this.testWindows = [this.selectedTestWindow];
        }

        this.onSelectedTestWindowChange();
      })
    // }
  }

  isSchoolAdmin(): boolean {
    return this.auth.isSchoolAdmin(this.accountType);
  }
  isDistrictAdmin(): boolean {
    return this.auth.isDistrictAdmin(this.accountType);
  }

  ngOnDestroy() {
    if (this.mySchoolSubscription) this.mySchoolSubscription.unsubscribe();
    if (this.myBoardSubscription) this.myBoardSubscription.unsubscribe();
    if (this.routeSubcription) this.routeSubcription.unsubscribe();
  }

  loadDistrictAndSchools() {
    // Todo: once api is configured, ask for this admin's accessible schools
    // temporarily assume this school admin has access to all schools in the district.
    if (this.auth.isSchoolAdmin(this.accountType)) {

      if (this.mySchoolSubscription) this.mySchoolSubscription.unsubscribe();
      this.mySchoolSubscription = this.mySchool.sub().subscribe(groupList => {
        if (!groupList || groupList.length === 0) return;

        const schl_group_id = groupList[0].group_id;
        const schl_group_ids = groupList.map(g => +g.group_id);

        this.bcAccounts.getSchoolDetail(schl_group_id).then(schoolDetail => {
          this.schoolDetail = schoolDetail;
          const dist_group_id = schoolDetail.district.groupId;

          this.bcAccounts.getDistrictDetail(dist_group_id).then(districtDetail => {
            this.districtDetail = districtDetail;
            this.onDistrictDetailReady();
          });

          this.bcAccounts.findSchools(dist_group_id, 'fsa').then(schools => {
            this.schools = [];
            schools.forEach(school => {
              if (schl_group_ids.indexOf(+school.groupId) !== -1){
                this.schools.push(school)
              }
            })

            this.coerceSchoolFromQueryParams();
          })
        })

      })

    }

    else if (this.auth.isDistrictAdmin(this.accountType)) {
      if (this.myBoardSubscription) this.myBoardSubscription.unsubscribe();
      this.myBoardSubscription = this.myBoard.sub().subscribe(groupList => {
        if (!groupList || groupList.length === 0) return;

        const dist_group_id = groupList[0].group_id;

        this.bcAccounts.getDistrictDetail(dist_group_id).then(districtDetail => {
          this.districtDetail = districtDetail;
          this.onDistrictDetailReady();
        });

        this.bcAccounts.findSchools(dist_group_id, AssessmentType.FSA).then(schools => {
          this.schools = schools;

          this.coerceSchoolFromQueryParams();
        })
      })
    }
  }

  private coerceSchoolFromQueryParams() {
    if (this.routeSubcription) this.routeSubcription.unsubscribe();
    this.routeSubcription = this.route.queryParams.subscribe(params => {
      let school = params.school;
      if (school) {
        let match = this.schools.find(s => s.groupId === +school);
        if (match) {
          this.selectedSchool = match;
        } else {
          this.selectedSchool = this.schools[0];
        }
      } else {
        this.selectedSchool = this.schools[0];
      }
      this.onSelectedSchoolChange();
      if (this.routeSubcription) this.routeSubcription.unsubscribe();
    })
  }

  onDistrictDetailReady() {
    this.district.emit(this.districtDetail);
  }

  onSelectedSchoolChange() {
    this.school.emit(this.selectedSchool);
    this.updateQueryParams();
  }

  onSelectedTestWindowChange() {
    this.testWindow.emit(this.selectedTestWindow);
  }

  getSchoolDisplay = (school: School): string => {
    if (!school) return '';
    return this.bcAccounts.getSchoolDisplay(school);
  }

  getTestWindowDisplay(testWindow: TestWindow): string {
    return this.bcAssessments.getTestWindowTitle(testWindow);
  }

  getTitle(): string {
    if (this.tra) {
      return this.lang.tra(this.tra);
    }
    return this.title;
  }

  extraButtonClicked(id: string) {
    this.button.emit(id);
  }

  getExtraButtonTitle(config: ButtonConfig): string {
    if (config.tra) {
      return this.lang.tra(config.tra);
    }
    return config.title || '';
  }

  forceSchoolSelection(school: School) {
    let s = this.schools.find(s => s.groupId === school.groupId);
    this.selectedSchool = s;
    this.onSelectedSchoolChange();
  }

  updateQueryParams() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: this.getQueryParams(),
      });
  }

  getQueryParams(): Params {
    let params: Params = {
      ...this.route.snapshot.queryParams,
    };
    if (this.selectedSchool && this.selectedSchool.groupId > 0) {
      params.school = this.selectedSchool.groupId;
    } else {
      params.school = undefined;
    }
    return params;
  }
}
