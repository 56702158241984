<div class="bc-paginated-table">
    <bc-export-button *ngIf="withExport" [disabled]="isLoading" [large]="true" (export)="onExport()"></bc-export-button>
    <div class="paginator-and-export">
        <bc-paginator [paginator]="pagination" (refresh)="onPaginationChange()" [showCount]="true"></bc-paginator>
        <bc-toggle class="toggle" *ngIf="extraToggle" [tra]="extraToggle.tra" [title]="extraToggle.title"
            [disabled]="isLoading" (toggle)="onToggleChange()"></bc-toggle>
    </div>
    <div class="table-container">
        <div *ngIf="isLoading" class="loader-container">
            <div class="bcg-loader">
                <div></div>
            </div>
        </div>
        <table [style.width]="getTotalWidth()">

            <tr>
                <th *ngIf="addCheckbox" class="checkbox-cell">
                    <div style="padding-left: 0.5em; padding-bottom: 0.5em;">
                        <input type="checkbox" [(ngModel)]="selectAll" (change)="onSelectAllRowsChange()">
                    </div>
                </th>
                <th *ngFor="let headingToSortBy of columnHeadings" class="no-border">
                    <div>
                        <div (click)="changeOrderBy(headingToSortBy.sortBy)" *ngIf="!headingToSortBy.sortDisabled">
                            <span>{{ headingToSortBy.heading }}</span>
                            <ng-container *ngIf="headingToSortBy.subheading">
                                <br>
                                <span class="subheading">{{headingToSortBy.subheading}}</span>
                            </ng-container>
                            <i *ngIf="isSortedBy(headingToSortBy.sortBy, OrderDirection.ASC)"
                                class="fa fa-caret-up"></i>
                            <i *ngIf="isSortedBy(headingToSortBy.sortBy, OrderDirection.DESC)"
                                class="fa fa-caret-down"></i>
                        </div>
                        <div *ngIf="headingToSortBy.sortDisabled" class="sort-disabled">
                            <span>{{ headingToSortBy.heading }}</span>
                            <ng-container *ngIf="headingToSortBy.subheading">
                                <br>
                                <span>{{headingToSortBy.subheading}}</span>
                            </ng-container>
                        </div>
                        <i *ngIf="!headingToSortBy.filterDisabled" class="fa fa-filter" aria-hidden="true"
                            (click)="toggleShowFilter(headingToSortBy.sortBy)"></i>
                    </div>
                    <div [hidden]="!isFilterVisible(headingToSortBy.sortBy)">
                        <input *ngIf="!headingToSortBy.isSelect" type="text" class="input is-small"
                            (input)="updateFilter($event, headingToSortBy.sortBy)"
                            [value]="filterInitValue(headingToSortBy.sortBy)">
                        <select [class]="getInputClass(headingToSortBy.sortBy)" *ngIf="headingToSortBy.isSelect"
                            (change)="updateFilter($event, headingToSortBy.sortBy)">
                            <option *ngFor="let option of headingToSortBy.options"
                                [selected]="filterInitValue(headingToSortBy.sortBy) == option.label">
                                {{option.label}}
                            </option>
                        </select>
                    </div>
                </th>
            </tr>

            <tr *ngFor="let row of rows, index as r" [style]="getStyleForRow(row, r)" [class.odd]="row._isOdd">
                <td *ngIf="addCheckbox" class="checkbox-cell">
                    <input type="checkbox" [(ngModel)]="row._checked" (change)="onSelectedRowChange(row)">
                </td>
                <td *ngFor="let headingToSortBy of columnHeadings, index as col" [style.width]="getColumnWidth(col)">
                    <ng-container *ngIf="!isDisplayComplex(displays[r][col])">
                        <a *ngIf="headingToSortBy.isLink === true"
                            (click)="onLinkClicked(row, r, headingToSortBy.sortBy)">{{displays[r][col]}}</a>
                        <span *ngIf="headingToSortBy.isLink !== true">{{displays[r][col]}}</span>
                    </ng-container>

                    <ng-container *ngIf="isDisplayComplex(displays[r][col])">
                        <ng-container
                            *ngFor="let element of getComplexHeadingValuesForRowCol(r, col).elements, index as e">
                            <div *ngIf="!element.isLink" [style]="element.style">
                                {{element.text}}
                            </div>
                            <a *ngIf="element.isLink && !element.disabled" [style]="element.style"
                                (click)="onLinkClicked(row, e, element.linkId)">{{element.text}}</a>
                            <a *ngIf="element.isLink && element.disabled" [style]="element.style"
                                class="disabled-link">{{element.text}}</a>
                            <br *ngIf="e < getComplexHeadingValuesForRowCol(r, col).elements.length - 1">
                        </ng-container>

                    </ng-container>

                    <div *ngIf="getTagForRow(headingToSortBy.sortBy, row)" class="custom-tag"
                        [ngStyle]="{'background-color': getTagColorForRow(headingToSortBy.sortBy, row)}">
                        {{getTagTextForRow(headingToSortBy.sortBy, row)}}
                    </div>
                </td>
            </tr>
        </table>
    </div>

</div>