import { getElementWeight, QuestionState, ScoringTypes } from "../../models";
import { TextToSpeechService } from "../../text-to-speech.service";
import { IContentElementVirtualTools } from "../model";
import * as PIXI from "pixi.js-legacy";

export interface IPoint {
  x: number,
  y: number,
  isRotationPoint?: boolean
}
export abstract class VirtualTools {

    abstract element: IContentElementVirtualTools;
    render;
    addGraphic;
    isLocked: boolean;
    textToSpeech: TextToSpeechService;
    stage: PIXI.Container;
    isRulerRotateDragging: boolean;
    isProtRotateDragging: boolean;
    isGlobalRotating: boolean;
    isGlobalDragging: boolean;
    constructor(addGraphic, render, stage, isLocked, textToSpeech: TextToSpeechService, isGlobalRotating: boolean, isGlobalDragging: boolean){
      this.isGlobalDragging = isGlobalDragging;
      this.isGlobalRotating = isGlobalRotating;
      this.addGraphic = addGraphic;
      this.render = render;
      this.textToSpeech = textToSpeech
      this.isLocked = isLocked
      this.stage = stage;
    }

    abstract loadAssets(): Promise<PIXI.Loader>;
    
    getText(text:string, style, resolution:number, x, y) {
      const textObj = new PIXI.Text(text, style)
      if (resolution) {
        textObj.resolution = resolution
      }
      if (x) {
        textObj.x = x
      }
      if (y) {
        textObj.y = y
      }
      return textObj
    }

    isHCMode(){
      return this.textToSpeech.isHiContrast;
    }

    getColor() {
      return this.isHCMode() ? 0xffffff : 0x000000;
    }

    getParsedColor(hex: string) {
      return PIXI.utils.string2hex(hex);
    }

    getRadians(angle:number): number {
      return angle * (Math.PI/180);
    }

    getDegrees(angle:number): number {
      return angle * (180/Math.PI);
    }

    getLocalCoordinates(parent: PIXI.Graphics, child: PIXI.Graphics) {
      return {
        x: parent.x + child.x,
        y: parent.y + child.y
      }
    }

    addDragAndRotateListener(obj: PIXI.Graphics, isRotate?: boolean, rotatePoint?: PIXI.Graphics) {
      let initialDiffX = 0;
      let initialDiffY = 0;
      let isDragging = false;
      this.isGlobalDragging = false;
      let isRotateDragging = false;
      let initialAngle = 0;
      obj.cursor = 'grab';
      obj.interactive = true;
      if(isRotate) {
        rotatePoint.cursor = 'ew-resize'
        rotatePoint.interactive = true;
      }
  
      const onDragStart = (e) => {
          const mousePosition = e.data.getLocalPosition(this.stage);
          isDragging = true;
          initialDiffX = mousePosition.x - obj.x
          initialDiffY = mousePosition.y - obj.y
          obj.cursor = 'grabbing';
          console.log('drag start')
      }
      const onDragEnd = (e) => {
          isDragging = false;
          obj.cursor = 'grab';
      }
      const onDragMove = (e: PIXI.InteractionEvent) => {
          if(isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging) {
              this.isGlobalDragging = true;
              const mousePosition = e.data.getLocalPosition(this.stage);
              obj.x = mousePosition.x - initialDiffX; // obj x = 3
              obj.y = mousePosition.y - initialDiffY;
              this.render();
          } else if(!isDragging) {
            this.isGlobalDragging = false;
          }
      }

      if(isRotate) {
        initialAngle = Math.atan2(obj.pivot.y - rotatePoint.y, obj.pivot.x - rotatePoint.x) + Math.PI;
      }
      const onRotateStart = (e) => {
        isRotateDragging = true;
        this.isGlobalRotating = true;
        console.log('rotate start')
      }
      const onRotateEnd = (e) => {
        isRotateDragging = false;
        this.isGlobalRotating = false;
      }
      const onRotate = (e: PIXI.InteractionEvent) => {
          if(isRotateDragging) {
            const mousePosition = e.data.getLocalPosition(this.stage);

            const mouseAngle = Math.atan2(obj.y - mousePosition.y, obj.x - mousePosition.x) + Math.PI;
            
            obj.rotation = mouseAngle - initialAngle;
  
            this.render();
          }
      }
  
      obj.on('pointerdown', onDragStart)
      .on('pointerup', onDragEnd)
      .on('pointerupoutside', onDragEnd)
      .on('pointermove', onDragMove);

      if(isRotate) {
        rotatePoint.on('pointerdown', onRotateStart)
        .on('pointerup', onRotateEnd)
        .on('pointerupoutside', onRotateEnd)
        .on('pointermove', onRotate);
      }
    }

    drawRotationPoint(point: {x: number, y: number}, parent: PIXI.Graphics) {
      const rotationPoint = new PIXI.Graphics();
      rotationPoint.beginFill(0x0000, 0.9);
      rotationPoint.drawCircle(point.x, point.y, 6);
      rotationPoint.endFill();
      rotationPoint.position.set(0 + point.x, 0 + point.y);
      rotationPoint.pivot.set(0 + point.x, 0 + point.y);
  
      parent.addChild(rotationPoint);
  
      this.addDragAndRotateListener(parent, true, rotationPoint);

      return rotationPoint;
    }

    drawPolygon(x: number, y: number, fill: {color: string, opacity: number}, points: IPoint[], isInteractive: boolean) {
      const polygon = new PIXI.Graphics;
      polygon.beginFill(this.getParsedColor(fill.color), fill.opacity);
      const transformedPoints = []
      
      points.map(point => {
        transformedPoints.push({x: point.x + x, y: point.y + y});
      })
  
  
      polygon.drawPolygon(transformedPoints);
  
      polygon.endFill();
  
      polygon.x += x;
      polygon.y += y;
      polygon.pivot.set(x, y);
      polygon.zIndex = 4;
  
      let hasRotationPoint = false;
      points.map(point => {
        if(point.isRotationPoint && isInteractive) {
          hasRotationPoint = true;
          this.drawRotationPoint({x: point.x + x, y: point.y + y}, polygon);
        }
      })
  
      if(isInteractive && !hasRotationPoint) {
        this.addDragAndRotateListener(polygon);
      }

      return polygon;
    }

    drawLine(x: number, y: number, fill: {color: string, opacity: number}, points: IPoint[], isInteractive: boolean, isVerticesDrawn:boolean = true) {
      const transformedPoints = []
      points.map(point => {
        transformedPoints.push({x: point.x + x, y: point.y + y});
      })

      const pointA = transformedPoints[1];
      const pointB = transformedPoints[0];
      const rotation = Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x) + Math.PI;
      const distanceX = Math.pow(pointB.x - pointA.x, 2);
      const distanceY = Math.pow(pointB.y - pointA.y, 2);
      const line = new PIXI.Graphics();
      const length = Math.sqrt(distanceX + distanceY);
      const height = 2;
      line.beginFill(this.getParsedColor(fill.color), fill.opacity);
      line.drawRect(pointB.x, pointB.y - (height / 2), length, height);
      line.endFill();
      line.pivot.set(pointB.x, pointB.y);
      line.position.set(pointB.x, pointB.y);
      line.rotation = rotation;
    
      line.x += x;
      line.y += y;
      line.pivot.set(x, y);
      line.zIndex = 4;
      line.interactive = true;

      const vertices = new PIXI.Graphics();
      if (isVerticesDrawn){
        vertices.beginFill(this.getParsedColor(fill.color));
        vertices.drawCircle(pointB.x, pointB.y, 4);
        vertices.drawCircle(length, 0, 4);
        vertices.endFill();
        vertices.interactive = true;
        vertices.zIndex = 6;
      }
      line.addChild(vertices);

      let hasRotationPoint = false;
      points.map(point => {
        if(point.isRotationPoint && isInteractive) {
          hasRotationPoint = true;
          this.drawRotationPoint({x: point.x + x, y: point.y + y}, line);
        }
      })
  
      if(isInteractive && !hasRotationPoint) {
        this.addDragAndRotateListener(line);
      }

      return line;
    }

    drawCircle(x: number, y: number, fill: {color: string, opacity: number}, points: IPoint[], isInteractive: boolean, circleRadius: number) {
      const transformedPoints = []
      points.map(point => {
        transformedPoints.push({x: point.x + x, y: point.y + y});
      })
      const point = transformedPoints[0];

      const circle = new PIXI.Graphics();
      circle.beginFill(this.getParsedColor(fill.color));
      circle.drawCircle(point.x, point.y, circleRadius);
      circle.endFill();
      circle.interactive = true;
      circle.zIndex = 6;
      circle.interactive = true;

      let hasRotationPoint = false;
      points.map(point => {
        if(point.isRotationPoint && isInteractive) {
          hasRotationPoint = true;
          this.drawRotationPoint({x: point.x + x, y: point.y + y}, circle);
        }
      })
  
      if(isInteractive && !hasRotationPoint) {
        this.addDragAndRotateListener(circle);
      }

      return circle;
    }

    // Some common method being used by the freehand polygon and line tools
    drawShapeVertices(vertices: PIXI.Graphics, lines: PIXI.Graphics, points: IPoint[], addMoveVertexListener: (vertice, i) => any) {
      vertices.removeChildren();
      lines.removeChildren();
      for(let i=0; i<points.length; i++) {
          if(points[i-1]) {
              this.drawVerticeLine(points[i], points[i-1], lines);
          }
          const vertice = new PIXI.Graphics();
          vertice.beginFill(0x0000);
          vertice.drawCircle(points[i].x, points[i].y, 7);
          vertice.endFill();
          vertice.interactive = true;
          vertice.zIndex = 6;
          addMoveVertexListener(vertice, i);
          vertices.addChild(vertice);
      }
      
      this.render();
    }

    // Draws the black dot and connect to the previous dot (if available)
    drawVerticeLine(pointA: IPoint, pointB: IPoint, lines: PIXI.Graphics) {
      const line = new PIXI.Graphics();
      line.position.set(0, 0);
      line.lineStyle(2, 0x0000, 1);
      line.moveTo(pointB.x, pointB.y)
      line.lineTo(pointA.x, pointA.y);
      lines.addChild(line);
    }

    getShapeCenter(points: IPoint[]) {
      let x = 0;
      let y = 0;
      let maxX = 0, minX = 0, maxY = 0, minY = 0;
      for(let i=0; i < points.length; i++) {
          x = points[i].x;
          y = points[i].y;
          if (i == 0) {
              maxX = x;
              minX = x;
              maxY = y;
              minY = y;
          } else {
              if (maxX < x)
                  maxX = x;
              if (minX > x)
                  minX = x;
              if (maxY < y)
                  maxY = y;
              if (minY > y)
                  minY = y;
          }
      }
      x = (maxX + minX) / 2;
      y = (maxY + minY) / 2;
      const height = maxY - minY;
      const width = maxX - minX;
  
      return {x, y, maxX, maxY, minX, minY, height, width};
    }

    drawSelectedBorder(container: PIXI.Graphics, points: IPoint[]) {
      const center = this.getShapeCenter(points);
      const border = new PIXI.Graphics();
      border.lineStyle(2, 0x0000, 1);
      border.beginFill(0x0000, 0.1);
      border.drawRect(center.minX - 2, center.minY - 2, (center.maxX-center.minX) + 4, (center.maxY-center.minY) + 4);
      border.endFill();
      border.alpha = 0;
  
      container.addChild(border);
  
      return border;
    }

    removeAccessibilityKeyListeners() {}
  }