import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MicService {

  constructor() {

  }

  requestPermission(){
    // navigator.permissions
    //   .query({name:'microphone'})
    //   .then(function(result) {
    //     if (result.state == 'granted') {
      
    //     } else if (result.state == 'prompt') {
      
    //     } else if (result.state == 'denied') {
      
    //     }
    //     result.onchange = function() {
      
    //     };
    //   });
  }
}
