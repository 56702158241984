import { IPanelLink } from "./type";
import { BC_FSA_DISTRICT_ADMIN_VIEWS } from '../../../ui-dist-admin/view-districtadmin-dashboard/data/views'

const items: IPanelLink[] = BC_FSA_DISTRICT_ADMIN_VIEWS.map(view => {
  return {
    caption: view.caption,
    routerLink: `/en/dist-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled
  }
})

export default items
