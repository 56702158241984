<ng-container [ngSwitch]="!!element.isParagraphMode"> 
    <ng-container *ngSwitchCase="false">
        <div [ngStyle]="getStyle()">
            <div class="block-el" *ngFor="let block of blocks; let i = index;" (click)="!isLocked ? toggleAnswer(block.index) : ''" [ngStyle]="getWordStyle(block, i)">
                <markdown-inline [input]="getFormattedWord(block.word)"></markdown-inline>        
            </div>
        </div>        
    </ng-container>
    <ng-container *ngSwitchCase="true">
        <ng-container *ngFor="let paragraph of paragraphs; let idx = index">
            <div [ngStyle]="getStyle(idx)">
                <div class="block-el" *ngFor="let block of paragraph; let i = index;" (click)="!isLocked ? toggleAnswer(block.index) : ''" [ngStyle]="getWordStyle(block, block.wordIdx)">
                    <markdown-inline [input]="getFormattedWord(block.word)"></markdown-inline>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
<div *ngIf="this.showMaxSelectedMsg" (click)="!isLocked ? this.showMaxSelectedMsg = false : ''" class="mcq-max-selected-msg">
    {{getMaxMsgText()}}
    <div class="click-dismiss-msg">
        {{getClickToDismissMsg()}}
    </div>
</div>