import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, ViewEncapsulation, ElementRef } from '@angular/core';
import { ElementType, getElementWeight, QuestionState, ScoringTypes, shuffle } from '../models';
import { IContentElementOrder, IContentElementOrderOption, IEntryStateOrder, OrderMode } from './model';
import { ImageStates } from '../element-render-image/model';
import { indexOf } from '../services/util';
import { KeyboardDrop } from '../element-render-dnd/model';
import { LangService } from '../../core/lang.service';
import { McqDisplay } from '../element-render-mcq/model';
import { moveItemInArray, CdkDragDrop, transferArrayItem, CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { StyleprofileService, processText } from '../../core/styleprofile.service';
import { Subject } from 'rxjs';
import { TextToSpeechService } from "../text-to-speech.service";
import { DEFAULT_VOICEOVER_PROP } from '../../ui-item-maker/element-config-mcq-option-info/element-config-mcq-option-info.component';
import { AccessibilityService } from '../accessibility.service';
import { IContentElementText } from '../element-render-text/model';
import { ISimplifiedDndState, findDndEl } from '../element-render-moveable-dnd/model';

const SCORING_TYPE = ScoringTypes.AUTO;

export const getDefaultOrderInstrSlug = (element: IContentElementOrder) => {
  if(!element.showDefaultText) {
    return "";
  } 
  return "txt_default_order_instr"
}

export const useSetOrders = (element: IContentElementOrder) => {
  for(const option of element.options) {
    if(option.initOrder == null) {
      return false;
    }
  }
  return true;
}

export const reorderOrderOptionsToInit = (options: IContentElementOrderOption[]) => {
  return options.sort((a, b) => a.initOrder - b.initOrder);
}

@Component({
  selector: 'element-render-order',
  templateUrl: './element-render-order.component.html',
  styleUrls: ['./element-render-order.component.scss'],
})
export class ElementRenderOrderComponent implements OnInit, OnChanges {

  @Input() element:IContentElementOrder;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    private lang:LangService,
    private profile:StyleprofileService,
    public textToSpeech:TextToSpeechService,
    private accessibility:AccessibilityService
  ) { }

  options: IContentElementOrderOption[];
  answers;
  currentSelections = [];

  target = OrderMode.TARGET;
  reorder = OrderMode.REORDER;

  dragTriggers:Map<any, Subject<boolean>> = new Map();
  dropTriggers:Map<any, Subject<boolean>> = new Map();
  keyboardDrop:KeyboardDrop;

  ngOnInit() {
    this._resetKeyBoardSelection()
    if (!this.questionState || !this.questionState[this.element.entryId]) {
      // console.log('initializing')
      this.resetOptions();
      this.ensureState();
    }
    else {
     // console.log(this.questionState);
      this.loadFromPrevState()
    //  console.log('options::ngOnInit', this.options)
    }
    if (!this.element.colourScheme) {
      this.element.colourScheme = { backgroundColor:"#FFFFFF", textColor:"#000000"};
    }
  }

  simplifyStateOptions = (options: IContentElementOrderOption[]): Partial<ISimplifiedDndState>[] => {
    return options.map(option => {
      return {
        id: option.optionId,
        key_id: option.key_id,
        caption: option.caption ?? option.content,
        isReadOnly: option.isReadOnly
      }
    })
  }

  simplifiedAnswerState = (): Partial<ISimplifiedDndState>[][] => {
    if(this.isReorderMode()) {
      return this.options.map(option => this.simplifyStateOptions([option]))
    }

    return this.answers.map(ans => this.simplifyStateOptions(ans));
  }

  loadFromPrevState = () => {

    const qState: IEntryStateOrder = this.questionState?.[this.element.entryId];
    if(!qState) return;
    if (this.isTargetMode()) {
      
      // load answers
      this.answers = qState.answers.map(simplifiedOptions => {
        return simplifiedOptions.map(simplifiedOption => findDndEl(this.element.options, 'optionId', simplifiedOption.id))
      });

      // load options
      this.options = qState.options.map(simplifiedOption => findDndEl(this.element.options, 'optionId', simplifiedOption.id));
      // console.log(this.answers, this.options)

    }  else {

      // reorder
      // load options
      this.options = [];
      qState.answers.forEach(ans => {
        if(ans.length) {
          const simplifiedOption = ans[0];
          const option = findDndEl(this.element.options, 'optionId', simplifiedOption.id)
          this.options.push(option);
        }
      })
    }
  }

  getOptionsExcludingFixed(options:IContentElementOrderOption[]) {
    let opt = [];
    options.forEach((option)=>{
      if (!option.isReadOnly) opt.push(option);
    })
    return opt;
  }

  useSetOrders() {
    return useSetOrders(this.element);
  }

  resetOptions(){
    this.options = [].concat(this.element.options);
    this.answers = [];
    this.options.forEach((option)=>{
      const ans = [];
      if (option.isReadOnly) {
        ans.push(option);
      }
      this.answers.push(ans);
    })

    if(this.useSetOrders()) {
      this.options = reorderOrderOptionsToInit(this.options);
      return;
    }

    if (this.element.isScrambled || !this.element.scrambledOptions) {
      this.options = shuffle(this.options);
      this.element.scrambledOptions = [].concat(this.options);
    } else {
      this.options = [].concat(this.element.scrambledOptions);
    }
    let thisOptions = this.options;
    let originalOptions = this.element.options;
    if (this.isTargetMode()) {
      thisOptions = this.getOptionsExcludingFixed(this.options);
      originalOptions = this.getOptionsExcludingFixed(this.element.options);
    }
	
    let isCorrectOrder = true;
    thisOptions.forEach((option, index)=>{
      if (originalOptions[index].optionId!=option.optionId) {
        isCorrectOrder = false;
      }
    })
    
    if (isCorrectOrder && thisOptions.length>2) {
      this.resetOptions();
    }
    //  console.log('options::resetOptions', this.options)
     // this.options = [];
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.changeCounter){
      // console.log("Resetting options", this.questionState[this.element.entryId]);
      this.resetOptions();
    }
  }

  getAnswers() {
    let answers = this.answers;
    if (this.element.orderMode == OrderMode.REORDER) {
      answers = [];
      for (let i = 0;i<this.options.length;i++) {
        let opt = this.options[i];
        let ans = [];
        ans.push(opt);
        answers.push(ans);
      }
    }
    return answers;
  }

  ensureState() {
    let score = 0;
    let weight = getElementWeight(this.element);

    let answers = this.getAnswers();
    if (this.questionState) {
      if (!this.questionState[this.element.entryId]) {
        const isCorrect = false;
        const isStarted = false;
        const isFilled = false;
        
        let entryState:IEntryStateOrder = {
          type: ElementType.ORDER,
          isCorrect,
          isStarted,
          isFilled,
          isResponded: false,
          answers: this.simplifiedAnswerState(),
          options: this.simplifyStateOptions(this.options) ,
          score,
          weight,
          scoring_type: ScoringTypes.AUTO, 
        }
        //console.log(entryState);
        if (this.questionState){
            this.questionState[this.element.entryId] = entryState;
        }
      }
    }
  }

  private getOptionWeight(): number {
    const weight = getElementWeight(this.element);
    return (weight / this.element.options.filter(option => !option.isReadOnly).length);
  }
  
  updateState() {
    let isStarted = false;
    let isCorrect = true;
    let isFilled = true;
    let weight = getElementWeight(this.element);
    const optionWeight = this.getOptionWeight();  
    let score = weight;
    
    let answers = this.getAnswers();
    let numWrong = 0
    let numPlaced = 0;
    answers.forEach((option, index)=>{
      if (this.element.options[index].isReadOnly) return;

      if (option.length==0) {
        isCorrect = false;
        //score = score - optionWeight;
        numWrong++;
        isFilled = false;
        return;
      }
      numPlaced += 1;
      isStarted = true;
      const currAnswer = option[0];
      
      if (currAnswer == undefined || currAnswer.optionId!=this.element.options[index].optionId) {
        isCorrect = false;
        numWrong++;
        //score = score - optionWeight;
      }
    })
    if (numWrong==0) {
      score = weight
    } else if (numWrong==answers.length) {
      score = 0
    } else {
      score = weight - optionWeight*numWrong
    }
    score = +score.toFixed(2);

    // console.log('answers', answers, isFilled)
    // console.log('options', this.options)

    //console.log(answers);
    if (score<0 || (!this.element.enableProportionalScoring && score<weight)){
      score=0;
    }
    //console.log(score);
    //console.log(isFilled);
    let isResponded = numPlaced >= 1;
    let entryState:IEntryStateOrder = {
      type: ElementType.ORDER,
      isCorrect,
      isStarted,
      isFilled,
      isResponded,
      answers: this.simplifiedAnswerState(),
      options: this.simplifyStateOptions(this.options),
      score,
      weight,
      scoring_type: ScoringTypes.AUTO, 
    }
    //console.log(entryState);

    if (this.questionState){
        this.questionState[this.element.entryId] = entryState;
    }

    
  }

  isVertical(){
    return (this.element.displayStyle===McqDisplay.VERTICAL)
  }
  isHorizontal(){
    // return (this.element.displayStyle==='vertical')
    return !this.isVertical()
  }

  private _resetKeyBoardSelection(){
    this.keyboardDrop = {
      lastSrcIndex: null,
      sourceElement: {},
      source: [],
    } 
  }

  private _setKeyBoardSelection(container, sourceElement, optionIndex){
    if(!this.keyboardDrop) this._resetKeyBoardSelection();  // init keyboardDrop
    this.keyboardDrop.source = container
    this.keyboardDrop.sourceElement = sourceElement
    this.keyboardDrop.lastSrcIndex = optionIndex
  }

  isSelected(element,container){
    return this.keyboardDrop && this.keyboardDrop.source && this.keyboardDrop.source.length && this.keyboardDrop.source === container && this.keyboardDrop.sourceElement === element
  }

  onEnter(e, optionElement, arr, optionIndex){
    e.stopPropagation();
    if (!this.keyboardDrop?.source.length && !optionElement.isReadOnly){
      this._setKeyBoardSelection(arr, optionElement, optionIndex)
      return
    } 

    // Same container
    if(this.keyboardDrop.source === arr){
      if(this.keyboardDrop.sourceElement === optionElement){
        this._resetKeyBoardSelection();
        return
      } 
      if(this.isTargetMode()) {
        if(!optionElement.isReadOnly){
          this._setKeyBoardSelection(arr, optionElement, optionIndex)
        } else {
          this._resetKeyBoardSelection()
        }
        return;
      }
    }

    // Move the element in the array
    this._drop({ 
      container: arr, 
      previousIndex: this.keyboardDrop.lastSrcIndex, 
      currentIndex: optionIndex })
    
  }

  onEnterdropTargetMode(e, optionContainer, targetElement, index, isHomeDest: boolean) {
    if(this.keyboardDrop?.source?.length){
      this._dropTargetMode({
        source: this.keyboardDrop.source,
        dest: optionContainer,
        previousIndex:this.keyboardDrop.lastSrcIndex,
        currentIndex: index,
      }, targetElement, isHomeDest )
      return;
    }

    // If ans container with element is selected - select that element:
    if(targetElement){
      this._setKeyBoardSelection(optionContainer, targetElement, index)
    }
  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    this._drop({ 
      container: arr, 
      previousIndex: event.previousIndex, 
      currentIndex: event.currentIndex })
  }

  dropTargetMode(event: CdkDragDrop<string[]>, targetElement: any, isHomeDest: boolean) {

    this._dropTargetMode({
      source: event.previousContainer.data,
      dest: event.container.data,
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex}, targetElement, isHomeDest)
  }

  private _drop(moveLocation){
    const {container, previousIndex, currentIndex} = moveLocation
    moveItemInArray(container, previousIndex, currentIndex);
    this.updateState();
    this.removeSelection();
    this._resetKeyBoardSelection()
  }

  private _dropTargetMode(dropLocation, targetElement, isHomeDest: Boolean){
    const {source, dest, previousIndex, currentIndex} = dropLocation
    
    console.log(source, dest, previousIndex, currentIndex)

    if (dest.length > 0 && !isHomeDest) {
      transferArrayItem(dest, source, currentIndex, previousIndex + 1);
    }

    transferArrayItem(source, dest, previousIndex, currentIndex);
  
    this.updateState();
    this.removeSelection();
    this.rearrangeByOrder()
    this._resetKeyBoardSelection()

    if (targetElement) {
      this.playTargetAudio(targetElement, this.dropTriggers, 'label_voiceover');
    }
  }

  removeSelection() {
    if (window.getSelection()) {
      window.getSelection().removeAllRanges()
    } else if (document.getSelection()) {
      document.getSelection().removeAllRanges();
    }
  }

  rearrangeByOrder() {
    if (!this.isTargetMode()) return;
    if (this.useSetOrders()){
      this.options = reorderOrderOptionsToInit(this.options);
      return;
    }
    const configOpts = this.element.scrambledOptions || this.element.options;
    const newOpts = []
    configOpts.forEach((opt)=>{
      if (this.options.find(o => o.entryId === opt.entryId )) {
        newOpts.push(opt)
      }
    })
    this.options = newOpts;
  }

  canBeDropped(optionElement:IContentElementOrderOption[]) {
    if (optionElement.length>0 && optionElement[0].isReadOnly) return false;
    return true;
  }

  canBeDroppedPredicate(drag : CdkDrag, list:CdkDropList) {
    if (list.data.length>0 && list.data[0].isReadOnly) return false;
    return true;
  }

  isTargetMode() {
    return this.element.orderMode == OrderMode.TARGET;
  }

  isReorderMode() {
    return this.element.orderMode == OrderMode.REORDER;
  }

  processThisText(str: string) {
    const lang = this.lang.c();
    return processText(str, this.profile.getStyleProfile()[lang].renderStyling.plainText.transforms);
  }

  getDims(pad: boolean) {
    const style = {}
    style["height.em"] = this.element.targetHeight ? this.element.targetHeight : 3;
    style["width.em"] = this.element.targetWidth ? this.element.targetWidth : 3;
    style["max-height.em"]=this.element.targetHeight ? this.element.targetHeight : 3
    style["max-width.em"]=this.element.targetWidth ? this.element.targetWidth : 3
    if (pad) {
      style["height.em"] += 0.3;
      style["width.em"] += 0.3;
      style["max-height.em"] += 0.3;
      style["max-width.em"] += 0.3;
    }
    return style;
  }

  hasDefaultImage(img:any) {
    return (img && img.images && img.images[ImageStates.DEFAULT] && img.images[ImageStates.DEFAULT].image && img.images[ImageStates.DEFAULT].image.url);
  }

  getTargetFlowDirection() {
    if (this.isHorizontal()) {
      if (this.element.isTargetOrientationReversed) {
        return 'column-reverse'
      } else {
        return 'column'
      }
    } else {
      if (this.element.isTargetOrientationReversed) {
        return 'row-reverse'
      } else {
        return 'row'
      }
    }
  }

  isDragWidthSet() {
    return this.element.widthOfDrags && this.element.isDragWidthSet 
  }

  getDragWidthSet() {
    let style = {}
    if (this.isDragWidthSet()) {
      style['min-width.em']=this.element.widthOfDrags;
      style['max-width.em']=this.element.widthOfDrags;
      style['width.em']=this.element.widthOfDrags;
      style['flex-wrap']='wrap'
    } else {
      style['flex-wrap']='nowrap'
    }
    return style
  }
  isVoiceoverEnabled() {
    return this.textToSpeech.isActive;
  }

  getElementAudio(voiceover: any): string {
    return (voiceover && voiceover.url) ? voiceover.url : '';
  }

  getAudioTrigger(element: any, triggers:Map<any, Subject<boolean>> = this.dragTriggers){
    let trigger = triggers.get(element);
    if (!trigger){
      trigger = new Subject();
      triggers.set(element, trigger);
    }
    return trigger;
  }

  playTargetAudio(element:any, triggers:Map<any, Subject<boolean>> = this.dragTriggers, voiceoverProp:string = DEFAULT_VOICEOVER_PROP) {
    const voiceover = element[voiceoverProp];
    if (voiceover && voiceover.url) {
      this.getAudioTrigger(element, triggers).next(true);
    }
  }

  getDefaultOrderInstrSlug() {
    return getDefaultOrderInstrSlug(this.element);
  }


  onClickToDrag(e, optionElement, arr, optionIndex){ 
    if(!this.accessibility.clickToDragDrop) {
      return;
    }
    this.onEnter(e, optionElement, arr, optionIndex);
  }

  onClickToDropTargetMode(e, optionContainer, targetElement, index, isHomeDest: boolean) {
    if(!this.accessibility.clickToDragDrop) {
      return;
    }
    return this.onEnterdropTargetMode(e, optionContainer, targetElement, index, isHomeDest);
  }

  isAdvTextEl(el:IContentElementText){
    if (el.paragraphStyle){
      return true;
    }
    return false;
  }

  getText(optionElement: IContentElementText){
    if(optionElement?.caption){      
      return this.processThisText(optionElement.caption)
    } else if(optionElement['content']){            
        return this.processThisText(optionElement['content'])
    } else {
      return ''
    }
    
  }

  private _isDragstarted: boolean = false;

  dragStarted(e){
    const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag.cdk-drag-preview'));
    console.log('drag started!')
    this._isDragstarted = true;
  }

  dragging(e, ans?){
    // #Imp: If condition to make sure block only fire once. 
    if(this._isDragstarted){
      // Reason: Drag preview and oiriginal element are opposite in high contrast mode.
      const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag.cdk-drag-preview'));
      preview.nativeElement

      if (preview.nativeElement){
        if(this.textToSpeech.isHiContrast ){
          preview.nativeElement.style.filter = "invert(1)";
        }

        const optionElPreview = new ElementRef<HTMLElement>(preview.nativeElement.querySelector('.option'));
        if (optionElPreview && optionElPreview.nativeElement){
          optionElPreview.nativeElement.style.display = 'flex';
          optionElPreview.nativeElement.style.justifyContent = this.element?.isCenterDraggableContent ? 'center' : 'left';
          optionElPreview.nativeElement.style.alignItems = this.element?.isCenterDraggableContent ? 'center' : 'left'
        }

        if (preview.nativeElement.classList.contains('order-option-in-answer')){
          preview.nativeElement.style.display = 'flex';
          preview.nativeElement.style.backgroundColor = this.element.colourScheme.backgroundColor;
        }
      }
      
      this._isDragstarted = false
    }
  }
}
