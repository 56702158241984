<ngx-charts-pie-chart
        [view]="view"
        [scheme]="colorScheme"
        [results]="data"
        [gradient]="gradient"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [legendTitle]="legendTitle"
        [labels]="showLabels"
        [doughnut]="isDoughnut"
        [trimLabels]="trimLabels"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
>
</ngx-charts-pie-chart>
