import { Injectable } from "@angular/core";

const DEFAULT_PLAYBACK_RATE = 1;

@Injectable({
    providedIn: 'root'
})
export class AudioPlaybackService {
    private _currentUniversalPlaybackSpeed: number = DEFAULT_PLAYBACK_RATE;
    private _isUniversalPlaybackSpeedDisabled: boolean = false;

    constructor() { }

    public get currentUniversalPlaybackSpeed(){
        return this._currentUniversalPlaybackSpeed;
    }

    public get isUniversalPlaybackSpeedDisabled(){
        return this._isUniversalPlaybackSpeedDisabled;
    }

    public set currentUniversalPlaybackSpeed(playbackSpeed: number){
        this._currentUniversalPlaybackSpeed = playbackSpeed;
    }

    public set isUniversalPlaybackSpeedDisabled(isUniversalPlaybackSpeedDisabled: boolean){
        this._isUniversalPlaybackSpeedDisabled = isUniversalPlaybackSpeedDisabled;
    }
}

