import { Component, Input, OnInit } from '@angular/core';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';



@Component({
  selector: 'element-render-reader',
  templateUrl: './element-render-reader.component.html',
  styleUrls: ['./element-render-reader.component.scss']
})
export class ElementRenderReaderComponent implements OnInit {
  
  @Input() contentElement: any;
  @Input() isLocked: any;
  @Input() questionState: any;
  @Input() element: any;
  @Input() questionPubSub?: QuestionPubSub;
  
  constructor() { }

  ngOnInit(): void {
  }

}
