import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { DEFAULT_TICK_MEASURE } from './constants'
import { OnInit } from "@angular/core";
import {ITableData, TextBox} from "./textbox";
export enum ACTIONS {
  ROW = "ROW",
  COLUMN = "COLUMN",
  DELETE = "DELETE",
  DELETEROW = "DELETEROW",
  DELETECOL = "DELETECOL"
}
interface ITable {
  x: number,
  y: number,
  isRevoked: boolean
}
export class Table extends VirtualTools{
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  hcPrevState: boolean;
  text: PIXI.Graphics;
  tables: PIXI.Graphics;
  tableManager: PIXI.Graphics[] = [];
  deleteSprite: PIXI.Sprite;
  rowSprite: PIXI.Sprite;
  delRow: PIXI.Sprite;
  columnSprite: PIXI.Sprite;
  tableData: ITableData[][][] = [];
  tableDrawInfo: ITable[] = [];
  TextBox: TextBox;
  cellIdCounter: number = 0;
  cellHeight = 30;
  cellWidth = 100;

  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.element = element;
    this.isGlobalRotating = isGlobalRotating;
    this.isGlobalDragging = isGlobalDragging;

    this.spriteLoader = new SpriteLoader();
    this.tables = new PIXI.Graphics();
    this.delRow = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();
    this.rowSprite = new PIXI.Sprite();
    this.columnSprite = new PIXI.Sprite();
    this.TextBox = new TextBox(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging);

    this.hcPrevState = this.textToSpeech.isHiContrast;  


    this.loadAssets().then(this.initSprites);

    this.addGraphic(this.tables);
  }

  initSprites = ({resources}) => {
    this.deleteSprite.texture = resources.delete.texture;
    this.rowSprite.texture = resources.row.texture;
    this.delRow.texture = resources.deleteRow.texture;
    this.columnSprite.texture = resources.column.texture;
  }

  populateEmptyTable = (rows: number, columns: number, defaultValue: ITableData) => {
    const table: ITableData[][] = [];
    for(let i=0; i<rows; i++) {
      const row: ITableData[] = [];
      for(let v=0; v<columns; v++) {
        let newCell = <ITableData>JSON.parse(JSON.stringify(defaultValue));
        newCell.id = this.cellIdCounter++;
        row.push(newCell);
      }
      table.push(row);
    }
    return table;
  }

  initialTableX = 45;
  initialTableY = 45;
  newTable() {
    // Default rows and columns are set by the config
    const rows = 3;
    const columns = 2;
    this.initialTableX += 5;
    this.initialTableY += 5;

    this.tableData.push(this.populateEmptyTable(rows, columns, {id: 0, input: '', isInited: false}));
    this.tableDrawInfo.push({x: this.initialTableX, y: this.initialTableY, isRevoked: false});
        
    this.drawTable();
  }

  initialTextboxX = 45;
  initialTextboxY = 45;
  newStandaloneTextbox() {
    this.initialTextboxX += 5;
    this.initialTextboxY += 5;

    this.TextBox.createTextBox(this.initialTextboxX, this.initialTextboxY, 0xffffff, this.tables, null, true);
  }

  drawTable() {
    if(this.tableManager) {
      this.tableManager.forEach((table, i) => {
        this.tableManager[i].destroy();
      })
      this.tableManager = [];
    }

    for(let table = 0; table<this.tableData.length; table++){
      if(!this.tableDrawInfo[table].isRevoked){
        const tableContainer = new PIXI.Graphics();

        for(let i = 0; i<this.tableData[table].length; i++) {
          for(let v = 0; v<this.tableData[table][i].length; v++) {
            this.TextBox.createTextBox(
              (this.cellWidth*v) + (v-0.25), 
              (this.cellHeight*i) + (i-0.25),
              i == 0 ? 0xd6d6d6 : 0xffffff,
              tableContainer,
              this.tableData[table][i][v]
              );
          }
        }

        tableContainer.x = this.tableDrawInfo[table].x;
        tableContainer.y = this.tableDrawInfo[table].y;
        tableContainer.zIndex = 20;
    
        this.addGraphic(tableContainer);
        this.selectTableListener(tableContainer, table);
        this.tableDragListener(tableContainer, table);
        this.tableManager.push(tableContainer)
      }
    }

    this.render();
  }

  drawMenu(container: PIXI.Graphics, tableIndex) {
    const menu = new PIXI.Graphics();
    const menuYOffset = 10;
    const menuHeight = 30;
    const menuWidth = 77;
    menu.beginFill(0x333c42);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.y = container.height + menuYOffset;
    menu.endFill();
    menu.interactive = false;

    const row = new PIXI.Sprite();
    row.texture = this.rowSprite.texture;
    row.scale.set(0.03);
    row.anchor.set(0,0.5);
    row.y = menuHeight / 2;
    row.x = 10;
    row.interactive = true;

    const col = new PIXI.Sprite();
    col.texture = this.columnSprite.texture;
    col.scale.set(0.03);
    col.anchor.set(0,0.5);
    col.y = menuHeight / 2;
    col.x = row.x + row.width + 5;
    col.interactive = true;

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = col.x + col.width + 5;
    del.interactive = true;

    const delRow = new PIXI.Sprite();
    delRow.texture = this.delRow.texture;
    delRow.scale.set(0.02);
    delRow.anchor.set(0,0.5);
    delRow.y = 0-menuYOffset-(menuHeight/2);
    delRow.x = 0-(menuYOffset*2);
    delRow.interactive = true;

    const delCol = new PIXI.Sprite();
    delCol.texture = this.delRow.texture;
    delCol.scale.set(0.02);
    delCol.anchor.set(0,0.5);
    delCol.y = 0 + (menuYOffset/2);
    delCol.x = container.width - (this.cellWidth/2) - (delCol.width/2);
    delCol.interactive = true;

    menu.addChild(row);
    menu.addChild(col);
    menu.addChild(del);
    menu.addChild(delRow);
    menu.addChild(delCol);
    this.addMenuListeners(container, ACTIONS.ROW, row, tableIndex);
    this.addMenuListeners(container, ACTIONS.COLUMN, col, tableIndex);
    this.addMenuListeners(container, ACTIONS.DELETE, del, tableIndex);
    this.addMenuListeners(container, ACTIONS.DELETEROW, delRow, tableIndex);
    this.addMenuListeners(container, ACTIONS.DELETECOL, delCol, tableIndex);
    
    this.TextBox.tableMenus.push(menu);
    container.addChild(menu);
    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, tableIndex?: number) {
    let func;
    option.cursor = 'pointer';
  
    if(action == ACTIONS.DELETE) {
      func = (e) => {
        if(tableIndex != null) {
          this.tableDrawInfo[tableIndex].isRevoked = true;
          this.drawTable();
          return;
        }


        obj.destroy();
        this.render();

      }
    } else if(action == ACTIONS.COLUMN) {
      func = (e) => {
        const table = this.tableData[tableIndex];
        table.forEach((row, i) => {
          const empty: ITableData = {
            id: this.cellIdCounter++,
            input: '',
            isInited: false
          }
          row.push(JSON.parse(JSON.stringify(empty)));
        });

        this.drawTable();
      }
    } else if(action == ACTIONS.ROW) {
      func = (e) => {
        const table = this.tableData[tableIndex];
        const prevIndexLengt = this.tableData[tableIndex][table.length-1].length;
        const emptyCell: ITableData = {
          id: this.cellIdCounter++,
          input: '',
          isInited: false
        }
        const emptyRow: ITableData[] = [];
        for(let i=0; i<prevIndexLengt; i++) {
          emptyRow.push(JSON.parse(JSON.stringify(emptyCell)))
        }
        table.push(emptyRow);

        this.drawTable();
      }
    } else if(action == ACTIONS.DELETEROW) {
      func = (e) => {
        const table = this.tableData[tableIndex];
        table.pop();

        this.drawTable();
      }
    } else if(action == ACTIONS.DELETECOL) {
      func = (e) => {
        const table = this.tableData[tableIndex];
        table.forEach((row) => {
          row.pop();
        })

        this.drawTable();
      }
    }
    option.on('pointerup', func);
  }

  selectTableListener(table: PIXI.Graphics, tableIndex) {
    const clearMenus = () => {
      this.TextBox.tableMenus.forEach((v, i) => {
        this.TextBox.tableMenus[i].destroy();
      })
      this.TextBox.tableMenus = [];
    }
    let menu: PIXI.Graphics;
    const selectTable = () => {
        clearMenus();
        menu = this.drawMenu(table, tableIndex);
        this.render();
    }

    table.on('pointerdown', selectTable)
  }

  tableDragListener(obj: PIXI.Graphics, tableIndex: number) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    obj.cursor = 'grab';
    obj.interactive = true;
    
    const onDragStart = (e) => {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragging = true;
        initialDiffX = mousePosition.x - obj.x
        initialDiffY = mousePosition.y - obj.y
        obj.cursor = 'grabbing';
    }
    const onDragEnd = (e) => {
        isDragging = false;
        obj.cursor = 'grab';

        this.tableDrawInfo[tableIndex].x = obj.x;
        this.tableDrawInfo[tableIndex].y = obj.y;
    }
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging) {
            this.isGlobalDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            obj.x = mousePosition.x - initialDiffX; // obj x = 3
            obj.y = mousePosition.y - initialDiffY;

            this.render();
        } else if(!isDragging) {
          this.isGlobalDragging = false;
        }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove);
  }

  loadAssets = () => {
    let assets = [];
    assets.push({
      name: "delete", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png"
    });
    assets.push({
      name: "column", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/right-arrow/1686793834889/right-arrow.png"
    });
    assets.push({
      name: "row", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/down-arrow/1686793819835/down-arrow.png"
    });
    assets.push({
      name: "deleteRow", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/x-2/1686941285169/x-2.png"
    });

    this.spriteLoader.addSpritestoLoader(assets);
    return this.spriteLoader.loadSprites()
  }
}
