import { IContentElement, IEntryState } from "../models";
import { IQPubSubPayload } from "../question-runner/pubsub/question-pubsub";

export interface IContentElementVideo extends IContentElement {
    url: string;
    urls?: string[];
    fileType?: string;
    altText?: string;
    subtitlesUrl?: string;
    maxViews?: number;
    isUnPausable?: boolean;
    isRestricted?: boolean;
    isAlwaysShowingTime?: boolean;
    allowFullScreen?: boolean;
    isAutoPlay?: boolean;
    autoPlayDelay?: number;
    onVideoEnd?: IQPubSubPayload[];
    enableVideoControls?: boolean;
  }

  export interface IEntryStateVideo extends IEntryState {
    numViews?: number;
    timeStamp?: number;
    seekTimes?: number[];
    loadTimes?: number[];
    pauseTimes?: number[];
    endedTimes?: number[];
  }

  export const videoEditInfo = {
    editExcludeFields: []
  }
