import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IENSCategory } from '../view-ens-homepage/view-ens-homepage.component';

@Component({
  selector: 'ens-diagnostic',
  templateUrl: './ens-diagnostic.component.html',
  styleUrls: ['./ens-diagnostic.component.scss']
})
export class EnsDiagnosticComponent implements OnInit {

  constructor() { }

  @Input() categoriesTop:IENSCategory[];
  @Input() categoriesBott:IENSCategory[];
  @Output() diagnosticContinue = new EventEmitter();

  ngOnInit(): void {

  }

}
