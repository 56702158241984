export enum ChecklistStep {
    TestWindow,
    Delivery,
    Location,
    TechnicalReadiness,
    DateAndTime,
    Accommodations,
    RestrictedSessions,
    MakeSessionsAvailable,
    SendInvitations,
}

export enum ChecklistStepRoute {
    TestWindow            = 'test-window',
    Delivery              = 'delivery-method',
    Location              = 'location',
    TechnicalReadiness    = 'technical-readiness',
    DateAndTime           = 'dates-times',
    Accommodations        = 'accommodations',
    RestrictedSessions    = 'restricted',
    MakeSessionsAvailable = 'make-available',
    SendInvitations       = 'invitations',
}