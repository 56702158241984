import { ELangs, LangService } from "../../../core/lang.service";
import { IQuestionConfig } from "../../../ui-testrunner/models";
import { QuestionTitleMap, getQuestionTitles } from "../../../ui-testrunner/test-runner/util/question-titles";
import { objectToNumMap } from "../../student-util";

/**
 *
 * @param testForm
 * @param {ELangs} lang
 * @returns questionDb Map for stored test-from
 */
export const getQuestionDb = (testForm: any, lang: ELangs): Map<number, IQuestionConfig> => {
  if (!testForm) return new Map();

  if (testForm.questionDbs) {
    return objectToNumMap(testForm.questionDbs[lang]);
  }

  return objectToNumMap(testForm.questionDb); // backward compatibility
};

/**
 * 
 * @param langs - test form languages
 * @param currentActiveLang - current active lang in vea
 * @returns - choose lang to load test questions
 */
export const getCurrentLangForTestForm = (langs: ELangs[] | ELangs, currentActiveLang: ELangs): ELangs => {
  if (Array.isArray(langs)) {
    if (langs.includes(currentActiveLang)) {
      return currentActiveLang; // load test in active lang
    }
    return langs[0]; // return the first default langugage
  }

  return langs; // backward compatibility for single lang
};


/**
 * 
 * @param testForm 
 * @param currentLangCode - active lang in vea
 * @returns - lang in the test question should load
 */
export const getTestLang = (testForm: any, currentLangCode: ELangs) => {
  const { lang, langs } = testForm;
  return getCurrentLangForTestForm(langs ?? lang, currentLangCode);
};

/**
 * 
 * @param testForm 
 * @returns Languages published in test form
 */
export const getTestFormLanguages = (testForm: any) => {
  const { langs, lang } = testForm;
  if (langs) 
    return langs;

  return [lang]; // Backward compatibility
};

/**
 * 
 * @param testForm 
 * @param lang: LangService
 * @returns QuestionTitleMap
 */
export const generateQuestionTitlesMap = (testForm: any, lang: LangService): QuestionTitleMap => {
  return getQuestionTitles(
    testForm.currentTestDesign.sections, 
    testForm.questionSrcDb, 
    testForm.currentTestDesign.useQuestionLabel, 
    lang,
    testForm.currentTestDesign.questionWordSlug,
  );
}


/**
 * Calculates the time difference between two time stamps in minutes
 * @param startTime 
 * @param endTime 
 * @returns difference in minutes, zero if difference is negative.
 */
export const calculateTimeDifferenceInMins = (startTime: number, endTime: number) => {
  const ms = endTime - startTime;
  const minutes = Math.max(Math.floor(ms / (1000 * 60)), 0);

  return minutes;
}