<div class="padding-left :1.5em;">
    Annotation
    <element-config-text [element]="element.text"></element-config-text>
    Annotation Tooltip
    <fieldset [disabled]="isReadOnly()">
        <textarea type="text" class="input" [(ngModel)]="element.annotation"></textarea>
        <label>
            <input type="checkbox" [(ngModel)]="element.isLeftAligned"/>
            Left Align?
        </label>
        <label>
            <input type="checkbox" [(ngModel)]="element.isConstrainedWidth"/>
            ConstrainedWidth?
        </label><br>
        <label>
            <input type="number" [style.width.em]="4" step=0.2 min=0 [(ngModel)]="element.annoteLineHeight"/>
            Annotation Line Height
        </label>
    </fieldset>
</div>