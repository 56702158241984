import { Injectable } from '@angular/core';


export interface ILinkRequest {
  readerId?: string;
  readerElementId?: string;
  itemLabel?: string;
  bookmarkId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class QuestionRunnerLayoutService {
  //First variable communicates from link to test runner
  id2ZIndex = new Map<number, number>();
  //The current element that is controlling the pop out functionality
  commandingID;
  constructor() { }
  
  public clear(commandingID) {
    if (commandingID==this.commandingID) {
      this.id2ZIndex = new Map<number,number>();
    } 
  }

  public mapId2ZIndex(id, zIndex, cID) {
    if (this.commandingID!=cID) {
      this.clear(this.commandingID)
    }
    this.commandingID = cID
    if (zIndex == undefined) {
        this.id2ZIndex.delete(id);
    } else {
        this.id2ZIndex.set(id,zIndex);
    }
  }

  public reset() {
    this.id2ZIndex = new Map<number, number>();
  }
}
