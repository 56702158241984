<table class="legend-table">
    <th (click)="toggleLegendExpand()">
        <div>
            <div style="visibility:hidden; width: auto">
                <i *ngIf="!isExpanded" class="fas fa-chevron-right legend-arrow"></i>
                <i *ngIf="isExpanded" class="fas fa-chevron-down legend-arrow"></i>
            </div>
            <span><tra slug="tr_results_legend_title"></tra></span>
            <i *ngIf="!isExpanded" class="fas fa-chevron-right legend-arrow"></i>
            <i *ngIf="isExpanded" class="fas fa-chevron-down legend-arrow"></i>
        </div>
    </th>
    <ng-container *ngIf="isExpanded">
        <tr>
            <td>
                <div>
                    <i class="fas fa-check-circle fa-fw" style="color: #0080FF;"></i>
                    <span><tra slug="tr_results_legend_check"></tra></span>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div>
                    <i class="far fa-dot-circle fa-fw" style="color: #0080FF;"></i>
                    <span><tra slug="tr_results_legend_circle"></tra></span>
                </div>
            </td>
        </tr>
        <tr *ngIf="numCRQuestions">
            <td>
                <div>
                    <i class="fas fa-keyboard fa-fw" style="color: #fcbc14;"></i>
                    <span><tra slug="tr_results_legend_keyboard"></tra></span>
                </div>
            </td>
        </tr>
    </ng-container>
</table>