import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IContentElementMcqOption, IContentElementMcqOptionInTable } from 'src/app/ui-testrunner/element-render-mcq/model';
import { IContentElementText, TextParagraphStyle } from 'src/app/ui-testrunner/element-render-text/model';
import { ElementType, IElementTypeDef } from 'src/app/ui-testrunner/models';
import { FormControl, FormArray } from '@angular/forms';
import { bindFormControls } from '../services/data-bind';
import { indexOf } from '../services/util';
import { createDefaultElement, elementIconById, ensureDefaultElementProps, generateDefaultElementTextLink } from '../item-set-editor/models';
import { ItemComponentEditService } from '../item-component-edit.service';
import { PubSubTypes } from '../../ui-testrunner/element-render-frame/pubsub/types';
import { eventType, IButtonEventType } from '../element-config-frame/element-config-frame.component';


@Component({
  selector: 'config-mcq',
  templateUrl: './config-mcq.component.html',
  styleUrls: ['./config-mcq.component.scss']
})
export class ConfigMcq implements OnInit {

  @Input() options:IContentElementMcqOption[];
  @Input() updateChangeCounter;
  @Input() optionType: ElementType;
  @Input() readOnly: boolean = false;
  @Input() isCanLink: boolean = false;
  @Input() isTableFormat: boolean = false;
  @Input() isShowAdvancedOptions: boolean = false;
  @Input() hasButtonLabels: boolean = true;
  @Input() subElementTypes: IElementTypeDef[];
  @Input() allowPositioning:boolean;
  @Input() isEnablePartialweights:boolean;
  @Input() useDistractorAsResponseSlug:boolean;
  @Output() change = new EventEmitter<string>();

  constructor(private itemComponentEdit : ItemComponentEditService) { }

  buttonLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  PubSubTypes = PubSubTypes;
  eventTypes:IButtonEventType[] = eventType
  
  ngOnInit() {
    this.initializeFrameData()
  }

  ngOnChanges(){
    this.initializeFrameData()
  }

  initializeFrameData(){
    this.options.forEach(option => {
      if(!option.frameData) option.frameData = {}
    })
  }

  insertListEntry(config:IElementTypeDef){
    const elementType = config.id;
    // console.log('this.element.options', this.element.options);
    let content:any = '';
    if (elementType === ElementType.IMAGE){
      content = {url: '', scale: 1}
    }
    const optionElement:IContentElementMcqOption = {
      ... createDefaultElement(elementType),
      elementType,
      optionType: this.optionType,
      content,
      isCorrect: false,
      optionId: -1,
      link: generateDefaultElementTextLink()
    };
    //console.log("hello");
    if (config.feedProps){
      const feedProps = JSON.parse(JSON.stringify(config.feedProps));
      Object.keys(feedProps).forEach(prop => {
        optionElement[prop] = feedProps[prop];
      });
    }
    this.options.push(optionElement)
    this.updateChangeCounter();
    this.emitChange();
  }

  isAdvTextEl(el:IContentElementText){
    if (el.paragraphStyle && el.paragraphStyle!=TextParagraphStyle.REGULAR){
      return true;
    }
    return false;
  }

  renderOptionTableCols(option:IContentElementMcqOption){
    const rowOption = <IContentElementMcqOptionInTable> option;
    return rowOption.cols
  }

  replaceOptionType(optionElement:IContentElementMcqOption, replaceCode:string){
    switch(replaceCode){
      case 'ADVTEXT':
        let optionElementText:IContentElementText = <any> optionElement;
        ensureDefaultElementProps(optionElementText, ElementType.TEXT);
        optionElementText.paragraphStyle = TextParagraphStyle.ADVANCED_INLINE;
        optionElementText.advancedList = [
          createDefaultElement(ElementType.MATH),
          createDefaultElement(ElementType.TEXT)
        ]
        break;
      case 'MATH':
        ensureDefaultElementProps(optionElement, ElementType.MATH);
        break;
      case 'IMAGE':
        ensureDefaultElementProps(optionElement, ElementType.IMAGE);
        break;
      case 'TABLE':
        ensureDefaultElementProps(optionElement, ElementType.TABLE);
        break;
    }
  }

  isReadOnly() { return this.readOnly;}

  toggleOptionCorrect(optionElement:IContentElementMcqOption){
    optionElement.isCorrect = ! optionElement.isCorrect;
    this.itemComponentEdit.update.next(true);
  }

  removeElement(content:any[], element:any){
    if (window.confirm('Remove this option?')){
      let i = indexOf(content, element);
      if (i !== -1){
        content.splice(i, 1)
      }
    }
    this.updateChangeCounter();
    this.emitChange();
  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  emitChange() {
    this.change.emit("");
  }

  getButtonLabel(index: number){
    if(index > 25){
      let newIndex = index % 26;
      let labelToAppend = this.buttonLabels[newIndex];
      let firstLetter = this.buttonLabels[Math.floor(index/26) - 1];
       
      return firstLetter + labelToAppend;
    } 
    return this.buttonLabels[index];
  }
}
