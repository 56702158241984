export const questionSrcDb = (PRACTICE_TEST_DATA:any) => {
    const copy = (obj:any) => JSON.parse(JSON.stringify(obj));
    const m = new Map();
    [
        PRACTICE_TEST_DATA.SQ_EN,
        PRACTICE_TEST_DATA.SQ_FR,
    ]
    .forEach(questionSet => {
        (<any[]>questionSet).forEach(question => {
            if (m.has((<any>question).taskId)){
                console.error('Question Task ID collision (likely between multi-lingual')
            }
            m.set((<any>question).taskId, question);
        });
    })
    return m
}