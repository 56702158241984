import { Injectable } from "@angular/core";
import { AuthService } from "../api/auth.service";
import { RoutesService } from "../api/routes.service";

@Injectable({
  providedIn: "root"
})
export class StudentSoftLockService {
  constructor(private auth: AuthService, private routes: RoutesService) {}

  getStudentSoftLock = async (test_attempt_id: number) => {
    //Checks if the soft lock is disabled for the current student
    return await this.auth.apiGet(this.routes.STUDENT_SOFT_LOCK, test_attempt_id);
  };

  pauseStudent = async (test_attempt_id: number) => {

    // check if for student soft-lock is disabled
    const softLockStatus = await this.getStudentSoftLock(test_attempt_id);
    const {is_soft_lock_disabled, is_paused} = softLockStatus
    
    if (is_soft_lock_disabled || is_paused) { return; }

    // pause
    this.auth
      .apiPatch(this.routes.STUDENT_SOFT_LOCK, test_attempt_id, {})
      .then(res => {
        console.log("ATTEMP_PAUSED");
      })
      .catch(e => {
        console.log("STUDENT_SOFT_LOCK_FAILED", e);
      });
  };


  invigUnpauseTestAttemptForStudents = async(testAttemptId:number, params, route = this.routes.EDUCATOR_SOFT_LOCK) => {

    return this.auth.apiPatch(route, testAttemptId, {isPausing : false}, params )
  }

  invigDisableSoftLockForStudentAttempt = async(testAttemptId:number, params:any, route = this.routes.EDUCATOR_SOFT_LOCK) => {
    return this.auth.apiPatch(route, testAttemptId, {
      isPausing: false,
      isDisableSoftLock: true
    }, params )
  }

}
