import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, AfterViewInit, OnDestroy, Output } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { StyleprofileService, processText } from 'src/app/core/styleprofile.service';
import { AuthScopeSettingsService, AuthScopeSetting } from '../../ui-item-maker/auth-scope-settings.service';
import { EventEmitter } from '@angular/core';


// const procesAutosSup = (str:string, lang:string) => {
//   const i_letters = str.search(/[a-zA-Z]+/);
//   const number = str.substr(0, i_letters)
//   let letters = str.substr(i_letters, str.length-1);
//   let allowedSup = [];
//   if (lang === 'en'){ 
//     allowedSup = [
//       'st',
//       'nd',
//       'rd',
//       'th',
//     ]
//   }
//   if (lang === 'fr'){
//     allowedSup = [
//       'e',
//       'er',
//       'ée',
//       'ee',
//       'ieme',
//       'ième',
//     ]
//   }
//   if (allowedSup.indexOf(letters) !== -1){
//     letters = '<sup>'+letters+'</sup>'
//   }
//   return number + letters
// }
// const stripMarkedupEdges = (count:number, str:string) => {
//   return str.substr(count, str.length-count*2)
// }
// const formatNumber = (str:string, lang:string) => {
//   // str = transformThousandsSeparator(str, lang);
//   // str = transformFrenchDecimal(str, lang);
//   return str;
// }

@Component({
  selector: 'markdown-inline',
  templateUrl: './markdown-inline.component.html',
  styleUrls: ['./markdown-inline.component.scss']
})
export class MarkdownInlineComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @ViewChild('span', {static:false}) span:ElementRef;
  @Input() input:string;
  @Input() notInline?: boolean;
  @Output() renderUpdate = new EventEmitter();
  @Input() isInvisible?: boolean = false;
  output:string;
  styleProfileChangeSub; 

  constructor(
    private lang:LangService,
    private profile:StyleprofileService,
    private authScope: AuthScopeSettingsService,
  ) { }


  ngOnInit() {
    this.updateRender();
    this.profile.getStyleProfileChanges().subscribe((hasStyleProfile) => {
      if(hasStyleProfile) {
        this.updateRender();
      }
    })
  }

  ngOnDestroy(): void {
    if(this.styleProfileChangeSub) {
      this.styleProfileChangeSub.unsubscribe();
    }
  }

  ngAfterViewInit(){
    // document.addEventListener('selectionchange', () => {
    //   // console.log('document change selection')
    // });
    // this.span.nativeElement.addEventListener('selectionchange', () => {
    //   console.log('element change selection')
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateRender();
  }

  updateRender(){
    const lang = this.lang.c();
    if (this.profile.getStyleProfile()) {
      this.output = processText(this.input, this.profile.getStyleProfile()[lang].renderStyling.plainText.transforms);
    } else {
      this.output = this.input
    }

    this.renderUpdate.emit(this.output);
  }

}


