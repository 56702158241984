<div class="header-layout" *ngIf="phantom"></div>
<ng-container *ngIf="!phantom">
    <div class="header-layout dont-print">
        <div class="view-header">
            <span>
                <tra [slug]="getTitle()"></tra>
            </span>
            <img [src]="imgSrc">
        </div>
        <div class="school-selector">
            <label>
                <tra slug="sa_aa_school"></tra>:
            </label>
            <select [(ngModel)]="selectedSchool" (change)="onSelectedSchoolChange()" [disabled]="isLoading">
                <option *ngFor="let school of schools" [ngValue]="school">
                    {{getSchoolDisplay(school)}}
                </option>
            </select>
            <button *ngFor="let config of buttons" class="extra-button" [style]="config.style" [id]="config.buttonId"
                (click)="extraButtonClicked(config.buttonId)">
                {{getExtraButtonTitle(config)}}
            </button>
        </div>
        <div class="assessment-session-selector">
            <label>
                <tra slug="sa_aa_assessment_session">:</tra>
            </label>
            <select [(ngModel)]="selectedTestWindow" (change)="onSelectedTestWindowChange()" [disabled]="isLoading">
                <option *ngFor="let testWindow of testWindows" [ngValue]="testWindow">
                    {{getTestWindowDisplay(testWindow)}}
                </option>
            </select>

        </div>
    </div>
    <hr>
</ng-container>