import { Injectable } from '@angular/core';
import { LangService } from '../core/lang.service';
import { RoutesService } from '../api/routes.service';
import { AuthService } from '../api/auth.service';
import { ITranslateLanguages } from './widget-translator/types';

export interface ITextTranslationParams {
  sourceLanguageCode: string,
  targetLanguageCode: string,
  text: string,
  terminologyNames?: string[]
}

/**
 * This service use AWS TRANSALTOR to translate provided text to target language.
 */
@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  supportedLangs: ITranslateLanguages[];

  constructor(
    private lang: LangService,
    private auth: AuthService,
    private routes: RoutesService
  ) { 
    if(!this.supportedLangs) this.fetchSupportedLangs();
  }

  private _isActive: boolean;
  public get isActive(): boolean {
    return this._isActive;
  }
  public set isActive(value: boolean) {
    this._isActive = value;
  }

  // fetch supported langs
  fetchSupportedLangs = async() => {
    const route = this.routes.AWS_TRANSLATOR_LANGUAGES;
    this.supportedLangs = await this.auth.apiFind(route, {});
  }

  translate = async(data: ITextTranslationParams) => {
    // checks
    if(!data.text || !data.targetLanguageCode) return;
     
    const route = this.routes.AWS_TRANSLATOR;
    let response = '';
    try {
      response = await this.auth.apiCreate(route, data);

    } catch(e) {
      // handle errors
      console.log(e)
    } finally {
      return response
    }
  }
}
