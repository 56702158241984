import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewEnsHomepageComponent } from './view-ens-homepage/view-ens-homepage.component';
import { EnsLandingComponent } from './ens-landing/ens-landing.component';
import { EnsTestRunnerComponent } from './ens-test-runner/ens-test-runner.component';
import { EnsDiagnosticComponent } from './ens-diagnostic/ens-diagnostic.component';
import { EnsCongratulationsComponent } from './ens-congratulations/ens-congratulations.component';
import { EnsCertificateComponent } from './ens-certificate/ens-certificate.component';
import { UiWhitelabelEnsRoutingModule } from './ui-whitelabel-ens-routing.module';
import { RouterModule } from '@angular/router';
import { UiPartialModule } from '../ui-partial/ui-partial.module';
import { MarkdownModule } from 'ngx-markdown';
import { UiTestrunnerModule } from '../ui-testrunner/ui-testrunner.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [
    UiWhitelabelEnsRoutingModule,
    CommonModule,
    RouterModule, 
    ReactiveFormsModule,
    UiPartialModule,
    MarkdownModule,
    UiTestrunnerModule,
  ],
  declarations: [
    ViewEnsHomepageComponent, 
    EnsLandingComponent, 
    EnsTestRunnerComponent, 
    EnsDiagnosticComponent, 
    EnsCertificateComponent, 
    EnsCongratulationsComponent
  ],
  exports:[
    ViewEnsHomepageComponent,
  ]
})
export class UiWhitelabelEnsModule { }
