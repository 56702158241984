import * as moment from 'moment-timezone';
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../api/auth.service';
import { ITranslation } from '../api/models/db/translations.schema';
import { RoutesService } from '../api/routes.service';
import { WhitelabelService } from '../domain/whitelabel.service';

const LS_TOGGLE_PULL_LIVE_TRANS = 'pull-live-trans';
const LS_STORE_DATA = 'trans-cache';

export enum ELangs {
  'English' = 'en',
  'French'  = 'fr'
} 

@Injectable({
  providedIn: 'root'
})
export class LangService {

  private supportedLanguages = ['en', 'fr'];
  private knownLanguages = {
    'en': 'English',
    'fr': 'Français',
  }
  private currentLangCode:string = 'en';
  private TRA_DB =  require('../../../data/translations.json');
  private currentUrl:string = ''
  private isLiveTrans:boolean = false;
  languageInitialized = new EventEmitter();

  constructor(
    private auth:AuthService,
    private routes:RoutesService,
    private router:Router,
    private whitelabel: WhitelabelService,
  ) { 
    try {
      if (window.localStorage.getItem(LS_TOGGLE_PULL_LIVE_TRANS) === 'yes'){
        this.isLiveTrans = true;
        this.loadTranslationMapFromDb();
      }
      else {
        window.localStorage.removeItem(LS_STORE_DATA);
      }
    }
    catch(e) { }
  }

  updateCurrentUrl(url:string){
    this.currentUrl = url;
  }

  getKnownLangName(langCode:string){
    return this.knownLanguages[langCode] || langCode;
  }

  directDownloadTranslationMapFromDb(){
    return this.auth
      .apiFind(this.routes.TRANSLATION, {
        query: {
          $limit: 6000, // assuming this will be enough for as many as we need while it still makes sense to serve it all in one lump
        }
      })
      .then(activeRecords => {
        return this.createTranslationMap( activeRecords.data );
      });
  }

  loadTranslationMapFromDb(){
    const cachedTranslations = window.localStorage.getItem(LS_STORE_DATA);
    if (cachedTranslations){
      const map = this.createTranslationMap( JSON.parse(cachedTranslations) );
      this.TRA_DB = map;
    }
    this.auth
      .apiFind(this.routes.TRANSLATION, {
        query: {
          $limit: 4000, // assuming this will be enough for as many as we need while it still makes sense to serve it all in one lump
        }
      })
      .then(activeRecords => {
        // console.log('got records', activeRecords.total);
        const newCachedTranslations = JSON.stringify(activeRecords.data);
        if (cachedTranslations !== newCachedTranslations){
          window.localStorage.setItem(LS_STORE_DATA, newCachedTranslations);
          setTimeout(()=>{
            window.location.reload();
          }, 500);
        }
      })
  }


  createTranslationMap(records:ITranslation[]){
    const map:any = {};
    const langs = this.supportedLanguages;
    langs.forEach(lang => {
      const langVoice = lang+'_voice';
      map[lang] = {};
      map[langVoice] = {};
    });
    records.forEach(record =>{
      const key = record.slug || record.id;
      langs.forEach(lang => {
        map[lang][key] = record[lang];
        const langVoice = lang+'_voice';
        const voiceUrl = record[langVoice];
        if (voiceUrl){
          map[langVoice][key] = voiceUrl;
        }
      })
    })
    return map;
  }

  getSupportedLanguages(){
    return this.supportedLanguages;
  }

  isUsingLiveTranslations(){
    return this.isLiveTrans; 
  }

  deactivateLiveTranslations(){
    this.isLiveTrans = false;
    window.localStorage.removeItem(LS_TOGGLE_PULL_LIVE_TRANS);
    setTimeout(()=>{
      window.location.reload();
    })
  }
  activateLiveTranslations(){
    this.isLiveTrans = true;
    window.localStorage.setItem(LS_TOGGLE_PULL_LIVE_TRANS, 'yes')
    this.loadTranslationMapFromDb();
  }

  c() {
    return this.getCurrentLanguage();  
  }
  getCurrentLanguage(){
    return this.currentLangCode;
  }

  setCurrentLanguage(newLangCode:string, isForceReload=false){
    if (this.supportedLanguages.indexOf(newLangCode) === -1){
      console.warn(`Provided language not supported, using English instead. (${newLangCode})`);
    }
    else if (newLangCode !== this.currentLangCode) {
      this.currentLangCode = newLangCode;
      if (isForceReload) {
        const routePath = this.currentUrl.split('/');
        if (routePath[1] === this.currentLangCode){
          routePath[1] = newLangCode;
        }
        const cleanRoute = [];
        routePath.forEach(part => {
          if (part.indexOf('?')!=-1) {
            part = part.substring(0, part.indexOf('?'))
          }
          cleanRoute.push(decodeURI(part))
        })
        // console.log('cleanRoute', cleanRoute);
        window.location.replace(window.location.origin+'/#'+cleanRoute.join('/'))
        window.location.reload();
      }
    }
    this.updateMomentLocale();
    this.languageInitialized.emit();
  }

  updateMomentLocale(){
    if (this.currentLangCode === 'fr'){
      moment.locale('fr');
    }
    else{
      moment.locale('en');
    }
  }

  loadInLang(newLangCode:string){
    const replacementPattern = '/'+ this.currentLangCode +'/';
    const routePath = this.currentUrl.split('/');
    let queryParams = {};
    if (routePath[1] === this.currentLangCode){
      routePath[1] = newLangCode;
    }
    const cleanRoute = [];
    routePath.forEach(part => {
      if (part.indexOf('?')!=-1) {
        const [path, params] = part.split('?');
        part = path;
        params.split('&').forEach(param => {
          const [key, value] = param.split('=');
          queryParams[key] = decodeURIComponent(value);
        });
      }
      cleanRoute.push(decodeURI(part))
    })
  
    // Check if queryParams contains 'vea-login=true'
    if (queryParams['vea-login'] === 'true' || queryParams['schoolType']) {
      this.router
        .navigate(cleanRoute, { queryParams })
        .then(v => {
          window.location.reload(); // force refresh...
        })
    } else {
      this.router
        .navigate(cleanRoute)
        .then(v => {
          window.location.reload(); // force refresh...
        })
    }
  }

  traWithWhitelabel(whitelabelSiteTextKey:string, slug:string | number, langOverride?:string, props?:{[key:string]: string | number}){
    if (whitelabelSiteTextKey){
      const wlSlug = this.whitelabel.getSiteText(whitelabelSiteTextKey);
      if (wlSlug){ slug = wlSlug }
    }
    return this.tra(slug, langOverride, props);
  }

  traVoice(key:string, langOverride?:string){
    const lang = langOverride || this.currentLangCode;
    return this.TRA_DB[lang+'_voice'][key]
  }

  tra(key:string | number, langOverride?:string, props?:{[key:string]: string | number} ): string{
    if (!key){ return ''; }
    key = (''+key).trim()
    const lang = langOverride || this.currentLangCode;
    let str = this.TRA_DB[lang][key] || key;
    if(props) {
      str = str.replace(/{{([^}}]+)?}}/g, ($1, $2) => 
      $2.split('.').reduce((p, c) => p ? p[c] : '', props));
    }
    return str;
  }

  traWithParam(key: string | number, param?: any, langOverride?: string) {
    if (!key) { return ''; }
    key = ('' + key).trim();
    const lang = langOverride || this.currentLangCode;
    return param ? `${this.TRA_DB[lang][key]} ${param}` : this.TRA_DB[lang][key];
  }

  traSrc(src:{[key:string]: string}){
    const lang = this.currentLangCode;
    return src[lang] || src[Object.keys(src)[0]]; // loose defaul
  }

  overrideLangEntry(key:string, val:string, langOverride?:string){
    const lang = langOverride || this.currentLangCode;
    this.TRA_DB[lang][key] = val;
  }

  overrideTranslationsDatabase(data){
    this.TRA_DB = data;
  }
  
  popupFrenchLangWIP(){
    alert('Cette page est en chantier et n\'est pas encore disponible en français.')
  }

  popupWIP(slug:string){
    if (slug){
      alert(this.tra(slug))
    }
    else{
      alert('This link is not currently available.')
    }
  }
  
}
