import { Component, OnDestroy, OnInit, Input,EventEmitter,Output } from '@angular/core';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { AuthService,IUserInfo, F_ERR_MSG__INVALID_LOGIN } from '../../api/auth.service';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LangService } from '../../core/lang.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { FormFail, LoginCtrl } from '../../core/login-ctrl';
import { AccountType } from '../../constants/account-types';
import { LoginGuardService } from '../../api/login-guard.service';
// import { LoginFormComponent } from 'src/app/ui-login/login-form/login-form.component';


@Component({
  selector: 'view-login-entry',
  templateUrl: './view-login-entry.component.html',
  styleUrls: ['./view-login-entry.component.scss']
})

export class ViewLoginEntryComponent implements OnInit,OnDestroy {
  @Input() accountType:AccountType;
  @Output() onLoginAttempt = new EventEmitter();
  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private router:Router,
    private auth: AuthService,
    private loginGuard: LoginGuardService, 
    private route: ActivatedRoute,
    public lang:LangService,
    private sidePanel: SidepanelService,
    private whiteLabel: WhitelabelService,
  ) { }

  public breadcrumb = [];
  public formFailReason:FormFail;
  private routeSub:Subscription;
  private userSub:Subscription;
  public EntryWorks: string;
  public signInText:string 
  private isLoginAttempted:boolean;
  public isLoaded: boolean;
  private dontLogout:string; 
  dismissMessage:boolean;
  public ssoAddress: string = this.whiteLabel.contextData.ssoAddress(window.location.hostname);
  sendToVeaLogin: boolean = false;
  ngOnInit(): void {
    this.sidePanel.deactivate();
    // check if path contains vea-login
    this.route.url.subscribe(url => {
      const path = url.join('/')
      if (path == 'vea-login') {
        this.sendToVeaLogin = true
        !this.auth.getCookie('veaLogin') ? this.auth.setCookie('veaLogin', true, 1) : null;
      }
      
      console.log('current path',path)
    })
    
    this.routeSub = this.route.data.subscribe(data => {
        this.EntryWorks = data.page;
        if (!this.EntryWorks){
          this.auth.getCookie('veaLogin') ? this.router.navigate([`/${this.lang.getCurrentLanguage()}/vea-login`]) : this.router.navigate([`/`])
        }
        // set params for routes with data to handle french issue problems
        this.breadcrumb = [
          data.page ? this.breadcrumbsService._CURRENT( this.breadcrumbsService.capitalizeFirstLetter(data.page), `/${this.lang.getCurrentLanguage()}/${data.page}`,`` ) : this.breadcrumbsService._CURRENT( this.lang.tra('lbl_login'), '/' )
        ];
        console.log(this.EntryWorks)
        this.initRouteView(data)
      });
      this.route.queryParams.subscribe((queryParams) => {
        this.accountType = <AccountType> queryParams['accountType'];
        this.dontLogout = queryParams['dontLogout'];
        if(queryParams['strategy'] == 'microsoftLogin' && queryParams['accessToken'])
        {
          this.isLoginAttempted = true;
          this.submitMSLogin(queryParams['accessToken']);
        }
      });
    

    // this.routeSub = this.route.params.subscribe(routeParams => {
    //   // Generate breadcrumbs based on the current route
    //   const breadcrumbs = this.router.url.split('/').filter(part => part);
    //   this.breadcrumb = breadcrumbs.map((breadcrumb, index) => {
    //     const url = `/${breadcrumbs.slice(0, index + 1).join('/')}`;
    //     const title = this.breadcrumbsService._CURRENT(this.lang.tra(`breadcrumb_title_${breadcrumb}`), url);
    //     return { title, url };
    //   });
  }
  ngOnDestroy() {
    this.routeSub.unsubscribe();
    if (this.userSub){
      this.userSub.unsubscribe();
    }
  }
  submitMSLogin(accessToken: string){
    this.onLoginAttempt.emit()
    this.auth.loginWithMS(accessToken).then(success => {
      console.log("success")
    }).catch(e => {
      this.onApiError(e);
      this.auth.apiCreate('public/log', {
        slug: 'FAILED_LOGIN',
        data: {
          strategy:'microsoftLogin Failure',
          errorMessage: e.message
        }
      })
    })
  }
  
  onApiError(e:{message:string}) {
    if (e.message === "NOT_VERIFIED") {
      this.formFailReason = FormFail.NOT_VERIFIED;
    }else if (e.message == F_ERR_MSG__INVALID_LOGIN){
      this.formFailReason = FormFail.NOT_FOUND;
    }
    else{
      this.formFailReason = FormFail.UNKNOWN;
    }
  }
  onUserInfo = (userInfo:IUserInfo) => {
    // console.log(userInfo);
    if (userInfo){
      // console.log(this.dontLogout, this.isLoginAttempted || this.dontLogout)
      if (this.isLoginAttempted || this.dontLogout === 'true')
      {
        this.loginGuard.gotoUserDashboard(userInfo);
      }
      else{
        this.auth.logout();
      }
    }
  }
  initRouteView(data:Data){
    this.isLoaded = true;
    this.userSub = this.auth.user().subscribe(this.onUserInfo);
  }
  getFrancoSchoolCategory() {
    return this.lang.tra('nbed_franco_schools_categories');
  }
  getDisabledText() {
    return this.lang.tra(`nbed_disabled_tooltip`);
  }
  getLoginAngloLink() {
    return this.sendToVeaLogin ?  ['/en/anglo', { 'vea-login': true }] : ['/en/anglo'];
  }
  getLoginFrancoLink() {
    return this.sendToVeaLogin ?  ['/fr/franco', { 'vea-login': true }] : ['/fr/franco'];
  }
}
