import { IContentElementText } from "../element-render-text/model";
import { ElementType } from "../models";

export interface IContentElementAnnotatedText extends IContentElementText {
    text: IContentElementText;
    annotation?: string;
    isLeftAligned?: boolean;
    isConstrainedWidth?: boolean;
    annoteLineHeight?: number; 
}

export const annotationEditInfo = {
    editExcludeFields: ['text', 'advancedList'],
    superType: ElementType.TEXT
  }