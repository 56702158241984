import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { IContentElementFrame } from 'src/app/ui-testrunner/element-render-frame/model';
import { PubSubTypes } from 'src/app/ui-testrunner/element-render-frame/pubsub/types';
import { ElementType, IElementTypeDef } from 'src/app/ui-testrunner/models';
import { elementTypes } from "src/app/ui-testrunner/models/ElementTypeDefs";
import { EditingDisabledService } from '../editing-disabled.service';
import { createDefaultElement, elementIconById } from '../item-set-editor/models';
import { indexOf } from '../services/util';
import { TwiddleState } from '../../ui-partial/twiddle/twiddle.component';
const _ = require('lodash');

export interface IButtonEventType {
  id: PubSubTypes,
  caption: string
}

export const eventType: IButtonEventType[] = [
  {id: PubSubTypes.ROTATE, caption: 'Rotate'},
  {id: PubSubTypes.TOGGLE, caption: 'Toggle'},
  {id: PubSubTypes.TOGGLE_ON, caption: 'Toggle On'},
  {id: PubSubTypes.TOGGLE_OFF, caption: 'Toggle Off'},
  {id: PubSubTypes.INC_HEIGHT, caption: 'Increment or Decrement Height'},
  {id: PubSubTypes.TOGGLE_CLICKABLE, caption: 'Toggle Clickable'},
  {id: PubSubTypes.CLICKABLE, caption: 'Clickable'},
  {id: PubSubTypes.NOT_CLICKABLE, caption: 'Not Clickable'},
];

@Component({
  selector: 'element-config-frame',
  templateUrl: './element-config-frame.component.html',
  styleUrls: ['./element-config-frame.component.scss']
})
export class ElementConfigFrameComponent implements OnInit {

  @Input() element:IContentElementFrame;

  PubSubTypes = PubSubTypes;
  eventTypes:IButtonEventType[] = eventType

  constructor(
    private editingDisabled: EditingDisabledService
  ) { }

  elementTypes:IElementTypeDef[] = elementTypes;
  newStyleProp:string;
  twiddleConfig = new TwiddleState(false);
  twiddleDisplayElements = new TwiddleState(false);

  ngOnInit() {
    if(!this.element.eventData) { 
      this.element.eventData = {};
    }
    if (!this.element.styleRaw) {
      this.element.styleRaw = {}
    }
  } 

  activatePortal(){

  }

  isEditingEntryId:boolean;
  startEditingEntryId(){
    if(confirm('Edit Entry ID? You should avoid doing this unless the entry is blank or duplicated due to an import.')){
      this.isEditingEntryId = true;
    }
  }

  importContent(){
    const newJson = prompt();
    this.element.content = JSON.parse(newJson);
  }

  exportContent(){
    prompt( 'Copy these elements', JSON.stringify(this.element.content) );
  }

  importStyles(){
    const newJson = prompt();
    this.element.styleRaw = JSON.parse(newJson);
    this.element.activatedStyles = Object.keys(this.element.styleRaw);
  }

  exportStyles(){
    prompt( 'Copy this style', JSON.stringify(this.element.styleRaw) );
  }

  addStyleProp(){
    if (!this.element.activatedStyles){
      this.element.activatedStyles = [];
      this.element.styleRaw = {};
    }
    const newProp = this.newStyleProp.trim();
    const i = this.element.activatedStyles.indexOf(newProp);
    console.log('prop', i)
    if (i === -1){
      this.element.activatedStyles.push(newProp)
      this.element.activatedStyles = _.sortBy(this.element.activatedStyles) 
    }
  }
    
  insertDisplayListEntry(elementType:ElementType){
    this.element.content.push(
      createDefaultElement(elementType)
    );
  }

  getIconByElementTypeId(elementTypeId:string){
    return elementIconById.get(elementTypeId);
  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr) 
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

  removeElement(content:any[], element:any){
    if (window.confirm('Remove this part?')){
      let i = indexOf(content, element);
      if (i !== -1){
        content.splice(i, 1)
      }
    }
  }

  isCurrQTrackChanges() {
    return this.editingDisabled.getCurrQTrackingChanges();
  }

}
