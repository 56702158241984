import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { AccountType } from '../constants/account-types';
import { ITestState } from './test-runner/test-runner.component';
import { WhitelabelService } from '../domain/whitelabel.service';
const _ = require('lodash');

export interface ICoord {
  x: number;
  y: number;
}

export interface IPathInfo {
  created: string,
  path: {
    points: ICoord[],
    type: string
  }
}

export const TIMESTAMP_FORMAT = new Intl.DateTimeFormat("en-US", {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
});

@Injectable({
  providedIn: 'root'
})
export class DrawingLogService {

  private drawingHistory: IPathInfo[] = [];
  private bufferedLogs: any[] = [];

  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private whitelabelService: WhitelabelService,
  ) { 
  }

  // the 2 methods below are not directly related to 
  bufferedLog(slug:string, data:any){
    if (!this.whitelabelService.getSiteFlag('QUESTION_DEBUG_LOGS')){
      return false;
    }
    this.bufferedLogs.push({
      timestamp: (new Date()).valueOf(),
      slug,
      data,
    });
    this.flushBufferedLogs();
  }
  flushBufferedLogs = _.throttle(()=> {
    this.auth.apiCreate(this.routes.LOG, {
      slug: "BUFFERED_LOG",
      data: {
        userAgent: navigator.userAgent,
        logs: this.bufferedLogs,
      }
    })
    this.bufferedLogs = []
  }, 10*1000, {leading: false});
  ////////////////


  createPathInfo(points: ICoord[], type: string) {
    return {
      created: TIMESTAMP_FORMAT.format(Date.now()),
      path: {
        points,
        type
      }
    };
  }

  logPath(ctx, nodeId, isLine, isHighlighter) {
    if(!this.auth.userIsStudent()) {
      return;
    }

    const node = ctx.getNodeObject(nodeId);

    let pathInfo: IPathInfo;
    if(node.type === "PathNode") {
      const coords = ctx.getPathAsPoints(nodeId);
      if(coords.length >= 1) {
        let type = "unknown";
        if(isLine) {
          type = "line";
        } else if(isHighlighter) {
          type = "highlighter"
        }

        if(coords[0].length > 0) {
          pathInfo = this.createPathInfo(coords[0], type);
        }
      }
    } else if(node.type === "BrushNode") {
      if(node.props.points.length > 0 ){
        pathInfo = this.createPathInfo(node.props.points, "eraser");
      }
    }

    if(pathInfo) {
      this.drawingHistory.push(pathInfo);
    }
  }

  commitLogAndClear(testState: ITestState, sessionId) {
    if(!this.auth.userIsStudent() || this.drawingHistory.length === 0 ) {
      return;
    }
    this.auth.apiCreate(this.routes.LOG, {
      slug: "STUDENT_DRAWING",
      data: {
        uid: this.auth.getUid(),
        session_id: sessionId,
        state: {
          section_index: testState.currentSectionIndex,
          question_index: testState.currentQuestionIndex,
        },
        history: this.drawingHistory
      }
    })
    this.drawingHistory = [];
  }
}
