import {Component, Input} from '@angular/core';

@Component({
  selector: 'pie-graph',
  templateUrl: './pie-graph.component.html',
  styleUrls: ['./pie-graph.component.scss']
})
export class PieGraphComponent {

  @Input() data: Array<{name, value}>;
  @Input() legendTitle: string;
  view: any[] = [350, 300];

  // options
  gradient = false;
  showLegend = true;
  showLabels = false;
  isDoughnut = false;
  legendPosition = 'below';
  trimLabels = false;

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#A10A28']
  };

  constructor() {
    Object.assign(this, { data: this.data });
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
