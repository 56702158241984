import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'element-render-text-constr',
  templateUrl: './element-render-text-constr.component.html',
  styleUrls: ['./element-render-text-constr.component.scss']
})
export class ElementRenderTextConstrComponent implements OnInit {

  @ViewChild('textArea') textArea:ElementRef;

  chars = ['π', '÷', '°', '²', '³', '≈', '≠', '≥', '≤' ];

  constructor() { }

  ngOnInit(): void {

  }

  appendChar(char:string){
    const el = this.textArea.nativeElement;
    el.focus();
    if (el.selectionStart || el.selectionStart === 0) {
      var startPos = el.selectionStart;
      var endPos = el.selectionEnd;
      el.value = el.value.substring(0, startPos) + char + el.value.substring(endPos, el.value.length);
      el.selectionStart = el.selectionEnd = startPos + char.length;
    } 
    else {
      el.value += char;
    }
    
  }

  isKeyboardButtonsEnabled:boolean;
  toggleKeyboardButtons(){
    this.isKeyboardButtonsEnabled = !this.isKeyboardButtonsEnabled;
  }

}
