import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TextToSpeechService } from './text-to-speech.service';

export const BACKGROUND_COLORS = [
  "#FFFFFF",
  '#ececec',
  '#87d8ef',
  '#96deae',
  '#ffbbc0',
  '#fff278',
  '#ffe275',
  '#ffa52f',
  '#ffd5a0',
  '#ffca9d',
  '#ffa134',
  '#ff8366',
  '#ff8154',
  '#ff81a7',
  '#ffafe2',
  '#bc6ea8',
  '#705eaa',
  '#6081ff',
  '#c6d5fe',
  '#3fc6ff',
  '#3bd0e8',
  '#9ef94e',
  '#3d8b55'
]

@Injectable({
  providedIn: 'root'
})
export class BackgroundFillService {
  public fillSub:BehaviorSubject<string> = new BehaviorSubject(BACKGROUND_COLORS[0]);

  constructor(
    private textToSpeech: TextToSpeechService
  ) { 
  }

  update(color: string) {
    this.fillSub.next(color);
  }

  reset() {
    this.update(BACKGROUND_COLORS[0]);
  }

  getFillColor() {
    if(this.textToSpeech.isHiContrast) return '';
    return this.fillSub.value
  }

  isFillWhite() {
    if (this.textToSpeech.isHiContrast) {
      return false
    }

    return this.getFillColor() == BACKGROUND_COLORS[0];
  }

  isNavBarBlockColored = () => {
    if (this.textToSpeech.isHiContrast) {
      return false
    }
    
    return !this.isFillWhite();
  }

  // Parent div needs its background color specified
  getMultiplyStyle() {
    if(this.isFillWhite) {
      return '';
    }
    return 'mix-blend-mode: multiply;'
  }
}
