<div class="progress-bar-container" [class.less-padding]="useLessPadding" [class.is-pj]="isPj">
    <ng-container *ngIf="isPj">
        <div *ngIf="!isDeviceiPad()" style="padding-left: 1em; padding-right: 1em">
            <div class="pj-progress-inactive" [class.is-questionnaire]="isQuestionnaire">
                <div [class.primary-progress-active]="!isG6" [class.junior-progress-active]="isG6" [style.width.%]="isQuestionnaire? getProgressBarProgress() : currProgressLocAsNum" style="height: 100%"></div>
            </div>
            <div *ngIf="isQuestionnaire && currentQuestions.length > 8" class="pj-progress-inactive-second">
                <div [class.primary-progress-active]="!isG6" [class.junior-progress-active]="isG6" [style.width.%]="getProgressBarProgress_secondRow()" style="height: 100%"></div>
            </div>
        </div>
        <div *ngIf="isDeviceiPad()" style="padding-left: 2em; padding-right: 2em" class="ipad">
            <div class="pj-progress-inactive" >
                <div [class.primary-progress-active]="!isG6" [class.junior-progress-active]="isG6" [style.width.%]="getProgressBarProgressiPad()" style="height: 100%"></div>
            </div>
            <div *ngIf="currentQuestions.length > 6" class="pj-progress-inactive-second-ipad">
                <div [class.primary-progress-active]="!isG6" [class.junior-progress-active]="isG6" [style.width.%]="getProgressBarProgressiPad_second()" style="height: 100%"></div>
            </div>
            <div *ngIf="currentQuestions.length > 12" class="pj-progress-inactive-third-ipad">
                <div [class.primary-progress-active]="!isG6" [class.junior-progress-active]="isG6" [style.width.%]="getProgressBarProgressiPad_third()" style="height: 100%"></div>
            </div>
        </div>
        <div class="pj-navbar-container" [class.is-questionnaire]="isQuestionnaire" [class.is-ipad]="isDeviceiPad()">
            {{resetPjQIndex()}}
            <button class="nav-bar-button" *ngFor="let question of currentQuestions; let qIndex = index;" tabindex="0"
            (click)="selectQuestion.emit(qIndex)" (onkeydown)="selectQuestion.emit(qIndex)"
            style="display: flex; justify-content: center;">
                <div
                    class="pj-question-navbar-block"
                    [class.is-filled]="isQuestionFilled(qIndex) && !isReadingSelection(question) && !(isQuestionnaire && qIndex == 0)"
                    [class.pj-is-active]="currentQuestionIndex === qIndex"
                    [class.is-junior]="isG6"
                    [class.is-primary]="!isG6"
                    >
                    <ng-container *ngIf="!isReadingSelection(question) && (!isQuestionnaire || qIndex > 0)">
                        <div class="filled-div" [class.is-filled]="isQuestionFilled(qIndex)">
                            <span>
                                {{incPjQIndex()}}
                            </span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isReadingSelection(question)">
                        <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%" [tooltip]="getQuestionTitle(qIndex)">
                            <i style="margin-left: -0.05em; margin-top: 0.04em" class="fas fa-book"></i>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isQuestionnaire && qIndex == 0">
                        <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%" [tooltip]="getQuestionTitle(qIndex)">
                            <i style="margin-left: -0.05em; margin-top: 0.04em" class="fas fa-info-circle"></i>
                        </div>
                    </ng-container>
                </div>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="!isPj">
        <!-- <div class="question-loc-sm">
            <tra slug="title_stage"></tra> {{testState.currentSectionIndex+1}},
            {{getQuestionTitle(testState.currentQuestionIndex)}}
        </div> -->
        <div  style="position:relative" #topBar [class.is-hidden]="isHidden" [ngSwitch]="useProgressBySession">
            <ng-container *ngSwitchCase="false || undefined">
                <progress  class="progress is-info" style="margin-bottom:0px;" [value]="currProgressLocAsNum" max="100">{{currProgressLoc}}</progress>
                <div *ngFor="let section of testRunnerSections; let isLast = last" class="section-progress-marker" [ngStyle]="{left: section.__meta.markLoc}" [class.is-hidden]="isLast"> </div>
            </ng-container>
            <ng-container  *ngSwitchCase="true">
                <progress class="progress is-info" style="margin-bottom:0px;" [value]="currProgressBySession" max="100"></progress>
            </ng-container>
        </div>
    </ng-container>
</div>