import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { renderDndElementStyle } from '../../ui-item-maker/element-config-dnd/element-config-dnd.component';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { ScoringTypes, QuestionState } from '../models';
import { IContentElementDnd, IContentElementDndSub, IContentElementDndDraggable, IElementPos } from './model';



const SCORING_TYPE = ScoringTypes.MANUAL;
@Component({
  selector: 'element-render-dnd',
  templateUrl: './element-render-dnd.component.html',
  styleUrls: ['./element-render-dnd.component.scss']
})
export class ElementRenderDndComponent implements OnInit, OnChanges {

  @Input() element:IContentElementDnd;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;
  
  backgroundElements:IElementPos[] = [];
  draggables:IElementPos[] = [];
  targets:IElementPos[] = [];

  constructor() { }

  ngOnInit() {
    this.updateDisplayEls();
  }

  ngOnChanges(changes:SimpleChanges){
    this.updateDisplayEls();
    // if (this.lastTrackedQuestionState !== this.questionState){
    //   this.lastTrackedQuestionState = this.questionState;
    //   if (this.questionState){
    //     this.handleNewState();
    //   }
    // }
    // if (this.isShowSolution && !this.isShowSolutionApplied){
    //   this.showSolution()
    //   this.isShowSolutionApplied = true;
    // }
  }

  updateDisplayEls(){
    // console.log('updateDisplayEls', elements.length, elements)
    this.backgroundElements = [];
    this.draggables = [];
    this.targets = [];
    this.element.backgroundElements.forEach(element => this.addElementToList(element, this.backgroundElements));
    this.element.targets.forEach(element => this.addElementToList(element, this.targets, true));
    this.element.draggables.forEach(element => this.addElementToList(element, this.draggables));
  }

  addElementToList(element:IContentElementDndSub, elementsToPosition:IElementPos[], isTarget:boolean=false){
    let hasElement:boolean = false;
    if ((<IContentElementDndDraggable> element).element){
      hasElement = true;
    }
    elementsToPosition.push({
      ref: element,
      originalX: element.x,
      originalY: element.y,
      isTarget,
      style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
    });
  }

}
