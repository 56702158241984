import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { WhitelabelService } from '../domain/whitelabel.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatpanelService {

  fcWidget;

  private signinSub:Subscription;

  constructor(
    private whiteLabel:WhitelabelService,
  ) {

    try {
      // this.fcWidget = window['fcWidget'];
      // this.fcWidget.init({
      //   token: "bb56b1f8-82b9-4a38-b92f-75d67102e720",
      //   host: "https://wchat.freshchat.com",
      //   siteId: whiteLabel.context,
      //   // locale: "en"
      // });
      // this.signinSub = this.auth.user.subscribe(userInfo => {
      //   if (userInfo){
      //     let firstName ='', lastName ='', email ='';
      //     let status = 'LOGGED_IN'
      //     // if (userInfo.firstName){
      //     //   firstName = userInfo.firstName;
      //     //   lastName = userInfo.lastName;
      //     // }
      //     // else{
      //     //   let nameSplit = userInfo.displayName.split(' ');
      //     //   firstName = nameSplit.splice(0, 1);
      //     //   lastName = nameSplit.join(' ');
      //     // }
      //     // this.fcWidget.user.setProperties({status, firstName, lastName, email})
      //     // this.fcWidget.setExternalId(userInfo.uid);
      //   }
      //   else {
      //     let status = 'LOGGED_OUT'
      //     // this.fcWidget.user.setProperties({status})
      //   }
      // });
    }
    catch(e){
      console.warn('trouble setting up the support chat')
    }
    
  }

  setLocale(locale:string){
    // should set up a subscription
    if (this.fcWidget){
      this.fcWidget.user.setLocale(locale);
    }
  }

  activate(){
    if (this.fcWidget){
      this.fcWidget.show();
    }
  }
  deactivate(){
    if (this.fcWidget){
      this.fcWidget.hide();
    }
  }
  expand(){
    if (this.fcWidget){
      this.fcWidget.open();
    }
  }
  collapse(){
    if (this.fcWidget){
      this.fcWidget.open();
    }
  }
}
