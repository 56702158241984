import {ILibraryAsset} from '../asset-details/types'

export enum AssetTypes {
  IMAGE  = 'IMAGE',
  VIDEO  = 'VIDEO',
  AUDIO  = 'AUDIO',
  GRAPHIC = 'GRAPHIC',
  TEXT   = 'TEXT',
  SUBTITLE = 'SUBTITLE',
  ITEM = 'ITEM'
}

export enum Modes {
  TEMP       = 'TEMP',
  NEW        = 'NEW',
  BATCH_NEW  = 'BATCH_NEW',
  SEARCH     = 'SEARCH',
  DETAIL     = 'DETAIL',
  NEW_PARAM  = 'NEW_PARAM'
}

export enum AssetField {
  ALT_TEXT = 'ALT_TEXT'
}

export interface IAssetGroup {
  id: number;
  name: string;
  assetIds: number[];
}

export const ALLOWED_ASSET_FILE_TYPES = 
{
  'IMAGE': ['JPG', 'JPEG', 'PNG', 'GIF', 'EPS'],
  'VIDEO': ['MP4', 'MOV', 'WEBM'],
  'AUDIO': ['AAC', 'WAV', 'MP3', 'OGG'],
  'GRAPHIC': [],
  'TEXT': ['DOCX', 'ODT', 'RTF', 'PDF', 'TXT', 'CSV', 'XLS'],
  'SUBTITLE': []
}
