<!-- <div class="overlay-container"> -->
<div>
    <div class="page-number">
        <button (click)="goToPreviousPage()" class="button is-small is-light has-icon">
            <span class="icon">
                <i class="fas fa-chevron-left"></i> 
            </span>
            <tra slug="btn_prev_bc"></tra>
        </button> <!-- Button to go to previous page -->
        <div class="page-number-inner">
            Page {{pageCurrent}} of {{pageMax}}
        </div>
        <button (click)="goToNextPage()"  class="button is-small is-light has-icon">
            <tra slug="btn_next_bc"></tra>
            <span class="icon">
                <i class="fas fa-chevron-right"></i>
            </span>
        </button> <!-- Button to go to next page -->
    </div>
    <div #paginationContainer 
        (scroll)="onScroll(paginationContainer)"    
        class="pagination-container" 
        [class.is-double-line]="isDoubleLine"
    >
        <div #richTextContainer class="rich-text-content"> </div>
    </div>
</div>
<!-- </div> -->