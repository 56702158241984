import { CLASS_FILTER_COURSE_MAPPING, ClassFilterId } from "../../my-school.service";

// tmp : remove entire dependency 
export const getClassCodePrefix = (classFilterId: ClassFilterId) => {
    switch(classFilterId){
        case ClassFilterId.Primary:
            return '_g3_';
        case ClassFilterId.Junior:
            return '_g6_';
        case ClassFilterId.G9:
            return ClassFilterId.G9
        case ClassFilterId.TCLE:
            return '_tcle_'
        case ClassFilterId.TCN:
            return '_tcn_'
        case ClassFilterId.SCIENCES8:
            return '_sciences8_'
        case ClassFilterId.LECTURE2:
            return '_lecture2_'
        case ClassFilterId.LECTURE7:
            return '_lecture7_'
        case ClassFilterId.MATH8:
            return '_math8_'
        case ClassFilterId.MATH6:
            return '_math6_'
        case ClassFilterId.ELPA:
            return '_elpa_'
        case ClassFilterId.NBED_DEMO:
            return '_demo_'
        case ClassFilterId.NBED_ANGLO_EN4:
            return '_anglo_en4_';
        case ClassFilterId.NBED_ANGLO_EN6:
            return '_anglo_en6_';
        case ClassFilterId.NBED_ANGLO_FI_READ10:
            return '_anglo_fi_read10_';
        case ClassFilterId.NBED_ANGLO_FR12:
            return '_anglo_fr12_';
        case ClassFilterId.NBED_ANGLO_FI_READ7:
            return '_anglo_fi_read7_';
        case ClassFilterId.NBED_ANGLO_FR5:
            return '_anglo_fr5_';
        case ClassFilterId.NBED_ANGLO_MATH7:
            return '_anglo_math7_';
        case ClassFilterId.NBED_ANGLO_SCI4:
            return '_anglo_sci4_';
        case ClassFilterId.NBED_ANGLO_SCI6:
            return '_anglo_sci6_';
        case ClassFilterId.NBED_ANGLO_SCI8:
            return '_anglo_sci8_';
        case ClassFilterId.NBED_ANGLO_MATH5:
            return '_anglo_math5_';
        case ClassFilterId.NBED_ANGLO_FI_READ5:
            return '_anglo_fi_read5_'
        case ClassFilterId.NBED_ANGLO_PIF_READ10:
            return '_anglo_pif_read10_'
        case ClassFilterId.NBED_ANGLO_PIF_READ7:
            return '_anglo_pif_read7_'
        case ClassFilterId.NBED_ECRITURE7:
            return '_ecriture7_'
        case ClassFilterId.NBED_FRANCO_DEMO:
            return '_franco_demo_'
        case ClassFilterId.OSSLT:
        default:
            return '_g10_'
    }
  }

export const getClassCodePropName = (currentClassFilter: ClassFilterId) => {
    const classCodeLabelPost = 'eqao_g9_class_code';
    const classCodeLabelPropNamePost = 'eqao_g9_class_code_label';

    if(currentClassFilter === ClassFilterId.G9) {
        return {
            classCodePropName: classCodeLabelPost, 
            classCodeLabelPropName: classCodeLabelPropNamePost 
        };
    }
        
    const prefix = getClassCodePrefix(currentClassFilter);
    const classCodePropName = prefix + classCodeLabelPost;
    const classCodeLabelPropName = prefix + classCodeLabelPropNamePost;
    return { classCodePropName, classCodeLabelPropName}
}


export const groupTypeToClassFilter = (group_type: string) => {
    const filtered = CLASS_FILTER_COURSE_MAPPING.list.filter(map => map.course_type === group_type);
    if(!filtered?.length) {
        console.error('CLASS_FILTER_NOT_FOUND', group_type);
        return undefined
    }
    return filtered[0].id
}

export const classFilterToGroupType = (classFilter: ClassFilterId | string) => {
    const filteredMap = CLASS_FILTER_COURSE_MAPPING.map[classFilter];
    if(!filteredMap){
        console.error('GROUP_TYPR_NOT_FOUND', classFilter);
        return undefined;
    } 
    return filteredMap.course_type;
}
