import {Component, Input, OnInit} from '@angular/core';

export enum GraphGroup {
  DISTRICT = 'District',
  SCHOOL   = 'School',
}

export enum Subject {
  NUMERACY = 'Numeracy',
  READING = 'Reading',
  WRITING = 'Writing',
}
export enum StudentType {
  ALL = 'All',
  FEMALE = 'Female',
  MALE = 'Male',
  INDIGENOUS = 'Indigenous',
  ELL = 'ELL',
  SPECIAL_NEEDS = 'Special Needs',
}
export enum GraphCategories {
  EXCEEDING = 'Exceeding',
  MEETING = 'Meeting',
  NOT_MEETING = 'Not Meeting',
}

@Component({
  selector: 'expectation-table',
  templateUrl: './expectation-table.component.html',
  styleUrls: ['./expectation-table.component.scss']
})
export class ExpectationTableComponent implements OnInit {

  GraphGroup = GraphGroup;

  @Input() records: any;

  // public graphRows = [GraphGroup.DISTRICT]; // GraphGroup
  public graphData = {[GraphGroup.DISTRICT]: {}, [GraphGroup.SCHOOL]: {}};
  public selected = [];

  public districtSchoolKeys = {[GraphGroup.DISTRICT]: [], [GraphGroup.SCHOOL]: []};
  public yearKeys = {[GraphGroup.DISTRICT]: {}, [GraphGroup.SCHOOL]: {}, };
  public studentTypeKeys = Object.values(StudentType);
  public graphGroupKeys = Object.values(GraphGroup);

  constructor() { }

  ngOnInit(): void {
    console.log('init:', this.records);
    this.processPieData(this.records);
    this.selected = [this.createNewRow()];
  }

  processPieData(records) {
    records.forEach(record => {
      const isParticipated = record.is_participated;

      // excluding students who have not participated.
      if (isParticipated) {
        const district = record.district_group_id;
        const school = record.school_group_id;
        const year = record.ac_year_ending;
        const subject = record.subject;

        const status = {
          exceeding: +record.is_exp_exceeding,
          meeting: +record.is_exp_meeting,
          notMeeting: +record.is_exp_not_yet,
        };

        const studentType = {
          isAboriginal: record.is_aboriginal,
          isEll: record.is_ell,
          isFemale: record.is_female,
          isMale: record.is_male,
          isSpecialNeeds: record.is_specneed
        };

        // districts
        if (district in this.graphData[GraphGroup.DISTRICT]) {
          if (year in this.graphData[GraphGroup.DISTRICT][district]) {
            this.addToSubject(GraphGroup.DISTRICT, 'All', 'All', subject, status, studentType);
            this.addToSubject(GraphGroup.DISTRICT, 'All', year, subject, status, studentType);
            this.addToSubject(GraphGroup.DISTRICT, district, 'All', subject, status, studentType);
            this.addToSubject(GraphGroup.DISTRICT, district, year, subject, status, studentType);
          } else {
            this.graphData[GraphGroup.DISTRICT]['All']['All'] = this.createEmptyData();
            this.addToSubject(GraphGroup.DISTRICT, 'All', 'All', subject, status, studentType);
            this.graphData[GraphGroup.DISTRICT]['All'][year] = this.createEmptyData();
            this.addToSubject(GraphGroup.DISTRICT, 'All', year, subject, status, studentType);

            this.graphData[GraphGroup.DISTRICT][district]['All'] = this.createEmptyData();
            this.addToSubject(GraphGroup.DISTRICT, district, 'All', subject, status, studentType);
            this.graphData[GraphGroup.DISTRICT][district][year] = this.createEmptyData();
            this.addToSubject(GraphGroup.DISTRICT, district, year, subject, status, studentType);
          }
        } else {
          if (!('All' in this.graphData[GraphGroup.DISTRICT])) {
            this.graphData[GraphGroup.DISTRICT]['All'] = {};
          }
          this.graphData[GraphGroup.DISTRICT]['All']['All'] = this.createEmptyData();
          this.addToSubject(GraphGroup.DISTRICT, 'All', 'All', subject, status, studentType);
          this.graphData[GraphGroup.DISTRICT]['All'][year] = this.createEmptyData();
          this.addToSubject(GraphGroup.DISTRICT, 'All', year, subject, status, studentType);

          this.graphData[GraphGroup.DISTRICT][district] = {};
          this.graphData[GraphGroup.DISTRICT][district]['All'] = this.createEmptyData();
          this.addToSubject(GraphGroup.DISTRICT, district, 'All', subject, status, studentType);
          this.graphData[GraphGroup.DISTRICT][district][year] = this.createEmptyData();
          this.addToSubject(GraphGroup.DISTRICT, district, year, subject, status, studentType);
        }

        // schools
        if (school in this.graphData[GraphGroup.SCHOOL]) {
          if (year in this.graphData[GraphGroup.SCHOOL][school]) {
            this.addToSubject(GraphGroup.SCHOOL, 'All', 'All', subject, status, studentType);
            this.addToSubject(GraphGroup.SCHOOL, 'All', year, subject, status, studentType);
            this.addToSubject(GraphGroup.SCHOOL, school, 'All', subject, status, studentType);
            this.addToSubject(GraphGroup.SCHOOL, school, year, subject, status, studentType);
          } else {
            this.graphData[GraphGroup.SCHOOL]['All']['All'] = this.createEmptyData();
            this.addToSubject(GraphGroup.SCHOOL, 'All', 'All', subject, status, studentType);
            this.graphData[GraphGroup.SCHOOL]['All'][year] = this.createEmptyData();
            this.addToSubject(GraphGroup.SCHOOL, 'All', year, subject, status, studentType);

            this.graphData[GraphGroup.SCHOOL][school]['All'] = this.createEmptyData();
            this.addToSubject(GraphGroup.SCHOOL, school, 'All', subject, status, studentType);
            this.graphData[GraphGroup.SCHOOL][school][year] = this.createEmptyData();
            this.addToSubject(GraphGroup.SCHOOL, school, year, subject, status, studentType);
          }
        } else {
          if (!('All' in this.graphData[GraphGroup.SCHOOL])) {
            this.graphData[GraphGroup.SCHOOL]['All'] = {};
          }
          this.graphData[GraphGroup.SCHOOL]['All']['All'] = this.createEmptyData();
          this.addToSubject(GraphGroup.SCHOOL, 'All', 'All', subject, status, studentType);
          this.graphData[GraphGroup.SCHOOL]['All'][year] = this.createEmptyData();
          this.addToSubject(GraphGroup.SCHOOL, 'All', year, subject, status, studentType);

          this.graphData[GraphGroup.SCHOOL][school] = {};
          this.graphData[GraphGroup.SCHOOL][school]['All'] = this.createEmptyData();
          this.addToSubject(GraphGroup.SCHOOL, school, 'All', subject, status, studentType);
          this.graphData[GraphGroup.SCHOOL][school][year] = this.createEmptyData();
          this.addToSubject(GraphGroup.SCHOOL, school, year, subject, status, studentType);
        }
      }

    });

    // get all the keys. The titles for each graph dropdown.
    this.districtSchoolKeys[GraphGroup.DISTRICT] = Object.keys(this.graphData[GraphGroup.DISTRICT]).reverse();
    this.districtSchoolKeys[GraphGroup.SCHOOL] = Object.keys(this.graphData[GraphGroup.SCHOOL]).reverse();

    this.districtSchoolKeys[GraphGroup.DISTRICT].forEach(key => {
      this.yearKeys[GraphGroup.DISTRICT][key] = Object.keys(this.graphData[GraphGroup.DISTRICT][key]).reverse();
    });
    this.districtSchoolKeys[GraphGroup.SCHOOL].forEach(key => {
      this.yearKeys[GraphGroup.SCHOOL][key] = Object.keys(this.graphData[GraphGroup.SCHOOL][key]).reverse();
    });

    console.log(this.selected);

  }

  createNewRow() {
    let row = {
      group: GraphGroup.DISTRICT,
      id: this.districtSchoolKeys[GraphGroup.DISTRICT][0],
    };
    row['year'] = this.yearKeys[row.group][row.id][0];
    row['primary'] = this.studentTypeKeys[0];
    row['secondary'] = this.studentTypeKeys[0];
    row['numeracy'] = this.graphData[row.group][row.id][row['year']][Subject.NUMERACY][row['primary']];
    row['reading'] = this.graphData[row.group][row.id][row['year']][Subject.READING][row['primary']];
    row['writing'] = this.graphData[row.group][row.id][row['year']][Subject.WRITING][row['primary']];

    return row;
  }

  createEmptyData() {
    return {
      [Subject.NUMERACY]: {
        [StudentType.ALL]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.FEMALE]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.MALE]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.INDIGENOUS]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.ELL]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.SPECIAL_NEEDS]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
      },
      [Subject.READING]: {
        [StudentType.ALL]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.FEMALE]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.MALE]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.INDIGENOUS]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.ELL]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.SPECIAL_NEEDS]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
      },
      [Subject.WRITING]: {
        [StudentType.ALL]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.FEMALE]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.MALE]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.INDIGENOUS]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.ELL]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
        [StudentType.SPECIAL_NEEDS]: [
          {'name': GraphCategories.EXCEEDING, value: 0},
          {'name': GraphCategories.MEETING, value: 0},
          {'name': GraphCategories.NOT_MEETING, value: 0}
        ],
      },
    };
  }

  addToSubject(type, id, year, subject, status, studentType) {
    if (+studentType.isAboriginal) {
      this.graphData[type][id][year][subject][StudentType.INDIGENOUS][0].value += +status.exceeding;
      this.graphData[type][id][year][subject][StudentType.INDIGENOUS][1].value += +status.meeting;
      this.graphData[type][id][year][subject][StudentType.INDIGENOUS][2].value += +status.notMeeting;
    }
    if (+studentType.isEll) {
      this.graphData[type][id][year][subject][StudentType.ELL][0].value += +status.exceeding;
      this.graphData[type][id][year][subject][StudentType.ELL][1].value += +status.meeting;
      this.graphData[type][id][year][subject][StudentType.ELL][2].value += +status.notMeeting;
    }
    if (+studentType.isFemale) {
      this.graphData[type][id][year][subject][StudentType.FEMALE][0].value += +status.exceeding;
      this.graphData[type][id][year][subject][StudentType.FEMALE][1].value += +status.meeting;
      this.graphData[type][id][year][subject][StudentType.FEMALE][2].value += +status.notMeeting;
    }
    if (+studentType.isMale) {
      this.graphData[type][id][year][subject][StudentType.MALE][0].value += +status.exceeding;
      this.graphData[type][id][year][subject][StudentType.MALE][1].value += +status.meeting;
      this.graphData[type][id][year][subject][StudentType.MALE][2].value += +status.notMeeting;
    }
    if (+studentType.isSpecialNeeds) {
      this.graphData[type][id][year][subject][StudentType.SPECIAL_NEEDS][0].value += +status.exceeding;
      this.graphData[type][id][year][subject][StudentType.SPECIAL_NEEDS][1].value += +status.meeting;
      this.graphData[type][id][year][subject][StudentType.SPECIAL_NEEDS][2].value += +status.notMeeting;
    }

    this.graphData[type][id][year][subject][StudentType.ALL][0].value += +status.exceeding;
    this.graphData[type][id][year][subject][StudentType.ALL][1].value += +status.meeting;
    this.graphData[type][id][year][subject][StudentType.ALL][2].value += +status.notMeeting;

  }

  changeGroupDropdown(e, i) {
    this.selected[i].group = e.target.value;
    this.selected[i].id = this.districtSchoolKeys[this.selected[i].group][0];
    this.selected[i].year = this.yearKeys[this.selected[i].group][this.selected[i].id][0];

    this.updateGraphs(i);
  }
  changeIdDropdown(e, i) {
    this.selected[i].id = e.target.value;
    this.updateGraphs(i);
  }
  changeYearDropdown(e, i) {
    this.selected[i].year = e.target.value;
    this.updateGraphs(i);
  }
  changePrimaryDropdown(e, i) {
    this.selected[i].primary = e.target.value;
    this.updateGraphs(i);
  }
  changeSecondaryDropdown(e, i) {
    this.selected[i].secondary = e.target.value;
    this.updateGraphs(i);
  }

  updateGraphs(i) {
    this.selected[i].numeracy = this.graphData[this.selected[i].group][this.selected[i].id][this.selected[i].year][Subject.NUMERACY][this.selected[i].primary];
    this.selected[i].reading = this.graphData[this.selected[i].group][this.selected[i].id][this.selected[i].year][Subject.READING][this.selected[i].primary];
    this.selected[i].writing = this.graphData[this.selected[i].group][this.selected[i].id][this.selected[i].year][Subject.WRITING][this.selected[i].primary];
  }

  addComparison() {
    this.selected.push(this.createNewRow());
  }

  removeComparison(i) {
    this.selected.splice(i, 1);
  }

}
