export enum PARAM_SPECIAL_FLAGS {
    SINGLE_RESPONSE_ENTRY = 'single_response_entry',
    CLONE_LINKER = 'clone_linker',
    WORD_COUNT = 'word_count',
    PSYCHO_EDIT = 'psycho_edit',
    VISIBLE_TO_EDITOR = 'visible_to_editor',
    EDITABLE_BY_EDITOR = 'editable_by_editor',
    LANG_INDEPENDENT = 'lang_independent',
    TOTAL_TEST_TAKERS = 'total_test_takers',
  }
  