import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from "src/app/bc-accounts/bc-accounts.service";
import { LangService } from "src/app/core/lang.service";

@Component({
  selector: 'bc-paginator',
  templateUrl: './bc-paginator.component.html',
  styleUrls: ['./bc-paginator.component.scss']
})
export class BcPaginatorComponent implements OnInit {
  @Input() paginator: Pagination;
  @Output() refresh = new EventEmitter();
  @Input() disabled: boolean = false;
  @Input() showCount: boolean = false;
  @Input() getCountDisplay: (page: number, total: number) => string;

  constructor(private lang: LangService) { }

  ngOnInit(): void {

  }

  getCurrentPageToDisplay(): number {
    const page = Math.floor(this.paginator.skip / this.paginator.size) + 1;
    return page === 0 ? 1 : page;
  }

  getTotalPagesToDisplay(): number {
    if (this.paginator.count === undefined) return 1;
    const pages = Math.ceil(this.paginator.count / this.paginator.size);
    return pages === 0 ? 1 : pages;
  }

  goToPreviousPage() {
    this.paginator.skip -= this.paginator.size;
    this.refresh.emit();
  }

  shouldDisablePreviousPage(): boolean {
    return this.paginator.isLoading || this.paginator.skip - this.paginator.size < 0;
  }

  goToNextPage() {
    this.paginator.skip += this.paginator.size;
    this.refresh.emit();
  }

  shouldDisableNextPage(): boolean {
    return this.paginator.isLoading || this.paginator.skip + this.paginator.size >= this.paginator.count;
  }

  getCount(): string {
    let page = this.getCurrentPageToDisplay();
    let total = this.paginator.count || 0;

    if (this.getCountDisplay) {
      return this.getCountDisplay(page, total);
    }

    return `${total} `+ this.lang.tra('sa_total_rows_bc');
  }

}
