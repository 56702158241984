<div
    [class.mouse-over]="isCustomMode()"
    [class.is-perm-tooltip]="element.isPermanentTooltip"
>
    <div [style.font-size.em]="element.buttonScale">
        <render-audio 
            #renderAudio
            [audioID]="'audio'+element.entryId"
            (play)="play()" 
            (onPlay)="onPlay()" 
            (end)="playEnd()"
            [isTriggerDisabled]="!isEnabled()"
            [hasControls]="hasControls()" 
            [startTime]="getStartTime()"
            [preventOverload]="true"
            [trigger]="trigger" 
            [isDisabled]="!isEnabled()" 
            *ngIf="element" 
            (timeUpdate)="onTimeUpdate()"
            [url]="element.url"
            (canStartPlaying)="canStartPlaying()"
            [mode]="element.displayMode"
        ></render-audio>
    </div>
    <div 
        *ngIf="!element.isHidden && isCustomMode()" 
        class="on-mouse-over" 
        [class.is-align-top]="element.toolTipPosition==ToolTipPositions.TOP || !element.toolTipPosition"
        [class.is-align-bottom]="element.toolTipPosition==ToolTipPositions.BOTTOM"
        [class.is-align-right]="element.toolTipPosition==ToolTipPositions.RIGHT"
        [class.is-align-left]="element.toolTipPosition==ToolTipPositions.LEFT"
    >
        <div class="on-mouse-over-offset" >
            <div>
                <div [style]="getRemainingPlayStyle()" *ngIf="resetter && element.numAudioPlays && element.isLimitAudioPlays && !element.isHidden">
                    <tra-md [isCondensed]="true" [slug]="getNumPlaysRemainingSlug()" [props]="{NUM_REM_PLAYS: element.numAudioPlays-getNumViews()}"></tra-md>
                </div>
                <div  [style]="getRemainingSecStyle()"> {{getSecRemainingStr()}} </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="allowPlaybackSpeed"> 
    <tra-md [isCondensed]="true" slug="bc_authoring_view_playbackrate"></tra-md>
    <div class="control is-expanded">
      <div class="select">
        <select [(ngModel)]="playBackRate" (ngModelChange)="adjustRate()">
          <option *ngFor="let rate of playBackRates; let index = index" [value]="rate">
            {{rate}}
          </option>
        </select>
      </div>
    </div>  
</div>

<div *ngIf="allowTranscripts" class="transcript">
  <button *ngIf="transcriptUrl" class="transcript-toggle" (click)="showTranscript()">
    {{transcriptOn ? 'Hide Transcript' : 'Show Transcript'}}
  </button>
  <div class="transcript-content" [innerHtml]="transcript" *ngIf="transcriptOn && transcriptUrl">
</div>

</div>
