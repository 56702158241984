<fieldset [disabled]="disabled || isReadOnly()">
    <div style="display:flex; justify-content: space-between;">
        <button *ngIf="element" (click)="updateContentElement()">
            <tra slug="auth_asset_library"></tra>
        </button>
        <button *ngIf="itemElement && !itemElement.assetId" class="button is-small" (click)="createAssetForItem()">
            <tra slug="ie_export_to_asset_lib"></tra>
        </button>
        <button *ngIf="itemElement && itemElement.assetId" class="button is-small" (click)="importItem(itemElement)">
            <tra slug="Edit Asset"></tra>
        </button>
        <span>
            <span *ngIf="element && element.assetId">
                <tra slug="auth_asset_id_in_use"></tra> <b>{{element.assetId}}</b>
            </span>
            <span *ngIf="itemElement && itemElement.assetId">
                <tra slug="auth_asset_id_in_use"></tra> <b>{{itemElement.assetId}}</b>
            </span>
        </span>
    </div>
</fieldset>