<div class="textarea-container">
    <textarea 
        placeholder="Type here..." 
        class="long-input"
        cdkTextareaAutosize 
        [cdkTextareaAutosize]="true" 
        [cdkAutosizeMinRows]="2"
        #textArea
    ></textarea>
    <div *ngIf="isKeyboardButtonsEnabled" class="buttons">
        <button class="button is-small" (click)="appendChar(char)" *ngFor="let char of chars">
            {{char}}
        </button>
    </div>
    <div class="buttons show-on-hover" style="flex-direction:row; justify-content: flex-end;">
        <button (click)="toggleKeyboardButtons()" class="button is-small">
            <i class="fas fa-ellipsis-h"></i>
        </button>
    </div>

</div>