import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineChartModule, PieChartModule, BarChartModule } from '@swimlane/ngx-charts';

import { PieGraphComponent } from './pie-graph/pie-graph.component';
import { LineGraphComponent } from './line-graph/line-graph.component';
import { HistogramComponent } from './histogram/histogram.component';



@NgModule({
  declarations: [
    HistogramComponent,
    PieGraphComponent,
    LineGraphComponent],
  imports: [
    CommonModule,
    LineChartModule,
    PieChartModule,
    BarChartModule
  ],
  exports: [
    HistogramComponent,
    PieGraphComponent,
    LineGraphComponent
  ]
})
export class UiChartsModule { }
