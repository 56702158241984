<fieldset [disabled]="isReadOnly()">
  <div class="field has-addons" style="margin-top:1em;">
    <div class="control is-expanded">
      <div [class.is-disabled]="isReadOnly()" class="select is-fullwidth">
        <select [formControl]="elementTypeForInsertion">
          <option *ngFor="let elementType of elementTypes; let index = index" [value]="index">
            {{lang.tra(elementType.caption)}}
          </option>
        </select>
      </div>
    </div>
    <div class="control">
      <button type="submit" [disabled]="isReadOnly()" (click)="insertListEntry()" class="button is-primary">Add</button>
    </div>
  </div>
</fieldset>