import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';


@Injectable({
  providedIn: 'root'
})
export class StudentAssistiveTechService {
  studentAssistiveTechStatus:boolean = false;
  constructor(
  ) {
   }

   setStudentAssistiveTechStatus(status:boolean){
       this.studentAssistiveTechStatus = status;
   }

}
