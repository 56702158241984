import { Injectable } from "@angular/core";
import moment from "moment";
import { AuthService } from "../api/auth.service";
import { RoutesService } from "../api/routes.service";
import { Filter, FilterCondition, PaginatedRows, Pagination } from "../bc-accounts/bc-accounts.service";
import { DailySubmissionSummaryReport, DailySubmissionSummaryReportParams, DistrictRollUpReport, DistrictRollUpReportParams, DistrictRollUpReportRow, EnrollmentExtract1300CReportParams, EnrollmentExtract1300CReportRow, EnrollmentExtract1300DReportParams, EnrollmentExtract1300DReportRow, SchoolCompletionReportParams, SchoolCompletionReport, SchoolsNotStartedReportParams, SchoolsNotStartedReportRow, IndividualRawScoresReport, IndividualRawScoresReportParams, CognitiveLevelScoresReportParams, CognitiveLevelScoresReport, StudentSelfReflectionReportParams, StudentSelfReflectionReport, ReportType, StudentLoginReportParams, StudentLoginReportRow, StudentResponseTimesReportParams, StudentResponseTimesReportRow, DailyDistrictSubmissionReport, DailyDistrictSubmissionReportParams, EnrollmentExtract1300DReportExportParams, SchoolsNotStartedReportExportParams, SchoolCompletionReportExportParams, StudentLoginReportExportParams, StudentResponseTimesReportExportParams, ConfirmationCodeReportParams, ConfirmationCodeReportRow, IndividualScaledScoresReportParams } from "./types";

@Injectable({
    providedIn: 'root'
})
export class BcReportsService {

    constructor(private auth: AuthService, private routes: RoutesService) {

    }



    /* Daily Submission Summary Report */

    getDailySumissionReport(params: DailySubmissionSummaryReportParams, pagination: Pagination): Promise<DailySubmissionSummaryReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.DAILY_SUBMISSION_SUMMARY,
                start_date: params.start_date.toISOString(),
                end_date: params.end_date.toISOString(),
                pagination,
            }
        });
    }

    async exportDailySubmissionSummaryReport(params: DailySubmissionSummaryReportParams) {
        let filename = `Daily-Submission-Summary-Report-${moment(params.start_date).format('YYYY-MM-DD')}-to-${moment(params.end_date).format('YYYY-MM-DD')}`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.DAILY_SUBMISSION_SUMMARY,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* District Roll Up Report */

    getDistrictRollUpReport(params: DistrictRollUpReportParams, pagination: Pagination): Promise<DistrictRollUpReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.DISTRICT_ROLL_UP,
                grades: JSON.stringify(params.grades),
                test_window_id: params.test_window_id,
                pagination,
            }
        });
    }

    async exportDistrictRollUpReport(params: DistrictRollUpReportParams) {
        let filename = `District-Roll-Up-Report-Grade(s)-${params.grades.join('-')}`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.DISTRICT_ROLL_UP,
                grades: JSON.stringify(params.grades),
                test_window_id: params.test_window_id,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* Enrollment Extract 1300C Report */

    getEnrollmentExtract1300cReport(params: EnrollmentExtract1300CReportParams, pagination: Pagination): Promise<EnrollmentExtract1300CReportRow[]> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.ENROLLMENT_EXTRACT_1300_C,
                load: params.load,
                pagination,
            }
        });
    }

    async exportEnrollmentExtract1300cReport() {
        let filename = `Summary-of-Enrolled-Students-from-Sept-and-Feb-1701-Report-Data`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.ENROLLMENT_EXTRACT_1300_C,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* Enrollment Extract 1300D Report */

    getEnrollmentExtract1300dReport(params: EnrollmentExtract1300DReportParams, pagination: Pagination): Promise<PaginatedRows<EnrollmentExtract1300DReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.ENROLLMENT_EXTRACT_1300_D,
                load: params.load,
                district_group_id: params.district_group_id,
                pagination,
            }
        });
    }

    async exportEnrollmentExtract1300dReport(params: EnrollmentExtract1300DReportExportParams) {
        let filename = `Summary-of-Participating-Schools-and-Students-(${params.district_name})`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.ENROLLMENT_EXTRACT_1300_D,
                district_group_id: params.district_group_id,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* School Completion Report */

    getSchoolCompletionReport(params: SchoolCompletionReportParams, pagination: Pagination): Promise<SchoolCompletionReport> {
        if (params.school_group_id && params.school_group_id > 0) {
            if (!pagination.filters) {
                pagination.filters = []
            }
            let map = new Map<string, Filter>();
            pagination.filters.map(f => {
                map.set(f.field, f);
            });
            map.set('school_group_id', {
                field: 'school_group_id',
                value: params.school_group_id,
                condition: FilterCondition.MATCH,
            })
            pagination.filters = [...map.values()];
        }
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.SCHOOL_COMPLETION,
                district_group_id: params.district_group_id,
                test_window_id: params.test_window_id,
                grades: JSON.stringify(params.grades),
                pagination,
            }
        });
    }

    async exportSchoolCompletionReport(params: SchoolCompletionReportExportParams) {
        let filename = `School-Completion-Report-(${params.district_name})`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.SCHOOL_COMPLETION,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* Schools Not Started Report */

    getSchoolsNotStartedReport(params: SchoolsNotStartedReportParams, pagination: Pagination): Promise<PaginatedRows<SchoolsNotStartedReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.SCHOOLS_NOT_STARTED,
                district_group_id: params.district_group_id,
                school_group_id: params.school_group_id,
                grades: JSON.stringify(params.grades),
                test_window_id: params.test_window_id,
                pagination,
            }
        });
    }

    async exportSchoolsNotStartedReport(params: SchoolsNotStartedReportExportParams) {
        let filename = `Schools-Not-Started-the-Online-FSA-and-CR-Score-Entry-(${params.district_name})`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.SCHOOLS_NOT_STARTED,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }

    getIndividualRawScoresReport(params: IndividualRawScoresReportParams, pagination: Pagination): Promise<IndividualRawScoresReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.INDIVIDUAL_RAW_SCORES,
                ...params,
                pagination,
            }
        });
    }

    getIndividualScaledScoresReport(params: IndividualScaledScoresReportParams, pagination: Pagination): Promise<IndividualRawScoresReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.INDIVIDUAL_SCALED_SCORES,
                ...params,
                is_scaled: 1,
                pagination,
            }
        });
    }

    getCognitiveLevelScoresReport(params: CognitiveLevelScoresReportParams, pagination: Pagination): Promise<CognitiveLevelScoresReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.COGNITIVE_LEVEL_SCORES,
                ...params,
                pagination,
            }
        });
    }

    getStudentSelfReflectionReport(params: StudentSelfReflectionReportParams, pagination: Pagination): Promise<StudentSelfReflectionReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.STUDENT_SELF_REFLECTION,
                ...params,
                pagination,
            }
        });
    }


    /* Student Login Report */

    getStudentLoginReport(params: StudentLoginReportParams, pagination: Pagination): Promise<PaginatedRows<StudentLoginReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.STUDENT_LOGIN,
                ...params,
                pagination,
            }
        });
    }

    async exportStudentLoginReport(params: StudentLoginReportExportParams) {
        let filename = `Student-Login-Report`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.STUDENT_LOGIN,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* Student Response Times Report */

    getStudentResponseTimesReport(params: StudentResponseTimesReportParams, pagination: Pagination): Promise<PaginatedRows<StudentResponseTimesReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.STUDENT_RESPONSE_TIME,
                ...params,
                pagination,
            }
        });
    }

    async exportStudentResponseTimesReport(params: StudentResponseTimesReportExportParams) {
        let filename = `Student-Response-Times`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.STUDENT_RESPONSE_TIME,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }



    /* Daily District Submission Report */

    getDailyDistrictSubmissionReport(params: DailyDistrictSubmissionReportParams, pagination: Pagination): Promise<DailyDistrictSubmissionReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.DAILY_DISTRICT_SUBMISSION,
                ...params,
                pagination,
            }
        });
    }

    async exportDailyDistrictSubmissionReport(params: DailyDistrictSubmissionReportParams) {
        let filename = `Daily-District-Submission-Report-${moment(params.start_date).format('YYYY-MM-DD')}-to-${moment(params.end_date).format('YYYY-MM-DD')}`;
        // let filename = 'Daily-District-Submission-Report';
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.DAILY_DISTRICT_SUBMISSION,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }

    /* Confirmation Code Report */

    async getConfirmationCodeReport(params: ConfirmationCodeReportParams, pagination: Pagination): Promise<PaginatedRows<ConfirmationCodeReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.CONFIRMATION_CODEs,
                ...params,
                pagination,
            }
        });
    }

    async exportConfirmationCodeReport(params: ConfirmationCodeReportParams) {
        let filename = `Confirmation-Code-Report`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.CONFIRMATION_CODEs,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }


}

