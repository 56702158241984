import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor() { }

  public scrollToTop(){
    window.scrollTo(0,0);
  }
  
}
