import { TMovableDndEl } from "../../ui-item-maker/element-config-moveable-dnd/utils/generate-dnd-id";
import { IContentElementDndDraggable, IContentElementDndSub, IContentElementDndTarget, IElementPos } from "../element-render-dnd/model";
import { IContentElementImage } from "../element-render-image/model";
import { IContentElement, IEntryStateScored, IScoredResponse } from "../models";

export interface IContentElementMoveableDragDrop extends IContentElement, IScoredResponse {
  width?: number;
  height?: number;
  useUniqueId?: boolean;
  draggables: IContentElementDndDraggable[];
  targets: IContentElementDndTarget[];
  isTargetsInvisible?: boolean;
  isDraggablesInvisible?: boolean;
  draggableCounter?: number;
  isDragsTransparent?: number;
  isTargetColourSame?: boolean;
  isAllTargetsToPlace?: boolean;
  isDraggableColourSame?: boolean;
  hasFrame?: boolean;
  moveableImages?: any[];
  targetColour?: string;
  draggableColour?: string;
  backgroundImg?: IContentElementImage;
  isOptionsReusable?: boolean;
  isCustomValidataion?: boolean;
  isMultipleOptionsRight?: boolean;
  pairMapping?: IDPairing[];
  isDraggableNotResizable?: boolean;
  isParaFullWidth?: boolean;
  isHideDraggableDropShadow?: boolean;
  isAcceptMultipleCombinations?: boolean;
  multipleCombinations?: any[]
  gradingMode: GradingType;
  howManyToFill?: number;
  isNoInvertDragPreview?: boolean
}

export interface IEntryStateMoveableDragAndDrop extends IEntryStateScored {
  targets: any[];
  isKeyIdUsed?: boolean,
  simplifiedStateTargets?: {key: string, contents: number[]}[]
}

export interface IContentElementLocAndDims extends IContentElement {
  x: number;
  y: number;
  width: number;
  height: number;
  padding: number;
}

export enum GradingType {
  PLUS_MINUS = "plus_minus",
  REDUCTION = "reduction",
  NORMAL = "normal"
}

export interface IDPairing {
  optionID?: string;
  targetID?: string;
}

export const moveableDndEditInfo = {
  editExcludeFields: ['draggables', 'targets', 'backgroundImg']
}

export interface ISimplifiedDndState {
  __uniqueId: string,
  key_id: string,
  id: number,
  caption?: string
}
export interface ISimplifiedDraggableState extends ISimplifiedDndState {}

export interface ISimplifiedTargetState extends ISimplifiedDndState{
  contents: ISimplifiedDraggableState[],
  isTarget: boolean
}

export interface IDndTargets {
  targetContext: IContentElementDndTarget; 
  contents: IElementPos[];
  originalPosition?: boolean
  isTarget?: boolean
}

export interface ILoadDndElOptions {
  applyStyle?: boolean
}

// took from Grouping component
export const renderDndElementStyle = (element: IContentElementDndSub, hasContent: boolean= false, isCustomDim: boolean= false, defaultTargetStyle: IContentElementDndSub= null) => {
  const style: {[key: string]: string | number} = {
    'transform': `translate3d(${element.x}px, ${element.y}px, 0px)`,
  };
  if (!hasContent) { // using as a proxy for targets right now...
    const dims = ['width', 'height'];
    dims.forEach(dim => {
      let val;
      if (isCustomDim) {
        val = element[dim];
      }
      if (!val && defaultTargetStyle) {
        val = defaultTargetStyle[dim];
      }
      if (!val) {
        val = 30;
      }
      style[dim + '.px'] = val;
    });
  }
  return style;
};

export const getDndDraggables = (draggables, simplified: boolean) => {
  if(simplified){
    return getDndQueStateSimplifiedDraggables(draggables)
  }
  return draggables;
}

export const getDndTargets = (targets, simplified?: boolean) => {
  if(simplified) {
    return getDndQueStateSimplifiedTargets(targets);    
  }
  return targets;
}

export const getDndQueStateSimplifiedCommonProps = (el): ISimplifiedDndState => {
  return {
    __uniqueId: el.__uniqueId,
    key_id: el.key_id,
    id: el.id,
    caption: (<any>el).caption ?? el.content ?? el.element?.caption
  }
}


export const getDndQueStateSimplifiedTargets  = (targets): ISimplifiedTargetState[] => {
  const simplifiedTargetState = targets.map(target => {
    return {
      ...getDndQueStateSimplifiedCommonProps(target.targetContext),
      contents: getDndQueStateSimplifiedDraggables(target.contents), //[ ....map(content => getDndQueStateSimplifiedTargetProps(content.ref)) ]
      isTarget: target.isTarget 
    }
  });

  return simplifiedTargetState;
}

export const getDndQueStateSimplifiedDraggables  = (draggables: IElementPos[]): ISimplifiedDraggableState[] => {
  return draggables.map(draggable => getDndQueStateSimplifiedCommonProps(draggable.ref));
}

// restore elem methods

const loadDndElFromSimplifiedState = (element: any, simplifiedEl: ISimplifiedDndState, isTarget: boolean) => {
  const { __uniqueId, id } = simplifiedEl;
    
  const el = findDndEl(
    getDndElArr(element, isTarget), 
    getDndElIdentityProp(__uniqueId), 
    getDndElIdentityVal(__uniqueId, id)
  );

  if(!el) console.error('DnD Element not found')

  return el;
}

export const loadTargetEls = (element: any, simplifiedEls: ISimplifiedTargetState[], options?: ILoadDndElOptions) => {
  return simplifiedEls.map(simplifiedEl => loadTargetEl(element, simplifiedEl, options)); 
}

export const loadTargetEl = (element: any, simplifiedEl: ISimplifiedTargetState, options?: ILoadDndElOptions) => {
  const { isTarget, contents } = simplifiedEl;
  const targetEl = loadDndElFromSimplifiedState(element, simplifiedEl, isTarget)
  const dragEls = loadDraggableEls(element, contents, options);
  return baseElToTarget(targetEl, dragEls, !isTarget, isTarget);
}

export const loadDraggableEls = (element: any, simplifiedEls: ISimplifiedDraggableState[], options?: ILoadDndElOptions) => {
  return simplifiedEls.map(simplifiedEl => loadDraggableEl(element, simplifiedEl, options)); 
}

const loadDraggableEl = (element: any, simplifiedEl: ISimplifiedDraggableState, options?: ILoadDndElOptions) => {  
  const dragElConfig = loadDndElFromSimplifiedState(element, simplifiedEl, false);
  return baseElToDraggable(element, <IContentElementDndSub>dragElConfig, [], false, options);
}

// utils

export const findDndEl = (arr: TMovableDndEl[]| any[], prop: string, val: string | number): TMovableDndEl | any => {
  return arr.find(a => a[prop] === val);
}

// updated items should have __uniqueId otherwise should be update either through audit or just by opening in the authoring view and saving
// using id is depricated to identify a DnD element
export const getDndElIdentityProp = (__uniqueId: string) => __uniqueId ? '__uniqueId' : 'id';
export const getDndElIdentityVal = (__uniqueId: string, id: number) => __uniqueId ?? id;
export const getDndElArr = (element: any, isTarget: boolean) => isTarget ? element.targets : element.draggables;


export const baseElToTarget = (element: IContentElementDndTarget | IContentElementDndDraggable, contents:any[], originalPosition: boolean, isTarget: boolean) => {
  return {
    targetContext: element,
    contents,
    originalPosition,
    isTarget
  }  
}

export const baseElToDraggable = (element: any, dragElement: IContentElementDndSub, elementsToPosition: IElementPos[], isTarget: boolean= false, options?: ILoadDndElOptions): IElementPos => {
  let hasElement  = false;
  if ((<IContentElementDndDraggable> dragElement).element) {
    hasElement = true;
  }

  let style = {};
  
  if (options?.applyStyle) {
    style = renderDndElementStyle(dragElement, hasElement, isTarget && element.customTargetDim, element.defaultTargetStyle)
  }
  
  const dragEl = {
    ref: dragElement,
    originalX: dragElement.x,
    originalY: dragElement.y,
    isTarget,
    style 
  }

  elementsToPosition.push(dragEl);
  return dragEl
}