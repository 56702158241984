import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewEnsHomepageComponent } from './view-ens-homepage/view-ens-homepage.component';


const routes: Routes = [
    { path: `home`, component: ViewEnsHomepageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UiWhitelabelEnsRoutingModule { }
