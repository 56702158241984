import { FormControl, FormArray, FormGroup } from "@angular/forms";


export const bindFormControls = (element:{[key:string]:any}, bindings:Array<{f:FormControl|FormArray|FormGroup, p:string, isFormArray?:boolean}>, changeCounter?: boolean) => {
    bindings.forEach(binding => {
        bindFormControl(binding.f, element, binding.p, binding.isFormArray, changeCounter);
    })
}

export const updateChangeCounter = (element) => {
    if (!element._changeCounter){
        element._changeCounter = 0;
    }
    element._changeCounter ++;
}

export const bindFormControl = (formControlorArray:FormControl|FormArray|FormGroup, element:{[key:string]:any}, property:string, isFormArray?:boolean, changeCounter?: boolean) => {
    if (element[property]){
        if (isFormArray){
            let formArray:FormArray = <FormArray>formControlorArray;
            element[property].forEach(entry => {
                formArray.push(new FormControl(entry));
            })
            // console.log('formArray', formArray)
            // formArray.setValue(element[property])
        }
        else{
            let formControl:FormControl|FormGroup = <FormControl|FormGroup>formControlorArray;
            formControl.setValue(element[property]);
        }
    }
    formControlorArray.valueChanges.subscribe( () => {
        element[property] = formControlorArray.value;
        if(changeCounter) {
            updateChangeCounter(element);
        }
    });
}

