
<div *ngIf="getSupportReq() | async" class="enlarged-popup-content content">
  <div *ngIf="supportCenterInfo">
    <tra-md [slug]="supportCenterInfo.text"></tra-md>
    <div style="margin-top:1em;">
      <table class="table is-bordered">
        <tr *ngIf="getEmail()">
          <th> <tra slug="lbl_fp_email"></tra> </th>
          <td><a [href]="emailUrl">{{getEmail()}}</a></td>
        </tr>
        <tr *ngIf="isPhoneDisplayed()">
          <th><tra slug="cts_location_phone_lbl"></tra></th>
          <td><tra-md [slug]="supportCenterInfo.phone"></tra-md></td>
        </tr>
      </table>
    </div>
  </div>
  <div style="margin-top:1em;">
    <button (click)="dismiss.emit()" class="button is-warning">
      <tra slug="btn_dismiss_notif"></tra>
    </button>
  </div>
</div>
    
      