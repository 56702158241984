import { IContentElement, IEntryStateScored } from "../models";
import { IQPubSubPayload } from "../question-runner/pubsub/question-pubsub";

export interface IContentElementMic extends IContentElement {
    setTimer?: boolean;
    isPractice?: boolean;
    time?: number;
    minDuration?: number;
    maxDuration?: number;
    onCountdownEnd?: IQPubSubPayload[];
    enablePeriodicSave?: boolean;
    saveInterval?: number;
    countdownProgressBarTopMargin?: number;
    absoluteCountdownProgressBarPosition?: boolean;
    mustListenBeforeUpload?: boolean;
    askTolistenBeforeUpload?: boolean;
    enablePause?: boolean;
    allowReplayAfterUpload?: boolean;
    allowDownload?:boolean;
    enableScrubber?:boolean;
    showScrubberOnPause?: boolean;
  }

export interface IEntryStateMic extends IEntryStateScored {
    fileType?: string;
    url?: string;
    time?: number;
}

export const micEditInfo = {
  editExcludeFields: []
}