import { PubSubTypes } from "../element-render-frame/pubsub/types";
import { IContentElementImage } from "../element-render-image/model";
import { McqDisplay } from "../element-render-mcq/model";
import { Direction, IContentElement, IEntryState, IEntryStateScored, IScoredResponse } from "../models";


export enum CustomInteractionType {
    GRID_DND          = "GRID_DND",
    GRID_FILL         = 'GRID_FILL',
    GRID_SCATTER_PLOT = 'GRID_SCATTER_PLOT',
    GROUPING          = 'GROUPING',
    PIE_CHART         = 'PIE_CHART',
    ROTATE_SPRITE     = "ROTATE_SPRITE",
    SCALE_RADIUS      = "SCALE_RADIUS",
    SLIDER            = "SLIDER",
    PLAYGROUND        = "PLAYGROUND",
  }

export interface IContentElementVirtualTools extends IContentElement, IScoredResponse {
    interactionType: CustomInteractionType;
    showGrid: boolean;
    tickSpacing: number;
    canvasWidth: number;
    canvasHeight: number;
    rulerHeight: number;
    rulerWidth: number;
    rulerX: number;
    rulerY: number;
    rulerImage?: IContentElementImage;
    isRulerDisabled: boolean;
    scale: number;
    spriteBgWidth: number;
    spriteBgHeight: number;
    spriteBgY: number;
    spriteBgX: number;
    scaleBg: number;
    bgImage?: IContentElementImage;
    protractorX: number;
    protractorY: number; 
    protractorRad: number;
    isProtractorDisabled: boolean;
    isShowAdvancedOptions: boolean;
    counterRadius: number;
    counterColor: string;
    counterNumberColor: string;
    counterOpacity: number;
    isCounterDisabled: boolean;
    polygonConfig: string;
    fhPolygonDisabled: boolean;
    fhPolygonColor: string;
    fhPolygonOpacity: number;
    isGraphDisabled: boolean;
    fhLineDisabled: boolean;
    fhLineHeadAsPivotPoint: boolean;
    graphDisabled: boolean;
    fhCircleDisabled: boolean;
    fhCircleOpacity: number;
    fhRectangleDisabled: boolean;
    fhRectangleOpacity: number;
    fhRectangleColor: string;
}

export const customInteractionEditInfo = {
    editExcludeFields: {}
}