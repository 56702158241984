import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IContentElementDndSub } from 'src/app/ui-testrunner/element-render-dnd/model';
import { EditingDisabledService } from '../editing-disabled.service';
import { bindFormControls } from '../services/data-bind';

@Component({
  selector: 'capture-dnd-style',
  templateUrl: './capture-dnd-style.component.html',
  styleUrls: ['./capture-dnd-style.component.scss']
})
export class CaptureDndStyleComponent implements OnInit {

  @Input() element:IContentElementDndSub;
  @Input() isSetDim:boolean;
  @Input() isHidePos:boolean;
  @Input() isHideId:boolean;
  @Output() change = new EventEmitter();
  @Output() idClick = new EventEmitter();
  
  id = new FormControl();
  x = new FormControl();
  y = new FormControl();
  width = new FormControl();
  height = new FormControl();

  constructor(private editingDisabled: EditingDisabledService) { }

  ngOnInit() {    
    const formControls = [
      {f: this.id,     p:'id' },
      {f: this.x,      p:'x' },
      {f: this.y,      p:'y' },
      {f: this.width,  p:'width' },
      {f: this.height, p:'height' },
    ]
    bindFormControls( <any>this.element, formControls);
    formControls.forEach(fc => {
      fc.f.valueChanges.subscribe(()=>{
        this.change.emit();
      });
    });
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }
}
