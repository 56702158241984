<!-- <pre>
sessionId: {{sessionId}}
</pre>

<div> Session has closed </div>
<div> Test Runner </div>
<div> Test submitted </div> -->
<div *ngIf="isNotYetOpen" class="pre-session-container">
    <div class="info-box">
        <div *ngIf="timeTillOpen === '00:00'">
            <tra slug="txt_sn_test_begins_shortly"></tra> 
        </div>
        <div *ngIf="timeTillOpen !== '00:00'">
            <div> <tra slug="txt_sn_test_begins_in"></tra> </div>
            <div class="time-display">{{timeTillOpen}}</div>
            <div class="sub-time-display"> <tra slug="txt_time_before_open"></tra> </div>
        </div>
    </div> 
</div>
<div *ngIf="isLoaded && !isNotYetOpen">
    <test-runner
        [asmtFmrk]="asmtFmrk"
        [currentTestDesign]="testDesign"
        [questionSrcDb]="questionSrcDb"
        [questionStates]="testAttemptInfo.questionStates"
        [sectionIndexInit]="testAttemptInfo.section_index"
        [questionIndexInit]="testAttemptInfo.question_index"
        [testLang]="testLang"
        [testFormLanguages]="getTestFormLanguages()"
        (refreshTestLang)="refreshTestLang($event)"
        [testTakerName]="testTakerName"
        [attemptKey]="attemptKey"
        [isIssueReportingEnabled]="isIssueReportingEnabled"
        [regularTimeRemaining]="regularTimeRemaining"
        [testSessionId]="sessionId"
        [saveQuestion]="saveQuestionResponse"
        [submitTest]="submitTest"
        [checkTime]="checkTime"
        [checkChat]="checkChat"
        [instit_group_id]="instit_group_id"
        [goToDashboard]="my.gotoDashboard"
        (studentPosition)="setStudentPosition($event)" 
        [documentItems]="asmtFmrk.referenceDocumentPages"
    ></test-runner>
</div>

<div *ngIf="currentWarning && !isSMCS()" class="warning-container" [class.is-select-step]="isInSelectionStep(currentWarning)">
    <div [ngSwitch]="currentWarning">
        <div *ngSwitchCase="WarningModes.INVALID_SEB" class="info-box">
             <tra-md slug="txt_invalid_seb_ta"></tra-md> 
            <div class="buttons" style="margin-top:1em;">
                <button class="button is-outlined"  (click)="goBack()">
                    <tra slug="lbl_back"></tra>
                </button>
                <button class="button is-outlined is-danger" (click)="logout()">
                    <tra slug="title_logout"></tra>
                </button>
            </div>
            <pre style="margin-top:1em;">{{unknownWarningErrorMsg}}</pre>
        </div>
        <div *ngSwitchCase="WarningModes.INVALID_SEB_BYOD" class="info-box">
            <tra-md slug="txt_invalid_seb_self"></tra-md> 
        </div>
        <div *ngSwitchCase="WarningModes.NO_QUESTIONS_FOUND" class="info-box">
             <tra-md slug="txt_test_unknown_load_error"></tra-md> 
            <div style="margin-top:1em;">
                <button class="button is-outlined is-danger" style="margin-right:0em;" (click)="logout()">
                    <tra slug="title_logout"></tra>
                </button>
            </div>
        </div>
        <div *ngSwitchCase="WarningModes.NOT_BOOKED_APPL" class="info-box">
             <tra-md slug="txt_test_access_denied_load_error"></tra-md> 
            <div style="margin-top:1em;">
                <button class="button is-outlined is-danger" style="margin-right:0em;" (click)="logout()">
                    <tra slug="title_logout"></tra>
                </button>
            </div>
        </div>
        <div *ngSwitchCase="WarningModes.UNKNOWN" class="info-box">
            <tra-md slug="An unknown error has occurred"></tra-md>
            <pre>{{unknownWarningErrorMsg}}</pre>
        </div>
        <div *ngSwitchCase="WarningModes.SELECT_LANG" class="info-box">
             <tra-md slug="txt_select_test_lang"></tra-md> 
            <div style="text-align:center" class="buttons">
                <button [disabled]="isLoadingAttempt" class="button is-large" (click)="selectTestLang('en')"> English </button>
                <button [disabled]="isLoadingAttempt" class="button is-large" (click)="selectTestLang('fr')"> Français </button>
            </div>
        </div>
        <div *ngSwitchCase="WarningModes.ACCEPT_ATTEST" class="info-box">
            <tra-md slug="txt_attestation"></tra-md> 
            <div style="text-align:center" class="buttons">
                <button [disabled]="isLoadingAttempt" class="button is-large" (click)="acceptAttestation()"> 
                    <tra slug="lbl_agree"></tra>
                </button>
            </div>
        </div>
    </div>
</div>
