<ng-container>    

    <div *ngIf="true" cdkDrag class="widget-display-container" [style.width.em]="dictionaryManager.selectedWord.isActive? 30 : 20">
        <div cdkDragHandle class="dragHandle">
            <!-- <img *ngIf="this.lang.getCurrentLanguage() === 'en'" class="sourceLogo" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Collins-logo-black/1701786840490/Collins-logo-black.png"> -->
            <!-- <img *ngIf="this.lang.getCurrentLanguage() === 'fr'" class="sourceLogo" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Logo-larousse-seul-blanc-1024x121/1702477671027/Logo-larousse-seul-blanc-1024x121.png"> -->
            <img *ngIf="true" class="sourceLogo" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Logo-larousse-seul-blanc-1024x121/1702477671027/Logo-larousse-seul-blanc-1024x121.png">
            <i class="fa fa-bars" style="margin: 0.5em"></i>
        </div>
        <div>
            <!-- <div *ngIf="this.lang.getCurrentLanguage() === 'en'"> -->
            <div *ngIf="false">
                <div class="container">
                    <div class="searchInput" [class.active]="showSearchInput">
                        <input type="text" [(ngModel)]="input" (keyup)="onUserInput($event)" placeholder="Enter a word..">
                        <div #resultBox class="resultBox">
                            <li *ngIf="isLoadingSuggestions" class="loadingDotsContainer">
                                <div class="dot-pulse"></div>
                            </li>
                            <li *ngFor="let suggestion of suggestions" (click)="selectWord(suggestion)"><markdown-inline [input]="suggestion"></markdown-inline></li>
                        </div>
                        <button class="icon" (click)="getSuggestions()">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                    <div class="main-container" *ngIf="dictionaryManager.selectedWord.isActive">
                        <main class="dirctionary_content">
                            <section class="section" *ngFor="let def of currentWordDefinition; let i = index">
                                <dl class="definition">
                                    <dt class="dictionary_panel definition__term">
                                        <h1>
                                            <span class="target-word">
                                                <markdown-inline [input]="dictionaryManager.selectedWord.word"></markdown-inline>
                                                <sup *ngIf="currentWordDefinition.length > 1">{{i + 1}}</sup>
                                            </span>
                                        </h1>
                                    </dt>
                                    <dd class="dictionary_panel definition__description">
                                        <!-- <p class="definition__text">a <a href="#">dictionary</a> of <a href="#">{{selectedWord.word}}</a> and <a href="#">definitions</a> listed in beautiful harmony</p> -->
                                            
                                        <div *ngFor="let defEntry of def; let index = index">
                                            <p class="definition__text" > 
                                                <b>{{index + 1}}: &nbsp;</b>
                                                <markdown-inline [input]="dictionaryManager.getDefEntryDefData(defEntry)"></markdown-inline>
                                            </p>
                                            <br>
                                            <div *ngIf="dictionaryManager.getDefEntryExampleData(defEntry)">
                                                <div *ngFor="let example of dictionaryManager.getDefEntryExampleData(defEntry)">
                                                    <blockquote class="definition__quote">
                                                        <p class="definition__example">
                                                            <markdown-inline [input]="dictionaryManager.highlightTargetWord(example)"></markdown-inline>
                                                        </p>
                                                        <ul class="tags">
                                                        </ul>
                                                    </blockquote>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </dd>
                                </dl>
                            </section>
                        </main>     
                    </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="this.lang.getCurrentLanguage() === 'fr'"> -->
        <div *ngIf="true">
            <div class="container">
                <div class="searchInput" [class.active]="showSearchInput">
                    <input type="text" [(ngModel)]="input" (keyup)="onUserInput($event)" placeholder="Entrez un mot..">
                    <div #resultBox class="resultBox">
                    <li *ngIf="isLoadingSuggestions" class="loadingDotsContainer">
                        <div class="dot-pulse"></div>
                    </li>
                    <li *ngFor="let suggestion of suggestions" (click)="selectWord(suggestion)"><markdown-inline [input]="suggestion"></markdown-inline></li>
                    </div>
                    <button class="icon" (click)="getSuggestions()">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                <div class="main-container" *ngIf="dictionaryManager.selectedWord.isActive">
                    <main class="dirctionary_content">
                        <div class="tabs">
                            <ul>
                                <li 
                                    *ngFor="let tab of wordViews" 
                                    [class.is-active]="isActiveId(tab.id)"
                                    (click)="selectWordView(tab.id)"
                                >
                                    <a>
                                        <span><tra [slug]="tab.caption"></tra></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- Definition Tab -->
                        <div *ngIf="isViewingDefinition()">
                            <section class="section" *ngFor="let def of currentWordDefinition; let i = index">
                                <dl class="definition">
                                    <dt class="dictionary_panel definition__term">
                                        <h1>
                                            <span class="target-word">
                                                <markdown-inline [input]="dictionaryManager.selectedWord.word"></markdown-inline>
                                                <sup *ngIf="currentWordDefinition.length > 1">{{i + 1}}</sup>
                                            </span>
                                            <div class="catgram-definition-container" *ngIf="i == 0">
                                                <p class="catgram-definition">{{dictionaryManager.getCatgramDefinition()}}</p>
                                            </div>
                                        </h1>
                                    </dt>
                                    <dd class="dictionary_panel definition__description">
                                        <!-- Sometimes there can be multiple definition under the same definition entry -->
                                        <div class="definition_container">
                                            <div *ngFor="let defEntry of dictionaryManager.getSelectedWordDefinitionContent(def); let index = index">
                                                <p class="definition__text"> 
                                                    <b>{{index + 1}}: &nbsp;</b>
                                                    <markdown-inline [input]="defEntry"></markdown-inline>
                                                </p>
                                            </div>
                                        </div>
    
                                        <!-- Display example sentence -->
                                        <div *ngIf="dictionaryManager.getDefEntryExampleData(def).length > 0">
                                            <div *ngFor="let example of dictionaryManager.getDefEntryExampleData(def); let i = index">
                                                <blockquote class="definition__quote" 
                                                    [class.definition__quote_multi_first]="i == 0" 
                                                    [class.definition__quote_multi_last]="i == dictionaryManager.getDefEntryExampleData(def).length - 1"
                                                >
                                                    <p class="definition__example">
                                                        <markdown-inline [input]="dictionaryManager.highlightTargetWord(example)"></markdown-inline>
                                                    </p>
                                                </blockquote>
                                            </div>
                                        </div>
    
                                        <!-- Display synonyms and opposite words if available -->
                                        <div *ngIf="isShowSynonyms_FR && dictionaryManager.getDefEntrySynonymOpposites(def).length > 0">
                                            <div *ngIf="dictionaryManager.getDefEntrySynonymDisplay(def).length > 0" class="fr_synonymes_container">
                                                <p class="fr_synonymes_title">Synonymes</p>
                                                <ul class="fr_synonymes_content_list">
                                                    <li *ngFor="let syn of dictionaryManager.getDefEntrySynonymDisplay(def); let i = index" 
                                                        class="fr_synonymes_content_item"
                                                        [class.is_syn_first]="i == 0"
                                                    >
                                                        <markdown-inline [input]="syn"></markdown-inline>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div *ngIf="dictionaryManager.getDefEntryOppositeDisplay(def).length > 0" class="fr_synonymes_container">
                                                <p class="fr_synonymes_title">Contraires</p>
                                                <ul class="fr_synonymes_content_list">
                                                    <li *ngFor="let syn of dictionaryManager.getDefEntryOppositeDisplay(def); let i = index" 
                                                        class="fr_synonymes_content_item"
                                                        [class.is_syn_first]="i == 0"
                                                    >
                                                        <markdown-inline [input]="syn"></markdown-inline>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                            </section>
                        </div>
                        <!-- Conjugation Tab -->
                        <div *ngIf="isViewingConjugation()">
                            <div *ngIf="!currentSelectedWordHasConjugation()" class="no_data_msg_container">
                                Ce mot n'a pas de conjugaison.
                            </div>
                            <div *ngIf="currentSelectedWordHasConjugation()">
                                <section class="section conjugation_section">

                                    <div class="conjugation_content_top_strip">
                                        <h1>
                                            <span class="conjugation-target-word">
                                                <markdown-inline [input]="dictionaryManager.selectedWord.word"></markdown-inline>
                                            </span>
                                        </h1>
                                        <div class="active_passive_toggle" >
                                            <button *ngFor="let conjugationView of conjugationViews" 
                                                class="conjugation_view" 
                                                (click)="setConjugationView(conjugationView)" 
                                                [class.is-active]="isConjugationViewActive(conjugationView)"
                                                [class.is-unavailable]="!isConjugationViewAvailable(conjugationView)">{{conjugationView}}</button>
                                        </div>
                                    </div>

                                    <div *ngIf="!isConjugationViewAvailable(activeConjugationView)" class="conjugation_main_content">
                                        {{activeConjugationView.toLowerCase()}}: Ce mot n'a pas de conjugaison.
                                    </div>
                                    <div *ngIf="isConjugationViewAvailable(activeConjugationView)" class="conjugation_main_content">
                                        <div  class="conjugation_description">
                                            <markdown-inline [input]="getConjugationDescription()"></markdown-inline>
                                            <div class="conjugation_form_filter">
                                                <select [(ngModel)]="currentConjFormFilter" type="dropdown" class="dropdown-validate">
                                                    <option *ngFor="let optionElement of conjugationFormsFilter">
                                                        {{optionElement}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div *ngFor="let conjugationForm of getConjugationForms()">
                                            <div class="conjugation-form">
                                                <div class="conjugation_form_title_container">
                                                    <p class="conjugation_form_title">{{conjugationForm}}</p>
                                                    <twiddle [state]="conjugationFormToggleStatus.get(conjugationForm)"></twiddle>
                                                </div>
                                                
                                                <div class="conjugation_form_main" [class.conjugation_form_main_hidden]="!conjugationFormToggleStatus.get(conjugationForm).value">
                                                    <div *ngFor="let key of getFormData(conjugationForm).keys()" class="conjugation_form_sections">
                                                        <p class="conjugation_form_section_title">{{getFormSectionDisplayTitle(key)}}</p>
                                                        <div class="conjugation-section-wrapper">
                                                            <ul class="conjugation-section-list">
                                                                <li *ngFor="let formSection of getFormData(conjugationForm).get(key)" class="conjugation-section-list-entry">{{getSectionEntryData(formSection.prefix, formSection.textContent)}}</li>
                                                            </ul>
                                                        </div>    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>     
                </div>
            </div>
        </div>
    </div>
</ng-container>
  