<div class="outside">
    <div class="container clearfix">
        <div class="chat">
            <div class="chat-history" #chathistory>
                <ul>

                    <li class="clearfix" *ngFor="let message of chatService.currentChat">
                        <!-- From current user -->
                        <div class="message-data align-right" *ngIf="message.sender == uid">
                            <span class="message-data-time" >{{ message.date }}</span> &nbsp; &nbsp;
                            <span class="message-data-name" >Me </span> <i class="fas fa-circle me"></i>
                        </div>
                        <div class="message other-message float-right" *ngIf="message.sender == uid">
                            {{ message.msg }}
                        </div>

                        <!-- From another user -->
                        <div class="message-data" *ngIf="!(message.sender == uid)">
                            <span class="message-data-name"><i class="fas fa-circle online"></i>{{ supervisorName }}</span>
                            <span class="message-data-time">{{ message.date }}</span>
                        </div>
                        <div class="message my-message" *ngIf="!(message.sender == uid) && !message.isBroadcast">
                            {{ message.msg }}
                        </div>
                        <div class="message broadcast" *ngIf="!(message.sender == uid) && message.isBroadcast">
                            {{ message.msg }}
                        </div>
                    </li>

                </ul>

            </div> <!-- end chat-history -->

            <div class="chat-message clearfix">
                <textarea name="message-to-send" id="message-to-send" placeholder ="Type your message" rows="3" [(ngModel)]="chatService.currentMessage"></textarea>
                <button (click)="sendMessage(chatService.currentMessage)">Send</button>
            </div> <!-- end chat-message -->

        </div> <!-- end chat -->

    </div> <!-- end container -->
</div>
