import * as PIXI from "pixi.js-legacy";
import { hexColorToNumber } from './utilities'

export type Color = string | number;

interface LineStyle {
    width?: number;
    color?: Color;
    alpha?: number;
}

interface CircleStyle extends LineStyle{
    isFilled: boolean;
}

const initStyle = (style: LineStyle) => {
    return {
        lineWidth: style.width || 1,
        color: hexColorToNumber(style.color),
        alpha: style.alpha || 1
    }
}


export const drawCircle = (x: number, y: number, radius:number, style:CircleStyle) : PIXI.Graphics => {
    let lineStyle = initStyle(style);
    let {lineWidth, color, alpha} = lineStyle;
    
    let circle = new PIXI.Graphics();
    if(style.isFilled){
        circle.beginFill(color, alpha);
    } else {
        circle.lineStyle(lineWidth, color, alpha);
    }
    circle.drawCircle(x, y, radius);
    circle.endFill();
    return circle;
}

export const drawLine = (xStart: number, yStart: number, xEnd: number, yEnd: number, style: LineStyle) : PIXI.Graphics => {
    let lineStyle = initStyle(style);
    let {lineWidth, color, alpha} = lineStyle;
    
    let line = new PIXI.Graphics();
    line.lineStyle(lineWidth, color, alpha).moveTo(xStart, yStart).lineTo(xEnd, yEnd);
    return line;
}

export const drawRectangle = (x: number, y: number, width: number, height: number, style: LineStyle) => {
    let lineStyle = initStyle(style);
    const {lineWidth, color, alpha} = lineStyle;
    
    let rectangle = new PIXI.Graphics();
    rectangle.beginFill(color);
    rectangle.drawRect(x, y, width, height);
    rectangle.endFill();
    rectangle.alpha = alpha
    return rectangle;
}