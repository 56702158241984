<div>

  <fieldset [disabled]="isReadOnly()">
    <div class="field" *ngIf="isShowAdvancedOptions.value">
      <div class="control">
        <div [class.is-disabled]="isReadOnly()" class="select is-fullwidth">
          <select [formControl]="paragraphStyle">
            <option *ngFor="let option of paragraphStyles" [value]="option.id">{{option.caption}}</option>
          </select>
        </div>
      </div>
    </div>
  </fieldset>

  <div style="text-align:center;">
    <div style="margin-bottom: 0.4em;">
      <tra slug="auth_enter_math"></tra>
    </div>
    <div style="text-align:center; font-size:150%;">
      <capture-math [class.no-pointer-events]="isReadOnly()" [obj]="element" prop="latex" [isManualKeyboard]="true"></capture-math>
    </div>
  </div>
  
  <fieldset [disabled]="isReadOnly()">
    <div  *ngIf="isShowAdvancedOptions.value" style="text-align:center;">
      <hr/>
      <a [class.is-disabled]="isReadOnly()" class="button is-warning" (click)="convertToInput()">
        <span class="icon is-small">
          <i class="fa fa-keyboard-o"></i>
        </span>
        <span>Convert into an Input</span>
      </a>
    </div>
  </fieldset>

  <hr />

  <div>
    <label class="checkbox">
      <input type="checkbox" [formControl]="isShowAdvancedOptions">
      <tra slug="auth_show_advanced_options"></tra>
    </label>
  </div>

</div>