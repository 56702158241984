import { Component, OnInit, Input } from '@angular/core';
import { ElementType, IContentElement, IContentElementUpload, QuestionState } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { AuthService } from 'src/app/api/auth.service';
import { memo } from '../../ui-testrunner/element-render-video/element-render-video.component';
import { DomSanitizer } from '@angular/platform-browser'
@Component({
  selector: 'element-render-upload',
  templateUrl: './element-render-upload.component.html',
  styleUrls: ['./element-render-upload.component.scss']
})
export class ElementRenderUploadComponent implements OnInit {

  @Input() element:IContentElementUpload;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() isShowingResponse?: boolean

  imageUrls = new Map();
  isRequestingReupload:boolean;

  constructor(
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    ) { }

  ngOnInit() {
    console.log('entryId', this.element.entryId)
  }

  getQuestionState(){
    if (this.questionState){
      let elQuestionState = this.questionState[this.element.entryId];
      if (!elQuestionState){
        elQuestionState = {
          type: ElementType.UPLOAD,
          url:  undefined
        };
        this.questionState[this.element.entryId] = elQuestionState;
      }
      return elQuestionState;
    }
  }

  getImgUrl(){
    const elQuestionState = this.getQuestionState();
    if (elQuestionState){
      return elQuestionState.url;
    }
  }

  getSanitizedImageUrl(){
    return this.getSanitizedUrl(this.getImgUrl());
  }

  getSanitizedUrl(url:string){
    return memo(this.imageUrls, url, url => {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  startUpload(event: FileList) {
    // let allowedFileTypes = this.fileType.split(',');
    // if (this.fileType === 'audio'){
    //   allowedFileTypes = ['mp3', 'wav']
    // }

    // The File object
    const file = event.item(0);
    // Client-side validation example
    const fileTypeFull = file.type;
    const fileType = fileTypeFull.split('/')[0];
    const fileExt = file.name.split('.').pop();
    //had to add filetype override for vtt because it was not being recognized
    // console.log(fileExt, allowedFileTypes)
    // if (this.fileType !== 'any'){
    //   if (!(_.includes(allowedFileTypes, fileType) || _.includes(allowedFileTypes, fileExt.toLowerCase()) || (allowedFileTypes.indexOf('Graphics') > -1 && file.type === 'image/svg+xml'))) {  
    //     alert('Unsupported File Type ' + fileType);
    //     return;
    //   }
    // }
    this.auth
      .uploadFile(file, file.name, 'authoring', true)
      .then(res => {
        this.getQuestionState().url = res.url;
        this.isRequestingReupload = false;
      })
  }

  isWaitingForUpload(){
    return this.isRequestingReupload || !this.getImgUrl();
  }


}
