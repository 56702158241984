<!-- <div>
  
  <div *ngIf="isDisplayStyleFreeform()">
    <div class="simple-form-container">
      <label >W  <num-scrub [el]="element" prop="width" [step]="0.1"  [max]="60"></num-scrub></label>
      <label >H  <num-scrub [el]="element" prop="height" [step]="0.1"  [max]="60"></num-scrub></label>
      <label >Has Frame?  <input type="checkbox" [(ngModel)]="element.hasFrame"/></label>
    </div>
  </div>

  <div style="margin-bottom: 0.5em;">
    <div >
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.isOptional"> 
        Optional Response? (no warning)
      </label>
    </div>
    <div>
      <label class="checkbox">
        <input type="checkbox" [formControl]="isMultiSelect">
        Allow more than one option to be selected?
      </label>
    </div>
    <div>
      <label class="checkbox" *ngIf="isMultiSelect.value">
        <input type="input" class="input is-small" [style.width.em]="4" [(ngModel)]="element.maxOptions">
        Maximum number of selections
      </label>
    </div>
    
    <div *ngIf="!isMultiSelect.value">
      <label class="checkbox">
        <input type="checkbox" [formControl]="isSelectToggle" [attr.disabled]="isDisabled('isSelectToggle')">
        Allow unselection? (toggle)
      </label>
    </div> -->
<fieldset [disabled]="isReadOnly()">
  <div class="simple-form-container">
    <label>   
        ID  
        <span  *ngIf="!isEditingEntryId">{{element.entryId}}</span>
        <input *ngIf="isEditingEntryId" type="number" [(ngModel)]="element.entryId" /> 
    </label>
    <button *ngIf="!isEditingEntryId" (click)="startEditingEntryId()">Edit</button>
    <button *ngIf="isEditingEntryId" (click)="isEditingEntryId = false">Save</button>
  </div>
</fieldset>

<div>
  
  <fieldset [disabled]="isReadOnly()">

    <div *ngIf="isDisplayStyleFreeform()">
      <div class="simple-form-container">
        <label >W  <num-scrub [el]="element" prop="width" [step]="0.1"  [max]="60"></num-scrub></label>
        <label >H  <num-scrub [el]="element" prop="height" [step]="0.1"  [max]="60"></num-scrub></label>
        <label >Has Frame?  <input type="checkbox" [(ngModel)]="element.hasFrame"/></label>
      </div>
    </div>
  
    <div style="margin-bottom: 0.5em;">
      <div >
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="element.isOptional"> 
          <tra slug="auth_optional_response"></tra>
        </label>
      </div>
      <div>
        <label class="checkbox">
          <input type="checkbox" [formControl]="isMultiSelect">
          <tra slug="auth_more_than_one_option"></tra>
        </label>
      </div>
      <div>
        <label class="checkbox" *ngIf="isMultiSelect.value">
          <input type="input" class="input is-small" [style.width.em]="4" [(ngModel)]="element.maxOptions">
          <tra slug="auth_max_num_sel"></tra>
        </label>
      </div>
      <div>
        <label class="checkbox" *ngIf="isMultiSelect.value">
          <input type="checkbox" [(ngModel)]="element.isEnablePartialweights">
          <tra slug="auth_enable_partial_weights"></tra>
        </label>
      </div>
    
    <div>
        <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isOnRightSideOptionIndicator"> 
            <tra slug="auth_option_indicator_right"></tra>  
        </label>
    </div>
      
      <div *ngIf="!isMultiSelect.value">
        <label class="checkbox">
          <input type="checkbox" [formControl]="isSelectToggle" [attr.disabled]="isDisabled('isSelectToggle')">
          <tra slug="auth_toggle"></tra>
        </label>
      </div>
  
      <div>
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="element.isDynamicallyCorrect"> 
            <tra slug="auth_option_dynamically_correct"></tra>
        </label>
      </div>
    </div>
    <div>
      <label *ngIf="canLockInputBoxesOnSelection()" class="checkbox">
        <input type="checkbox" [(ngModel)]="element.lockInputBoxOnSelection">
        <tra slug="Lock Input Box(es) On Selection"></tra>
      </label>
    </div>
    <div>
      <label class="checkbox" *ngIf="element.lockInputBoxOnSelection">
        <tra slug="Input Box ID List (Separated by comma):  "></tra>
        <input type="input" class="input is-small" [class.invalid-input]="!checkInputBoxArrayFormat()" [style.width.em]="8" [(ngModel)]="element.inputBoxIDs">
      </label>
    </div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="element.isTextLeftAligned">
      <tra slug="auth_align_dropdown_text_left"></tra>
    </label>
    <div class="buttons">
      <button [disabled]="isReadOnly()" class="button is-small" (click)="selectDisplayStyleVertical()" [class.is-info]="isDisplayStyleVertical()">
        <span class="icon is-small">
          <i class="fa fa-ellipsis-v"></i>
        </span>
        <span><tra slug="auth_vertical"></tra></span>
      </button>
      <button [disabled]="isReadOnly()" class="button is-small" (click)="selectDisplayStyleHorizontal()" [class.is-info]="isDisplayStyleHorizontal()">
        <span class="icon is-small">
          <i class="fa fa-ellipsis-h"></i>
        </span>
        <span><tra slug="auth_horizontal"></tra></span>
      </button>
      <button [disabled]="isReadOnly()" class="button is-small" (click)="selectDisplayStyleGrid()" [class.is-info]="isDisplayStyleGrid()">
        <span class="icon is-small">
          <i class="fa fa-th-large"></i>
        </span>
        <span><tra slug="auth_grid"></tra></span>
      </button>
      <button [disabled]="isReadOnly()" class="button is-small " (click)="selectDisplayStyleLikert()" [class.is-info]="isDisplayStyleLikert()">
        <span class="icon is-small">
          <i class="fa fa-arrow-right"></i>
        </span>
        <span><tra slug="auth_likert"></tra></span>
      </button>
      <button [disabled]="isReadOnly()" class="button is-small " (click)="selectDisplayStyleDropdown()" [class.is-info]="isDisplayStyleDropdown()">
        <span class="icon is-small">
          <i class="fa fa-caret-square-o-down"></i>
        </span>
        <span><tra slug="auth_dropdown"></tra></span>
      </button>
      <button [disabled]="isReadOnly()" class="button is-small " (click)="selectDisplayStyleCustomDropdown()" [class.is-info]="isDisplayStyleCustomDropdown()">
        <span class="icon is-small">
          <i class="fa fa-caret-square-o-down"></i>
        </span>
        <span><tra slug="auth_custom_dropdown"></tra></span>
      </button>
      <button [disabled]="isReadOnly()" class="button is-small " (click)="selectDisplayStyleBubble()" [class.is-info]="isDisplayStyleBubble()" [style.margin-top.em]="0.5">
        <span class="icon is-small">
          <i class="fas fa-caret-square-o-down"></i>
        </span>
        <span><tra slug="auth_bubble"></tra></span>
      </button>
      <button [disabled]="isReadOnly()" class="button is-small " (click)="selectDisplayStyleFreeform()" [class.is-info]="isDisplayStyleFreeform()" [style.margin-top.em]="0.5">
        <span class="icon is-small">
          <i class="fas fa-move"></i>
        </span>
        <span><tra slug="auth_freeform"></tra></span>
      </button>
      <button [disabled]="isReadOnly()" class="button is-small " (click)="selectDisplayStyleTable()" [class.is-info]="isDisplayStyleTable()" [style.margin-top.em]="0.5" *ngIf="isDisplayStyleTable() || isShowAdvancedOptions.value">
        <span class="icon is-small">
          <i class="fas fa-table"></i>
        </span>
        <span>Table</span>
      </button>
    </div>
    
    <div *ngIf="false" style="margin-bottom:1em; display:flex; flex-direction:row;     align-items: center;">
      <strong style="margin-right:1em;">Standard Styling:</strong>
      <select [class.is-disabled]="isReadOnly()" class="select" [(ngModel)]="this.element.currentSettings" (ngModelChange)="onStyleChange($event)">
        <option [value]="this.customStr" >Custom</option>
        <option *ngFor="let styles of this.defaultStyles; let i = index" [value]="i" >
          Style {{i+1}}
        </option>
      </select>
    </div>
  
  
  
    <div *ngIf="isShowAdvancedOptions.value">
  
        <!-- Not specific to grid display style. Validity prevents typing of negative numbers-->
      <div *ngIf="!isDisplayStyleDropdown() && !isDisplayStyleLikert()">
  
        <div class="simple-form-container">
          <label >Fixed OH?  <input type="checkbox" [(ngModel)]="element.optionHeightSet"/></label>
          <label >OH  <num-scrub [el]="element" prop="optionHeight" [step]="0.1"  [max]="60"></num-scrub></label>
          <label >OW  <num-scrub [el]="element" prop="gridCellWidth" [step]="0.1" [max]="60"></num-scrub></label>
          <label >Cols  <num-scrub [el]="element" prop="gridNumColumns" [step]="1"  [min]="1" [max]="10"></num-scrub></label>
          <label >MR  <num-scrub [el]="element" prop="marginRight" [step]="0.05" [max]="4"></num-scrub></label>
          <label >MB  <num-scrub [el]="element" prop="marginBottom" [step]="0.05" [max]="4"></num-scrub></label>
        </div>
          <!-- oninput="validity.valid||(value='');" -->
        <hr/>
        <div>
          <label class="checkbox">
            <tra slug="Global hotspot color"></tra>
            <input type="color" [(ngModel)]="element.hotspotGlobalColor">
          </label>
        </div>
        <div class="simple-form-container">
          <label class="checkbox">
            <tra slug="Global hotspot opacity"></tra>
            <input type="number" min="0" max="100" [(ngModel)]="element.hotspotGlobalOpacity">
          </label>
        </div>
        <div>
          <label class="checkbox">
            <input type="checkbox" [formControl]="isLimittedWidth">
            <tra slug="auth_limited_width"></tra>
          </label>
        </div>
        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isPolaroidStyle">
            <tra slug="auth_polaroid"></tra>
          </label>
          <label class="checkbox" style="margin-left:2em;">
            <input type="checkbox" [(ngModel)]="element.isNoThickBorder">
            <tra slug="auth_no_border"></tra>
          </label>
        </div>
        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isHCBorderColor">
            <tra slug="auth_border_color"></tra>
          </label>
          <input type="color" [(ngModel)]="element.HCSelectionBorderColor">
        </div>
        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isBorderColor">
            <tra slug="auth_hard_selection_color"></tra>
          </label>
          <input type="color" [(ngModel)]="element.selectionBorderColor">
        </div>
  
        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isOffsetSelectionBorder">
            <tra slug="auth_offset_border"></tra>
          </label>
        </div>

        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isSelectionScale">
            <tra slug="auth_selection_scale"></tra> &nbsp;  
          </label>
          <input *ngIf="element.isSelectionScale" type="number" min="0.1" max="10" [(ngModel)]="element.selectionScale">
        </div>

        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isSelectionBorder">
            <tra slug="auth_selection_border"></tra> &nbsp;
          </label>
          <input *ngIf="element.isSelectionBorder" type="color" [(ngModel)]="element.selectionBorder">
        </div>
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="element.isHideOptionActivated">
          <tra slug="auth_hide_border_shadow"></tra>
        </label>
  
        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isBgClear">
            <tra slug="auth_hide_background"></tra>
          </label>
          <label class="checkbox" *ngIf="element.isBgClear">
            <input type="checkbox" [(ngModel)]="element.isBgClearAlways">
            <tra slug="auth_even_on_selection"></tra>
          </label>
        </div>
        
        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="this.element.isRadioBackgroundHidden">
            <tra slug="auth_hide_option_bkg"></tra>
          </label>
          <label class="checkbox" *ngIf="element.isRadioBackgroundHidden">
            <input type="checkbox" [(ngModel)]="element.isRadioBackgroundHiddenAlways">
            <tra slug="auth_even_on_selection"></tra>
          </label>
        </div>
  
        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="this.element.isCanLink">
            <tra slug="auth_reading_passages"></tra>
          </label>
        </div>

        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isOptionsBgColor">
            <tra slug="auth_background_color"></tra>
          </label>
          <span *ngIf="element.isOptionsBgColor">
            <label class="checkbox">
              <input type="color" [(ngModel)]="element.optionsBgColor">
            </label>
          </span>
        </div>

        <div>
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="element.isOptionsFontColor">
            <tra slug="auth_font_color"></tra>
          </label>
          <span *ngIf="element.isOptionsFontColor">
            <label class="checkbox">
              <input type="color" [(ngModel)]="element.optionsFontColor">
            </label>
          </span>
        </div>
        
        <div>
          <label class="checkbox">
            <input type="checkbox"  [(ngModel)]="element.isOptionsBoxShadow">
            <tra slug="auth_box_shadow"></tra>
          </label>
          <div *ngIf="element.isOptionsBoxShadow">
            <label class="checkbox">
              <tra slug="auth_shadow_x"></tra>
              <input type="number" [(ngModel)]="element.optionDropshadowX">
            </label>
            <label class="checkbox">
              <tra slug="auth_shadow_y"></tra>
              <input type="number" [(ngModel)]="element.optionDropshadowY">
            </label>
            <label class="checkbox">
              <tra slug="auth_shadow_blur_rad"></tra>
              <input type="number" [(ngModel)]="element.optionDropshadowBlur">
            </label>
            <label class="checkbox">
              <tra slug="auth_shadow_colour"></tra>
              <input type="color" [(ngModel)]="element.optionDropshadowColor">
            </label>
          </div>
        </div>
  
        <label class="checkbox">
          <input type="checkbox" [attr.disabled]="isDisabled('isOptionLabelsDisabled')" [formControl]="isOptionLabelsDisabled">
          <tra slug="auth_exclude_labels"></tra>
        </label>
        <label class="checkbox">
          <input type="checkbox" [attr.disabled]="isDisabled('isRadioDisabled')" [formControl]="isRadioDisabled">
          <tra slug="auth_exclude_radio_lab"></tra>
        </label>
  
        <label class="checkbox">
          <input type="checkbox" [attr.disabled]="isDisabled('isContentsCentered')" [formControl]="isContentsCentered">
          <tra slug="auth_center_content_hor"></tra>
        </label>
        <label class="checkbox">
          <input type="checkbox" [attr.disabled]="isDisabled('isContentsCenteredVertically')" [formControl]="isContentsCenteredVertically">
          <tra slug="auth_center_content_vert"></tra>
        </label>
        <label class="checkbox">
          <input type="checkbox" [attr.disabled]="isDisabled('isContentsCenteredVertically')" [(ngModel)]="element.isVerticallyCenterOptionIndicator">
          <tra slug="auth_center_opt_ind_vert"></tra>
        </label>
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="element.isCustomHoverEffect">
          <tra slug="auth_hover_effect"></tra>
        </label><br/>
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="element.isContentsVertPaddDisabled">
          <tra slug="auth_disable_padding"></tra>
        </label>
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="element.avoidInversionOnHighContrast"/>
          <tra slug="Avoid Inversion On High Contrast"></tra>
        </label>
      </div>
      
      <hr *ngIf="!isShowAdvancedOptions.value" />
      
    </div>
      
    <div *ngIf="isShowAdvancedOptions.value">

      
      <hr />
      
    <div>
      <label class="checkbox">
        <input type="checkbox" [formControl]="isScrambled">
        <tra slug="auth_scramble_order"></tra>
      </label>
    </div>


    <hr />
  
  </div>

  <div *ngIf="isDisplayStyleTable()">
    <div class="col-headers">
      <ng-container *ngFor="let col of element.tableCols">
        <textarea [(ngModel)]="col.label"></textarea>
      </ng-container>
    </div>
    <div>
      <button (click)="addTableCol()">add column</button>
      <button (click)="removeTableCol()">remove column</button>
    </div>
  </div>

    
    <div *ngIf="isDisplayStyleDropdown()">
      <label class="checkbox">
        <tra slug="Width"></tra>  
        <input type="number" min="2" max="10" step="0.1" class="input is-small" [(ngModel)]="element.dropdownWidth">
      </label>
      <label class="checkbox">
        <tra slug="auth_default_text"></tra> <input type="text" class="input is-small" [(ngModel)]="this.element.defaultDropdownText">
      </label>
      <label class="checkbox">
        <tra slug="auth_option_font_size"></tra>  <input type="number" min="0" max="10" step="0.1" class="input is-small" [(ngModel)]="element.fontSizeDropdownOptions">
      </label>
    </div>

    <div *ngIf="isDisplayStyleCustomDropdown()">
      <label class="checkbox">
        Max Width  <input type="number" min="1" step="0.1" class="input is-small" [(ngModel)]="element.maxCustomDDWidth">
      </label>
      <label class="checkbox">
        Max Height  <input type="number" min="1" step="0.1" class="input is-small" [(ngModel)]="element.maxCustomDDHeight">
      </label>
      <label class="checkbox">
        Frame IDs  <input type="text" min="1" step="0.1" class="input is-small" [(ngModel)]="element.framePopIds">
      </label>
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.hideDropdownArrowIcon"><tra slug="Hide Arrow Icon"></tra> 
      </label>
    </div>

    <div *ngIf="isDisplayStyleLikert()" >
      <div style="margin-bottom:0.5em;">
        <strong><tra slug="auth_likert_settings"></tra></strong>
      </div>
      <div style="margin-bottom:0.5em;">
        <label class="checkbox"><input type="checkbox" [(ngModel)]="this.element.isColoredLikert"> <tra slug="auth_use_colour_style"></tra></label>
      </div>
      <div style="display:flex; flex-direction:row; flex-direction:row; ">
        <div>
          <div><tra slug="auth_left_most"></tra></div>
          <input type="text" class="input is-small" [(ngModel)]="getFirstOption().caption">
        </div>
        <div>
          <div><tra slug="auth_right_most"></tra></div>
          <input type="text" class="input is-small" [(ngModel)]="getLastOption().caption">
        </div>
      </div>
    </div>
  </fieldset>
  
  <div *ngIf="!isDisplayStyleLikert()">
    <config-mcq 
      [options]="element.options" 
      [optionType]="ElementType.MCQ_OPTION"
      [allowPositioning]="isDisplayStyleFreeform()"
      [isTableFormat]="isDisplayStyleTable()"
      [isEnablePartialweights]="element.isEnablePartialweights"
      [updateChangeCounter]="updateChangeCounter" 
      [readOnly]="isReadOnly()" 
      [isCanLink]="this.element.isCanLink" 
      [isShowAdvancedOptions]="this.element.isShowAdvancedOptions" 
      [subElementTypes]="subElementTypes"
      [useDistractorAsResponseSlug]="element.useDistractorAsResponseSlug"
      (change)="onOptionsChange()"
    ></config-mcq>
  </div>

  <!-- <div *ngIf="isMultiSelect.value">
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="element.enableProportionalScoring">
      Enable proportional scoring?
    </label>
  </div>

  <div class="form-row" *ngIf="isShowAdvancedOptions.value && isScoreWeightEnabled()">
    <div class="form-row-label">
        Score Weight -->
  <fieldset [disabled]="isReadOnly()">
    <div *ngIf="isMultiSelect.value">
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.enableProportionalScoring">
        <tra slug="auth_proportional_scoring"></tra>
      </label>
    </div>
  
    <div class="form-row" *ngIf="isShowAdvancedOptions.value && isScoreWeightEnabled()">
      <div class="form-row-label">
          <tra slug="auth_score_weight"></tra>
      </div>
      <div class="form-row-input">
        <input type="text" class="input" [formControl]="scoreWeight">
      </div>
    </div>
  </fieldset>

  <div class="form-row" *ngIf="isShowAdvancedOptions.value">
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="element.useDistractorAsResponseSlug">
      <span>Distractor as Response Slug</span>
    </label>
  </div>

  <hr />

  <div>
    <label class="checkbox">
      <input type="checkbox" [formControl]="isShowAdvancedOptions">
      <tra slug="auth_show_advanced_options"></tra>
    </label>
  </div>

</div>