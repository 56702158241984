import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'render-input-long-print-preview',
  templateUrl: './render-input-long-print-preview.component.html',
  styleUrls: ['./render-input-long-print-preview.component.scss']
})
export class RenderInputLongPrintPreviewComponent implements OnInit {

  @Input('strHtml') strHtml:string;
  @Input('isDoubleLine') isDoubleLine:boolean;

  @ViewChild('paginationContainer') paginationContainerRef: ElementRef;
  @ViewChild('richTextContainer') richTextContainerRef: ElementRef;

  pageCurrent:number = 1
  pageMax:number = 1

  constructor() { }

  ngOnInit(){

  }

  ngAfterViewInit() {
    this.renderHtml();
    this.calculatePageNumber();
  }

  renderHtml() {
    const richTextContainer = this.richTextContainerRef.nativeElement;
    richTextContainer.innerHTML = this.strHtml;
  }

  onScroll(paginationContainer: HTMLElement) {
    this.calculatePageNumber();
  }

  calculatePageNumber() {
    const paginationContainer = this.paginationContainerRef.nativeElement;

    const fullWidth = paginationContainer.scrollWidth
    const fullWidthOffset = paginationContainer.offsetWidth
    const scrollLeft = paginationContainer.scrollLeft
    const scrollPercentage = scrollLeft/(fullWidth)

    const numPagesTotal = Math.round(fullWidth / fullWidthOffset);
    const estimatedCurrentPage = Math.min(numPagesTotal, 1+ Math.round(numPagesTotal*scrollPercentage ))

    this.pageCurrent = estimatedCurrentPage
    this.pageMax = numPagesTotal
  }

  scrollToPage() {
    const SCROLL_ADJUST_PERC = 0.005;
    const paginationContainer = this.paginationContainerRef.nativeElement;
    const fullWidth = paginationContainer.scrollWidth
    const fullWidthOffset = paginationContainer.offsetWidth
    const scrollPercentage = (this.pageCurrent - 1) / (this.pageMax*(1-SCROLL_ADJUST_PERC))
    const scrollLeft = fullWidth * scrollPercentage 
    console.log(scrollPercentage, fullWidth, fullWidthOffset)
    paginationContainer.scrollTo({
      left: scrollLeft,
      behavior: 'smooth'
    });
  }


  goToPreviousPage() {
    if (this.pageCurrent > 1) {
      this.pageCurrent--;
      this.scrollToPage();
    }
  }

  goToNextPage() {
    if (this.pageCurrent < this.pageMax) {
      this.pageCurrent++;
      this.scrollToPage();
    }
  }


}
