import accounting from 'accounting-js';
import { countDecimals } from '../../ui-testrunner/models';

export const INPUT_NUMBER_FORMATS = {
  'None': function(val, isFrench){
    if(!isFormattable(val, isFrench)) return val;
    let valToFormat = val;
    let splitValues = [];
    const decimalType = getDecimal(isFrench)
    if(valToFormat.includes(decimalType)){
      splitValues = valToFormat.toString().split(decimalType)
      valToFormat = splitValues[0];
    }
    let unformattedNumber:any = unformatNumber(valToFormat, isFrench);    
    let formattedNum = accounting.formatNumber(unformattedNumber, getFormatNumberSettings(0, isFrench));
    if(splitValues.length) formattedNum = `${formattedNum}.${splitValues[1]}`;
    return formattedNum;
  },
  '0': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(0, isFrench));
  },
  '0.0': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(1, isFrench));
  },
  '0.00': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(2, isFrench));
  },
  '0.000': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(3, isFrench));
  }
}

const isFormattable = (val, isFrench) => {
  if(isFrench) return !val.endsWith(',');
  return !val.endsWith('.');
}

const getFormatNumberSettings = (precision: number, isFrench: boolean) => {
  return { precision, thousand: getThousand(isFrench), decimal: getDecimal(isFrench) }
}

const getThousand = (isFrench) => {
  return isFrench ? " " : ","
}

const getDecimal = (isFrench) => {
  return isFrench ? "," : "."
}

const unformatNumber = (formattedVal, isFrench) => {
  return Number(accounting.unformat(String(formattedVal), getDecimal(isFrench)))
}