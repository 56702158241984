import { IPanelLink } from "./type";
import { BC_FSA_SCHOOL_ADMIN_VIEWS } from '../../../ui-schooladmin/view-schooladmin-dashboard/data/views'

export const fsaSchoolAdminPanels: IPanelLink[] = BC_FSA_SCHOOL_ADMIN_VIEWS.map(view => {
  return {
    caption: view.caption,
    routerLink: `/en/school-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled
  }
})

export const fsaSchoolAdminScoreEntryPanels: IPanelLink[] = BC_FSA_SCHOOL_ADMIN_VIEWS.map(view => {
  return {
    caption: view.caption,
    routerLink: `/en/school-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled,
  }
})