<div class="school-level-data-report">
    <bc-export-button [disabled]="isLoading || !schoolDetail" [large]="true" (export)="export()"></bc-export-button>
    <div class="select-sheet">
        <button
            *ngFor="let s of [Sheet.INDIVIDUAL_RAW_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION]"
            class="sheet-button" [class.selected]="sheet == s" (click)="selectSheet(s)">
            <tra [slug]="getSheetName(s)"></tra>
        </button>
    </div>
    <div class="no-school" *ngIf="!schoolDetail">
        <tra slug="sa_fr_issr_selectSchool"></tra>
    </div>

    <div class="report-section" *ngIf="schoolDetail">
        <ng-container *ngIf="sheet == Sheet.INDIVIDUAL_RAW_SCORES">
            <bc-paginator [paginator]="pagination" (refresh)="updateTable()"></bc-paginator>
            <div class="cover-with-loading">
                <div *ngIf="isLoading" class="bcg-loader">
                    <div></div>
                </div>
                <div class="table-container">
                    <table *ngIf="!isLoading && individualRawScoresReport">
                        <thead>
                            <tr>
                                <th colspan="4"></th>
                                <th colspan="7">
                                    <tra slug="sa_literacy"></tra>
                                </th>
                                <th colspan="7">
                                    <tra slug="sa_numeracy"></tra>
                                </th>
                            </tr>
                            <tr>
                                <th rowspan="2">
                                    <tra slug="sa_lastname"></tra>
                                </th>
                                <th rowspan="2">
                                    <tra slug="sa_firstname"></tra>
                                </th>
                                <th rowspan="2">
                                    <tra slug="sa_pen"></tra>
                                </th>
                                <th rowspan="2">
                                    <tra slug="sa_grade"></tra>
                                </th>
                                <th colspan="2">
                                    <tra slug="sa_sr_items"></tra>
                                </th>
                                <th colspan="3">
                                    <tra slug="sa_cr_items"></tra>
                                </th>
                                <th rowspan="2">
                                    <tra slug="sa_se_totaL_score"></tra>
                                </th>
                                <th rowspan="2">
                                    <tra slug="sa_se_literacy_pro"></tra>
                                </th>
                                <th colspan="2">
                                    <tra slug="sa_sr_items"></tra>
                                </th>
                                <th colspan="3">
                                    <tra slug="sa_cr_items"></tra>
                                </th>
                                <th rowspan="2">
                                    <tra slug="sa_se_totaN_score"></tra>
                                </th>
                                <th rowspan="2">
                                    <tra slug="sa_se_numeracy_pro"></tra>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <tra slug="sa_sr_attempted"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_sr_score"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_l1_score"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_l2_score"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_l3_score"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_sr_attempted"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_sr_score"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_sr_n1score"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_sr_n2score"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_sr_n3score"></tra>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of individualRawScoresReport.data.data">
                                <td>{{row.last_name}}</td>
                                <td>{{row.first_name}}</td>
                                <td>{{row.pen}}</td>
                                <td>{{row.grade}}</td>

                                <td>{{row.li_sr_attempted}}</td>
                                <td>{{row.li_sr_score}}</td>
                                <td>{{row.li_1_score}}</td>
                                <td>{{row.li_2_score}}</td>
                                <td>{{row.li_3_score}}</td>
                                <td>{{sumScore(row.li_sr_score, row.li_1_score, row.li_2_score, row.li_3_score)}}</td>
                                <td>{{getProficiencyLevel('English', row.grade, 'literacy', sumScore(row.li_sr_score,
                                    row.li_1_score, row.li_2_score, row.li_3_score))}}</td>

                                <td>{{row.nu_sr_attempted}}</td>
                                <td>{{row.nu_sr_score}}</td>
                                <td>{{row.nu_1_score}}</td>
                                <td>{{row.nu_2_score}}</td>
                                <td>{{row.nu_3_score}}</td>
                                <td>{{sumScore(row.nu_sr_score, row.nu_1_score, row.nu_2_score, row.nu_3_score)}}</td>
                                <td>{{getProficiencyLevel('English', row.grade, 'numeracy', sumScore(row.nu_sr_score,
                                    row.nu_1_score, row.nu_2_score, row.nu_3_score))}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="table-container">
                    <table>
                        <tr>
                            <th colspan="11">
                                <tra slug="sa_sr_pro_level"></tra>
                            </th>
                        </tr>
                        <tr>
                            <th colspan="2"></th>
                            <th colspan="4">
                                <tra slug="sa_literacy"></tra>
                            </th>
                            <th colspan="2"></th>
                            <th colspan="4">
                                <tra slug="sa_numeracy"></tra>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <tra slug="sa_sr_lang"></tra>
                            </th>
                            <th>
                                <tra slug="sa_grade"></tra>
                            </th>
                            <th>
                                <tra slug="sa_sr_plu"></tra>
                            </th>
                            <th>
                                <tra slug="sa_sr_emerging"></tra>
                            </th>
                            <th>
                                <tra slug="sa_sr_ontrack"></tra>
                            </th>
                            <th>
                                <tra slug="sa_sr_extend"></tra>
                            </th>
                            <th colspan="2"></th>
                            <th>
                                <tra slug="sa_sr_plu"></tra>
                            </th>
                            <th>
                                <tra slug="sa_sr_emerging"></tra>
                            </th>
                            <th>
                                <tra slug="sa_sr_ontrack"></tra>
                            </th>
                            <th>
                                <tra slug="sa_sr_extend"></tra>
                            </th>
                        </tr>
                        <tr>
                            <th>English</th>
                            <td>4</td>
                            <td>-</td>
                            <td>0 - 16</td>
                            <td>17 - 30</td>
                            <td>31 - 42</td>
                            <td colspan="2"></td>
                            <td>-</td>
                            <td>0 - 15</td>
                            <td>16 - 33</td>
                            <td>34 - 42</td>
                        </tr>
                        <tr>
                            <th>English</th>
                            <td>7</td>
                            <td>-</td>
                            <td>0 - 15</td>
                            <td>16 - 33</td>
                            <td>34 - 42</td>
                            <td colspan="2"></td>
                            <td>-</td>
                            <td>0 - 15</td>
                            <td>16 - 31</td>
                            <td>32 - 42</td>
                        </tr>
                        <tr>
                            <th>French</th>
                            <td>4</td>
                            <td>-</td>
                            <td>0 - 14</td>
                            <td>15 - 26</td>
                            <td>27 - 42</td>
                            <td colspan="2"></td>
                            <td>-</td>
                            <td>0 - 15</td>
                            <td>16 - 33</td>
                            <td>34 - 42</td>
                        </tr>
                        <tr>
                            <th>French</th>
                            <td>7</td>
                            <td>-</td>
                            <td>0 - 15</td>
                            <td>16 - 28</td>
                            <td>29 - 42</td>
                            <td colspan="2"></td>
                            <td>-</td>
                            <td>0 - 15</td>
                            <td>16 - 31</td>
                            <td>32 - 42</td>
                        </tr>
                    </table>
                    <div class="proficiency-level-description">
                        <div>
                            <tra slug="sa_sr_plu"></tra>
                        </div>
                        <div>
                            <tra slug="sa_sr_plu_desc"></tra>
                        </div>
                    </div>
                    <div class="proficiency-level-description">
                        <div>
                            <tra slug="sa_sr_emerging"></tra> (EM)
                        </div>
                        <div>
                            <tra slug="sa_sr_emerging_desc"></tra>
                        </div>
                    </div>
                    <div class="proficiency-level-description">
                        <div>
                            <tra slug="sa_sr_ontrack"></tra> (OT)
                        </div>
                        <div>
                            <tra slug="sa_sr_ontrack_desc"></tra>
                        </div>
                    </div>
                    <div class="proficiency-level-description">
                        <div>
                            <tra slug="sa_sr_extend"></tra> (EX)
                        </div>
                        <div>
                            <tra slug="sa_sr_extend_desc"></tra>
                        </div>
                    </div>


                    <div class="proficiency-level-note">
                        <b>
                            <tra slug="sa_sr_note"></tra>
                        </b>
                        <tra slug="sa_sr_note_desc"></tra>
                    </div>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="sheet == Sheet.COGNITIVE_LEVEL_SCORES && cognitiveLevelScoresReport">
            <bc-paginator [paginator]="pagination" (refresh)="updateTable()"></bc-paginator>
            <table>
                <thead>
                    <tr>
                        <th colspan="4"></th>
                        <th colspan="6">
                            <tra slug="sa_literacy"></tra>
                        </th>
                        <th colspan="6">
                            <tra slug="sa_numeracy"></tra>
                        </th>
                    </tr>
                    <tr>
                        <th rowspan="2">
                            <tra slug="sa_lastname"></tra>
                        </th>
                        <th rowspan="2">
                            <tra slug="sa_firstname"></tra>
                        </th>
                        <th rowspan="2">
                            <tra slug="sa_pen"></tra>
                        </th>
                        <th rowspan="2">
                            <tra slug="sa_grade"></tra>
                        </th>
                        <th colspan="2">
                            <tra slug="sa_sr_cog_lvl"></tra> 1
                        </th>
                        <th colspan="2">
                            <tra slug="sa_sr_cog_lvl"></tra> 2
                        </th>
                        <th colspan="2">
                            <tra slug="sa_sr_cog_lvl"></tra> 3
                        </th>
                        <th colspan="2">
                            <tra slug="sa_sr_cog_lvl"></tra> 1
                        </th>
                        <th colspan="2">
                            <tra slug="sa_sr_cog_lvl"></tra> 2
                        </th>
                        <th colspan="2">
                            <tra slug="sa_sr_cog_lvl"></tra> 3
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <tra slug="sa_sr_actual_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_possible_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_actual_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_possible_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_actual_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_possible_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_actual_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_possible_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_actual_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_possible_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_actual_score"></tra>
                        </th>
                        <th>
                            <tra slug="sa_sr_possible_score"></tra>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of cognitiveLevelScoresReport.data.data">
                        <td>{{row.last_name}}</td>
                        <td>{{row.first_name}}</td>
                        <td>{{row.pen}}</td>
                        <td>{{row.grade}}</td>

                        <td>{{row.l1_score}}</td>
                        <td>{{row.l1_weight}}</td>
                        <td>{{row.l2_score}}</td>
                        <td>{{row.l2_weight}}</td>
                        <td>{{row.l3_score}}</td>
                        <td>{{row.l3_weight}}</td>
                        <td>{{row.n1_score}}</td>
                        <td>{{row.n1_weight}}</td>
                        <td>{{row.n2_score}}</td>
                        <td>{{row.n2_weight}}</td>
                        <td>{{row.n3_score}}</td>
                        <td>{{row.n3_weight}}</td>

                    </tr>
                </tbody>
            </table>
        </ng-container>


        <ng-container *ngIf="sheet == Sheet.STUDENT_SELF_REFLECTION && studentSelfReflectionReport">
            <bc-paginator [paginator]="pagination" (refresh)="updateTable()"></bc-paginator>
            <table>
                <thead>
                    <tr>
                        <th colspan="4"></th>
                        <th colspan="2">
                            <tra slug="sa_sr_student_reflection"></tra>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <tra slug="sa_lastname"></tra>
                        </th>
                        <th>
                            <tra slug="sa_firstname"></tra>
                        </th>
                        <th>
                            <tra slug="sa_pen"></tra>
                        </th>
                        <th>
                            <tra slug="sa_grade"></tra>
                        </th>
                        <th>
                            <tra slug="sa_literacy"></tra>
                        </th>
                        <th>
                            <tra slug="sa_numeracy"></tra>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of studentSelfReflectionReport.data.data">
                        <td>{{row.last_name}}</td>
                        <td>{{row.first_name}}</td>
                        <td>{{row.pen}}</td>
                        <td>{{row.grade}}</td>

                        <td>{{row.literacy}}</td>
                        <td>{{row.numeracy}}</td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
</div>