import { Component, Input, OnInit } from '@angular/core';
import { IContentElementPassage } from './model';
import { DEFAULT_WIDTH_EM, IContentElementColorPanel, QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { StyleprofileService } from 'src/app/core/styleprofile.service';
import { AuthScopeSettingsService } from 'src/app/ui-item-maker/auth-scope-settings.service';
import { LangService } from 'src/app/core/lang.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BackgroundFillService } from '../background-fill.service';
import { TextToSpeechService } from '../text-to-speech.service';

const PARARGRAPH_SPACING = 1;
type ILine = {
  // identSpans:{
  //   str: string,
  //   width: number,
  // }[]
}
type ISegment = {
  // lines:ILine[], 
  str:string,
  isComplete?:boolean
}

type ImageSubTextConfig = {
  subText: string;
  subTextAlignment: string;
  subTextWidth: number;
  subTextSzie: number;
  subTextColourScheme: IContentElementColorPanel;
  subTextX: number;
  subTextY: number;
}
@Component({
  selector: 'element-render-passage',
  templateUrl: './element-render-passage.component.html',
  styleUrls: ['./element-render-passage.component.scss']
})
export class ElementRenderPassageComponent implements OnInit {

  @Input() element:IContentElementPassage;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  hcPrevState: boolean;

  constructor(
    private lang:LangService,
    private styleProfile:StyleprofileService,
    private authScope: AuthScopeSettingsService,
    private sanitizer: DomSanitizer,
    public bgFillService: BackgroundFillService,
    public text2Speech: TextToSpeechService,
  ) {
    this.hcPrevState = this.text2Speech.isHiContrast
   }

  ngOnInit() {
    // this.updateRender();
    // this.profile.getStyleProfileChanges().subscribe((hasStyleProfile) => {
    //   if(hasStyleProfile) {
    //     this.updateRender();
    //   }
    // })
  }

  ngOnDestroy(): void {
    // if(this.styleProfileChangeSub) {
    //   this.styleProfileChangeSub.unsubscribe();
    // }
  }

  getImageRefs(){
    const imageRef:Map<number, {url:string, width_em:number, alignment?:string, subTextConfig?: ImageSubTextConfig, isNoInvertOnHiContrast?: boolean}> = new Map()
    if (this.element.images){
      for (let image of this.element.images){
        const alignment = image.alignment
        const imageEl = image.el?.images?.default?.image || {url:'', scale:1}; // todo:bad backwards compatibility
        const url = imageEl.url;
        const width_em = DEFAULT_WIDTH_EM * (imageEl.scale /100);
        const isNoInvertOnHiContrast = image.el.isNoInvertOnHiContrast;

        let subTextConfig: ImageSubTextConfig;
        if (image.el?.subtexts && image.el?.subtexts.length != 0){
          const subTextEl = image.el?.subtexts[0]
          subTextConfig = {
            subText: subTextEl.text,
            subTextAlignment: subTextEl.alignment,
            subTextWidth: subTextEl.width,
            subTextSzie: subTextEl.size,
            subTextColourScheme: subTextEl.colourScheme,
            subTextX: subTextEl.x,
            subTextY: subTextEl.y? subTextEl.y: 0,
          }
        }

        imageRef.set(+image.id, { url, alignment, width_em, subTextConfig, isNoInvertOnHiContrast })
      }
    }
    return imageRef
  }

  processPStyle(htmlContent: string): string {
    const htmlContentSplit = htmlContent.split('\n')

    return htmlContentSplit.map(line => {
      let paragraphStyle:{tabs:{sizeEm:number}[]};
      line = line.replace(/<pstyle id="(\d+)"\/?>/g, (match:string, id:string) => {
        for (let ps of this.element.paragraphStyles){
          if (+ps.id == +id){
            paragraphStyle = ps;
          }
        }
        return '';
      });
      if (paragraphStyle){
        const lineIndentSpans = line.split('<t/>');
        line = `${ lineIndentSpans.map((str, i) => {
          paragraphStyle.tabs
          const tab = paragraphStyle.tabs[i]
          const widthStyle = (tab && tab.sizeEm) ? ' width:'+tab.sizeEm+'em' : '';
          return `<span style="display:inline-block;${widthStyle}">${str}</span>`
        }).join('') }`
      }

      return line;
    }).join('\n');
  }

  lastTextSnapshot:string;
  segments:{html?:SafeHtml, isComplete?:boolean}[];
  renderTextSegments(){
    const getBookmarkTag = (i, body: string, isParagraph?: boolean): string => {
      return `<div class="bookmark id-${isParagraph ? 'paragraph' : 'line'}_${i+1}">${body}</div>`
    }
    const snapshot = this.element._changeCounter+this.element.text;
    if (this.lastTextSnapshot != snapshot || this.hcPrevState != this.text2Speech.isHiContrast){

      this.hcPrevState = this.text2Speech.isHiContrast
      this.lastTextSnapshot = snapshot

      let htmlContent = this.element.text || '';
      const imageRef = this.getImageRefs()
      // use custom small caps
      htmlContent = htmlContent.replace(/<sc\b[^>]*>(.*?)<\/sc>/g, '<span class="small-caps">$1</span>');
      // apply bookmark tags (and other style profile transforms)
      htmlContent = this.processPStyle(htmlContent);
      htmlContent = this.processBookmark(htmlContent);
      htmlContent = this.styleProfile.processBookmark(htmlContent);
      htmlContent = this.styleProfile.processBoldedMarkup(htmlContent);
      htmlContent = this.styleProfile.processItalicsMarkup(htmlContent);
      htmlContent = this.styleProfile.processTooltip(htmlContent);
      // apply image tags
      htmlContent = htmlContent.replace(/<img id="(\d+)">/g, (match:string, id:string) => {
        const imageData = imageRef.get(+id);
        if (imageData) {

          const alignmentClass = imageData.alignment ? ` align-${imageData.alignment} ` : ''
          const imgSubTextPosition = imageData.subTextConfig? 'position: relative;' : ''
          const isNoInversionOnHC = imageData.isNoInvertOnHiContrast && this.text2Speech.isHiContrast
          const imageInversionStyle = isNoInversionOnHC? 'filter: invert(1);': '';

          let replacement = `<div class="img-container ${alignmentClass}" style="${imgSubTextPosition}${imageInversionStyle}"><img id="${id}" style="width:${imageData.width_em}em" src="${imageData.url}" `;

          if (imageData.subTextConfig){
            replacement += `><div class="image-sub-text" style="font-size: ${imageData.subTextConfig.subTextSzie}em; left: ${imageData.subTextConfig.subTextX}em; top: ${imageData.subTextConfig.subTextY}em; line-height: 1.3; text-align: ${imageData.subTextConfig.subTextAlignment}; color: ${imageData.subTextConfig.subTextColourScheme.textColor};"><markdown-inline class="ignore-inversion" style="text-align: ${imageData.subTextConfig.subTextAlignment};"><span class="markdown-inline">${imageData.subTextConfig.subText}</span></markdown-inline></div`
          }

          replacement += '></div>';
          return replacement;
        }
        return match; // Return the original match if no image data is found
      })
      // split the lines
      
      const segments:ISegment[] = [];
      let currentSegment:{chunks:string[], numLines:number};
      const resetCurrentSegment = () => currentSegment = {chunks:[], numLines:0};
      resetCurrentSegment();

      const htmlContentSplit = htmlContent.split('\n')
      if (this.element.counterType === 'LINE'){
        let lineCount = 0;
        for (let line of htmlContentSplit){
          // strippedLine holds the version of the line without bookmark tags to check for emptyness
          let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
          strippedLine = strippedLine.replace(/<\/div>/g, '');
          // bookmarkTag holds the original bookmark tag before it was replaced.
          const bookmarkTag = line.match(/<div class="bookmark id-[^>]+>/g)

          const isLineFilled = ((strippedLine || ' ').trim() != '');
          let isLineSkipped = !(isLineFilled || !this.element.isLineCountSkipBlank);
          line = line.replace(/<skip\/>/g, (match:string, id:string) => {
            isLineSkipped = true
            return '';
          })

          // If the line is empty, an empty space needs to be added to be parsed by the HTML
          if(!isLineFilled && bookmarkTag.length > 0) {
            currentSegment.chunks.push(`${bookmarkTag[0]} </div>`)
          } else {
            currentSegment.chunks.push(line)
          }
          if (!isLineSkipped){
            currentSegment.numLines += 1
          }
          if (currentSegment.numLines >= this.element.lineCountInterval){
            segments.push({ str: currentSegment.chunks.join('\n'), isComplete:true })
            resetCurrentSegment();
          }
        }
      }
      else if (this.element.counterType === 'PARAGRAPH'){
        for (let line of htmlContentSplit){
          let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
          strippedLine = strippedLine.replace(/<\/div>/g, '');

          const isBlankLine = (
            strippedLine.trim() == ''
          )

          if (!isBlankLine){
            currentSegment.chunks.push(line);
          }
          if (isBlankLine && currentSegment.chunks.length > 0){
            segments.push( {str:currentSegment.chunks.join('\n'), isComplete:true} )
            resetCurrentSegment();
          }
        }
      }
      else if (this.element.counterType === 'NONE'){
        segments.push( {str:htmlContentSplit.join('\n')} )
      }
      // push whatever is left
      if (currentSegment.chunks.length){
        segments.push( {
          str: currentSegment.chunks.join('\n'),
          isComplete: (this.element.counterType === 'PARAGRAPH'), // if paragraph mode, then whereever the text ends is considered the end of the paragraph (dont need another set of spaces afterwards)
        })
      }
      this.segments = segments.map((segment, i) =>{
        const {str, isComplete} = segment;
        return {
          html: this.sanitizer.bypassSecurityTrustHtml(str),
          isComplete,
        }
      });
      // console.log('this.segments', this.segments)
    }
    return this.segments
  }

  paragraphSpacing(){
    if (this.element.counterType == 'PARAGRAPH'){
      return PARARGRAPH_SPACING;
    }
    return 0
  }

  isCounterAlignRight(){
    return (this.element.counterAlignment == 'right')
  }
  isCounterAlignLeft(){
    return ! this.isCounterAlignRight()
  }

  isLinesMode(){
    return (this.element.counterType == 'LINE')
  }
  isParagraphMode(){
    return (this.element.counterType == 'PARAGRAPH')
  }

  isShowCounter(){
    return (this.element.counterType !== 'NONE') && (+this.element.lineCountInterval > 0);
  }
  
  renderLineCount(index:number){
    const count = (+index + 1);
    if (this.element.counterType == 'PARAGRAPH'){
      return count
    }
    else if (this.element.counterType == 'LINE'){
      return count*this.element.lineCountInterval
    }
  }

  processBookmark(html: string): string {
    const passage = html.split('\n')
    let processedPassage = passage.map((line, i) => {
      return `<bookmark id="line_${i+1}">${this.balanceTags(line)}</bookmark>`;
    });
    return processedPassage.join('\n');
  }

  // we represent a stack. We push every opening tag we see onto the 
  // stack and pop the most recent tag when we see a closing tag
  openTags = []
  balanceTags(line: string) : string {

    const regex = /<\/?bookmark[^>]*>/g;
    const matches = line.match(regex) || [];

    let preLine = this.openTags.join("")

    for (const match of matches) {
      if (match.startsWith('</')) {
        // Closing tag
        if (this.openTags.length === 0) {
          // break if there is a closing tag without any open tags. This is invalid bookmark tag structure
          break;
        }
        this.openTags.pop();
      } else {
        // Opening tag
        this.openTags.push(match);
      }
    }

    // close any unclosed opening tags
    const postLine = Array.from({ length: this.openTags.length }, (_, index) => `</bookmark>`).join("");


    return preLine + line + postLine

  }

}
