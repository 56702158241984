import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';

@Component({
  selector: 'export-table-contents',
  templateUrl: './export-table-contents.component.html',
  styleUrls: ['./export-table-contents.component.scss']
})
export class ExportTableContentsComponent implements OnInit {

  constructor(
    private auth: AuthService,
  ) { }

  isDownloadingData:boolean = false;
  @Input() tableContents = [];
  @Input() filename = '';
  @Input() toGenerateData = false;

  ngOnInit(): void {
  }

  async downloadData(){
    this.isDownloadingData = true;
    const {url} = <any> await this.auth.jsonToExcel(this.tableContents, this.filename);
    // downloadFile(url) // cant import?
    const a = document.createElement("a"); a.href = url; a.click(); // see above
    this.isDownloadingData = false;
  }
}
