import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { DEFAULT_TICK_MEASURE } from './constants'
import { OnInit } from "@angular/core";
import { IExtendedTools } from "../element-render-virtual-tools.component";

export class FreehandPencil extends VirtualTools{
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  hcPrevState: boolean;
  staticBg: PIXI.Graphics;
  isDrawMode = false;
  pencilTools: IExtendedTools;
  pencilColor = '#000000'
  drawings: PIXI.Graphics;
  pencilThickness = 1; // could be three

  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    staticBg: PIXI.Graphics
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()
    this.hcPrevState = this.textToSpeech.isHiContrast;  

    this.element = element;
    this.isGlobalRotating = isGlobalRotating;
    this.isGlobalDragging = isGlobalDragging;
    this.staticBg = staticBg;

    this.spriteLoader = new SpriteLoader();
    this.drawings = new PIXI.Graphics();
    this.drawings.zIndex = 2;

    this.pencilTools = {
      parent: 'pencil',
      colors: [
        '#000000',
        '#c43f2e',
        '#2a91d6'
      ],        
      colorHandler: (color: string) => {
        this.pencilColor = color;
      },
      tools: [
      {
        id: 'delete',
        action: () => this.clearDrawing(),
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
      },
      {
        id: 'colorPicker',
        isColorPick: true,
        colorController: this.pencilColor,
        action: () => {},
      },
    ]}


    this.loadAssets().then(this.initSprites);

    this.addDrawListener();
  }

  initSprites = ({resources}) => {
    // this.deleteSprite.texture = resources.delete.texture;
  }

  clearDrawing() {
    this.drawings.clear();
    this.render();
  }

  toggleDrawMode(toggle?: boolean) {
    this.isDrawMode = toggle == null ? !this.isDrawMode : toggle;

    if(this.isDrawMode) {
      this.staticBg.cursor = 'crosshair';
    } else {
      this.staticBg.cursor = 'default';
    }

    return this.isDrawMode;
  }

  lastPoint;
  addDrawListener() {
    let clicked = false;

    this.addGraphic(this.drawings);

    const draw = (x, y) => {
      this.drawings.drawCircle(x, y, 3);
      this.render();
    }

    const onClick = (e) => {
      if(this.isDrawMode) {
        this.drawings.beginFill(this.getParsedColor(this.pencilColor));
        const mousePosition = e.data.getLocalPosition(this.stage);
        clicked = true;

        // this.drawings.lineStyle(this.pencilThickness, this.getParsedColor(this.pencilColor), 1);
        // // this.drawings.beginFill(this.getParsedColor(this.pencilColor));
        // const mousePosition = e.data.getLocalPosition(this.stage);
        // clicked = true;
        draw(mousePosition.x, mousePosition.y);
      }
    }

    const onDragEnd = () => {
      if(this.isDrawMode) {
        this.drawings.endFill();
        clicked = false;
      }
    }

    const onDrag = (e) => {
      if(clicked && this.isDrawMode) {
        const mousePosition = e.data.getLocalPosition(this.stage);

        draw(mousePosition.x, mousePosition.y);
      }
    }
    this.staticBg.on('pointerdown', onClick).on('pointerup', onDragEnd).on('pointermove', onDrag)
  }

  getPencilTools() {
    return this.pencilTools;
  }

  loadAssets = () => {
    let assets = [];
    assets.push({
      name: "delete", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png"
    });

    this.spriteLoader.addSpritestoLoader(assets);
    return this.spriteLoader.loadSprites()
  }
}
