<div #bgDiv class="map-background" [ngStyle]="getBgStyle()">
    <div class="user-name-logout-btn" [class.is-g6]="isG6" [class.is-g3]="!isG6">
        <span  *ngIf="displayName" class='user-name' style="font-weight: bold;"><tra audio-slug [slug]="displayName"></tra></span>
        <button class="map-logout-button" (click)="logout()">
            <span *ngIf="!isPublic"><a class='logout-btn' [class.is-g6]="isG6" [class.is-g3]="!isG6" ><tra slug="btn_logout"></tra></a></span>
        </button>
    </div>
    <pj-map-button [btnConfig]="map.homeBtnConfig" (click)="goHome.emit($event)"></pj-map-button>
    <div class="map-midground" [ngStyle]="mgStyle || getInitMgStyle()">
        <img [src]="getMgSrc()" #mgImg style="height: 100%">
        <map-element *ngFor="let mapElement of mapElements" [element]="mapElement" [config]="getMapElInput()" (selectElement)="selectElement(mapElement)"></map-element>
    </div>
    <pj-map-button *ngIf="allModulesComplete()" [btnConfig]="map.finishBtnConfig" (click)="goToCompletion.emit($event)"></pj-map-button>
</div>