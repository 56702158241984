import { Injectable } from '@angular/core';
import { PrintModeService } from './print-mode.service';

@Injectable({
  providedIn: 'root'
})
export class PrintAltTextService {

  private altTextVisible: boolean;

  constructor(private printMode: PrintModeService) { }

  resetAltTextVisible() {
    this.altTextVisible = false;
  }

  toggleAltTextVisible() {
    this.altTextVisible = !this.altTextVisible;
  }

  getAltTextVisible() { 
    return this.altTextVisible && this.printMode.isActive;
  }
}
