<div class="custom-column-filter">
    <div [ngSwitch]="filter.type">
        <div *ngSwitchCase="IColumnFilterType.BOOLEAN_LIST">
            <mat-slide-toggle *ngFor="let option of filter.options"
              class="example-margin"
              [(ngModel)]="option.checked"
              (change)="updateFilter()"
            >
                {{option.label}}
            </mat-slide-toggle>
        </div>
    </div>
</div>
