// Overview
// export interface ConjugationEntry{
//     searchWord: string;
//     data: {
//         active: {
//             indicatif: {
//                 present: string[];
//                 imparfait: string[];
//                 passe_simple: string[];
//                 futur: string[];
//                 passe_compose: string[];
//                 plus_que_parfait: string[];
//                 passe_anterieur: string[];
//                 futur_anterieur: string[];
//             };
//             subjonctif: {
//                 //...
//             };
//             conditionnel: {
//                 //...
//             };
//             imperatif: {
//                 //...
//             };
//             infinitif: {
//                 //...
//             };
//             participe: {
//                 //...
//             }
//         };

//         passive:{
//             //... same as active
//         };
//     };
// }

export enum WordView {
    DEFINITION = 'DEFINITION',
    CONJUGATION = 'CONJUGATION',
}

export enum ConjugationView{
    ACTIVE = "Voix active",
    PASSIVE = "Voix passive",
}

export enum ConjugationForm {
    ALL = 'Tout',
    INDICATIF = 'INDICATIF',
    SUBJONCTIF = 'SUBJONCTIF',
    CONDITIONNEL = 'CONDITIONNEL',
    IMPÉRATIF = 'IMPÉRATIF',
    INFINITIF = 'INFINITIF',
    PARTICIPE = 'PARTICIPE'
}

export enum ConjugationFormSection {
    PRESENT = 'prÈsent',
    IMPARFAIT = 'imparfait',
    PASSE_SIMPLE = 'passÈ simple',
    FUTUR = 'futur',
    PASSE_COMPOSE = 'passÈ composÈ',
    PLUS_QUE_PARFAIT = 'plus-que-parfait',
    PASSE_ANTERIEUR = 'passÈ antÈrieur',
    FUTUR_ANTERIEUR = 'futur antÈrieur',
    PASSE = 'passÈ',
    INFINITIF_PRESENT = 'infinitif prÈsent',
    INFINITIF_PASSE = 'infinitif passÈ',
    PARTICIPE_PRESENT = 'participe prÈsent',
    PARTICIPE_PASSE_COMPOSE = 'participe passÈ composÈ'
}

export interface ConjugationEntry{
    searchWord: string;
    description: string;
    active:  ConjugationData;
    passive: ConjugationData;
}

// 12       |abdiquer|vt           |Verbe du 1&SUPer; groupe|avoir|
// wordIndex| word   |dataColThree |description             |conjugation
export interface ListGroupAllEntry{
    wordIndex: number;
    word: string;
    dataColThree: string;
    description: string;
    conjugation: string; // not sure what this is called
}

export interface ConjugationData{
    indicatif: ConjugationFormData;
    subjonctif: ConjugationFormData;
    conditionnel: ConjugationFormData;
    imperatif: ConjugationFormData;
    infinitif: ConjugationFormData;
    participe: ConjugationFormData;
}

export interface ConjugationFormData{
    present?: ConjugationSectionDataRaw[];
    imparfait?: ConjugationSectionDataRaw[];
    passe_simple?: ConjugationSectionDataRaw[];
    futur?: ConjugationSectionDataRaw[];
    passe_compose?: ConjugationSectionDataRaw[];
    plus_que_parfait?: ConjugationSectionDataRaw[];
    passe_anterieur?: ConjugationSectionDataRaw[];
    futur_anterieur?: ConjugationSectionDataRaw[];
    passe?: ConjugationSectionDataRaw[];
    infinitif_present?: ConjugationSectionDataRaw[];
    infinitif_passe?: ConjugationSectionDataRaw[];
    participe_present?: ConjugationSectionDataRaw[];
    participe_passe_compose?: ConjugationSectionDataRaw[];
}

// 62        |1            |indicatif   |1            |prÈsent     |1                  |je    |suis accastillÈ(e)|7924
// word index|form index   |form name   |section index|section name|section entry index|prefix|text content      |overall index
export interface ConjugationSectionDataRaw{
    wordIndex: number;
    formIndex: number;
    formName: string;
    sectionIndex: number;
    sectionName: string;
    sectionEntryIndex: number;
    prefix: string;
    textContent: string;
    overallIndex: number;
}