<div 
[class.is-panel-visible]="isSidePanelVisible()" 
[class.is-panel-mobile-visible]="isMobileLeftPanel()" 
[class.is-panel-expanded]="isSidePanelExpanded()" 
style="height:100%;"
id="top-level"
>
  <div class="mobile-overhead">
    <div class="mobile-overhead-bar">
      <div class="btn-menu" (click)=openMobileLeftPanel()>
        <i class="fa fa-bars" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div style="height:100%;">
    <div class="page-content content" >
      <router-outlet></router-outlet>
    </div>
    <div class="confirmation-modal"></div>
    <div class="log-back-in modal-screen-cover" [class.is-hidden]="!( getLoginReq() | async)">
      <div class="modal-screen-cover-content is-boxed">
        <div class="modal-scrollpane">
          <login-form></login-form>
        </div>
        <div class="delete-container">
          <button (click)="gotoHomeScreen()" class="delete is-large"></button>
        </div>
      </div>
    </div>
    <div class="connection-display">
      <span class="tag is-danger" [class.is-hidden]="!(getDevNetFail() | async)">
        <tra slug="error_device_net"></tra>
      </span>
      <span class="tag is-warning" [class.is-hidden]="!(getApiNetFail() | async)">
        <tra slug="error_server_net"></tra>
      </span>
    </div>
    <div class="connection-display">
      <span class="tag is-warning" [class.is-hidden]="getAllImagesLoaded()">
        <tra slug="Some images are still loading"></tra>
      </span>
    </div>
    <div class="connection-display">
      <span class="tag is-danger" *ngIf="(getAutoLogout() | async)">
        <tra slug="alert_logged_out"></tra>
      </span>
      <span class="tag is-warning" *ngIf="(getAutoLogoutWarning() | async)">
        <tra slug="alert_logging_out"></tra>
        <a (click)="refreshLogin()"> <tra slug="link_logging_out"></tra> </a>
      </span>
    </div>
    <div class="device-net-fail-overlay modal-screen-cover" [class.is-hidden]="!isShowingDevNetFailOverlay">
      <div class="modal-screen-cover-content  is-boxed">
        <div class="modal-scrollpane">
          <div>
            <tra slug="offline_warning"></tra>
          </div>
          <div>
            <button (click)="isShowingDevNetFailOverlay = false" class="button is-danger">
              <tra slug="btn_dismiss_notif"></tra>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="api-net-fail-overlay modal-screen-cover" [class.is-hidden]="true"> <!-- !isShowingApiNetFailOverlay -->
      <div class="modal-screen-cover-content  is-boxed">
        <div class="modal-scrollpane">
          <div>
            <tra slug="error_server_net_long"></tra>
          </div>
          <hr/>
          <strong>Service Health</strong>
          <table class="table is-outlined is-bordered" style="margin-top: 1em;">
            <tr>
              <th>Current Status</th>
              <th>Current Details</th>
            </tr>
            <tr>
              <td>✅ Registration</td>
              <td>Service is operating normally</td>
            </tr>
            <tr>
              <td>✅ Login</td>
              <td>Service is operating normally</td>
            </tr>
            <tr>
              <td>✅ Invigilation</td>
              <td>Service is operating normally</td>
            </tr>
          </table>
          <hr/>
          <div>
            You may be receiving an error due to unusual traffic coming from your location. If you continue to experience a localized issue, please send <a target="_blank" href="https://www.google.com/search?q=what+is+my+ip&oq=what+is+my+ip">your IP address</a> to the technical support team (<a href="mailto:eqao-support@vretta.com">eqao-support@vretta.com</a>) so that the issue can be investigated.
          </div>
          <hr/>
          <div>
            <button (click)="isShowingApiNetFailOverlay = false" class="button is-warning"><tra slug="btn_dismiss_notif"></tra></button>
          </div>
        </div>
      </div>
    </div>
    <div class="support-overlay modal-screen-cover" [class.is-hidden]="!(getSupportReq() | async) " style="z-index: 5000;">
      <div class="modal-screen-cover-content  is-boxed is-wide">
        <div class="modal-scrollpane">
          <info-center-details [isAuthActive]="isAuthActive()" [supportCenterInfo]="getSupportReq() | async" (dismiss)="dismissSupportPopup()"></info-center-details>
        </div>
      </div>
    </div>
    <div class="confirmation-overlay modal-screen-cover" [class.is-hidden]="!currentConfirmation" style="z-index:5000; line-height: 1.3em" [style.font-size.em]="currentConfirmation?.fontSize">
      <div class="modal-screen-cover-content  is-boxed" [style.max-width]="setCustomWidth()">
        <div class="modal-scrollpane">
          <div *ngIf="getCurrentConfirmationIcon()" class="content icon-display" [style]="getCurrentConfirmationIcon().iconStyle">
            <img [src]="getCurrentConfirmationIcon().iconSrcUrl">
          </div>
          <div class="content">
            <tra-md [slug]="getCurrentConfirmationCaption()" [props]="currentConfirmation?.props"></tra-md>
          </div>
          <div class="content" *ngIf="haveDescription()" style="width: 75%; margin: auto">
            <tra-md [slug]="getCurrentConfirmationDescription()" [props]="currentConfirmation?.descriptionProps"></tra-md>
          </div>
          <div class="content" *ngIf="showDropDownCategories()" style="width: 75%; margin: auto">
            <label><tra [slug]="getCurrentConfirmationCategoriesLabel()"></tra></label>
            <select [(ngModel)]="categorySelection" (ngModelChange)="categorySelectionChange($event)">
              <option *ngFor="let catagory of currentConfirmation.catagoriesList" [value]="catagory">{{getCatagoryText(catagory)}}</option>
            </select>
          </div>
          <div class="content" *ngIf="showExtraText()" style="width: 75%; margin: auto">
            <br>
            <textarea [(ngModel)]="extraTextInput" (ngModelChange)="extraTextAreaChange($event)"></textarea>
          </div>
          <br>
          <div class="content" *ngIf="showCurrentConfirmationSubCaption()" style="width: 75%; margin: auto">
            <tra-md [slug]="getCurrentConfirmationSubCaption()"></tra-md>
          </div>            
          <div *ngIf="showCurrentConfirmationInputBox()" class="modal-buttons">
            <input class="input" type="text" [(ngModel)]="confirmationInput">
          </div>
          <div *ngIf="showCurrentConfirmationCheckBox()" style="display:flex; margin-top:1em;">
            <div style="padding-top:0.3em;">
              <input type="checkbox" [(ngModel)]="confirmationCheckbox" id="confirmationCheckbox">
            </div>
            <div style="margin-left:1em;" class="text-checkbox"><label for="confirmationCheckbox"><tra-md [slug]="getCurrentConfirmationCheckBox()"></tra-md></label></div>
          </div>
          <div class="modal-buttons">
            <button class="button" style="font-size: 1.0em" [ngStyle]="getConfirmationBtnStyle(currentConfirmation?.btnCancelConfig)" (click)="closeModal()" *ngIf="!hideCancel()">
              <ng-container *ngFor="let caption of confirmationCancelCaptions; let i = index">
                <tra audio-slug [slug]="caption"></tra>
                <span *ngIf="i !== confirmationCancelCaptions.length - 1">&nbsp;</span>
              </ng-container>              
            </button>
            <button class="button is-info" style="font-size: 1.0em" [ngStyle]="getConfirmationBtnStyle(currentConfirmation?.btnProceedConfig)" (click)="confirmModal()" [disabled]="blockConfirm()" *ngIf="!hideConfirm()">
              <ng-container *ngFor="let caption of confirmationProceedCaptions; let i = index">
                <tra audio-slug [slug]="caption"></tra>
                <span *ngIf="i !== confirmationProceedCaptions.length - 1">&nbsp;</span>
              </ng-container>               
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmation-overlay modal-screen-cover" [class.is-hidden]="!isShowingInfoOverlay" style="z-index:5000;">
      <div class="modal-screen-cover-content is-boxed" style="background-color: #dcf3ff;padding-top:0em;">
        <div (click)="closeInfoOverlay()" class="close-button">
          <img src=" https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Close/1621026645213/Close.svg">
        </div>
        <div class="info-component-header">
          <div class="info-button">
            <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Info/1621008934819/Info.svg">
          </div>
          <div class="heading-text">
            &nbsp;
            Instructions
          </div>
        </div>
        <div class="modal-scrollpane">
          <div class="info-component">
            <img [src]="getBackPicUrl()">
             <span style="margin-left: 1em;">{{lang.tra("instruction_back")}}</span>
          </div>
          <div class="info-component">
            <img [src]="getNextPicUrl()">
            <span style="margin-left: 1em;">{{lang.tra("instruction_forward")}}</span>
          </div>
          <div class="info-component">
            <img [src]="getTextPicUrl()">
            <span *ngIf="!isNBED()" style="margin-left: 1em;">{{lang.tra("instruction_open_text_info")}}</span>
            <span *ngIf="isNBED()" style="margin-left: 1em;">{{lang.tra("instruction_open_text_info_NBED")}}</span>
          </div>
          <div class="info-component" style="margin-bottom:0em;">
            <img *ngIf="!isNBED" style="width:3em" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Question/1621021878125/Question.svg">
            <img *ngIf="isNBED" style="width:3em" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/Question_v2/1649344333742/Question_v2.svg">

            <span style="margin-left: 1em;">{{lang.tra("instruction_open_page")}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="support-overlay modal-screen-cover" [class.is-hidden]="!(getHealthCheck() | async) "  style="z-index:5000;">
      <div class="modal-screen-cover-content  is-boxed is-wide" style="padding:2em; max-width:50em; max-height: 80vh; overflow:auto;">
        <tra-md [slug]="getHealthCheck().value.message"></tra-md>
      </div>
    </div>
  </div>
  <div class="content-shadow" (click)="closeMobileLeftPanel()"></div>
  <main-nav class="left-panel-container hide-on-paper" [class.is-hidden]="!isSidePanelVisible()"></main-nav>
</div>
