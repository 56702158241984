// will want to run a process like this from the DB when saving the design:

// q = temp1.map(question => {
// const config = JSON.parse(question.config);
//   return {
//       id: question.id,
//       config: { meta: config.meta}
//   }
// })


export const ENS_ITEM_MAP = [
    {
      "id": 1809,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i01",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1814,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i01",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1815,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i01",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1925,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i01",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1810,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i02",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1816,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i02",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1817,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i02",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1818,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i03",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1819,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i03",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1820,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i03",
          "": "NUM",
          "T": "NUM"
        }
      }
    },
    {
      "id": 1823,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i4",
          "": "RP",
          "T": "RP"
        }
      }
    },
    {
      "id": 1824,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i4",
          "": "RP",
          "T": "RP"
        }
      }
    },
    {
      "id": 1825,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i4",
          "": "RP",
          "T": "RP"
        }
      }
    },
    {
      "id": 1826,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i5",
          "": "RP",
          "T": "RP"
        }
      }
    },
    {
      "id": 1827,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i5",
          "": "RP",
          "T": "RP"
        }
      }
    },
    {
      "id": 1828,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i5",
          "": "RP",
          "T": "RP"
        }
      }
    },
    {
      "id": 1829,
      "config": {
        "meta": {
          "EA": "A",
          "QS": "i6",
          "": "RP",
          "T": "RP"
        }
      }
    },
    {
      "id": 1861,
      "config": {
        "meta": {
          "EA": "A",
          "QS": "i6",
          "": "RP",
          "T": "RP"
        }
      }
    },
    {
      "id": 1862,
      "config": {
        "meta": {
          "EA": "A",
          "QS": "i6",
          "": "RP",
          "T": "RP"
        }
      }
    },
    {
      "id": 1830,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i7",
          "": "ALG",
          "T": "ALG"
        }
      }
    },
    {
      "id": 1831,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i7",
          "": "ALG",
          "T": "ALG"
        }
      }
    },
    {
      "id": 1832,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i7",
          "": "ALG",
          "T": "ALG"
        }
      }
    },
    {
      "id": 1833,
      "config": {
        "meta": {
          "QS": "i8",
          "EA": "B",
          "": "ALG",
          "T": "ALG"
        }
      }
    },
    {
      "id": 1871,
      "config": {
        "meta": {
          "QS": "i8",
          "EA": "B",
          "": "ALG",
          "T": "ALG"
        }
      }
    },
    {
      "id": 1872,
      "config": {
        "meta": {
          "QS": "i8",
          "EA": "B",
          "": "ALG",
          "T": "ALG"
        }
      }
    },
    {
      "id": 1834,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i9",
          "": "B",
          "T": "B"
        }
      }
    },
    {
      "id": 1835,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i9",
          "": "B",
          "T": "B"
        }
      }
    },
    {
      "id": 1836,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i9",
          "": "B",
          "T": "B"
        }
      }
    },
    {
      "id": 1873,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i10",
          "": "B",
          "T": "B"
        }
      }
    },
    {
      "id": 1875,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i10",
          "": "B",
          "T": "B"
        }
      }
    },
    {
      "id": 1876,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i10",
          "": "B",
          "T": "B"
        }
      }
    },
    {
      "id": 1874,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i11",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1877,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i11",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1878,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i11",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1879,
      "config": {
        "meta": {
          "EA": "A",
          "QS": "i12",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1880,
      "config": {
        "meta": {
          "EA": "A",
          "QS": "i12",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1881,
      "config": {
        "meta": {
          "EA": "A",
          "QS": "i12",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1882,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i13",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1883,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i13",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1884,
      "config": {
        "meta": {
          "EA": "D",
          "QS": "i13",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1885,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i14",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1886,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i14",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1887,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i14",
          "": "CM",
          "T": "CM"
        }
      }
    },
    {
      "id": 1888,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i15",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1918,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i15",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1919,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i15",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1889,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i16",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1890,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i16",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1891,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i16",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1892,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i17",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1893,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i17",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1894,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i17",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1895,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i18",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1896,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i18",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1897,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i18",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1898,
      "config": {
        "meta": {
          "QS": "i19",
          "EA": "B",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1899,
      "config": {
        "meta": {
          "QS": "i19",
          "EA": "B",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1900,
      "config": {
        "meta": {
          "QS": "i19",
          "EA": "B",
          "": "DM",
          "T": "DM"
        }
      }
    },
    {
      "id": 1901,
      "config": {
        "meta": {
          "QS": "i20",
          "EA": "C",
          "": "T",
          "T": "T"
        }
      }
    },
    {
      "id": 1902,
      "config": {
        "meta": {
          "QS": "i20",
          "EA": "C",
          "": "T",
          "T": "T"
        }
      }
    },
    {
      "id": 1903,
      "config": {
        "meta": {
          "QS": "i20",
          "EA": "C",
          "": "T",
          "T": "T"
        }
      }
    },
    {
      "id": 1904,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i21",
          "": "T",
          "T": "T"
        }
      }
    },
    {
      "id": 1905,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i21",
          "": "T",
          "T": "T"
        }
      }
    },
    {
      "id": 1906,
      "config": {
        "meta": {
          "EA": "C",
          "QS": "i21",
          "": "T",
          "T": "T"
        }
      }
    },
    {
      "id": 1907,
      "config": {
        "meta": {
          "EA": "A",
          "QS": "i22",
          "": "BS",
          "T": "BS"
        }
      }
    },
    {
      "id": 1908,
      "config": {
        "meta": {
          "EA": "A",
          "QS": "i22",
          "": "BS",
          "T": "BS"
        }
      }
    },
    {
      "id": 1909,
      "config": {
        "meta": {
          "EA": "A",
          "QS": "i22",
          "": "BS",
          "T": "BS"
        }
      }
    },
    {
      "id": 1910,
      "config": {
        "meta": {
          "QS": "i23",
          "EA": "B",
          "": "BS",
          "T": "BS"
        }
      }
    },
    {
      "id": 1911,
      "config": {
        "meta": {
          "QS": "i23",
          "EA": "B",
          "": "BS",
          "T": "BS"
        }
      }
    },
    {
      "id": 1912,
      "config": {
        "meta": {
          "QS": "i23",
          "EA": "B",
          "": "BS",
          "T": "BS"
        }
      }
    },
    {
      "id": 1913,
      "config": {
        "meta": {
          "EA": "C",
          "": "BID",
          "T": "BID",
          "QS": "i24"
        }
      }
    },
    {
      "id": 1914,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i25",
          "": "BID",
          "T": "BID"
        }
      }
    },
    {
      "id": 1915,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i25",
          "": "BID",
          "T": "BID"
        }
      }
    },
    {
      "id": 1916,
      "config": {
        "meta": {
          "EA": "B",
          "QS": "i25",
          "": "BID",
          "T": "BID"
        }
      }
    },
    {
      "id": 1917,
      "config": {
        "meta": {
          "Pr": true
        }
      }
    }
  ]