<div [class.is-icon-only]="isIconOnly">
    <a (click)="openLink()" (keydown.enter)="openLink()" 
        [style.color]="isHighContrast ? '#ff0' : ''" 
        tabindex="0" 
        class="text-link"
        [class.is-icon-only]="isIconOnly"
    > 
            <i *ngIf="!element.isIconDisabled || isIconOnly" class="fas fa-link"></i>&nbsp;
            <!-- <i style="width: 1.2em;" *ngIf="!element.isIconDisabled && textLinkType[textLinkCaption] === 'link'" class="fas fa-link">&nbsp;</i>
            <i style="width: 1.2em;" *ngIf="!element.isIconDisabled && textLinkType[textLinkCaption] === 'document'" class="fas fa-file">&nbsp;</i>
            <i style="width: 1.2em;" *ngIf="!element.isIconDisabled && textLinkType[textLinkCaption] === 'playback'" class="fas fa-play-circle">&nbsp;</i> -->

            <!-- use tra-md for &nbsp; and other html rendering if needed -->
            <tra-md *ngIf="!isIconOnly" [isCondensed]="true" style="font-weight: normal; text-decoration:underline" [slug]="textLinkCaption"></tra-md>
            <render-image *ngIf="element.thumbNail && !isIconOnly" [element]="element.thumbNail"></render-image>
    </a>
</div>
