export const MIN_PASSWORD_LEN = 8;
export const MAX_PASSWORD_LEN = 16;

// It looks like you made used the Forgot

export enum PassValConstraint {
    PASS_VALC_UNREC = 'PASS_VALC_UNREC', // unrecognized character
    PASS_VALC_MIN_CHARS = 'PASS_VALC_MIN_CHARS', // cannot contain less than 8 characters
    PASS_VALC_MAX_CHARS = 'PASS_VALC_MAX_CHARS', // cannot contain more than 16 characters
    PASS_VALC_UPPERCASE = 'PASS_VALC_UPPERCASE', // needs to contain an upper case letter
    PASS_VALC_LOWERCASE = 'PASS_VALC_LOWERCASE', // needs to contain a lower case letter
    PASS_VALC_NUMBER = 'PASS_VALC_NUMBER', // needs to ontain a number
    PASS_VALC_SYMBOL = 'PASS_VALC_SYMBOL', // needs to contain one of the specified symbols
}

