<div [ngClass]="{'page-container': loginGuard.getIsLoginFormActivated() == false}">
  <div *ngIf="loginGuard.getIsLoginFormActivated() == false" class="header-container">
    <header [breadcrumbPath]="breadcrumb" [isLoginPage]="true"> </header>
    </div>
    <div [ngClass]="{'panel-container': loginGuard.getIsLoginFormActivated() == false}"> 
      <div>
        <div class="form-card" [ngClass]="{'login-guard': loginGuard.getIsLoginFormActivated() == true, 'is-wide': loginGuard.getIsLoginFormActivated() == false}">
          <div *ngIf="loginGuard.getIsLoginFormActivated() == false" class="form-heading">
             <!-- <img src="assets/icons/student_diverse_icon_2.png">  -->
            <tra [slug]="getAdminLoginSlug()"></tra>
          </div>

          <div [ngClass]="loginGuard.getIsLoginFormActivated() == false ? 'authenticate-text' : 'authenticate-text-login-guard'" id="account-text">
          <tra slug="txt_account" > </tra>
          </div>
          <div id=email-display-parent>
            <div id="email-address-display" [ngStyle]="{'width': loginGuard.getIsLoginFormActivated() == true ? '17em' : '18em'}"> 
            {{emailAddress}} 
            </div>
          </div>
          <!-- <br> -->
          <div [ngClass]="loginGuard.getIsLoginFormActivated() == false ? 'authenticate-text' : 'authenticate-text-login-guard'">
          <tra slug="txt_code1"></tra>
          </div>
          <!-- <br> -->
          <br> 
          <div [ngClass]="loginGuard.getIsLoginFormActivated() == false ? 'authenticate-text' : 'authenticate-text-login-guard'" id="authenticate-note">
          <tra slug="txt_code2" id="authenticate-note-text"> </tra>
          </div>
          <div [ngClass]="{'form-container': loginGuard.getIsLoginFormActivated() == false, 'form-container-login-guard': loginGuard.getIsLoginFormActivated() == true}">
              <form [formGroup] = "formGroup" (ngSubmit)="attemptLogin()">

                <div class="field">                    
                    <div class="control">
                    <input [formControl]="formGroup.controls.totpToken" placeholder="XXXXXX" class="input is-success" type="text">
                    </div>
                </div>

                <div class="control">
                    <input type="submit" value={{authenticateText}} id="authenticate" class="button is-link is-fullwidth">
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div> 