<ng-container>
  <ng-container *ngFor="let acc of accommodationList">
    <div>
      <ng-container *ngIf="acc.type == 'checkbox'">
        <input [id]="acc.accommodation_slug" [disabled]="isDisabled" type="checkbox" [(ngModel)]="acc.value" />&nbsp;
      </ng-container>
      <label [for]="acc.accommodation_slug">
        <b><tra [slug]="acc.name"></tra></b>
      </label>&nbsp;
      <ng-container *ngIf="acc.has_extra_notes">
        <input type="text" [disabled]="!acc.value || isDisabled" [(ngModel)]="acc.extra_notes_value" />
      </ng-container>
    </div>
    <br>
  </ng-container>
</ng-container>