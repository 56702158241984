import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LangService } from '../../core/lang.service';

@Component({
  selector: 'tra',
  templateUrl: './tra.component.html',
  styleUrls: ['./tra.component.scss']
})
export class TraComponent implements OnInit, OnChanges {

  @Input() id: number;
  @Input() slug: string;
  @Input() whitelabel: string; // whitelabel text which needs to be routed to determine what it should be

  val: string;

  constructor(
    private langService:LangService,
  ) { }

  ngOnInit() {
    this.initSubScribers();  // change the val when lang changes actively
    this.render();
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.slug){
      this.render();
    }
  }

  initSubScribers = () => {
    this.langService?.languageInitialized.subscribe(() => this.render())
  }

  private render(){
    this.val = this.langService.traWithWhitelabel(this.whitelabel, this.slug || this.id);
  }

}
