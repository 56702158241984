import * as moment from 'moment-timezone';

const tzGuess = moment.tz.guess();
export const mtz = (dateStr?:string | Date, isDbUtc:boolean = false) => {
    if (isDbUtc && dateStr){
        dateStr = ''+dateStr
        if (dateStr[dateStr.length - 1] !== 'Z'){
            dateStr += 'Z'
        }
    }
    return moment.tz(dateStr, tzGuess);
}