import { IContentElement } from "../models";

export interface IContentElementDocLink extends IContentElement {
  url?: string;
  fileType?: string;
  caption?: string;
}

export const docLinkEditInfo = {
  editExcludeFields: [],
  editTextFields: ['caption']
}