<div class="page-content">
    <bc-export-button [disabled]="pagination.isLoading" [large]="true" (export)="export()"></bc-export-button>
    <div class="report-title-row">
        <div class="report-title">
            <tra slug="sc_scr_title"></tra>
        </div>
        <div class="horizontal-grow"></div>
        <a [routerLink]="getBackRoute()" class="back-to-reports">
            <tra slug="sa_sr_backto_list"></tra>
        </a>
    </div>
    <div class="report-description">
        <tra slug="sa_sr_desc_full"></tra>
    </div>
    <div class="dropdown-selectors">
        <div *ngIf="!isDistrictAdmin() && !isSchoolAdmin()" class="district-selector dropdown-selector">
            <bc-search-dropdown placeholder="District" [disabled]="pagination.isLoading" [inputValue]="selectedDistrict"
                [itemDisplayFn]="districtDisplayFn" [filterFn]="getFilterDistrictsFn()"
                (itemSelected)="districtSelected($event)"></bc-search-dropdown>
        </div>
        <div class="grade-selector dropdown-selector">
            <span class="selector-label">
                <tra slug="sa_sr_viewing"></tra>
            </span>
            <select [(ngModel)]="selectedGradeOption" (change)="onSelectedGradeChange($event)"
                [disabled]="pagination.isLoading">
                <option *ngFor="let grade of gradeOptions" [ngValue]="grade">
                    <tra-md [slug]="grade.caption"></tra-md>
                </option>
            </select>
        </div>
    </div>
    <div class="cover-with-loading">
        <div *ngIf="pagination.isLoading" class="reports-loader">
            <div></div>
        </div>
        <div class="district-summary" *ngIf="!isSchoolAdmin()">
            <table>
                <thead>
                    <tr>
                        <td>
                            <tra slug="sa_grade"></tra>
                        </td>
                        <th><span>
                                <tra slug="sa_sr_literacy"></tra>
                            </span></th>
                        <th><span>
                                <tra slug="sa_sr_numeracy"></tra>
                            </span></th>
                        <th><span>
                                <tra slug="sa_cr_literacy"></tra>
                            </span><br><span>
                                <tra slug="sa_written_component"></tra>
                            </span></th>
                        <th><span>
                                <tra slug="sa_cr_numeracy"></tra>
                            </span><br><span>
                                <tra slug="sa_written_component"></tra>
                            </span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="districtOverview && selectedGradeOption.grade != '7'">
                        <td>
                            <tra slug="sa_grade"></tra> 4
                        </td>
                        <td>{{districtOverview[0].sr_literacy}}
                            ({{getDivisionPercentageDisplay(districtOverview[0].sr_literacy,
                            districtOverview[0].enrollment)}}%)</td>
                        <td>{{districtOverview[0].sr_numeracy}}
                            ({{getDivisionPercentageDisplay(districtOverview[0].sr_numeracy,
                            districtOverview[0].enrollment)}}%)</td>
                        <td>{{districtOverview[0].cr_literacy}}
                            ({{getDivisionPercentageDisplay(districtOverview[0].cr_literacy,
                            districtOverview[0].enrollment)}}%)</td>
                        <td>{{districtOverview[0].cr_numeracy}}
                            ({{getDivisionPercentageDisplay(districtOverview[0].cr_numeracy,
                            districtOverview[0].enrollment)}}%)</td>
                    </tr>
                    <tr *ngIf="districtOverview && selectedGradeOption.grade != '4'">
                        <td>
                            <tra slug="sa_grade"></tra> 7
                        </td>
                        <td>{{districtOverview[1].sr_literacy}}
                            ({{getDivisionPercentageDisplay(districtOverview[1].sr_literacy,
                            districtOverview[1].enrollment)}}%)</td>
                        <td>{{districtOverview[1].sr_numeracy}}
                            ({{getDivisionPercentageDisplay(districtOverview[1].sr_numeracy,
                            districtOverview[1].enrollment)}}%)</td>
                        <td>{{districtOverview[0].cr_literacy}}
                            ({{getDivisionPercentageDisplay(districtOverview[1].cr_literacy,
                            districtOverview[1].enrollment)}}%)</td>
                        <td>{{districtOverview[0].cr_numeracy}}
                            ({{getDivisionPercentageDisplay(districtOverview[1].cr_numeracy,
                            districtOverview[1].enrollment)}}%)</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container>
            <bc-paginator [paginator]="pagination" (refresh)="updateTable()"></bc-paginator>
            <div class="table-section">
                <table>
                    <thead>
                        <th *ngFor="let headingToSortBy of getHeadingToSortBy()">
                            <div>
                                <div (click)="changeOrderBy(headingToSortBy.sortBy)"
                                    *ngIf="!headingToSortBy.sortDisabled">
                                    <span>
                                        <tra [slug]="headingToSortBy.heading"></tra>
                                    </span>
                                    <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'asc')" class="fa fa-caret-up"></i>
                                    <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'desc')" class="fa fa-caret-down"></i>
                                </div>
                                <div *ngIf="headingToSortBy.sortDisabled" class="sort-disabled">
                                    <span>
                                        <tra [slug]="headingToSortBy.heading"></tra>
                                    </span>
                                </div>
                                <i *ngIf="!headingToSortBy.filterDisabled" class="fa fa-filter" aria-hidden="true"
                                    (click)="toggleShowFilter(headingToSortBy.sortBy)"></i>
                            </div>
                            <div *ngIf="isFilterVisible(headingToSortBy.sortBy)">
                                <input type="text" (input)="updateFilter($event, headingToSortBy.sortBy)"
                                    [value]="getFilterValue(headingToSortBy.sortBy)">
                            </div>
                        </th>
                        <th *ngFor="let headingToSortBy of getComponentsHeadingToSortBy()">
                            <div>
                                <div (click)="changeOrderBy(headingToSortBy.sortBy)"
                                    *ngIf="!headingToSortBy.sortDisabled">
                                    <span>{{headingToSortBy.heading}}</span><br><span>{{headingToSortBy.subheading}}</span>
                                    <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'asc')" class="fa fa-caret-up"></i>
                                    <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'desc')" class="fa fa-caret-down"></i>
                                </div>
                                <div *ngIf="headingToSortBy.sortDisabled">
                                    <span>{{headingToSortBy.heading}}</span><br><span>{{headingToSortBy.subheading}}</span>
                                </div>
                                <i *ngIf="!headingToSortBy.filterDisabled" class="fa fa-filter" aria-hidden="true"
                                    (click)="toggleShowFilter(headingToSortBy.filterBy)"></i>
                            </div>
                            <div *ngIf="isFilterVisible(headingToSortBy.filterBy)">
                                <select class="filter-select"
                                    (change)="updateComponentFilter($event, headingToSortBy.filterBy)"
                                    [formControl]="getComponentFilterFormControl(headingToSortBy.filterBy)"
                                    [disabled]="pagination.isLoading">
                                    <option *ngFor="let option of componentFilterOptions" [value]="option.id">
                                        {{option.caption}} </option>
                                </select>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of reportTable, index as i" [class.odd]="row._isOdd">
                            <td><span *ngIf="row._isInfo">{{row.school_name}}</span></td>
                            <td><span *ngIf="row._isInfo">{{formatSchoolCode(row.school_foreign_id)}}</span></td>
                            <td><span *ngIf="row._isInfo">{{row.school_type}}</span></td>
                            <td>
                                <tra slug="sa_grade"></tra> {{row.grade}}
                            </td>
                            <td>{{row.enrollment}}</td>
                            <td>{{row.sr_literacy}}
                                ({{getDivisionPercentageDisplay(row.sr_literacy,
                                row.enrollment)}}%)</td>
                            <td>{{row.sr_numeracy}}
                                ({{getDivisionPercentageDisplay(row.sr_numeracy,
                                row.enrollment)}}%)</td>
                            <td>{{row.cr_literacy}}
                                ({{getDivisionPercentageDisplay(row.cr_literacy,
                                row.enrollment)}}%)</td>
                            <td>{{row.cr_numeracy}}
                                ({{getDivisionPercentageDisplay(row.cr_numeracy,
                                row.enrollment)}}%)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>

    </div>
</div>