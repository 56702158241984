import { Component, OnInit } from '@angular/core';
import { LangService } from '../../core/lang.service';

@Component({
  selector: 'formula-sheet',
  templateUrl: './formula-sheet.component.html',
  styleUrls: ['./formula-sheet.component.scss']
})
export class FormulaSheetComponent implements OnInit {

  constructor(
    public lang: LangService
  ) { }

  ngOnInit() {
  }

}
