<div class="annotation" [style.line-height]="element.annoteLineHeight ? element.annoteLineHeight : 1">
    <div *ngIf="element.text" class="annotation--symbol">
        <element-render-text [element]="element.text"></element-render-text>
    </div>
    <div 
        *ngIf="element.annotation" 
        class="annotation-text-container"
        [class.is-left-align]="isLeftAlign()"
        [class.is-right-align]="isRightAlign()"
        [class.is-constrained-width]="element.isConstrainedWidth"
    >
        <div class="annotation-text">
            <markdown-inline [input]="element.annotation"></markdown-inline>
        </div>
    </div>
</div>
