// 3647-18C8-E9E3-B2D3-D608-6A3A-7962-9B75
// bcb5-c7d0-949d-6a2e
// var str = '3647-18C8-E9E3-B2D3-D608-6A3A-7962-9B75';

export const removeSecretKeyMask = (str:string) => {
    return str
        .split('-')
        .join('')
        .toLowerCase()
}

export const applySecretKeyMask = (str:string) => {
    var chunks = [];
    var currentChunk = '';
    for (var i=0; i<str.length; i++){
        currentChunk += str[i];
        if (currentChunk.length >= 4){
            chunks.push(currentChunk);
            currentChunk = '';
        }
    }
    if (currentChunk.length > 0){
        chunks.push(currentChunk);
    }
    var transformed = chunks.join('-');
    return transformed.toUpperCase();
}
