<div class="table-paginator-container" 
    [class.is-strip]="isStrip"
    [class.is-small]="isSmall"
>
    
    <div class="is-centered" style="justify-content: space-between; width: 100%">
        <div class="is-centered">
            <!-- <button title="page config" style="margin-right:0.5em;"> <i class="fas fa-cog" aria-hidden="true"></i> </button> -->
            <tra slug="lbl_page"></tra> 
            <input type="number" min="1" [max]="getMaxPages()" (blur)="updatePage()"  (keydown.enter)="updatePage()" (click)="$event.target.select()" class="input is-small" [formControl]="currentPage">
            <tra slug="of_enum"></tra> 
            <span class="total-pages">{{getMaxPages()}}</span>
        
            <button class="button is-small" title="previous page" [disabled]="isOnFirstPage()" (click)="prevPage()"> <i class="fa fa-caret-left" aria-hidden="true"></i> </button>
            <button class="button is-small" title="next page"     [disabled]="isOnLastPage()" (click)="nextPage()"> <i class="fa fa-caret-right" aria-hidden="true"></i> </button>
        </div>
    
        <div *ngIf="showNumEntries">
            Showing {{getFirstDataIndex()}} - {{getLastDataIndex()}} of {{getDataCount()}}
        </div>

        <div *ngIf="model.configurablePageSize" class="page-size-selection">
            <span><tra slug="lbl_page_size"></tra></span>
            <div class="select is-small">
                <select [formControl]="selectedPageSize" (change)="selectPageSize($event.target.value)">
                    <option *ngFor="let ps of PageSizes" [value]="ps.size">
                        <tra [slug]="getPageSizeLabel(ps)"></tra>
                    </option>
                </select>
            </div>  
        </div>  
        
    </div>
</div>