import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from "rxjs";

export interface IDevTools {
  isOpen: boolean;
  orientation: string;
} 

declare global {
  interface Window {
    Firebug: any;
  }  
}

const THRESHOLD = 160;


@Injectable({
  providedIn: 'root'
})
export class DevtoolsDetectService implements OnDestroy {
  private readonly interval;
  private devtoolsSub:BehaviorSubject<IDevTools> = new BehaviorSubject(null);
  private devtools: IDevTools;
  
  public debugModePass:string;
  
  constructor() {
    this.interval = setInterval(() => {
      this.main()
    }, 500)
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }
  
  public sub = (): BehaviorSubject<IDevTools> => this.devtoolsSub
  
  private emitEvent = (isOpen, orientation) => {
    this.devtoolsSub.next({
      isOpen,
      orientation
    })
  }

  private main = (emitEvents:boolean = true) => {
    const widthThreshold = window.outerWidth - window.innerWidth > THRESHOLD;
    const heightThreshold = window.outerHeight - window.innerHeight > THRESHOLD;
    const orientation = widthThreshold ? 'vertical' : 'horizontal';
    if (
        !(heightThreshold && widthThreshold) &&
        ((window.Firebug && window.Firebug.chrome && window.Firebug.chrome.isInitialized) || widthThreshold || heightThreshold)
    ) {
      if ((!this.devtools || !this.devtools.isOpen || this.devtools.orientation !== orientation) && emitEvents) {
        this.emitEvent(true, orientation);
      }
      this.setDevtools(true, orientation)
    } else {
      if (this.devtools && this.devtools.isOpen && emitEvents) {
        this.emitEvent(false, undefined);
      }
      this.setDevtools(false, '')
    }
  }
  
  private setDevtools = (isOpen: boolean, orientation: string) => {
    if (!this.devtools) {
      this.devtools = {isOpen, orientation}
    } else {
      this.devtools.isOpen = isOpen;
      this.devtools.orientation = orientation;
    }
  } 
  
}
