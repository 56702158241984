import { Component, Input, OnInit } from '@angular/core';
import { QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementSbs } from './model';



@Component({
  selector: 'element-render-sbs',
  templateUrl: './element-render-sbs.component.html',
  styleUrls: ['./element-render-sbs.component.scss']
})
export class ElementRenderSbsComponent implements OnInit {
  
  @Input() element:IContentElementSbs;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;

  constructor() { }

  ngOnInit(): void {
  }

}
