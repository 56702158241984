import { FormControl, FormGroup } from '@angular/forms';
import { AuthService, F_ERR_MSG__INVALID_LOGIN } from '../api/auth.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { RoutesService } from '../api/routes.service';

interface ILoginFormErrors {
    isBlank?:boolean,
    isInvalid?: boolean,
  }
  
  export enum FormFail {
    NOT_FOUND,
    NOT_VERIFIED,
    UNKNOWN,
    PSW_RESET_REQ,
    MAX_LOGIN_ATTEMPT,
  }
  
export class LoginCtrl 
{
    public isFormValidated:boolean;
    public isLoginAttempted:boolean;
    public isFormSent:boolean;
    public isLoginTroubleshooting:boolean;
    public loginErrorTroubleshootMsg;
    public isFormFailed:boolean;
    public formFailReason:FormFail;
    public formGroupEmailAddress = new FormControl();
    public formGroupPassword = new FormControl();
    public formGroup = new FormGroup({
      emailAddress: this.formGroupEmailAddress,
      password: this.formGroupPassword,
    });
    public isLoginAttemptedEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public loginFailSub = new Subject()
    public isMFAUser: boolean = null; // by default, isMFAUser must be set to null for views to work properly
    public isFirstLoginWithTotp: boolean = null;
    private isMFAUserSub:BehaviorSubject<boolean> = new BehaviorSubject(null); // by default, isMFAUser's initial value emitted to observers must be set to null for views to work properly
    private isLoginFormActivated: boolean = false; //only used for MFA logins

    constructor(
      private auth: AuthService,
      private routes: RoutesService
    )
    {
  
    }

    public getisMFAUser() {
      return this.isMFAUserSub;
    }

    public setIsLoginFormActivated(isLoginFormActivated: boolean) 
    {
      this.isLoginFormActivated = isLoginFormActivated;
    }

    validateForm()
    {
      //this.isFormValidated.next(false); 
      this.isFormValidated = false;
      let hasAnyErrors = false;
      const email = <FormControl>this.formGroup.controls.emailAddress;
      const password = <FormControl>this.formGroup.controls.password;
      let emailErrors:ILoginFormErrors = {};
      let passwordErrors:ILoginFormErrors = {};
      if (!email.value)
      {
        emailErrors.isBlank = true;
        hasAnyErrors = true;
      }
      else
      {
        const str = <string>email.value;
        const isMissingAtSign = str.indexOf('@') === -1;
        const isTooShort = str.length < 3;
        if (isMissingAtSign || isTooShort)
        {
          emailErrors.isInvalid = true;
          hasAnyErrors = true;
        }
      }
      
      if (!password.value)
      {
        passwordErrors.isBlank = true;
        hasAnyErrors = true;
      }
      else
      {
        const str = <string>password.value;
        if (str.length < 3)
        { // should be 8, but I need to type in my password
          this.onApiError({ message: F_ERR_MSG__INVALID_LOGIN });
          return;
        }
      }
      // email.setErrors(emailErrors);
      // password.setErrors(passwordErrors);
      this.formGroup.markAsUntouched();
      if (hasAnyErrors)
      {
        this._onApiError()
        return; 
      }
      // const controls = [email, password];
      this.formGroup.enable(); //enable formGroup for next login
      this.isFormValidated = true;
    
    }
  
  public async submitForm()
  {
    this.isFormFailed = false;
    this.validateForm();
    if (this.isFormValidated){
      this.isFormSent = true;
      const email = this.formGroup.controls.emailAddress.value;
      const password = this.formGroup.controls.password.value;
      this.isLoginAttempted = true;
      this.isLoginAttemptedEvent.next(true);

      this.auth
        .login(email, password)
        .then(async (success) => 
          {
            if (success) {
              // sets this.isMFAUser
              await this.userRequiresMFA();
              if (this.isMFAUser || success.user.isTotpUser) {
                if (this.isLoginFormActivated) {
                  this.auth.credentialsSub.next(true); 
                  this.isLoginFormActivated = false;   
                }
              }

              // bypass MFA 
              this.auth.setCredentials([email, password]);
              this.auth.credentialsSub.next(true); 
              this.isLoginFormActivated = false;   
            }
            else {
              this._onApiError();
              this.clearForm();
            }
          })
        .catch(e => 
          {
            this.onApiError(e);
            this.formGroup.controls.password.reset();
            this.auth.apiCreate('public/log', {
              slug: 'FAILED_LOGIN',
              data: {
                email, // password, // todo: remove this
                errorMessage: e.message
              }
            })
          })
    }
  }

  clearForm()
  {
    this.formGroup.controls.emailAddress.reset();
    this.formGroup.controls.password.reset();
  }

 
  onApiError(e:{message:string})
  {
    //console.log(e.message);
    this.formGroup.enable();
    this.isFormValidated = false;
    this.isFormFailed = true;
    if (e.message == 'NOT_VERIFIED'){
      this.formFailReason = (FormFail.NOT_VERIFIED);
    }

    else if (e.message == 'MAX_LOGIN_ATTEMPT')
    {
      this.formFailReason = (FormFail.MAX_LOGIN_ATTEMPT);
    }

    else if (e.message == 'PSW_RESET_REQ'){
      this.formFailReason = (FormFail.PSW_RESET_REQ);
    }
    else if (e.message == F_ERR_MSG__INVALID_LOGIN){
      this.formFailReason = (FormFail.NOT_FOUND);
    }
    else{
      this.formFailReason = (FormFail.UNKNOWN);
    }

    // easier troubleshooting
    try {
      this.loginErrorTroubleshootMsg = JSON.stringify(e, null, '  ');
    }
    catch(e){
      this.loginErrorTroubleshootMsg = e.code + ' ' + e.message;
    }
    // console.log('formFailReason', this.formFailReason)
    this.loginFailSub.next(true)
  }

  private _onApiError()
  {
    this.formGroup.enable();
    this.isFormValidated = false;
    this.loginFailSub.next(true)
  }

  //the below API call will always an object, which contains:
  //1) userRequiresMFA, a boolean based on whether they require MFA or not, and
  //2) isFirstLoginWithTotp, a boolean based on if this is their first login with MFA or not
  private async userRequiresMFA(): Promise<void>
  {
    let response = await this.auth.apiFind
    (
      this.routes.AUTH_REGISTER_MFA,
      {
        query: 
        {
          email: this.formGroup.controls.emailAddress.value
        }
      }
    );
    this.isMFAUser = response.userRequiresMFA;
    //response.isFirstLoginWithTotp might be null for 1st time MFA users (valid and accounted for)
    this.isFirstLoginWithTotp = response.isFirstLoginWithTotp; 
    this.isMFAUserSub.next(this.isMFAUser); 
    // console.log(this.isFirstLoginWithTotp);
    return;
  }
}