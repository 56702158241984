<div class="formula-sheet">

  <div style="font-size:1.2em; font-weight:600; padding:0.5em;">
    <tra slug="form_sheet_title"></tra>
  </div>
  <table class="table is-condensed is-bordered">
    <tr>
      <th> <tra slug="form_sheet_title_shape"></tra> </th>
      <th> <tra slug="form_sheet_title_perim"></tra> </th>
      <th> <tra slug="form_sheet_title_area"></tra> </th>
    </tr>
    <tr>
      <td>
        <div> <tra slug="form_sheet_shape_rectangle"></tra> </div>
        <img style="width: 12em;" src="{{lang.tra('form_sheet_img-rectangle')}}">
      </td>
      <td>
        <render-math [raw]="lang.tra('form_sheet_math_r1_c1_1')"></render-math>
        <div class="fs-or"><tra slug="form_sheet_txt_or"></tra></div>
        <render-math [raw]="lang.tra('form_sheet_math_r1_c1_2')"></render-math>
      </td>
      <td>
        <render-math [raw]="lang.tra('form_sheet_math_r1_c2')"></render-math>
      </td>
    </tr>
    <tr>
      <td>
        <div> <tra slug="form_sheet_shape_parallelogram"></tra> </div>
        <img style="width: 12em;" src="{{lang.tra('form_sheet_img-parallelogram')}}">
      </td>
      <td>
        <render-math [raw]="lang.tra('form_sheet_math_r2_c1_1')"></render-math>
          <div class="fs-or"><tra slug="form_sheet_txt_or"></tra></div>
          <render-math [raw]="lang.tra('form_sheet_math_r2_c1_2')"></render-math>
        </td>
        <td>
          <render-math [raw]="lang.tra('form_sheet_math_r2_c2')"></render-math>
        </td>
    </tr>
    <tr>
      <td>
        <div> <tra slug="form_sheet_shape_triangle"></tra> </div>
        <img style="width: 12em;" src="{{lang.tra('form_sheet_img-triangle')}}">
      </td>
      <td>
        <render-math [raw]="lang.tra('form_sheet_math_r3_c1')"></render-math>
        </td>
        <td>
          <render-math [raw]="lang.tra('form_sheet_math_r3_c2_1')"></render-math>
          <div class="fs-or"><tra slug="form_sheet_txt_or"></tra></div>
          <render-math [raw]="lang.tra('form_sheet_math_r3_c2_2')"></render-math>
        </td>
    </tr>
    <tr>
      <td>
        <div> <tra slug="form_sheet_shape_trapezoid"></tra> </div>
        <img style="width: 12em;" src="{{lang.tra('form_sheet_img-trapezoid')}}">
      </td>
      <td>
        <render-math [raw]="lang.tra('form_sheet_math_r4_c1')"></render-math>
        </td>
        <td>
          <render-math [raw]="lang.tra('form_sheet_math_r4_c2_1')"></render-math>
          <div class="fs-or"><tra slug="form_sheet_txt_or"></tra></div>
          <render-math [raw]="lang.tra('form_sheet_math_r4_c2_2')"></render-math>
        </td>
    </tr>
    <tr>
      <td>
        <div> <tra slug="form_sheet_shape_circle"></tra> </div>
        <img style="width: 9em;" src="{{lang.tra('form_sheet_img-circle')}}">
      </td>
      <td>
        <render-math [raw]="lang.tra('form_sheet_math_r5_c1_1')"></render-math>
          <div class="fs-or"><tra slug="form_sheet_txt_or"></tra></div>
          <render-math [raw]="lang.tra('form_sheet_math_r5_c1_2')"></render-math>
        </td>
        <td>
          <render-math [raw]="lang.tra('form_sheet_math_r5_c2')"></render-math>
        </td>
    </tr>
    <tr>
      <th> <tra slug="form_sheet_title_solid"></tra> </th>
      <th> <tra slug="form_sheet_title_surface_area"></tra> </th>
      <th> <tra slug="form_sheet_title_volume"></tra> </th>
    </tr>
    <tr>
      <td>
        <div> <tra slug="form_sheet_shape_cylinder"></tra> </div>
        <img style="width: 12em;" src="{{lang.tra('form_sheet_img-cylinder')}}">
      </td>
      <td>
        <div> <render-math [raw]="lang.tra('form_sheet_math_r6_c1_1')"></render-math> </div>
        <br/>
        <div> <render-math [raw]="lang.tra('form_sheet_math_r6_c1_2')"></render-math> </div>
        <br/>
        <div> <render-math [raw]="lang.tra('form_sheet_math_r6_c1_3')"></render-math> </div>
      </td>
      <td>
        <div><render-math [raw]="lang.tra('form_sheet_math_r6_c2_1')"></render-math></div>
        <br/>
        <div><render-math [raw]="lang.tra('form_sheet_math_r6_c2_2')"></render-math></div>
      </td>
    </tr>
    <tr>
      <td>
        <div> <tra slug="form_sheet_shape_rect_prism"></tra> </div>
        <img style="width: 12em;" src="{{lang.tra('form_sheet_img-rect_prism')}}">
      </td>
      <td>
        <render-math [raw]="lang.tra('form_sheet_math_r7_c1_1')"></render-math>
        </td>
        <td>
          <render-math [raw]="lang.tra('form_sheet_math_r7_c2_1')"></render-math>
          <br/><br/>
          <render-math [raw]="lang.tra('form_sheet_math_r7_c2_2')"></render-math>
        </td>
    </tr>
    <tr>
      <td>
        <div> <tra slug="form_sheet_shape_triang_prism"></tra> </div>
        <img style="width: 6em;" src="{{lang.tra('form_sheet_img-triang_prism')}}">
      </td>
      <td>
        <div><render-math [raw]="lang.tra('form_sheet_math_r8_c1_1')"></render-math></div>
        <br/>
        <div><render-math [raw]="lang.tra('form_sheet_math_r8_c1_2')"></render-math></div>
        <br/>
        <div><render-math [raw]="lang.tra('form_sheet_math_r8_c1_3')"></render-math></div>
      </td>
      <td>
        <render-math [raw]="lang.tra('form_sheet_math_r8_c2_1')"></render-math>
        <br/><br/>
        <div>
          <render-math [raw]="lang.tra('form_sheet_math_r8_c2_2')"></render-math>
          <span class="fs-or"><tra slug="form_sheet_txt_or"></tra></span>
          <render-math [raw]="lang.tra('form_sheet_math_r8_c2_3')"></render-math>
        </div>
      </td>
    </tr>
  </table>
</div>
