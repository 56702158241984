import { IContentElement } from "../models";

export interface IContentElementResultsPrint extends IContentElement {
    content: IContentElement[];
    inputEntryId?: number;
    isPlainText?:boolean;
}

export const resultsPrintEditInfo = {
    editExcludeFields: ['content']
}