<div [ngSwitch]="currentSubView">

    <ens-landing      *ngSwitchCase="ViewSub.LANDING"    
                      (startTest)="showTestRunner()" 
                      ></ens-landing>

    <ens-test-runner  *ngSwitchCase="ViewSub.TEST_RUNNER" 
                      [questionStates]="questionStates" 
                      [testDesign]="testDesign" 
                      [questionSrcDb]="questionSrcDb" 
                      [saveQuestionResponse]="saveQuestionResponse" 
                      [submitTest]="submitTest"
                      ></ens-test-runner>

    <ens-diagnostic    *ngSwitchCase="ViewSub.DIAGNOSTIC" 
                       (diagnosticContinue)="showCongratulations()" 
                       [categoriesTop]="categoriesTop" 
                       [categoriesBott]="categoriesBott" 
                       ></ens-diagnostic>

    <ens-congratulations *ngSwitchCase="ViewSub.CONGRATULATIONS" 
                         (congratulationsSubmit)="showCertificate($event)" 
                         ></ens-congratulations>

    <ens-certificate   *ngSwitchCase="ViewSub.CERTIFICATE" 
                       (certificateEnd)="returnToStart()" 
                       [name]="name" 
                       [email]="email" 
                       ></ens-certificate>

</div>