<div class="modal-buttons">
    
    <button 
        [disabled]="pageModal.isSaving" 
        class="button" 
        *ngIf="!pageModal.getCurrentModal().isProceedOnly"
        (click)="closeModal()"
    > <tra [slug]="pageModal.getCurrentModal().config.closeCaption ||closeMessage"></tra> </button>

    <button 
        *ngIf="confirmButton"
        [disabled]="isEditDisable || pageModal.isSaving" 
        class="button is-info" 
        (click)="confirmSubmission()"
    >
        <tra [slug]="pageModal.getCurrentModal().confirmationCaption || pageModal.getCurrentModal().config.confirmationCaption || 'btn_ok'"></tra>
    </button>

</div>
