import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintModeService {

  constructor() { }

  public isActive = false;

  public showOnlyScript = false;

  toggleShowOnlyScript() {
    this.showOnlyScript = !this.showOnlyScript;
  }
  
}
