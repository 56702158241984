import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecialKeyboardService {

  constructor() {}

  public currentElement: BehaviorSubject<any> = new BehaviorSubject({})
  public currentTimer

  focusBufferTime = 50

  focusOut() {
    if (this.currentTimer) {
      clearTimeout(this.currentTimer)
    }
    this.currentTimer = setTimeout(()=>{
      this.currentElement.next({})
    }, this.focusBufferTime)
  }
}