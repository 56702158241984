import { NgModule } from '@angular/core';
import { NotifyService } from './notify.service';
import { SidepanelService } from './sidepanel.service';
import { ChatpanelService } from './chatpanel.service';
import { RouteViewBaseComponent } from './route-view-base/route-view-base.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports : [
    HttpClientModule,
  ],
  providers: [
    NotifyService, 
    SidepanelService, 
    ChatpanelService,
  ],
  declarations: [RouteViewBaseComponent],
})
export class CoreModule { }
