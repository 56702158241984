export enum EFSAReportDataMode {
  ADMIN_SESSION,
  SAMPLE_ASSESSMENTS
}
  
export interface IFSAReportDataMode {
  id: EFSAReportDataMode;
  caption: string;
}

export const FSA_REPORT_DATA_MODES: IFSAReportDataMode[] = [
  {id: EFSAReportDataMode.ADMIN_SESSION, caption: 'Administration Session'},
  {id: EFSAReportDataMode.SAMPLE_ASSESSMENTS, caption: 'Sample Assessments'}
]  