import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextDiffService {

  constructor() { }

  // Subscription in text diff component to delete the 
  private textDiffMapping = new Map();


  // maps the textdiff after edit to its before edit
  mapTextDiff(after, before) {
    this.textDiffMapping.set(after, before);
  }

  getDiff(after) {
    return this.textDiffMapping.get(after);
  }


}
