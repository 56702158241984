import { Component, OnInit, Input, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { QuestionState } from '../models';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementIframe } from './model';



@Component({
  selector: 'element-render-iframe',
  templateUrl: './element-render-iframe.component.html',
  styleUrls: ['./element-render-iframe.component.scss']
})
export class ElementRenderIframeComponent implements OnInit, DoCheck {

  @Input() element:IContentElementIframe;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() zoomLevel:number = 1;
  @Input() frameId:string;

  urlRaw:string;
  url:SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.updateUrl();
  }
  ngDoCheck() {
    this.updateUrl();
  }

  updateUrl(){
    if (this.urlRaw !== this.element.url){
      this.urlRaw = this.element.url;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRaw);
    }
  }

  getTransformScale(){
    let min_zoom = Math.max(1, this.zoomLevel)
    return 'scale(' + min_zoom + ')' ;
  }

  getFrameHeight(){
    let min_zoom = Math.max(1, this.zoomLevel)
    let parentElement = document.getElementById("calcContainer")
    if(parentElement){
      let parentHeight = getComputedStyle(parentElement).getPropertyValue('height')
      let height = parseFloat(parentHeight)/min_zoom 
      return height > 510 ? height+'px' : '510px';
    }
    return '510px';
  }

}
