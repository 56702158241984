import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { LangService } from 'src/app/core/lang.service';

@Component({
  selector: 'bc-toggle',
  templateUrl: './bc-toggle.component.html',
  styleUrls: ['./bc-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BcToggleComponent implements OnInit {
  @Input() title: string;
  @Input() tra: string;
  @Input() disabled: boolean;
  @Output() toggle = new EventEmitter();

  checked: boolean;
  constructor(
    private lang: LangService,
  ) {
    this.checked = false;
    this.disabled = false;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    if (typeof (changes.disabled) !== 'undefined') {
      this.disabled = changes.disabled.currentValue;
    }
  }

  onToggle() {
    this.toggle.emit(this.checked);
  }

  getTitle(): string {
    if (this.tra) {
      return this.lang.tra(this.tra);
    }
    return this.title || '';
  }

}
