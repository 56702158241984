<div class="bc-paginator">
    <div>Page</div>
    <button class="minimal" [disabled]="disabled"><span>{{ getCurrentPageToDisplay() }}</span></button>
    <div><tra slug="sa_of"></tra> {{ getTotalPagesToDisplay() }}</div>
    <button class="minimal" (click)="goToPreviousPage()" [ngClass]="{'disabled': shouldDisablePreviousPage()}"
        [disabled]="disabled || shouldDisablePreviousPage()">
        <i class="fa fa-caret-left" aria-hidden="true"></i>
    </button>
    <button class="minimal" (click)="goToNextPage()" [ngClass]="{'disabled': disabled || shouldDisableNextPage()}"
        [disabled]="disabled || shouldDisableNextPage()">
        <i class="fa fa-caret-right" aria-hidden="true"></i>
    </button>
    <span class="count-display" *ngIf="showCount">{{getCount()}}</span>
</div>