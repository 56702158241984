import { Component, Input, OnInit } from "@angular/core";
import { PubSubTypes } from "../element-render-frame/pubsub/types";
import { ElementType, getElementWeight, ScoringTypes, IEntryStateScored } from "../models";
import { QuestionPubSub } from "../question-runner/pubsub/question-pubsub";
import * as _ from 'lodash';
import { IContentElementValidator, IValidatorCombinationProp, IEntryStateValidator, ValidatorMode } from "./model";

@Component({
  selector: "element-render-validator",
  templateUrl: "./element-render-validator.component.html",
  styleUrls: ["./element-render-validator.component.scss"]
})
export class ElementRenderValidatorComponent implements OnInit {
  @Input() element: IContentElementValidator;
  @Input() isLocked: boolean;
  @Input() questionState: any;
  @Input() questionPubSub?: QuestionPubSub;

  combinationMap: Map<string, IValidatorCombinationProp>;
  combinationValidateId: Map<number, { isFilled: boolean }>;

  constructor() {}

  ngOnInit(): void {
    this.ensureState();
    if (this.isComboMode()) {
      this.initComboMode();
    }
    this.questionPubSub.allSub().subscribe(payload => {
      if (payload.type === PubSubTypes.UPDATE_VALIDATOR){
        if (this.isSingleMode() && (payload.entryId === this.element.validateId)) {
          this.updateState();
        } 
        else if (this.isAllOrNothingMode()) {
          this.updateAllorNothingState();
        }
        else if (this.isComboMode() && this.combinationValidateId.has(payload.entryId)) {
          this.updateStateCombo();
        }
      }
    });
  }

  ngOnChange() {
    if (this.isComboMode()) {
      this.initComboMode();
    }
  }

  updateAllorNothingState(){
    let nume = 0;
    let deno = 0;
    let score = 0;
    const weight = getElementWeight(this.element);
    let isFilled = true;
    let isStarted = false;
    let isResponded = false;
    let isCorrect = false

    console.log('updateAllorNothingState')

    const entryIds = (this.element.targetEntryIdList || '').split(',').map(str => str.trim());
    for (let entryId of entryIds){
      const subState = this.questionState[+entryId];

      console.log('  :: subState', subState)
      
      deno += 1;

      if (subState){
        if (subState.isCorrect){
          nume += 1;
        }
        if (!subState.isResponded){
          isResponded = true;
        }
        if (!subState.isStarted){
          isStarted = true;
        }
        if (!subState.isFilled){
          isFilled = false
        }
      }
      else {
        isFilled = false
        // todo:ASSUMPTION giving a default weight of 1
      }
    }

    console.log('  :: nume/deno', nume, deno)


    if (deno > 0){
      if (nume == deno){
        score = weight;
        isCorrect = true;
      }
    }

    const es: IEntryStateValidator = {
      type: ElementType.VALIDATOR,
      entryIds: entryIds.join(),
      isCorrect,
      isFilled,
      isStarted,
      weight,
      score,
      scoring_type: ScoringTypes.AUTO
    };

    this.questionState[this.element.entryId] = es;
    
  }

  updateState() {
    const value = this.questionState[this.element.validateId][this.element.validateProp];
    const isFilled = value != null;
    const isCorrect = isFilled && value == this.element.correctValue;
    const weight = getElementWeight(this.element);
    const score = isCorrect ? weight : 0;

    // console.log("Value " + value);
    // console.log("isCorrect " + isCorrect);
    // console.log("Correct Value " + this.element.correctValue);
    const es: IEntryStateValidator = {
      type: ElementType.VALIDATOR,
      [this.element.validateProp as string] : value,
      isCorrect,
      isFilled,
      isStarted: isFilled,
      weight,
      score,
      scoring_type: ScoringTypes.AUTO
    };

    const questionState = this.questionState[this.element.validateId]
    if (questionState) {
      questionState.isCorrect = isCorrect;
      questionState.isStarted = isFilled;
      questionState.isFilled = isFilled;
    }

    this.questionState[this.element.entryId] = es;
  }

  // TODO : refactor
  updateStateCombo() {
    let isCorrect,
    isFilled = false;
    const weight = getElementWeight(this.element);
    let comboScore = 0;
    for (let combination of this.element.combinations) {
      isCorrect = false;
      for (let element of combination) {
        const { validateId, elementType, correctValue, dndElements } = element;
        const value = this.getElementData(elementType, this.questionState[validateId]);
        // console.log("Value " ,typeof value, value);

        if (!isFilled) isFilled = value != null;
        this.combinationValidateId.set(validateId, { isFilled: value != null });
        
        // if (Array.isArray(value)){
        //   const ansKey = new Set(correctValue.trim().split(','))
        //   for(let val of value){
        //     isCorrect = isFilled && ansKey.has(val)
        //     if(!isCorrect) break;
        //   }
        // } else if(_.isObject(value)){
        //     if(elementType === ElementType.GROUPING){
        //       for(let target of dndElements){
        //         const ansKey = target.correctValue.trim().split(','); 
        //         const ans = value[target.targetId]
        //         if(target.isOrderImp){
        //           isCorrect = this.compareArray(ansKey, ans);
        //         } else {
        //           const ansKeySet = new Set(ansKey) 
        //           isCorrect = ans.every(val => ansKeySet.has(val))
        //         }
        //         if(!isCorrect) break;
        //       }
        //     }
        //   }
        // else { isCorrect = isFilled && value == correctValue }

        isCorrect = this.validateElemenetData(value, isCorrect, isFilled, element)
        
        if (!isCorrect) break;        
      }
      if (isCorrect) {
        if (!combination[0].score && combination[0].score!=0) comboScore = weight;
        else comboScore = combination[0].score; 
        if (comboScore!=weight) isCorrect=false;
        break;
      }
    }

    // console.log("isCorrect", isCorrect)
    let entryState: IEntryStateValidator = {
      type: ElementType.VALIDATOR,
      value: undefined,
      isCorrect: isCorrect,
      isStarted: true,
      isFilled: isFilled,
      score: isCorrect ? weight : comboScore,
      weight: weight,
      scoring_type: ScoringTypes.AUTO
    };

    //update iscorrect, isFilled, isStarted for the elements
    this.combinationValidateId.forEach((elementState, id) => {
      const questionState = this.questionState[id];
      if (questionState) {
        questionState.isCorrect = isCorrect;
        questionState.isStarted = true;
        // Only override isFilled when it's true otherwise go with original questionState
        if(elementState.isFilled){ 
          questionState.isFilled = elementState.isFilled;
        }
      }
    });

    this.questionState[this.element.entryId] = entryState;
  }

  ensureState() {
    let entryState: IEntryStateValidator;
    if (this.questionState) {
      const entryId = this.element.entryId;
      entryState = this.questionState[entryId];
      if (!entryState) {
        entryState = {
          type: ElementType.VALIDATOR,
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: ScoringTypes.AUTO
        };

        if(this.isSingleMode()) {
          entryState[this.element.validateProp as string] = null
        } else {
          entryState.value = null
        }

        this.questionState[entryId] = entryState;
      }
    }
  }

  isAllOrNothingMode() { return this.element.mode == ValidatorMode.ALL_OR_NOTHING; }
  isSingleMode() { return this.element.mode == ValidatorMode.NORMAL; }
  isComboMode() { return this.element.mode == ValidatorMode.COMBINATION; }


  initComboMode() {
    this.combinationValidateId = new Map();
    if (this.element.combinations){
      this.element.combinations[0].forEach(element => {
        if (!this.combinationValidateId.has(element.validateId)) {
          this.combinationValidateId.set(element.validateId, { isFilled: false });
        }
      });
    }
  }

  validateElemenetData (value, isCorrect, isFilled, element) {
    const { elementType, correctValue, dndElements } = element; 

    if (Array.isArray(value)){
      const ansKey = new Set(correctValue.trim().split(','));
      for(let val of value){
        isCorrect = isFilled && ansKey.has(val);
        if(!isCorrect) break;
      }
    } 
    else if (_.isObject(value)){
      if(elementType === ElementType.GROUPING){
        for(let target of dndElements){
          const ansKey = target.correctValue.trim().split(','); 
          const ans = value[target.targetId]
          if(target.isOrderImp){
            isCorrect = this.compareArray(ansKey, ans);;
          } else {
            const ansKeySet = new Set(ansKey); 
            isCorrect = ans.every(val => ansKeySet.has(val));
          }
          if(!isCorrect) break;
        }
      }
      else if(elementType === ElementType.MOVEABLE_DND){
        console.log('here')
        console.log('dndElements: ', dndElements)
        if(dndElements.length == 0){
          return false
        }
        for(let target of dndElements){
          const ansKey = target.correctValue.trim().split(','); 
          const ans = value[target.targetId]
          // console.log('ansKey: ', ansKey)
          // console.log('ans: ', ans)
          
          const ansKeySet = new Set(ansKey); 
          if(ans.length == 0){
            isCorrect = false
          } else {
            isCorrect = ans.every(val => ansKeySet.has(val));
          }       
          // console.log('isCorrect: ', isCorrect); 
          if(!isCorrect) break;
        }
      }
    }
    else if (typeof value === 'number'){
      if (!isNaN(correctValue))
        isCorrect = isFilled && +value == +correctValue
      else
        isCorrect = isFilled && value == correctValue
    }
    else { isCorrect = isFilled && value == correctValue }
    return isCorrect;
  }

  getElementData(elementType, questionState) {
    switch (elementType) {
      case ElementType.MCQ:
        if (!questionState.selections.length) return null;
        let selection : string | string[];
        if(questionState.selections.length > 1){
          selection = questionState.selections.map(sel => this.getButtonLabel('ABCDEFGHIJKLMNOPQRSTUVWXYZ', sel.i));
        } else {
          let selectionIdx = questionState.selections[0].i;
          selection = this.getButtonLabel('ABCDEFGHIJKLMNOPQRSTUVWXYZ', selectionIdx);
        }
        return selection;
        
      case ElementType.FRAME:
        if(!questionState) return null;
        let values = []
        return questionState.currHeight;

      case ElementType.CUSTOM_INTERACTION:
      case ElementType.GROUPING:
        if(!questionState.targets || !questionState.targets.length) return null;
        let targets = {}
        questionState.targets.forEach((target, idx) => {
          let draggables = [];
          if(target.contents.length){
            draggables = target.contents.map((content) => content.ref.id)
          }
          targets[target.targetContext.id] = draggables;
        })
        return targets;

      case ElementType.INPUT:
        switch (questionState.type) {
          case 'input-number': return questionState.value;
          case 'input-fraction': return questionState.numerator + '/' + questionState.denominator;
          // case 'input-fraction' : return 
        }
        return questionState.value
      case ElementType.MOVEABLE_DND:
        if(!questionState.targets.length) return null;
        let targets_dnd = {}
        // console.log('questionState.targets', questionState.targets)
        questionState.targets.forEach((target, idx) => {
          let draggables = [];
          if(target.contents.length){
            draggables = target.contents.map((content) => content.ref.id)
          }
          targets_dnd[target.targetContext.id] = draggables;
        })
        return targets_dnd;
      default:
        return null;
    }
  }

  compareArray(array1: any[], array2: any[]){
    return array1.length === array2.length && 
            array1.every((value, index) => String(value) === String(array2[index]))
  }

  getButtonLabel(labelSelection: string, index: number){
    if(index > 25){
      let newIndex = index % 25;
      let labelToAppend = labelSelection[newIndex - 1];
      let firstLetter = labelSelection[Math.floor(index/25) - 1];
       
      return firstLetter + labelToAppend;
    } 
    return labelSelection[index];
  }
}
