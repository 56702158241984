import { BookmarkType } from "../element-render-bookmark-link/model";
import { IContentElementImage } from "../element-render-image/model";
import { IContentElement } from "../models";

export interface IContentElementTextLink extends IContentElement {
    caption: string;
    readerId?: string;
    readerElementId?: string;
    itemLabel?: string;
    thumbNail?: IContentElementImage;
    isIconDisabled?: boolean;
    bookmarkId?: string;
    readingSelectionCaption?: string;
    mode?: BookmarkType;
    bookmarkImg?: IContentElementImage;
    fontSize?: number;
    baseLine?: number;
  }

export const textLinkEditInfo = {
  editExcludeFields: ['thumbNail', 'bookmarkImg']
}

