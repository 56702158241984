
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LangService } from 'src/app/core/lang.service';
import { getVoiceChange } from 'src/app/io-audio/capture-voice/capture-voice.component';
import { IContentElementOption } from 'src/app/ui-testrunner/element-render-mcq/model';
import { EditingDisabledService } from '../editing-disabled.service';
import { bindFormControls } from '../services/data-bind';

export const DEFAULT_VOICEOVER_PROP = 'voiceover';

@Component({
  selector: 'element-config-mcq-option-info',
  templateUrl: './element-config-mcq-option-info.component.html',
  styleUrls: ['./element-config-mcq-option-info.component.scss']
})
export class ElementConfigMcqOptionInfoComponent implements OnInit {

  @Input() mcqOption:IContentElementOption;
  @Input() voiceoverProp:string;
  @Input() showReplaceOptionType:boolean; 
  @Input() useDistractorAsResponseSlug:boolean;
  @Input() notesSlug: string;
  @Output() replaceOptionType = new EventEmitter();

  public content = new FormControl();

  constructor(private editingDisabled: EditingDisabledService, 
    public lang:LangService) { }

  ngOnInit() {
    this.voiceoverProp = this.voiceoverProp || DEFAULT_VOICEOVER_PROP;
    const formControls = [ {f: this.content, p:'info'}];
    
    bindFormControls(this.mcqOption, formControls)
    if (!this.mcqOption[this.voiceoverProp]){
      this.mcqOption[this.voiceoverProp] = {};
    }
  }

  getMcqChange(){
    return getVoiceChange(this.mcqOption, this.voiceoverProp);
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

  getNotesSlug() {
    return this.notesSlug || 'Distractor'; 
  }

  getNotesPlaceholderSlug() {
    return this.notesSlug || 'Distractor Info';
  }
}
