import { Injectable } from '@angular/core';

export const CLICK_TO_DRAG_DEFAULT = true;
@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {
  public clickToDragDrop:boolean = CLICK_TO_DRAG_DEFAULT;
  constructor() { }
}
