import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UrlLoaderService {

  private memo:Map<string,SafeResourceUrl> = new Map();

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  sanitize(url:string){
    if (!this.memo.has(url)){
      this.memo.set(url, this.sanitizer.bypassSecurityTrustResourceUrl(url));
    }
    return this.memo.get(url);
  }
  
}
