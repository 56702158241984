import { Component, OnInit, Input } from '@angular/core';
import {AssetLibraryService, AssetTypeFilter} from '../services/asset-library.service'
import { IContentElement,IQuestionConfig } from 'src/app/ui-testrunner/models';
import { EditingDisabledService } from '../editing-disabled.service';

@Component({
  selector: 'asset-library-link',
  templateUrl: './asset-library-link.component.html',
  styleUrls: ['./asset-library-link.component.scss']
})
export class AssetLibraryLinkComponent implements OnInit {

  @Input() element: IContentElement;
  @Input() itemElement: IQuestionConfig;
  @Input() disabled: boolean;

  constructor(
    private assetLibraryService: AssetLibraryService,
    private editingDisabled: EditingDisabledService
    ) { }

  ngOnInit(): void {
  }
    
  updateContentElement() {
    this.assetLibraryService.update(this.element, false);
  }
  
  createAssetForItem() {
    this.assetLibraryService.create(this.itemElement, false);
  }

  updateAsset() {
    if (this.itemElement) {
      const assetId = this.itemElement.assetId;
      if (assetId) {
        this.assetLibraryService.updateAsset(assetId);
      }
    }
  }
  
  importItem(currItem: IQuestionConfig) {
    this.assetLibraryService.importItem(currItem)
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

}
