

export enum ItemType {
    ITEM = 'item',
    SEQUENCE = 'sequence',
    PAGE = 'page',
    TEXT = 'text'
}

export interface IItemTypeDef {
    id: ItemType | string;
    caption: string;
    icon: string;
}


export const ItemTypeDefs = {
    'item': { id: ItemType.ITEM, caption: "Item", icon: "fa-file" },
    'sequence': { id: ItemType.SEQUENCE, caption: "Sequence", icon: "fa-folder"}
  };

export const itemTypes: IItemTypeDef[] = [
    ItemTypeDefs[ItemType.ITEM],
    ItemTypeDefs[ItemType.SEQUENCE]
];

export enum CustomButtonPos {
    AFTER_CONTENT = 'AFTER_CONTENT',
    BOT_RIGHT = 'BOT_RIGHT'
}

export const DEF_CUSTOM_BTN_BG_COLOR = '#d5e9f2'
export const DEF_CUSTOM_BTN_FG_COLOR = '#5d57b8'