import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { ScoringTypes, QuestionState, getElementWeight } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementGraphing, IEntryStateGraphing } from './model';



const SCORING_TYPE = ScoringTypes.MANUAL;

@Component({
  selector: 'element-render-graphing',
  templateUrl: './element-render-graphing.component.html',
  styleUrls: ['./element-render-graphing.component.scss']
})
export class ElementRenderGraphingComponent implements OnInit {

  @Input() questionState: QuestionState;
  @Input() element: IContentElementGraphing;
  @Input() isLocked: boolean;
  @Input() questionPubSub?: QuestionPubSub;

  public iframeUrl: SafeResourceUrl;
  private postMessageSubCancel: Function;
  private isInited = false;
  lastTrackedQuestionState;


  constructor(
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {
    this.postMessageSubCancel = renderer.listen('window', 'message', this.handleMessage.bind(this));
  }

  ngOnInit(): void {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://eassessment.vretta.com/subcomponents/shapespace/v.1.0.0/index.html');
    // this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:8080/index.html');
  }

  ngOnChanges() {
    if (this.lastTrackedQuestionState !== this.questionState) {
      this.lastTrackedQuestionState = this.questionState;
    }
  }

  ngOnDestroy() {
    this.postMessageSubCancel();
  }

  messageIntoLesson(type: string, payload: any) {
    let el = <HTMLIFrameElement>document.getElementById('graphing-iframe');
    if (!el) {
      console.warn('NO GRAPHING IFRAME FOUND');
      return;
    }
    console.log('sending post message:', {
      type,
      payload
    });
    el.contentWindow.postMessage(JSON.stringify({
      type,
      payload
    }), '*');
  }

  handleMessage(event: Event) {
    const message = event as MessageEvent;
    let data: {type: string, payload: any};
    if (!message.data || typeof message.data === 'object' ) {
      return;
    }
    try {
      data = JSON.parse(message.data);
    } catch (e) {
      console.error(e);
      return;
    }

    if (data.type === 'RESOURCE_LOAD_COMPLETE') {
      if (this.element.availableTools) {
        this.messageIntoLesson('TOOLBAR_SETUP', this.element.availableTools);
      }

      if (this.questionState && this.questionState[this.element.entryId]) {
        setTimeout(() => {
          this.messageIntoLesson('RESTORE_STATE', {
            component_type: '',
            node_id: '',
            state: this.questionState[this.element.entryId].state
          });
        }, 100);
      }
    } else if (data.type === 'STATE_UPDATE') {
      let entryState: IEntryStateGraphing = {
        type: 'graphing',
        isCorrect: null, // data.payload.validation
        isStarted: this.isInited,
        isFilled: this.isInited,
        isResponded: this.isInited,
        state: data.payload.state,
        score: 0,
        weight: getElementWeight(this.element),
        scoring_type: SCORING_TYPE,
      };
      if (this.questionState && this.isInited) {
        // console.log('writing graphing: ' , this.element.entryId, entryState)
        this.questionState[this.element.entryId] = entryState;
      }
      this.isInited = true;
    }
  }

}
