import { Injectable } from '@angular/core';
import DeepDiff from 'deep-diff';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AuthService } from '../api/auth.service';
import { IContentElementCustomInteraction } from '../ui-testrunner/element-render-custom-interaction/model';
import { defaultEditExcludeFields, ElementType, IContentElement } from '../ui-testrunner/models';
import { ElementTypeDefs } from '../ui-testrunner/models/ElementTypeDefs';
import { AuthScopeSetting, AuthScopeSettingsService } from './auth-scope-settings.service';
import { getElementChildren } from './item-set-editor/models';

export enum EditType {
  ADDED = "ADDED",
  DELETED = "DELETED",
  EDITED = "EDITED",
  NONE = "NONE",
}

interface ISuggestion { 
  id?: number,
  state: any,
  changes: any
}

interface IUserInfo {
  [key:number]: {
    id: number
    first_name: string,
    last_name: string
  }
}

export const ignoreFromDiff = (path, key) =>  key === 'diff' || key === 'scrambledOptions';
@Injectable({
  providedIn: 'root'
})
export class ItemComponentEditService {

  constructor(private auth: AuthService,
    private authScopeSettings: AuthScopeSettingsService) { }

  public update:Subject<boolean> = new Subject();

  // Subscriptions in item set editor to update diffs and the current question model
  public refreshDiffSub: Subject<any> = new Subject();
  public refreshAllChangesSub: Subject<any> = new Subject();
  public saveCurrQSub: Subject<any> = new Subject();
  public editRejected: Subject<any> = new Subject();

  public updateOriginalQuestion: Subject<any> = new Subject();
  public entryIdMappedToDiffs = new Map();
  public originalQuestionState;
  public entryIdToOrigElement = new Map();

  public questionIdToSug = new Map<number, {[key:string]: ISuggestion}>();
  suggestion: ISuggestion;
  userInfo: IUserInfo;
  suggestionStateCopy: any; //A copy of the suggestion state used to re-insert deleted elements for display without altering the actual suggestion state
  suggestionStateOnLoad: any;

  public diff = [];

  public selectedEntry: {
    id: number,
    border: string
  };
  
  reset() {
    this.selectedEntry = null;
  }

  getViewableDiffs(entryId: number) {
    const diffs = this.getDiffs(entryId);

    let el = this.getElementFromDiff(diffs[0]);
    if(!this.isViewableElement(el)) {
      return [];
    }

    const viewableDiffs = diffs.filter((d) => {
      return (d.kind !== "E") || (d.kind === "E" && this.isViewableField(d.path[d.path.length - 1], el));
    });
    return viewableDiffs; 
  } 

  getDiffs(entryId:number) {
    if (!this.entryIdMappedToDiffs.get(entryId)) {
      return [];
    }
    return this.entryIdMappedToDiffs.get(entryId);
  }

  mapElementToDiff(element, diff=undefined) {
    if (!element?.entryId) {
      return;
    }
    if (!this.entryIdMappedToDiffs.get(element.entryId)) {
      this.entryIdMappedToDiffs.set(element.entryId, [diff])
      return
    }

    const diffs = this.entryIdMappedToDiffs.get(element.entryId);
    
    _.remove(diffs, (d) => _.isEqual(d.path, diff.path))
    this.entryIdMappedToDiffs.get(element.entryId).push(diff);
  }

  updateOriginalQState(originalQs) {
    this.originalQuestionState = originalQs;

    this.entryIdToOrigElement.clear();

    const processChildren = (elements) => {
      for(const element of elements) {
        if(element.entryId) {
          this.entryIdToOrigElement.set(element.entryId, element);
        }
        const children = getElementChildren(element);
        processChildren(children);
      }
    }
    if(this.originalQuestionState?.content) processChildren(this.originalQuestionState?.content);
  }
  refreshDiffs(originalQs) {
    this.updateOriginalQState(originalQs);

    //Requires suggestedQuestionState to be updated first
    this.appendDateDiffs();

    const diff = this.diff;
    const diffArr = Array.prototype.concat.apply([], diff)
    this.clearElementToDiff();
    for (let d of diffArr) {
      this.mapElementToDiff(this.getElementFromDiff(d), d);
    }

    this.suggestionStateCopy = this.suggestion?.state ? this.customArrCloneDeep(this.suggestion.state) : undefined;

    this.updateEditedQuestionState();
  } 

  customArrCloneDeep(state) {
    //Clone so that each element is pointing to the original element, but each array is a different array.
    return _.cloneDeepWith(state, (val) => {
      if(val?.elementType) {
        return val;
      }
      return undefined;
    })
  }

  getElementFromDiff(d) {
    const elems = this.suggestion?.state;

    // Mapping based on added item or regularly edited item
    if (d.kind === "A") {
        if (d.item.kind === "N") {
            if(!elems) {
              return undefined;
            }
            let elem = _.get(elems, [...d.path, d.index]);
            if (elem && elem.elementType === undefined) {
                elem = elem.element;
            }
            return elem;
        } else if(d.item.kind === "D") {
            let elem = d.item.lhs;
            if (elem && elem.elementType === undefined) {
                elem = elem.element;
            } 
            return elem;
        }
    } else {
        if(!elems) {
          return undefined;
        }
        let elem;
        if (d.kind === "N") {
            elem = _.get(elems, d.path);

        } else {
            elem = _.get(elems, _.slice(d.path, 0, d.path.length - 1));
        }

        if (elem && elem.elementType === undefined) {
            elem = elem.element;
        }
        return elem;
    }
}

  clearElementToDiff() {
    this.entryIdMappedToDiffs.clear();
  }

  // Accepts all changes
  // Acccepts by calling the correct accept function for all diffs
  acceptAllChanges() {

    const entryIdDiffEntries = Array.from(this.entryIdMappedToDiffs.entries()).sort(
      (a,b) => {
        const diffsA = a[1];
        const diffsB = b[1];

        if(diffsA.length === 1 && diffsB.length === 1) {
          const diffA = diffsA[0];
          const diffB = diffsB[0];
          if(diffA.item?.kind === 'D' && diffB.item?.kind === 'D') {
            return diffB.index - diffA.index; //sort the deletion entries in reverse index order for deleting all.
          }
        }
        return 0;
      }
    )

    const entryIds = [...entryIdDiffEntries.map(([k,v]) => k)];

    //TODO: simplify it so it doesn't need to call accept diff for all changes
    //TODO: Add support for newly added items
    for (let entryId of entryIds) {
      const diffs = this.getViewableDiffs(entryId)

      // Accept each change and refresh the fields for each element
      for(const diff of diffs) {
        if (diff.kind === "E" || diff.kind === "N") {
          this.acceptDiff(entryId, diff.path[diff.path.length - 1], true);
        } else if (diff.kind === "A") {
          if(diff.item.kind == "N") {
            _.set(this.originalQuestionState, [...diff.path, diff.index], _.cloneDeep(diff.item.rhs));    
            this.entryIdMappedToDiffs.delete(entryId);
          } else if(diff.item.kind == "D") {
            const arr = _.get(this.originalQuestionState, diff.path);
            arr.splice(diff.index, 1);
            this.entryIdMappedToDiffs.delete(entryId);
          }
        }
      }
    }
    this.refreshDiffSub.next();
    this.refreshAllChangesSub.next();
    this.saveCurrQSub.next();
  }

  // accept the diff and send a subject a signal to update diffs
  acceptDiff(entryId: number, field, skipSave = false) {
    const elementDiffs = this.getDiffs(entryId);
    let diffAccepted = elementDiffs.filter((d) => {
      return field === d.path[d.path.length - 1];
    });

    //TODO: test if cloneDeep is needed here
    _.set(this.originalQuestionState, diffAccepted[0].path, _.cloneDeep(diffAccepted[0].rhs))

    if(!skipSave) {
      this.refreshDiffSub.next();
      this.saveCurrQSub.next();
    }
  }
  // reject the diff and send the object to a subscription in item set editor to inject back into the current question
  rejectDiff(element, field) {
    const elementDiffs = this.getDiffs(element.entryId);
    let diffRejected = []
    const fieldDiff = elementDiffs.filter((d) => {
       return field === d.path[d.path.length - 1];
    });

    this.editRejected.next(fieldDiff);
  }

  // accepts only text diffs
  acceptTextDiff(oldStr, newStr, path) {

    // Checking if the edit has overwritten text
    if (!oldStr) {

      // Checking to see if string is added or removed
      if (newStr.added) {

        // If a string is being added, manipulate caption to include the new string
        const oldVal = _.get(this.originalQuestionState, path);

        const preStr = oldVal.substring(0, newStr.index);
        const sufSter = oldVal.substring(newStr.index,  oldVal.length);
        const newVal = preStr + newStr.value + sufSter;
        
        _.set(this.originalQuestionState, path, newVal);

        // If a string is being removed, manipulate caption to not include the deleted string 
      } else if (newStr.removed) {

        const oldVal = _.get(this.originalQuestionState, path);

        const preStr = oldVal.substring(0, newStr.index);
        const sufSter = oldVal.substring(newStr.index + newStr.value.length, oldVal.length);
        const newVal = preStr + sufSter;
        
        _.set(this.originalQuestionState, path, newVal);

      } else {
      }
  
      // If text was overwritten and an edit was made
    } else {
      
      // Manipulate string and update the old caption value with the new value
      const oldVal = _.get(this.originalQuestionState, path);

      const preStr = oldVal.substring(0, oldStr.index);
      const sufSter = oldVal.substring(oldStr.index + oldStr.value.length, oldVal.length);
      const newVal = preStr + newStr.value + sufSter;
  
  
      _.set(this.originalQuestionState, path, newVal);
    }

    this.saveCurrQSub.next();
    this.refreshDiffSub.next();
  }

  // Send text diff to a subscription in item set editor, then it'll be reverted
  rejectTextDiff(oldStr, newStr, path) {
    this.editRejected.next([{ path: path, lhs: oldStr, rhs: newStr, kind: 'T'}, {}]);
  }

  // Accepts addition of new elements
  acceptNewEl(element) {

    const elementDiffs = this.getDiffs(element.entryId);
    const filtered = elementDiffs.filter((d) => { 
      return (d.kind === "A" && d.item.kind === "N") || d.kind === "N";
    });

    // Must be deep copied or changes might be referenced
    // Splices the saved question state to contain the new element
    if (filtered[0].item) {
      _.set(this.originalQuestionState, [...filtered[0].path, filtered[0].index], _.cloneDeep(filtered[0].item.rhs));
    } else {
      _.set(this.originalQuestionState, filtered[0].path, _.cloneDeep(filtered[0].rhs));
    }

    // Update diffs 
    this.refreshDiffSub.next();
  }

  // Reject the addition of new elements, by deleting the corresponding ones in current question
  rejectNewEl(element) {
    const elementDiffs = this.getDiffs(element.entryId);

    const filtered = elementDiffs.filter((d) => { 
      return d.kind === "A" && d.item.kind === "N";
    });
    this.editRejected.next(filtered);
  }

  // accepts deletion by deleting the appropriate element in the saved question
  acceptDeleteEl(element) {
    const elementDiffs = this.getDiffs(element.entryId);
    const filtered = elementDiffs.filter((d) => { 
      return d.kind === "A" && d.item.kind === "D";
    });

    const arr = _.get(this.originalQuestionState, filtered[0].path);
    arr.splice(filtered[0].index, 1);

    // Update diffs
    this.refreshDiffSub.next();
  }

  // rejects deletion by adding it back to the current question by sending it to a subscription in item set editor and changing current question
  rejectDeleteEl(element) {
    const elementDiffs = this.getDiffs(element.entryId);

    const filtered = elementDiffs.filter((d) => { 
      return d.kind === "A" && d.item.kind === "D";
    });

    this.editRejected.next(filtered);
  }

  processAddDelDiffs(suggestionState, arrPath: string[]) {
    const diffs = [];

    const suggestionElMap = {};
    const suggestionEntryIds = new Set<number>();
    const orderedSuggestionEntryIds = []; //ordered by found index
    
    let i = 0;
    for(const el of _.get(suggestionState, arrPath)) {
      let entryId = el.entryId;
      if(!entryId && el.element) {
        entryId = el.element.entryId
      }

      if(entryId) {
        suggestionElMap[entryId] = {index: i, element: el};
        suggestionEntryIds.add(entryId);
        orderedSuggestionEntryIds.push(entryId);
      }
      i++;
    }

    const originalElMap = {};
    const originalEntryIds = new Set<number>();
    const orderedOriginalEntryIds = [];

    i = 0;
    for(const el of _.get(this.originalQuestionState, arrPath)) {
      let entryId = el.entryId;
      if(!entryId && el.element) {
        entryId = el.element.entryId;
      }
      if(entryId) {
        originalElMap[entryId] = {index: i, element: el};
        originalEntryIds.add(entryId);
        orderedOriginalEntryIds.push(entryId)
      }
      i++;
    }
    
    for(const entryId of orderedSuggestionEntryIds.reverse()) {
      if(!originalEntryIds.has(entryId)) {
        const index = suggestionElMap[entryId].index;
        const value = suggestionElMap[entryId].element;
        //addition
        diffs.push({
          kind: 'A',
          path: arrPath,
          index,
          item: {
            kind: 'N',
            rhs: value
          }
        })
        //undo the addition so that the rest of the diff can run with this already being processed how we want.
        //undo ==> delete it
        const arr = _.get(suggestionState, arrPath);
        arr.splice(index, 1);
      }
    }

    //Need to undo the deletions after the additions and in order of index
    for(const entryId of orderedOriginalEntryIds) {
      if(!suggestionEntryIds.has(entryId)) {
        const index =  originalElMap[entryId].index;
        const value = originalElMap[entryId].element;
        //deletion
        diffs.push({
          kind: 'A',
          path: arrPath,
          index,
          item: {
            kind: 'D',
            lhs: value
          }
        })
        //undo the deletion, so that the rest of the diff can run with this already being processed how we want.
        //undo ==> add it back in
        const arr = _.get(suggestionState, arrPath)
        arr.splice(index, 0, _.cloneDeep(value));
      }
    }


    return diffs;
  }

  isPositiveInteger(str) {
    return /^\+?(0|[1-9]\d*)$/.test(str);
  }


  deepDiff(originalState, suggestionState) {
    //Make a copy so we can modify this copy without modifying the original
    //need to modify it to undo adds/deletes for more precise add / delete diff behaviour that is not covered by the DeepDiff library itself.
    const suggestionCopy  =  _.cloneDeep(suggestionState);
    suggestionCopy.langLink = null; //Don't include diffs from the other language

    const originalStateCopy = _.cloneDeep(originalState);
    originalStateCopy.langLink = null; //Don't include diffs from the other language

    let diffs = [];
    let originalDiff;
    //entryIds should not be modifiable -- if we see that, we need to replace it with some combination of adds / deletes
    //that DeepDiff was not capable of recognizing.
    while(true) {
      originalDiff =  DeepDiff(originalStateCopy, suggestionCopy, ignoreFromDiff) || [];
      //this relies on the DeepDiff algorithm being depth-first in its reporting of diffs
      let arrPath;
      for(const d of originalDiff) {
        if(d.kind === 'E' && d.path[d.path.length - 1] === "entryId") {

          arrPath = d.path;
          while(arrPath?.length && !this.isPositiveInteger(arrPath[arrPath.length - 1])) { //remove one item off the end until we reach an index in the path
            arrPath = arrPath.slice(0,arrPath.length-1);
          }

          arrPath = arrPath.slice(0, arrPath.length-1); //remove the index off the end
          break;
        }
      }
  
      if(arrPath?.length) {
        const addDelDiffs = this.processAddDelDiffs(suggestionCopy, arrPath);
        if(!addDelDiffs?.length) {
          //prevent infinite looping, no progress was made.
          break;
        }
        diffs = diffs.concat(addDelDiffs); //modifies the suggestionCopy

      } else {
        break; //No entryId modifications recorded - the reported diffs should be accurate.
      }
    }

    diffs = diffs.concat(originalDiff); //Add the diffs reported by DeepDiff to the ones reported by us.
    return diffs;
  }


  // Retain old diffs that were made before, and add on the timestamp and name of the author
  appendDateDiffs() {

    if(!this.suggestion) {
      this.diff = [];
      return; 
    }

    const diffs = this.deepDiff(this.originalQuestionState, this.suggestion.state);

    const newDiffs = diffs.map((d) => {
      const element = this.getElementFromDiff(d);
      const entryId = element?.entryId;
      let author;
      let suggDate;
      if(entryId) {
        let change = this.suggestion.changes[entryId];
        if(d.kind === 'E') {
          const propName = d.path[d.path.length-1];
          if( change && change[propName]) {
            change = change[propName];
          }
        }

        if(change) {
          const userInfo = this.userInfo[change.uid];
          if(userInfo) {
            author = this.auth.renderName(userInfo.first_name, userInfo.last_name);
            suggDate = moment(change.changed_on).format('h:mma MMM Do YYYY');
          }
        }
      }
      return { ...d, author, dateEdited: suggDate };
    });

    this.diff = newDiffs;
  }

  // Checks for deleted items and inject fake items to help with version control
  updateEditedQuestionState() {

    // Checking for deleted items
    const deletedItems = this.diff.filter((diff) => {
        return (diff.kind === "A" && diff.item.kind === "D");
    }).sort( (a,b) => a.index - b.index); 
    //sort them by index so they are inserted back into their original spots correctly.


    // Inject if there are deletedItems
    if (deletedItems.length !== 0) {

        deletedItems.forEach(item => {
            const arr = _.get(this.suggestionStateCopy, item.path);

            // If it already exists dont add it on to the displayed edits
            if (arr[item.index] !== item.item.lhs) {
                arr.splice(item.index, 0, item.item.lhs);
            }
        });
    }

  }

  hasSuggestions() {
    return !!this.suggestion;
  }

  isViewableElement(element:IContentElement) {
    return element && element.elementType !== ElementType.TEXT_LINK;
  }

  genExcludedFields(element:IContentElement) {
    return this.genExcludedFieldsHelper(element, element.elementType, 0, [])
  }

  genExcludedFieldsHelper(element:IContentElement, type, depth: number, acc: any[]) {
    const editingInfo = ElementTypeDefs[type?.toUpperCase()]?.editingInfo; 

    let fields;
    if(!editingInfo) {
      fields = [];
    } else {
      fields = editingInfo.editExcludeFields || []
    }
    
    if(element.elementType === ElementType.CUSTOM_INTERACTION) {
      fields = fields[(<IContentElementCustomInteraction>element).interactionType] || [];
    }

    if(editingInfo?.superType) {
      acc = this.genExcludedFieldsHelper(element, editingInfo.superType, depth + 1, acc);
    } 
    
    acc = acc.concat(fields);
    if(depth === 0) { //prevent infinite recursion for options
      //IContentElementMcqOption and other "Option" types are set to elementType = another type, which sometimes contains combinations of fields from both the elementType's interface it represents and the "Option" type itself
      //Take this into account by using a separate "optionType" for the specific element type for that option.
      const optionEditingInfo = ElementTypeDefs[(<any>element).optionType?.toUpperCase()]?.editingInfo;
      let optionFields;
      
      if(!optionEditingInfo) {
        optionFields = [];
      } else {
        optionFields = optionEditingInfo.editExcludeFields || [];
      }
      
      acc = acc.concat(optionFields);

      if(optionEditingInfo?.superType) {
        acc = this.genExcludedFieldsHelper(element, optionEditingInfo.superType, depth + 1, acc);
      }
    }
    return acc;
  }

  genTextDiffFieldsHelper(element:IContentElement, type, depth: number, acc: any[]) {
    let editingInfo = ElementTypeDefs[type?.toUpperCase()]?.editingInfo; 

    let fields;

    if(depth === 0 && (<any>element).optionType) { //prevent infinite recursion for options
      //IContentElementMcqOption and other "Option" types are set to elementType = another type, which sometimes contains combinations of fields from both the elementType's interface it represents and the "Option" type itself
      //Take this into account by using a separate "optionType" for the specific option element type.
      //properties from the option override the usual element's properties
      
      editingInfo = ElementTypeDefs[(<any>element).optionType?.toUpperCase()]?.editingInfo;
    }

    if(!editingInfo) {
      fields = [];
    } else {
      fields = editingInfo.editTextFields || []
    }
    
    if(element.elementType === ElementType.CUSTOM_INTERACTION) {
      fields = fields[(<IContentElementCustomInteraction>element).interactionType] || [];
    }

    if(editingInfo?.superType) {
      acc = this.genTextDiffFieldsHelper(element, editingInfo.superType, depth + 1, acc);
    } 
    

    acc = acc.concat(fields);

    return acc;
  }

  genTextDiffFields(element) {
    return this.genTextDiffFieldsHelper(element, element.elementType, 0, []);
  }

  isViewableField(field, element:IContentElement) {
    let excludedFields = this.genExcludedFields(element);
    
    excludedFields = excludedFields.concat(defaultEditExcludeFields);
    
    return !excludedFields.includes(field);
  }

  isTextDiffProp(prop: string, element: IContentElement) {
    const diffFields = this.genTextDiffFields(element);
    let res = diffFields.includes(prop) && (typeof element[prop] === 'string');
    if(this.usingEditingMode()) {
      const originalEl = this.entryIdToOrigElement.get(element.entryId); 
      return res && originalEl && originalEl[prop];
    }
    return res;
  }

  usingEditingMode() {
    return this.authScopeSettings.getSetting(AuthScopeSetting.USE_EDITING_MODE);
  }
}
