<div *ngIf="testDesign">
    <test-runner
        [currentTestDesign]="testDesign"
        [questionSrcDb]="questionSrcDb"
        [questionStates]="questionStates"
        testLang="en"
        [testSessionId]="sessionId"
        [sectionIndexInit]="0"
        [questionIndexInit]="0"
        [isHelpEnabled]="false"
        [isText2SpeechEnabled]="false"
        [autoScrollOnSelect]="true"
        [saveQuestion]="saveQuestionResponse"
        [submitTest]="submitTest"
        [isTimeEnabled]="false"
        [instit_group_id]="instit_group_id"
    ></test-runner>
</div>