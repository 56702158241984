import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../api/auth.service';

@Component({
  selector: 'ens-test-runner',
  templateUrl: './ens-test-runner.component.html',
  styleUrls: ['./ens-test-runner.component.scss']
})
export class EnsTestRunnerComponent implements OnInit {

  constructor(
    
  ) { }

  @Input() questionStates:any;
  @Input() testDesign :any;
  @Input() questionSrcDb :any;
  @Input() saveQuestionResponse:any;
  @Input() submitTest :any;
  @Output() testRunnerComplete = new EventEmitter();

  instit_group_id = 1;
  sessionId = 1;

  ngOnInit(): void {
    // return;
    
  }



}
