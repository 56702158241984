import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from "@angular/router";
import {LangService} from "./lang.service";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DataGuardService implements CanDeactivate<any> {
    private active: boolean = false;
    private forceSave = new Subject()
    constructor(public lang: LangService) {
        //console.log('constructing DataGuardService');
    }
    triggerForceSave(){
        this.forceSave.next(true);
    }
    forceSaveSub(){
        return this.forceSave;
    }
    canDeactivate(
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): boolean {
        return this.active ? confirm(this.lang.tra('leave_page_unsaved_changes')) : true;
    }
    isActive() {
        //console.log(`retrieving guard: ${this.active}`);
        return this.active;
    }
    activate() {
        //console.log('activating guard');
        this.active = true 
    }
    deactivate(){
        //console.log('deactivating guard');
        this.active = false 
    }
}
