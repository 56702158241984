import { Component, OnInit, Input } from '@angular/core';
import { IHotspotConfig, QuestionState } from '../models';
import { Subject } from 'rxjs';
import * as PIXI from "pixi.js-legacy";
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementMcq } from '../element-render-mcq/model';



@Component({
  selector: 'element-render-hotspot',
  templateUrl: './element-render-hotspot.component.html',
  styleUrls: ['./element-render-hotspot.component.scss']
})
export class ElementRenderHotspotComponent implements OnInit {

  @Input() element:IHotspotConfig;
  @Input() parentElement:IContentElementMcq;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor() { }

  ngOnInit() {
  }

}
