import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../api/auth.service';

@Component({
  selector: 'ens-landing',
  templateUrl: './ens-landing.component.html',
  styleUrls: ['./ens-landing.component.scss']
})
export class EnsLandingComponent implements OnInit {

  @Output() startTest = new EventEmitter();

  constructor(
    private auth:AuthService,
  ) { }

  ngOnInit(): void {


  }

  

}
