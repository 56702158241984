import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bc-tabs',
  templateUrl: './bc-tabs.component.html',
  styleUrls: ['./bc-tabs.component.scss']
})
export class BcTabsComponent implements OnInit {

  @Input() tabs: string[] = [];

  @Output() select = new EventEmitter();

  selectedTab: string;

  constructor() { }

  ngOnInit(): void {
    if (this.tabs.length > 0) {
      this.selectedTab = this.tabs[0];
    }
  }

  isTabSelected(tab: string): boolean {
    return this.selectedTab === tab;
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
    this.select.emit(this.selectedTab);
  }

}
