import { Injectable } from '@angular/core';
import { type } from 'os';
import { LangService } from './lang.service';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { WhitelabelService } from '../domain/whitelabel.service';
import { BehaviorSubject } from 'rxjs';

interface ISoftlockProfileActionPopup {
  popupText: string
}

export enum EPopupBtnStackStyle {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

interface ISoftlockProfileWarningPopup extends ISoftlockProfileActionPopup {
  options: {
    // display and hide buttons 
  };
  config: {
    btnStackStyle: EPopupBtnStackStyle;
    btnGroupStyle?: any,
    pauseBtnStyle?: any,
    dismissBtnStyle?: any
    cancleBtnStyle?: any
  };
  pauseBtnText?: string; // default Pause Student
  dismissBtnText?: string // default Dimiss Notification (red exclamation)
  cancleBtnText?: string // 'Dismiss Popup'
}

interface ISoftlockProfilePausePopup extends ISoftlockProfileActionPopup {
  options: {
    // display and hide buttons 
    isShowingRemoveSoftlockBtn: boolean
  };
  config: {
    btnStackStyle: EPopupBtnStackStyle;
    btnGroupStyle?: any,
    unpauseBtnStyle?: any
    removeSoftlockForStudentBtnStyle?: any
    cancelBtnStyle?: any
  };
  unpauseBtnText?: string; // Unpause Student
  removeSoftlockForStudentBtnText?: string; // Remove Softlock
  cancleBtnText?: string // 'Dismiss Popup'
}

export enum ESoftlockProfileTypes {
  MANUAL = 'manual', // students are manually paused by educator
  AUTO = 'auto'  // students are auto paused
}

interface IPauseNotificationStudentPopup {
  showPauseNotification: boolean;
  isKeepShowingPauseNotification: boolean;
  pauseNotificationText: string
}

interface ISoftlockProfile {
  type: ESoftlockProfileTypes,
  showNumStudentPauseNotif?: boolean
  isShowingWarningBtn: boolean // this is when type is manual and educator needs to click on red exclamation mark to pause / ignore student
  isShowingPauseBtn: boolean // mostly true
  pauseNotificationToStudent: IPauseNotificationStudentPopup // show pause notification
  isKeepShowingRetryBtn: boolean;  // when students are paused, Keep showing retry button
  warningPopup: ISoftlockProfileWarningPopup; // text, buttons text
  pausePopup: ISoftlockProfilePausePopup;  // text, buttons text
}

export enum ESoftlockProfile {
  SAMPLE = 'sample-softlockProfile',
  OSSLT = 'osslt-softlockProfile',
  G9 = 'g9-softlockProfile',
  NB = 'nb-softlockProfile',
  MB = 'mb-softlockProfile',
  AB = 'ab-softlockProfile'
}

@Injectable({
  providedIn: 'root'
})
export class SoftlockProfileService {

  private softlockProfileId: number;
  private selectedSoftlockProfileSlug: ESoftlockProfile;
  private softlockProfile: ISoftlockProfile;
  public softlockProfileSub: BehaviorSubject<boolean> = new BehaviorSubject(false)
  private softlockProfilesCache: { [slug: string]: ISoftlockProfile } = {}

  constructor(
    private lang: LangService,
    private auth: AuthService,
    private routes: RoutesService,
    private whitelabel: WhitelabelService
  ) {
    // set when fetching the assessment configs
    this.auth.user().subscribe(u => {
      if(u) this.setSoftlockProfile(ESoftlockProfile.SAMPLE)
    })
  }

  getSoftlockProfile(): ISoftlockProfile {
    return this.softlockProfile
  }

  getSoftlockProfileChanges(): BehaviorSubject<boolean> {
    return this.softlockProfileSub;
  }

  async setSoftlockProfile(slug: ESoftlockProfile, pullFromS3: boolean = false) {
    // console.log('softlock profile change request', slug)
    if (this.selectedSoftlockProfileSlug === slug) {
      return;
    }

    const setProfile = (profile, isCached?: boolean) => {
      this.softlockProfileId = profile.id
      this.softlockProfile = JSON.parse(profile.config);
      this.selectedSoftlockProfileSlug = slug;
      if (!isCached) this.softlockProfilesCache[slug] = profile
      this.softlockProfileSub.next(true);
      console.log('softlock profile changed', slug)
      return Promise.resolve(true)
    }

    const cachedProfile = this.softlockProfilesCache[slug]
    if (cachedProfile) return setProfile(cachedProfile);

    const query: any = { slug, pullFromS3: pullFromS3 ? 1 : 0 };

    return this.auth.apiGet(this.routes.ASSESSMENT_SOFTLOCK_PROFILES, 1, { query }).then(res => {
      if (!res) return Promise.resolve(false)
      return setProfile(res)
    })
      .catch((e) => {        
        console.warn('set Softlock failed', e)
        return Promise.reject({msg: 'set Softlock failed', e})
      })
  }

  getSoftlockProfileType(): ESoftlockProfileTypes {
    return this.softlockProfile?.type;
  }

  showPauseNotificationToStudent = () => {
    return this.softlockProfile?.pauseNotificationToStudent?.showPauseNotification
  }

  keepShowingPauseNotificationToStudent = () => {
    const pauseNotificationPopup = this.softlockProfile?.pauseNotificationToStudent
    return this.showPauseNotificationToStudent() && pauseNotificationPopup.isKeepShowingPauseNotification
  }

  showWarningPopup = () => this.softlockProfile?.isShowingWarningBtn;

  showUnpausePopup = () => this.softlockProfile?.isShowingPauseBtn;

  getSoftlockPausePopupText() {
    return this.softlockProfile?.pausePopup?.popupText;
  }

  getSoftlockWarningPopupText() {
    return this.softlockProfile?.warningPopup?.popupText;
  }

  isVerticallyStackedPausePopupBtns() {
    return this.softlockProfile?.pausePopup?.config?.btnStackStyle === EPopupBtnStackStyle.VERTICAL;
  }

  isHorizontallyStackedPausePopupBtns() {
    return this.softlockProfile.pausePopup?.config?.btnStackStyle === EPopupBtnStackStyle.HORIZONTAL;
  }

  isShowingRemoveSoftlockBtnInPausePopup() {
    return this.softlockProfile?.pausePopup?.options?.isShowingRemoveSoftlockBtn;
  }

  getWarningPauseTestBtnText = () => {
    const text = this.softlockProfile?.warningPopup?.pauseBtnText;
    return text ? text : 'btn_pause'
  }

  getWarningPauseTestDismissBtnText = () => {
    const text = this.softlockProfile?.warningPopup?.dismissBtnText;
    return text ? text : 'dismiss_btn'
  }

  getUnpauseTestBtnText = () => {
    const text = this.softlockProfile?.pausePopup?.unpauseBtnText;
    return text ? text : 'btn_unpause_ta'
  }

  getUnpauseTestRemoveSoftlockBtnText = () => {
    const text = this.softlockProfile?.pausePopup?.removeSoftlockForStudentBtnText;
    return text ? text : 'btn_disable_soft_lock'
  }

  getUnpauseTestCancelBtnText = () => {
    const text = this.softlockProfile?.pausePopup?.cancleBtnText;
    return text ? text : 'btn_close_stu_warn_invig'
  }

  getPausePopupConfig = () => {
    return this.softlockProfile?.pausePopup?.config || {};
  }

  getPausePopupBtnGroupStyle() {
    return this.softlockProfile?.pausePopup?.config?.btnGroupStyle || {};
  }

  getPausePopupUnpauseBtnStyle() {
    return this.softlockProfile?.pausePopup?.config?.unpauseBtnStyle || {};
  }

  showNumStudentPauseNotif() {
    return this.softlockProfile?.showNumStudentPauseNotif
  }

}
