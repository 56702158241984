import { IPanelLink } from "./type";
import { BC_GRAD_MINISTRY_ADMIN_VIEWS } from '../../../ui-ministryadmin/view-bcgrad-ministryadmin-dashboard/data/views'

const items: IPanelLink[] = BC_GRAD_MINISTRY_ADMIN_VIEWS.map(view => {
  return {
    caption: view.caption,
    routerLink: `/en/ministry-admin/bc-grad/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled
  }
})

export default items