import { IContentElementDndDraggable } from "../element-render-dnd/model";
import { IContentElement, IEntryStateScored, IScoredResponse } from "../models";

export interface IContentElementInsertion extends IContentElement, IScoredResponse {
    textBlocks: IContentElementDndDraggable[];
    draggables: IContentElementDndDraggable[];
    draggableCounter: number;
    textBlocksCounter: number;
    isDragBetweenWords: boolean;
    isShowInstructions?: boolean;
    isTargetsAbove?: boolean;
    isRepeatableOptions?: boolean;
    blankTargetSize?: boolean;
    isDragContainerFixedWidth?: boolean;
    widthOfDraggables?: number;
    targetGroupLh?: number;
  }

  export interface IEntryStateInsertion extends IEntryStateScored {
    draggables: any[];
    targets: any[];
  }

  export const insertionEditInfo = {
    editExcludeFields: ['textBlocks', 'draggables']
  }
