<div *ngFor="let contentElement of element.content">
    <element-render 
        [contentElement]="contentElement" 
        [questionState]="questionState"
        [isLocked]="isLocked"
        [contentElement]="contentElement"
        [questionPubSub]="questionPubSub"
        [isShowSolution]="isShowSolution"
    ></element-render>
</div> 
