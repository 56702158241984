<mat-accordion>
  <mat-expansion-panel [ngStyle]="style || {}">
    <mat-expansion-panel-header>
      <mat-panel-title>
          <div  style="font-weight: bold;">{{title}}</div>
          <div style="margin-left: 0.5em;">({{subTitle}})</div>
      </mat-panel-title>
      <mat-panel-description>
        {{description}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ng-container *ngFor="let c of content">
        <ng-container [ngSwitch]="c.type">
          <div *ngSwitchCase="'JsonView'">
            <label>{{c.label}} </label>
            <div style="margin-left: 0.5em;">
              <ngx-json-viewer [json]="c.data" [expanded]="false" ></ngx-json-viewer>
            </div>
          </div>
          <div *ngSwitchCase="'TextArea'">
            <div class="flex-gap">
              <span class="text-area-middle">
                <label>{{c.label}} </label>
                <textarea style="margin-left:0.5em" [(ngModel)]="c.data"></textarea>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
      
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>