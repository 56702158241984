import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { Dictionary } from './controllers/dictionary';
import { DictionaryEN } from './controllers/dictionary-en';
import { DictionaryFR } from './controllers/dictionary-fr';
import { ConjugationData, ConjugationEntry, ConjugationForm, ConjugationFormData, ConjugationView, WordView } from './model';
import { IMenuTabConfig } from 'src/app/ui-partial/menu-bar/menu-bar.component';
import { TwiddleState } from 'src/app/ui-partial/twiddle/twiddle.component';


export interface SelectedWord {
  word: string,
  json: any,
  isActive: boolean,
  conjugation_json?: ConjugationEntry
}

@Component({
  selector: 'widget-dictionary',
  templateUrl: './widget-dictionary.component.html',
  styleUrls: ['./widget-dictionary.component.scss']
})
export class WidgetDictionaryComponent implements OnInit, OnDestroy {
  
  @Input() zoom:number;
  @Input() isZoomed:boolean;
  @Input() isShowSynonyms_FR?: boolean;
  @Input() isShowConjugation_FR?: boolean = true;
  @ViewChild('resultBox') resultBox: ElementRef<HTMLDivElement>;

  wordViews: IMenuTabConfig<WordView>[];
  conjugationForms = [ConjugationForm.INDICATIF, ConjugationForm.SUBJONCTIF, ConjugationForm.CONDITIONNEL, ConjugationForm.IMPÉRATIF, ConjugationForm.INFINITIF, ConjugationForm.PARTICIPE];
  conjugationFormsFilter = [ConjugationForm.ALL, ConjugationForm.INDICATIF, ConjugationForm.SUBJONCTIF, ConjugationForm.CONDITIONNEL, ConjugationForm.IMPÉRATIF, ConjugationForm.INFINITIF, ConjugationForm.PARTICIPE];

  conjugationTenseOrder: string[] = ['present', 'imparfait', 'passe_simple', 'futur', 'passe_compose', 'plus_que_parfait', 'passe_anterieur', 'futur_anterieur', 'passe', 'infinitif_present', 'infinitif_passe', 'participe_present', 'participe_passe_compose']

  currentConjFormFilter = ConjugationForm.ALL;
  conjugationViews = [ConjugationView.ACTIVE, ConjugationView.PASSIVE]
  twiddleMainImage = new TwiddleState(false);
  conjugationFormToggleStatus = new Map();

  constructor(
    public lang: LangService,
    private auth: AuthService,
    private route: RoutesService,
  ) { }

  input;
  suggestions: [];
  selectedWord: SelectedWord;
  dictionaryManager: DictionaryEN | DictionaryFR;
  currentWordDefinition;
  activeWordView: WordView;
  conjugationSectionTitles: Map<string, string>;
  activeConjugationView: ConjugationView;

  ngOnInit() {
    this.selectedWord = {
      word: '',
      json: undefined,
      isActive: false
    }

    this.wordViews = [
      {id: WordView.DEFINITION, caption: this.lang.tra('btn_dictionary')},
    ]

    if (this.isShowConjugation_FR){
      this.wordViews.push({id: WordView.CONJUGATION, caption: 'Conjugaison'},)
    }

    this.activeWordView = WordView.DEFINITION;
    this.activeConjugationView = ConjugationView.ACTIVE;

    this.conjugationSectionTitles = new Map<string, string>([
      ['present', 'Présent'],
      ['imparfait', 'Imparfait'],
      ['passe_simple', 'Passé simple'],
      ['futur', 'Futur'],
      ['passe_compose', 'Passé composé'],
      ['plus_que_parfait', 'Plus-que-parfait'],
      ['passe_anterieur', 'Passé antérieur'],
      ['futur_anterieur', 'Futur antérieur'],
      ['passe', 'Passé'],
      ['infinitif_present', 'Présent'],
      ['infinitif_passe', 'Passé'],
      ['participe_present', 'Présent'],
      ['participe_passe_compose', 'Passé composé'],
    ]);


    // switch(this.lang.getCurrentLanguage()){
    //   case 'en':
    //     this.dictionaryManager = new DictionaryEN(this.lang, this.route, this.auth);
    //     return;
    //   case 'fr':
    //     this.dictionaryManager = new DictionaryFR(this.lang, this.route, this.auth);
    //     return;
    //   default:
    //     this.dictionaryManager = new DictionaryEN(this.lang, this.route, this.auth);
    // } 

    // The English dictionary is currently only contracted for ABED use.
    this.dictionaryManager = new DictionaryFR(this.lang, this.route, this.auth);
  }

  ngAfterViewInit(){}
  ngOnDestroy() {}

  isLoadingSuggestions: boolean = false;
  showSearchInput: boolean = false;
  currentUserInput: string = '';
  onUserInput(e: KeyboardEvent){

    let userData = (<HTMLInputElement>e.target).value; //user enetered data
    this.currentUserInput = userData;

    if (e.key && e.key == 'Enter'){
      this.getSuggestions(userData)
    }

    if(!userData || userData == ''){
      this.showSearchInput = false;
    }
  }

  getSuggestions(userInput?: string){

    const searchWord = userInput ? userInput : this.currentUserInput;
    this.isLoadingSuggestions = true;
    this.showSearchInput = true;

    this.dictionaryManager.getSuggestions(searchWord).then((res => {
      this.isLoadingSuggestions = false;
      this.suggestions = res.map(suggestion => suggestion.word);
      const searchWordCandidates = res.filter(suggestion => suggestion.is_searched).map(suggestion => suggestion.word); // not ideal
      const actualSearchWord = searchWordCandidates[0] || searchWord;
      const exactWordMatch = this.suggestions.filter(suggestion => suggestion === actualSearchWord)[0];
      if (exactWordMatch){
        this.selectWord(exactWordMatch);
        return;
      }
      
    })).catch((error)=>{
      this.isLoadingSuggestions = false;
      console.error('Error retrieving data');
    })
  }

  userValue
  showSuggestions(list){
      let listData;
      if(!list.length){
          this.userValue = this.input.value;
          listData = '<li>'+ this.userValue +'</li>';
      }else{
          listData = list.join('');
      }
      this.resultBox.nativeElement.innerHTML = listData;
  }

  selectWord(suggestion: string){
    this.dictionaryManager.getWordData(suggestion).then(res => {
      try {
        const processedJSON = this.dictionaryManager.preprocessResponseRawJSON(res[0].raw_json);
        const wordDictData_json = JSON.parse(processedJSON)
        this.dictionaryManager.selectedWord = {
          word: suggestion,
          json: wordDictData_json,
          isActive: true,
          conjugation_json: res[0].conjugation_raw_json ? JSON.parse(res[0].conjugation_raw_json) : undefined
        }
        this.showSearchInput = false;
        this.currentWordDefinition = this.dictionaryManager.getCurrentWordDefinition();
        this.conjugationForms.forEach((value, key)=> {
          const twiddle = new TwiddleState(true);
          this.conjugationFormToggleStatus.set(value, twiddle);
          
        });

      } catch (error) {
        console.log(error)
      }
    })
  }


  // French conjugation related functions, it's only here for the sake of time, 
  // should be put into the French dictionary class.
  selectWordView(view: WordView) {
    this.activeWordView = view;
  }
  
  isViewingDefinition(){
    return this.activeWordView === WordView.DEFINITION;
  }

  isViewingConjugation(){
    return this.activeWordView === WordView.CONJUGATION;
  }

  isActiveId(id:string){ return this.activeWordView === id; }

  setConjugationView(view: ConjugationView){
    this.activeConjugationView = view;
  }

  isConjugationViewActive(view: ConjugationView){
    return this.activeConjugationView === view;
  }

  currentSelectedWordHasConjugation(){
    const conjugationData = this.dictionaryManager.selectedWord.conjugation_json;

    return conjugationData;
  }

  currentSelectedWordHasActiceConjugation(){
    return this.dictionaryManager.selectedWord.conjugation_json.active;
  }

  currentSelectedWordHasPassiveConjugation(){
    return this.dictionaryManager.selectedWord.conjugation_json.passive;
  }

  isConjugationViewAvailable(view: ConjugationView){
    switch (view) {
      case ConjugationView.ACTIVE:
        return this.currentSelectedWordHasActiceConjugation();
      case ConjugationView.PASSIVE:
        return this.currentSelectedWordHasPassiveConjugation();
      default:
        return false;
    }
  }

  getConjugationDescription(){
    const supErRegex = /&SUPer;/g;
    let returnString = this.dictionaryManager.selectedWord.conjugation_json.description.replace(supErRegex, '<sup>er</sup>');

    const supERegex = /&SUPe;/g;
    returnString = returnString.replace(supERegex, '<sup>e</sup>');

    return returnString;
  }

  getConjugationForms(){
    switch (this.currentConjFormFilter){
      case ConjugationForm.ALL:
        return this.conjugationForms;
      default:
        return this.conjugationForms.filter(form => form === this.currentConjFormFilter);
    }
  }

  getFormData(formName: ConjugationForm, conjData?: ConjugationData): any{
    if (!conjData){
      switch (this.activeConjugationView){
        case ConjugationView.ACTIVE:
          conjData = this.dictionaryManager.selectedWord.conjugation_json.active;
          break;
        case ConjugationView.PASSIVE:
          conjData = this.dictionaryManager.selectedWord.conjugation_json.passive;
          break;
      }
    }
      
    switch (formName){
      case ConjugationForm.INDICATIF:
        return this.sortTense(conjData.indicatif);
      case ConjugationForm.SUBJONCTIF:
        return this.sortTense(conjData.subjonctif);
      case ConjugationForm.CONDITIONNEL:
        return this.sortTense(conjData.conditionnel);
      case ConjugationForm.IMPÉRATIF:
        return this.sortTense(conjData.imperatif);
      case ConjugationForm.INFINITIF:
        return this.sortTense(conjData.infinitif);
      case ConjugationForm.PARTICIPE:
        return this.sortTense(conjData.participe);
    }
  }

  sortTense(rawTense: ConjugationFormData){

    let sorted: Map<string, string[]> = new Map();

    this.conjugationTenseOrder.forEach(tenseTitle => {
      if (rawTense[tenseTitle]){
        sorted.set(tenseTitle, rawTense[tenseTitle])
      }
    })
    return sorted;
  }

  getFormSectionDisplayTitle(title){
    return this.conjugationSectionTitles.get(title);
  }

  getSectionEntryData(prefix: string, content: string){
    if (prefix == "j'")
      return prefix + content;
    
    else if (prefix === "-")
      return content;

    return prefix + ' ' + content;
  }
}
