import { Component, Input, OnInit } from '@angular/core';
import { DisplayMode, IContentElementAudio, ToolTipPositions } from 'src/app/ui-testrunner/element-render-audio/model';
// import vd from 'vidar'
import { AuthService } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { LangService } from "../../core/lang.service";
import { EditingDisabledService } from '../editing-disabled.service';
@Component({
  selector: 'element-config-audio',
  templateUrl: './element-config-audio.component.html',
  styleUrls: ['./element-config-audio.component.scss']
})
export class ElementConfigAudioComponent implements OnInit {

  @Input() element:IContentElementAudio;
  
  displayModes = [
    {id: DisplayMode.NORMAL, caption: "Normal"},
    {id: DisplayMode.CUSTOM, caption: "Custom"}
  ]

  constructor(private editingDisabled: EditingDisabledService,
              private auth: AuthService,
              private routes: RoutesService,
              private lang: LangService) { }

  ToolTipPositions = ToolTipPositions;
  transcriptUrl:string;

  ngOnInit(): void {
    if (this.element.assetId) {
      this.auth.apiGet(this.routes.TEST_AUTH_ASSET, this.element.assetId).then((res)=>{
        if (!res) {
          return
        }
        if (this.lang.c()=='en' && res["transcript_en"]) {
          this.transcriptUrl = res["transcript_en"]
        } else {
          this.transcriptUrl = res["transcript_fr"]
        }
      })
    } else {
      this.transcriptUrl = this.element.transcriptUrl
    }
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

  newAudioEndPub(){
    if (!this.element.onAudioEnd){
      this.element.onAudioEnd = []
    }
    this.element.onAudioEnd.push({type:'TOGGLE_ON', data:null})
  }

  clearUrl(){
    if (confirm('Are you sure you want to clear this upload?')){
      this.element.url = null;
      this.element.assetId = null;
      this.element.transcriptUrl = null;
    }
  }
}
