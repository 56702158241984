import { IContentElement, IEntryState } from "../models";

export interface IContentElementGraphics extends IContentElement {
    url?: string;
}

export interface IEntryStateGraphics extends IEntryState {
    state: any;
}

export const graphicsEditInfo = {
    editExcludeFields: []
}