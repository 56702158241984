import { Injectable } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
  })
export class ImageLoadService {
    private imageStatus: Map<SafeResourceUrl, boolean>

    constructor(){
        this.imageStatus = new Map()
    }

    setImageStatus(imageUrl: SafeResourceUrl, isLoaded:boolean){
        this.imageStatus.set(imageUrl, isLoaded)
    }

    getImageStatus(imageUrl){
        return this.imageStatus[imageUrl]
    }

    getImageStatusMap(){
        return this.imageStatus
    }

    removeImage(imageUrl){
        this.imageStatus.delete(imageUrl)
    }

    allImageLoaded(){
        // console.log('checking')
        if (!this.imageStatus)
        return true
        for (let [url, loaded] of this.imageStatus) {
            if (!loaded){
                return false
            }
        }
        return true
    }

}