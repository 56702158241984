<fieldset [disabled]="isReadOnly()">
    <div>
        <tra slug="auth_entry_id"></tra> <input [(ngModel)]="element.entryId">
    </div>
    <div>
        
        <label >   
            <tra slug="auth_auto_play"></tra>  <input type="checkbox" [(ngModel)]="element.isAutoPlay"> 
        </label>
        <label >   
            <tra slug="auth_delay"></tra>  <num-scrub [el]="element" prop="autoPlayDelay" [step]="1" [max]="120"></num-scrub>
        </label>
    </div>
    <div>
        <label >   
            <tra slug="auth_hide"></tra>  <input type="checkbox" [(ngModel)]="element.isHidden"> 
        </label>
    </div>
    <div>
        <label >   
            <tra slug="auth_tooltip"></tra>  <input type="checkbox" [(ngModel)]="element.isPermanentTooltip"> 
        </label>
        <select [(ngModel)]="element.toolTipPosition">
            <option [value]="ToolTipPositions.TOP"><tra slug="auth_top"></tra></option>
            <option [value]="ToolTipPositions.BOTTOM"><tra slug="auth_bottom"></tra></option>
            <option [value]="ToolTipPositions.RIGHT"><tra slug="auth_right"></tra></option>
            <option [value]="ToolTipPositions.LEFT"><tra slug="auth_left"></tra></option>
        </select>
    </div>
    <div >
        <label >   
            <tra slug="auth_limit_plays"></tra>  <input type="checkbox" [(ngModel)]="element.isLimitAudioPlays"> 
        </label>
        <div  *ngIf="element.isLimitAudioPlays">
            <label>   
                <tra slug="auth_number_of_plays"></tra>  <num-scrub [el]="element" prop="numAudioPlays" [step]="1" [max]="10"></num-scrub>
            </label>
            <label>   
                <tra slug="auth_label_x"></tra>  <num-scrub [el]="element" prop="labelX" [min]="-10" [max]="10"></num-scrub>
            </label>
            <label>
              <tra slug="auth_label_y"></tra>  <num-scrub [el]="element" prop="labelY" [min]="-10" [max]="10"></num-scrub>
            </label>
        </div>
    </div>
    <div >
        <label >   
            <tra slug="auth_button_scale"></tra>  <num-scrub [el]="element" prop="buttonScale"  [max]="6"></num-scrub>
        </label>
    </div>
    <div style="margin-top:1em;">
        <capture-image [element]="element" fileType="audio" [isNoScale]="true" [displayImageControls]="false"></capture-image>
        <asset-library-link [element]="element"></asset-library-link>
        <button (click)="clearUrl()">Clear</button>
    </div>
    
    <div style="margin-top:2em">
        <tra slug="auth_on_complete"></tra>
        <table>
          <tr>
            <td><tra slug="auth_entry_id_cap"></tra></td>
            <td><tra slug="auth_signal_type"></tra></td>
            <td><tra slug="auth_data"></tra></td>
          </tr>
          <tr *ngFor="let pub of element.onAudioEnd">
            <td><input type="number" [(ngModel)]="pub.entryId"></td>
            <td><input type="text" [(ngModel)]="pub.type"></td>
          </tr>
        </table>
        <button (click)="newAudioEndPub()"><tra slug="auth_new_row"></tra></button>
      </div>
<!--
      <tra slug="auth_display_mode"></tra>
      <div class="control is-expanded">
        <div [class.is-disabled]="isReadOnly()" class="select is-fullwidth">
          <select [(ngModel)]="element.displayMode">
            <option *ngFor="let mode of displayModes; let index = index" [value]="mode.id">
              {{mode.caption}}
            </option>
          </select>
        </div>
      </div>-->
</fieldset>
<div style="display: flex; flex-direction: row;">
    <strong style="margin-right:0.5em;"><tra slug="auth_transcript"></tra> </strong>
    <capture-image 
            [element]="element" 
            uploadCaption="Upload .TXT file" 
            fileType="txt" 
            [urlStr]="transcriptUrl ? transcriptUrl : ''" 
            urlProp="transcriptUrl" 
            [isCondensed]="true" 
            [isNoScale]="true" 
            [displayImageControls]="false">
    </capture-image>
</div>