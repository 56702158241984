import { IIdentifiedEntry } from "./types";

export interface IMappedList<T extends IIdentifiedEntry> {
    map: {[key:number]:T}
    list: T[]
}
export const renderMappedValue = (ml:IMappedList<IIdentifiedEntry>, key:number) => {
    const entry = ml.map[key];
    if (entry){
        return entry.label.trim();
    }
}
export const initMappedList = <T extends IIdentifiedEntry>(list:T[]):IMappedList<T> => {
    const mappedList:IMappedList<T> = {
        map: {},
        list,
    }
    list.forEach(entry => {
        mappedList.map[entry.id]  = entry;
    })
    return mappedList;
}

export const filterArr = <T>(arr:T[], check:(entry:T)=>boolean):T[] => [].concat(arr).filter(check);
export const isInArr = <T>(arr:T[], entry:T) => (arr.indexOf(entry) !== -1);
export const pickFromArr = <T>(arr:T[], check: (entry:T)=>boolean) => filterArr(arr, check)[0];