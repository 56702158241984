<div class="virtual-tools-container">
    <div class="virtual-tools-container-child">
        <div #pixiDiv></div>
        <div class="virtual-tools-toolbar ">
            <div *ngFor="let tool of toolbarTools" 
                class="img-container"
                [class.is-selected]="tool.isSelected()"
                [class.is-disabled]="tool.isDisabled()"
            >
                <img [src]="tool.iconUrl" (click)="tool.toggle()" [ngStyle]="disableHC()"/>
                <div class="virtual-tools-extended-toolbar" *ngIf="!isObjectEmpty(extendedTools) && extendedTools.parent == tool.id && extendedTools.tools.length>=1">
                    <div *ngFor="let extTool of extendedTools.tools"
                        (click)="extTool.action()"
                        class="img-container"
                    >
                        <img *ngIf="!extTool.isColorPick" 
                            [src]="extTool.iconUrl"
                            [ngStyle]="disableHC()"
                        />
                        <div 
                            style="position: relative;" 
                            *ngIf="extTool.isColorPick"
                            [class.is-selected]="showColorPalette"
                        >
                            <img
                                [src]="'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/color-palette-3/1685478658551/color-palette-3.png'"
                                style="width: 1.4em; height: auto;"
                                [ngStyle]="disableHC()"
                                (click)="toggleColorPalette()"
                            />
                        </div>
                    </div>

                    <div class="dropdown" *ngIf="showColorPalette">
                        <div *ngFor="let color of extendedTools.colors" 
                            class="color-square" 
                            [style.backgroundColor]="color"
                            (click)="extendedTools.colorHandler(color)"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>