import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LangService } from '../../core/lang.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SidepanelService } from '../../core/sidepanel.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AuthService, IUserInfo, F_ERR_MSG__INVALID_LOGIN  } from '../../api/auth.service';
import { LoginValidationService } from '../../core/login-validation.service';
import { WhitelabelService } from '../../domain/whitelabel.service'
import { FormFail } from '../../core/login-ctrl';


interface ILoginFormErrors {
  isBlank?:boolean,
  isInvalid?: boolean,
}

@Component({
  selector: 'multi-factor-authentication',
  templateUrl: './multi-factor-authentication.component.html',
  styleUrls: ['./multi-factor-authentication.component.scss']
})
export class MultiFactorAuthenticationComponent implements OnInit 
{
  
  constructor(
    public loginGuard: LoginGuardService,
    private breadcrumbsService: BreadcrumbsService,
    private router:Router,
    private auth: AuthService,
    private whiteLabel: WhitelabelService,
    public lang:LangService,
    private sidePanel: SidepanelService,
    private loginValidatorService: LoginValidationService
  ) 
  {
    //this.loginValidatorService.formGroup = this.formGroup;
  }

  public formGroup = new FormGroup({
    totpToken: new FormControl(),
  });
  public isLoginAttemptedEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public isFormValidated:boolean;
  public isFormSent:boolean;
  public isFormFailed:boolean;
  public formFailReason:FormFail;
  private isLoginAttempted:boolean;
  public FormFail = FormFail;
  public isLoaded:boolean;
  private userSubTotp:Subscription;
  public breadcrumb = [];
  public authenticateText = this.lang.tra("btn_authenticate");
  private credentials: Array<string> = [];
  public emailAddress: string;
  loginErrorTroubleshootMsg: any;
  public failureRedirectUrl: string = "/" + this.lang.c() + "/login-educator";
  private isTotpLoginSuccess: boolean = false;
  private isLoginFormActivated: boolean;

  ngOnInit(): void 
  {
    this.credentials = this.auth.getCredentials();
    this.emailAddress = this.credentials[0];
    this.sidePanel.deactivate();
    this.breadcrumb = [
      this.breadcrumbsService._CURRENT( this.lang.tra('lbl_login'), `/${this.lang.c()}/login-router-st`),
      this.breadcrumbsService._CURRENT( this.lang.tra(this.getAdminLoginSlug()), this.router.url),
    ];
    this.initRouteViewForTotp();

  }
  ngOnDestroy() 
  {
    if (this.userSubTotp)
    {
      this.userSubTotp.unsubscribe();
    }
  }

  getAdminLoginSlug = () => this.whiteLabel.getSiteText( 'login_admins', 'lbl_administrators');

  initRouteViewForTotp(){
    this.isLoaded = true;
    this.userSubTotp = this.auth.user().subscribe(info => {
      if (info && this.isLoginAttempted){
        //console.log(info);
        if (!this.isLoginFormActivated)
        {
          this.loginGuard.gotoUserDashboard(info);
        }
      }
    });
  }

  validateForm()
  {
     this.isFormValidated = false;
     let hasAnyErrors = false;
     const totpToken = <FormControl>this.formGroup.controls.totpToken;
     let totpErrors:ILoginFormErrors = {}

     //if username and password are not set for any reason when the user tries to submit their TOTP token
     if (!this.credentials[0] || !this.credentials[1])
     {
       hasAnyErrors = true;
       this.isFormFailed = true;
     }

     if (!totpToken.value)
     {
       //console.log("User has input no TOTP token.");
       totpErrors.isBlank = true;
       hasAnyErrors = true;
     }
     else
     {
       const str = <string>totpToken.value;
       const totpTokenRequiredLength: number = 6;
       const isTooShortOrLong = !(str.length == totpTokenRequiredLength);

       if (isTooShortOrLong)
       {
         //console.log("User's TOTP token is not " + totpTokenRequiredLength + " digits long.")''
         totpErrors.isInvalid = true;
         hasAnyErrors = true;
       }
     }
     
     // totpToken.setErrors(totpErrors);
     this.formGroup.markAsUntouched();
     if (hasAnyErrors)
     {
       this._onApiError()
       return; 
     }

     this.formGroup.disable();
     this.isFormValidated = true; 
  }
  
  clearForm()
  {
    this.formGroup.controls.totpToken.reset();
  }

  public onApiError(e: {message: string})
  {
    this.formGroup.enable();
    this.isFormValidated = false;
    this.isFormFailed = true;

    //console.log(e.message);
    if (e.message == 'NOT_VERIFIED')
    {
      this.formFailReason = (FormFail.NOT_VERIFIED);
    }
    else if (e.message == 'PSW_RESET_REQ')
    {
      this.formFailReason = (FormFail.PSW_RESET_REQ);
    }
    else if (e.message == F_ERR_MSG__INVALID_LOGIN)
    {
      this.formFailReason = (FormFail.NOT_FOUND);
    }
    else
    {
      this.formFailReason = (FormFail.UNKNOWN);
    }

    // easier troubleshooting
    try 
    {
      this.loginErrorTroubleshootMsg = JSON.stringify(e, null, '  ');
    }
    catch(e)
    {
      this.loginErrorTroubleshootMsg = e.code + " " + e.message;
    }
     //console.log('formFailReason', this.formFailReason)
  }

  private _onApiError() 
  {
    this.formGroup.enable();
    this.isFormValidated = false;
  }

  public async attemptLogin(): Promise<void>
  {
    this.isFormFailed = false;
    this.validateForm();

    if (this.isFormValidated)
    {
      this.isLoginFormActivated = this.loginGuard.getIsLoginFormActivated();
      this.isFormSent=true;
      const totpToken = this.formGroup.controls.totpToken.value;
      this.isLoginAttempted = true;
      this.isLoginAttemptedEvent.next(true);
      await this.auth
        .login(this.credentials[0], this.credentials[1], totpToken) 
        .then(success => 
          {
            if (!success)
            {
              this._onApiError();
              this.clearForm();
            }

            else
            {
              this.isTotpLoginSuccess = true;
            }
          })
        .catch(error => 
          {
            this.onApiError(error);
            this.formGroup.controls.totpToken.reset();
            this.auth.apiCreate("public/log", 
            {
              slug: "FAILED_LOGIN",
              data: 
              {
                email: this.credentials[0],
                errorMessage: error.message
              }
            })
          })
    }

    //Ensure all credentials stored in memory are immediately destroyed and set to null values.
    //This happens regardless of whether the authentication request is a failure or succes.
    //This also happens regardless of how a user got to this route.
    if (this.isFormFailed || this.isTotpLoginSuccess)
    {
      // console.log("Creds cleared")
      this.auth.setCredentials([]);
      this.credentials = [];
      this.emailAddress = "";
    }

    if (!this.isFormValidated && this.isFormFailed)
    {
      this.auth.totpAuthenticationFailed = true;

      if (!this.loginGuard.getIsLoginFormActivated())
      {
        this.router.navigateByUrl(this.failureRedirectUrl);
      }

      else
      {
        this.loginGuard.setisTOTPLogin(false);
        this.auth.credentialsSub.next(false); 
      }
    }
  }
}