import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { ALL_DISTRICT, BcAccountsService, District, DistrictCompletionReport, DistrictDetail, Filter, FilterCondition, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcReportsService } from 'src/app/bc-reports/bc-reports.service';
import { CompletionReportRow, SchoolCompletionReportRow } from 'src/app/bc-reports/types';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { MyBoardService } from 'src/app/ui-dist-admin/my-board.service';
import { EFSAReportDataMode, FSA_REPORT_DATA_MODES, IFSAReportDataMode } from 'src/app/ui-ministryadmin/ma-progress-reports/types';

interface IColumnHeading {
  heading: string;
  sortBy: string;
  sortDisabled?: boolean;
  filterDisabled?: boolean;
}
const headingToSortBy: IColumnHeading[] = [
  { heading: 'sa_aa_school', sortBy: 'school_name' },
  { heading: 'sa_sr_school_code', sortBy: 'school_foreign_id' },
  { heading: 'sa_sr_school_type', sortBy: 'school_type' },
  { heading: 'sa_sr_grades', sortBy: 'grade', sortDisabled: true, filterDisabled: true },
  { heading: 'sa_sr_enrollment', sortBy: 'enrollment', sortDisabled: true, filterDisabled: true },
]
interface IComponentColumnHeading {
  heading: string;
  subheading: string;
  sortBy?: string;
  filterBy?: string;
  formControl: FormControl,
  sortDisabled?: boolean,
  filterDisabled?: boolean,
}
interface IGradeOption {
  grade: string;
  caption: string;
}
const GRADE_OPTIONS: IGradeOption[] = [
  { grade: '4', caption: 'sa_sr_grade4' },
  { grade: '7', caption: 'sa_sr_grade7' },
  { grade: '', caption: 'sa_sr_grade4n7' },
]
enum EComponentFilter {
  ALL,
  NOT_STARTED,
  ONGOING,
  SUBMITTED
}
interface IComponentFilterOption {
  id: EComponentFilter;
  caption: string;
  filters: Partial<Filter>[]
}
const COMPONENT_FILTER_OPTIONS: IComponentFilterOption[] = [
  { id: EComponentFilter.ALL, caption: 'All', filters: [] },
  {
    id: EComponentFilter.NOT_STARTED, caption: 'Not Started', filters: [{
      condition: FilterCondition.MATCH,
      value: 0,
    }]
  },
  {
    id: EComponentFilter.ONGOING, caption: 'Ongoing', filters: [{
      condition: FilterCondition.DONT_MATCH,
      value: 0,
    }, {
      condition: FilterCondition.DONT_MATCH_FIELD,
      value: 'total',
    }]
  },
  {
    id: EComponentFilter.SUBMITTED, caption: 'Submitted', filters: [{
      condition: FilterCondition.MATCH_FIELD,
      value: 'total',
    }]
  }
];

@Component({
  selector: 'school-completion-report',
  templateUrl: './school-completion-report.component.html',
  styleUrls: ['./school-completion-report.component.scss']
})
export class SchoolCompletionReportComponent implements OnInit, OnChanges {

  @Input() testWindow: TestWindow;
  @Input() accountType: AccountType;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;

  selectAll: boolean;
  districts: District[];
  selectedDistrict: District;
  gradeOptions: IGradeOption[] = GRADE_OPTIONS;
  selectedGradeOption: IGradeOption;
  reportTable: SchoolCompletionReportRow[];
  districtOverview: CompletionReportRow[];
  districtCompletion: DistrictCompletionReport | null;
  pagination: Pagination;
  visibleFilters: Set<string>;
  filters: Map<string, Filter | Filter[]>;
  filterThrottle: NodeJS.Timeout | null;
  dataModes: IFSAReportDataMode[] = FSA_REPORT_DATA_MODES;
  selectedDataMode: IFSAReportDataMode;
  componentFilterOptions: IComponentFilterOption[] = COMPONENT_FILTER_OPTIONS;
  componentFilterOptionsMap: Map<EComponentFilter, IComponentFilterOption> = new Map();
  componentsHeadingToSortBy: IComponentColumnHeading[];
  numeracyFilterFC = new FormControl();
  literacyFilterFC = new FormControl();
  scoreEntryFilterFC = new FormControl();

  constructor(
    private bcAccounts: BcAccountsService,
    private lang: LangService,
    private bcReports: BcReportsService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {
    this.selectAll = false;
    this.districts = [ALL_DISTRICT];
    this.selectedDistrict = ALL_DISTRICT;
    this.pagination = bcAccounts.getInitialPagination();
    this.visibleFilters = new Set();
    this.filters = new Map();
    this.reportTable = [];
    this.districtCompletion = null;
    this.selectedDataMode = FSA_REPORT_DATA_MODES.filter(mode => mode.id === EFSAReportDataMode.ADMIN_SESSION)[0];
    this.filterThrottle = null;
    this.componentFilterOptions.forEach(option => this.componentFilterOptionsMap.set(option.id, option));
  }

  ngOnInit(): void {
    this.componentsHeadingToSortBy = [
      { heading: this.lang.tra('sa_sr_numeracy_lvl2'), subheading: this.lang.tra('sa_sr_numeracy_lvl2_sub'), sortBy: 'sort submitted1', filterBy: 'submitted1', formControl: this.numeracyFilterFC, sortDisabled: true, filterDisabled: true },
      { heading: this.lang.tra('sa_sr_literacy_lvl2'), subheading: this.lang.tra('sa_sr_literacy_lvl2_sub'), sortBy: 'sort submitted2', filterBy: 'submitted2', formControl: this.literacyFilterFC, sortDisabled: true, filterDisabled: true },
      { heading: this.lang.tra('sa_cr_literacy_lvl2'), subheading: this.lang.tra('sa_cr_literacy_lvl2_sub'), sortBy: 'sort submitted3', filterBy: 'submitted3', formControl: this.scoreEntryFilterFC, sortDisabled: true, filterDisabled: true },
      { heading: this.lang.tra('sa_cr_numeracy_lvl2'), subheading: this.lang.tra('sa_cr_numeracy_lvl2_sub'), sortBy: 'sort submitted4', filterBy: 'submitted4', formControl: this.scoreEntryFilterFC, sortDisabled: true, filterDisabled: true },
    ]
    // initialize component filters
    this.numeracyFilterFC.setValue(EComponentFilter.ALL)
    this.literacyFilterFC.setValue(EComponentFilter.ALL)
    this.scoreEntryFilterFC.setValue(EComponentFilter.ALL)

    const params = this.route.snapshot.queryParams;
    const districtId = params.district;

    const postAction = () => {
      this.selectedGradeOption = GRADE_OPTIONS.filter(option => option.grade === '')[0]
      const withoutAPICall = true;
      this.updateGradeFilter(withoutAPICall);
      this.applyFiltersFromURL();
      this.setFilterURLParams();
    }

    postAction();
    // this.bcAccounts.findDistricts().then(districts => {

    //   this.districts = districts;
    //   this.districts.shift();
    //   if (this.isDistrictAdmin()) {
    //     this.myBoard.sub().subscribe(groupList => {
    //       if (!groupList || groupList.length < 1) return;

    //       const dist_group_id = groupList[0].group_id;
    //       this.selectedDistrict = this.districts.find(d => d.groupId === dist_group_id);
    //       postAction();
    //     })
    //   } else if (this.isSchoolAdmin()) {
    //     postAction();
    //   }
    //   else {
    //     this.selectedDistrict = districtId ? this.districts.filter(d => d.groupId === +districtId)[0] : this.districts[1];
    //     postAction();
    //   }

    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.schoolDetail || changes.testWindow || changes.districtDetail) {
      this.updateTable();
    }
  }

  async updateTable() {
    if (!this.selectedGradeOption || !this.districtDetail || !this.testWindow) return;

    this.pagination.isLoading = true;

    const grades: (4 | 7)[] = [];
    switch (this.selectedGradeOption.grade) {
      case '4':
        grades.push(4);
        break;
      case '7':
        grades.push(7);
        break;
      default:
        grades.push(4);
        grades.push(7);
    }

    const { schools, district } = await this.bcReports.getSchoolCompletionReport({
      district_group_id: this.districtDetail.groupId,
      test_window_id: this.testWindow.id,
      school_group_id: this.schoolDetail ? this.schoolDetail.groupId : undefined,
      grades: grades,
    }, this.pagination);
    this.reportTable = schools.data;
    this.pagination.count = schools.count;

    this.districtOverview = [
      district['4'], district['7']
    ];

    this.markInfoRows(this.reportTable);

    this.pagination.isLoading = false;

    // const promises = [];
    // if (this.pagination.count === undefined) {
    //   promises.push(this.bcAccounts.countSchoolCompletionReport(
    //     this.pagination,
    //     this.selectedDistrict.groupId === -1 ? undefined : this.selectedDistrict.groupId,
    //   ));
    // }
    // promises.push(this.bcAccounts.getSchoolCompletionReport(
    //   this.pagination,
    //   this.selectedDistrict.groupId === -1 ? undefined : this.selectedDistrict.groupId,
    // ));
    // if (updateDistrictTable) {
    //   promises.push(this.bcAccounts.getDistrictCompletionReport(this.selectedDistrict.groupId === -1 ? undefined : this.selectedDistrict.groupId));
    // }
    // Promise.all(promises).then(values => {
    //   if (this.pagination.count === undefined) {
    //     this.pagination.count = values[0];
    //     this.reportTable = values[1];
    //     if (updateDistrictTable) {
    //       this.districtCompletion = values[2][0];
    //     }
    //   } else {
    //     this.reportTable = values[0];
    //     if (updateDistrictTable) {
    //       this.districtCompletion = values[1][0];
    //     }
    //   }
    //   this.markInfoRows(this.reportTable);
    //   this.pagination.isLoading = false;
    // });
  }

  private markInfoRows(data: SchoolCompletionReportRow[]) {
    let saved = null;
    let odd = false;
    data.forEach(entry => {
      const current = entry.school_group_id;
      if (current !== saved) {
        entry._isInfo = true;
        saved = current;
        odd = !odd;
      }
      entry._isOdd = odd;
    })
  }

  export() {
    this.bcReports.exportSchoolCompletionReport({
      district_group_id: this.selectedDistrict.groupId,
      district_name: this.selectedDistrict.name,
      test_window_id: this.testWindow.id,
    })
  }

  onSelectedDistrictChange(e) {
    this.pagination = this.bcAccounts.getInitialPagination();
    this.pagination.filters = this.makeFilterArray();
    this.setFilterURLParams();
    this.updateTable();
  }

  onSelectedGradeChange(e) {
    this.pagination = this.bcAccounts.getInitialPagination();
    this.updateGradeFilter();
  }

  private updateGradeFilter(passive = false): void {
    this.doUpdateFilter('grade', this.selectedGradeOption.grade, passive);
  }

  makeFilterArray(): Filter[] {
    const arr = [];
    this.filters.forEach((value) => {
      if (Array.isArray(value)) {
        arr.push(...value);
      } else {
        arr.push(value)
      }
    })
    return arr;
  }

  getDisplayDistrict(district: District): string {
    return this.bcAccounts.getDistrictDisplay(district);
  }

  changeOrderBy(by: string) {
    if (this.pagination.orderBy === by) {
      this.pagination.orderDirection = this.pagination.orderDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.pagination.orderBy = by;
      this.pagination.orderDirection = 'asc';
    }
    this.pagination.skip = 0;
    this.setFilterURLParams();
    this.updateTable();
  }

  isSortedBy(by: string, direction: 'asc' | 'desc'): boolean {
    return this.pagination.orderBy === by && this.pagination.orderDirection === direction;
  }

  getHeadingToSortBy(): IColumnHeading[] {
    return headingToSortBy;
  }

  getComponentsHeadingToSortBy(): IComponentColumnHeading[] {
    return this.componentsHeadingToSortBy;
  }

  toggleShowFilter(by: string) {
    if (this.visibleFilters.has(by)) {
      this.visibleFilters.delete(by);
    } else {
      this.visibleFilters.add(by);
    }
  }

  isFilterVisible(by: string): boolean {
    return this.visibleFilters.has(by);
  }

  private doUpdateFilter(field: string, value: string, passive = false): void {
    if (value === '') {
      this.filters.delete(field);
    } else {
      switch (field) {
        case 'schoolType':
        case 'schoolName':
        case 'schoolId':
        case 'grade':
          this.filters.set(field, {
            field: field,
            condition: FilterCondition.LIKE,
            value
          });
          break;
        case 'total':
          this.filters.set(field, {
            field,
            condition: FilterCondition.MATCH,
            value
          });
          break;
      }
    }
    this.pagination.filters = this.makeFilterArray();
    this.pagination.count = undefined;
    this.pagination.skip = 0;
    if (this.filterThrottle !== null) {
      clearTimeout(this.filterThrottle);
    }
    this.filterThrottle = setTimeout(() => {
      if (!passive) {
        this.setFilterURLParams();
        this.updateTable();
      }
      this.filterThrottle = null;
    }, 500);
  }

  updateFilter(event, by: string) {
    this.doUpdateFilter(by, event.target.value)
  }

  updateComponentFilter(e, by: string) {
    let filters: Partial<Filter>[];
    switch (+e.target.value) {
      case EComponentFilter.ALL:
        this.filters.delete(by);
        break;
      case EComponentFilter.NOT_STARTED:
        filters = this.componentFilterOptionsMap.get(EComponentFilter.NOT_STARTED).filters;
        filters = filters.map(filter => { return { field: by, ...filter } });
        this.filters.set(by, filters as Filter[]);
        break;
      case EComponentFilter.ONGOING:
        filters = this.componentFilterOptionsMap.get(EComponentFilter.ONGOING).filters;
        filters = filters.map(filter => { return { field: by, ...filter } });
        this.filters.set(by, filters as Filter[]);
        break;
      case EComponentFilter.SUBMITTED:
        filters = this.componentFilterOptionsMap.get(EComponentFilter.SUBMITTED).filters;
        filters = filters.map(filter => { return { field: by, ...filter } });
        this.filters.set(by, filters as Filter[]);
        break;
    }
    this.pagination.filters = this.makeFilterArray();
    this.pagination.count = undefined;
    this.pagination.skip = 0;
    this.setFilterURLParams();
    this.updateTable();
  }

  getCompletionStatus(row: SchoolCompletionReportRow, col: number): string {
    const submitted: number = row[`submitted${col}`];
    if (submitted === undefined || submitted === 0) return 'Not Started (0%)';
    return `${submitted}/${row.enrollment} (${Math.floor(submitted / row.enrollment * 100)}%)`;
  }

  getBackRoute() {
    if (this.isSchoolAdmin()) {
      return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/bc-fsa/session_reports`;
    } else if (this.isDistrictAdmin()) {
      return `/${this.lang.c()}/${AccountType.DIST_ADMIN}/bc-fsa/session_reports`;
    } else {
      return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}/bc-fsa/session_reports`;
    }
  }

  formatSchoolCode(code: number): string {
    return this.bcAccounts.formatSchoolCode(code);
  }

  getDistrictCompletion(col: number): string {
    if (this.districtCompletion === null) return '';
    const submitted: number = this.districtCompletion[`submitted${col}`];
    if (submitted === undefined || submitted === 0 || this.districtCompletion.total === 0) return 'Not Started (0%)';
    return `${submitted}/${this.districtCompletion.total} (${Math.floor(submitted / this.districtCompletion.total * 100)}%)`;
  }

  private setFilterURLParams(): void {
    let queryParams: any = {};
    if (this.selectedDistrict.groupId !== -1) {
      queryParams.district = this.selectedDistrict.groupId;
    }
    let index = 0;
    const filters = [...this.filters.values()];
    const populateQueryParams = (filter: Filter) => {
      queryParams[`field${index}`] = filter.field;
      queryParams[`condition${index}`] = filter.condition;
      queryParams[`value${index}`] = filter.value;
      ++index;
    }
    filters.forEach((filter: Filter | Filter[]) => {
      if (Array.isArray(filter)) {
        filter.forEach(subFilter => {
          populateQueryParams(subFilter);
        })
      } else {
        populateQueryParams(filter);
      }
    })
    this.router.navigate([], {
      queryParams,
      relativeTo: this.route,
      skipLocationChange: true
    });
    setTimeout(() => {
      history.replaceState(null, null, `#${this.router.routerState.snapshot.url}`);
    }, 0)
  }

  private applyFiltersFromURL(): void {
    const params = this.route.snapshot.queryParams;
    const getNumOfFilters = (): number => {
      const keys: string[] = Object.keys(params)
      let index = 0;
      if (!!keys.length) {
        while (keys.indexOf(`field${index}`) !== -1) {
          ++index;
        }
      }
      return index;
    }
    const numOfFilters: number = getNumOfFilters();

    // Need to support multiple filters with the same field name (Component filters)
    const filterMap: Map<string, Filter[]> = new Map();
    for (let i = 0; i < numOfFilters; i++) {
      const field = params[`field${i}`];
      const filter: Filter = {
        field,
        condition: params[`condition${i}`],
        value: params[`value${i}`]
      }
      const filters = filterMap.get(field);
      if (filters) {
        filters.push(filter)
      } else {
        filterMap.set(field, [filter])
      }
    }

    filterMap.forEach((filters: Filter[], field: string) => {
      this.filters.set(field, filters);
      if (!headingToSortBy.filter(h => h.sortBy === field && h.filterDisabled).length) {
        this.visibleFilters.add(field);
      }
    })

    // pre-set Grade dropdown if in URL
    const gradeFilterArr = filterMap.get('grade');
    if (gradeFilterArr && gradeFilterArr.length) {
      const gradeFilter = gradeFilterArr[0];
      this.selectedGradeOption = this.gradeOptions.filter(option => option.grade === gradeFilter.value)[0]
    }

    // pre-set Component filters if in URL
    this.componentsHeadingToSortBy.forEach(f => {
      const value = this.getComponentFilterValue(f.filterBy)
      f.formControl.setValue(value);
    })

    this.pagination.filters = this.makeFilterArray();
    this.pagination.count = undefined;
  }

  getFilterValue(field: string): string {
    const filter = this.filters.get(field) as Filter;
    let value = '';
    if (filter) {
      if (Array.isArray(filter)) {
        value = filter[0].value;
      } else {
        value = String(filter.value);
      }
    }
    return value;
  }

  getComponentFilterValue(field: string): EComponentFilter {
    const filters = this.filters.get(field) as Filter[];
    if (filters) {
      if (filters.length === 2) {
        return EComponentFilter.ONGOING
      } else if (filters.filter(i => i.condition === FilterCondition.MATCH).length) {
        return EComponentFilter.NOT_STARTED
      } else if (filters.filter(i => i.condition === FilterCondition.MATCH_FIELD).length) {
        return EComponentFilter.SUBMITTED
      }
    }
    return EComponentFilter.ALL
  }

  getComponentFilterFormControl(field: string): FormControl {
    return this.componentsHeadingToSortBy.filter((h: IComponentColumnHeading) => h.filterBy === field)[0].formControl
  }

  public districtDisplayFn = (district: District) => {
    let display = '';
    if (district) {
      display = this.getDisplayDistrict(district)
    }
    return display;
  }

  public getFilterDistrictsFn() {
    const self = this;
    return (value: string | District): District[] => {
      let filtered: District[] = [];
      if (self.districts) {
        let filterValue: string;
        if ((value as District).name) {
          filterValue = (value as District).name.toLowerCase()
        } else {
          filterValue = (value as string).toLowerCase();
        }
        filtered = self.districts.filter(district => district.name.toLowerCase().includes(filterValue) || self.formatDistrict(district.foreignId).includes(filterValue));
      }
      return filtered;
    }
  }

  formatDistrict(district: number): string {
    return this.bcAccounts.formatDistrictCode(district);
  }

  public districtSelected = (event) => {
    const district: District = event.option.value;
    this.selectedDistrict = district;
    this.onSelectedDistrictChange(event);
  }

  getDivisionPercentageDisplay(numerator: number, denominator: number): string {
    if (!denominator || denominator === 0) return '0';
    const quotient = numerator * 100 / denominator;
    return quotient.toFixed(2);
  }

  isSchoolAdmin(): boolean {
    return this.auth.isSchoolAdmin(this.accountType);
  }

  isDistrictAdmin(): boolean {
    return this.auth.isDistrictAdmin(this.accountType);
  }

}
