import { Component, OnInit, Input } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';

@Component({
  selector: 'student-accommodations',
  templateUrl: './student-accommodations.component.html',
  styleUrls: ['./student-accommodations.component.scss']
})
export class StudentAccommodationsComponent implements OnInit {

  // @Input() studentAccount;
  @Input() accommodationList: any[];
  @Input() isDisabled: boolean = false;
  
  
  // accommodationList = [];
  is_loading: boolean = false;

  constructor(
    public lang: LangService,
    private whitelabel: WhitelabelService,
    private auth: AuthService,
    private routes: RoutesService,
  ) { }

  ngOnInit() {
    // this.loadAccommodations();
  }
  
  isNBED = () => {
    return this.whitelabel.isNBED();
  }
}
