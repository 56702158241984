import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface ISelectBlockContent {
  id: string, // used by the `select` output event
  caption?: string,
  imageUrl?: string,
  body?: string,
  data?: any
}

export interface ISelectBlockContentPagelet extends ISelectBlockContent {
  data: {
    targetPagelet: string
  }
}


@Component({
  selector: 'body-block-arr-select',
  templateUrl: './body-block-arr-select.component.html',
  styleUrls: ['./body-block-arr-select.component.scss']
})
export class BodyBlockArrSelectComponent implements OnInit {

  @Input() arr:ISelectBlockContent[];
  @Input() isLargeImage:boolean;
  @Input() isHorizontal:boolean;
  @Input() formValue:FormControl;
  
  // @Output() select:EventEmitter<string> = new EventEmitter();

  currentSelection:ISelectBlockContent;

  constructor() { }

  ngOnInit() {
  }

  isOptionSelected(option:ISelectBlockContent){
    return (this.formValue.value === option);
  }
  selectOption(option:ISelectBlockContent){
    this.formValue.setValue(option);
  }

}
