import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { DEFAULT_TICK_MEASURE } from './constants'
import { IPoint } from "./virtual-tools";

export class Graph extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  staticBg: PIXI.Graphics;
  hcPrevState: boolean;
  graph: PIXI.Graphics;
  rulerAnchorA: PIXI.Graphics;
  rulerAnchorB: PIXI.Graphics;
  tick: PIXI.Graphics;
  axisLength_X = 300;
  axisLength_Y = 300;


  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.element = element;
    this.spriteLoader = new SpriteLoader();
    this.isGlobalRotating = isGlobalRotating;
    this.isGlobalDragging = isGlobalDragging;
    
    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.hcPrevState = this.textToSpeech.isHiContrast;

    // Render scene
    this.render();
  }
  
  loadAssets = () => {
    return null;
  }

  graphOriginOffset_X: number;
  graphOriginOffset_Y: number;
  setGraphOrigin(){
    this.getGridCenter()
    const gridGap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;
    this.graphOriginOffset_X = this.a * gridGap;
    this.graphOriginOffset_Y = this.b * gridGap;
  }

  a;
  b;
  getGridCenter() {

    const gap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;

    const centerIndex_X = Math.floor(this.element.canvasWidth / gap / 2)
    const centerIndex_Y = Math.floor(this.element.canvasHeight / gap / 2)

    let counter = 0;
    let closestX = 0;
    let distanceX = 999999;
    let closestY = 0;
    let distanceY = 999999;

    for(let i = gap; i<this.element.canvasWidth; i+=gap) {
      if (counter % 10 == 0 && Math.abs(centerIndex_X - counter) < distanceX){
        distanceX = Math.abs(centerIndex_X - counter);
        closestX = counter + 1;
      }
      counter++

    }
    counter = 0;
    for(let i = gap; i<this.element.canvasHeight; i+=gap) {
      if ((counter % 10 == 0) && (Math.abs(centerIndex_Y - counter) < distanceY)){
        distanceY = Math.abs(centerIndex_Y - counter);
        closestY = counter + 1;
      }
      counter++

    }
    this.a = closestX;
    this.b = closestY;
  }

  drawXAxis(graph: PIXI.Graphics) {
    const xAxis = new PIXI.Graphics();
    xAxis.name = 'xAxis';
    const text = new PIXI.Text('x', {fontSize: 8});
    const lineWidth = this.axisLength_X;
    const lineThickness = 2;
    // const xOffset = this.graphOriginOffset_X  - lineWidth / 2;
    // const yOffset = this.graphOriginOffset_Y ;

    // const xOffset =   - lineWidth / 2;
    const yOffset = this.axisLength_Y/2 ;

    text.style.fill = 0x0000;
    text.x = lineWidth;
    text.y = 5;

    // draw arrow
    const arrow = new PIXI.Graphics();
    arrow.x = lineWidth + lineThickness;
    arrow.y = lineThickness/2;
    let arrowWidth = 5, arrowHalfWidth = arrowWidth / 2, arrowHeight = arrowWidth;
    arrow.beginFill(0x0000, 1);
    arrow.lineStyle(0, 0x0000, 1);
    arrow.lineTo(-arrowWidth, arrowHalfWidth);
    arrow.lineTo(-arrowWidth, -arrowHalfWidth);
    arrow.lineTo(0, 0);
    arrow.endFill();

    xAxis.beginFill(0x0000);
    xAxis.drawRect(0,0, lineWidth, lineThickness);
    // xAxis.x = xOffset;
    xAxis.y = yOffset;
    xAxis.endFill();

    xAxis.addChild(text);
    xAxis.addChild(arrow);
    this.drawScales(xAxis, true);

    xAxis.interactive = true
    xAxis.cursor = 'grab';
    graph.addChild(xAxis);
  }

  drawYAxis(graph: PIXI.Graphics) {
    const yAxis = new PIXI.Graphics();
    yAxis.name = 'yAxis';
    const text = new PIXI.Text('y', {fontSize: 8});
    const lineWidth = this.axisLength_Y;
    const lineThickness = 2;

    text.style.fill = 0x0000;
    text.x = 5;
    text.y = -5;
    yAxis.beginFill(0x0000);
    yAxis.drawRect(0,0, lineThickness, lineWidth);

    const xOffset = this.graphOriginOffset_X;
    const yOffset = this.graphOriginOffset_Y - lineWidth / 2;
    yAxis.x = this.axisLength_X/2;
    // yAxis.y = yOffset;
    yAxis.endFill();

    // draw arrow
    const arrow = new PIXI.Graphics();
    arrow.x = lineThickness/2;
    arrow.y = -lineThickness;
    let arrowWidth = 5, arrowHalfWidth = arrowWidth / 2, arrowHeight = arrowWidth;
    arrow.beginFill(0x0000, 1);
    arrow.lineStyle(0, 0x0000, 1);
    arrow.lineTo(arrowHalfWidth, arrowHeight);
    arrow.lineTo(-arrowHalfWidth, arrowHeight);
    arrow.lineTo(0, 0);
    arrow.endFill();

    yAxis.beginFill(0x0000);
    yAxis.drawRect(0,0, 2, lineWidth);

    yAxis.addChild(text);
    yAxis.addChild(arrow);
    this.drawScales(yAxis, false);
    graph.addChild(yAxis);
  }

  drawScales(line: PIXI.Graphics, isXAxis: boolean){
    const scale = new PIXI.Graphics();
    const gap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;

    scale.beginFill(0x000);
    let counter = 0
    if (isXAxis){
      for (let i = this.axisLength_X / 2 + gap; i<this.axisLength_X; i+=gap) {
        counter++
        scale.drawRect(i, 0, 1.5, -gap/4);
        this.addNumberDisplay(i, 2, counter + '', line, true)
      }
      counter = 0
      for (let i = this.axisLength_X / 2 - gap; i> 0; i-=gap) {
        counter--
        scale.drawRect(i, 0, 1.5, -gap/4);
        this.addNumberDisplay(i, 2, counter + '', line, true)
      }
    } else {
      for (let i = this.axisLength_Y / 2 - gap; i> 0; i-=gap) {
      
        counter++
        scale.drawRect(0, i,  -gap/4, 1.5);
        this.addNumberDisplay(5, i, counter + '', line, false)
      }
      counter = 0
      for (let i = this.axisLength_Y / 2 + gap; i<this.axisLength_Y; i+=gap) {
        counter--
        scale.drawRect(0, i, -gap/4, 1.5);
        this.addNumberDisplay(5, i, counter + '', line, false)
      }
    }
    scale.endFill();
    scale.zIndex = 20;

    line.addChild(scale);
  }

  addNumberDisplay(xOffset: number, yOffset: number, text: string, line: PIXI.Graphics, isXAxis: boolean){
    const numberDisplay = new PIXI.Text(text, {fontSize: 8});
    numberDisplay.style.fill = 0x0000;
    numberDisplay.x = isXAxis ? xOffset - numberDisplay.width / 2 : xOffset;
    numberDisplay.y = !isXAxis ? yOffset - numberDisplay.height / 2 : yOffset;;
    line.addChild(numberDisplay)
  }

  isGraphMode = false;
  toggleGraph(mode?: boolean) {
    this.isGraphMode = mode == null ? !this.isGraphMode : mode;

    if(this.isGraphMode) {
      this.renderGraph();
      this.render();
    } else{
      this.graph.destroy();
      this.render();
    }

    return this.isGraphMode;
  }

  drawRotationPoint(point: {x: number, y: number}, parent: PIXI.Graphics) {
    const rotationPoint = new PIXI.Graphics();

    rotationPoint.beginFill(0x0000, 0.9);
    rotationPoint.moveTo(point.x, point.y)
    rotationPoint.lineStyle(2)
    rotationPoint.lineTo(point.x, point.y - 20)
    rotationPoint.lineStyle(0)
    rotationPoint.drawCircle(point.x, point.y - 20, 6);
    rotationPoint.endFill();
    rotationPoint.position.set(0 + point.x, 0 + point.y);
    rotationPoint.pivot.set(0 + point.x, 0 + point.y);

    parent.addChild(rotationPoint);

    this.addDragAndRotateListener(parent, true, rotationPoint);

    return rotationPoint;
  }

  getGraphCornerPoints(){
    const topLeft = {x: 0, y: 0}
    const topRight = {x: this.axisLength_X, y: 0}
    const bottomLeft = {x: 0, y: this.axisLength_Y}
    const bottomRight = {x: this.axisLength_X, y: this.axisLength_Y}
    return [topLeft, topRight, bottomLeft, bottomRight];
  }


  initGraph() {
    this.graph = new PIXI.Graphics();
    this.rulerAnchorA = new PIXI.Graphics();
    this.rulerAnchorB = new PIXI.Graphics();
    this.tick = new PIXI.Graphics();
    this.setGraphOrigin();
  }

  graphContainer
  renderGraph(){
    const graphContainer = new PIXI.Graphics();
    this.initGraph();
    // Graph graphic
    this.drawXAxis(this.graph);
    this.drawYAxis(this.graph);
    this.graph.interactive = true;
    this.graph.cursor = 'grab';
    this.graph.zIndex = 10;
    graphContainer.addChild(this.graph)
    graphContainer.zIndex = 7

    const graphCornorPoints = this.getGraphCornerPoints();
    const center = this.getShapeCenter(graphCornorPoints);
    graphContainer.pivot.set(center.x,center.y);
    const rotationPoint = this.drawRotationPoint({x: center.x, y: center.minY}, graphContainer);
    rotationPoint.alpha = 0;
    graphContainer.position.set(this.graphOriginOffset_X, this.graphOriginOffset_Y);

    this.stage.addChild(graphContainer);
    this.graphContainer = graphContainer;

    // Mask
    // this.rulerOutterBounds = new PIXI.Graphics();
    // this.rulerOutterBounds.beginFill(this.toolDetailColor, 0.5);
    // this.rulerOutterBounds.drawRoundedRect(this.element.rulerX, this.element.rulerY-this.pivotRadius, this.minRulerWidth, this.ruler.height+this.pivotRadius, this.rectRadius);
    // this.rulerOutterBounds.endFill();
    // this.rulerOutterBounds.pivot.set(this.element.rulerX, this.element.rulerY);
    // this.rulerOutterBounds.position.set(this.element.rulerX, this.element.rulerY);
    // this.ruler.addChild(this.rulerOutterBounds);
    // this.ruler.mask = this.rulerOutterBounds;

    // // Render details
    // this.renderTicks();
    // this.renderRulerRotationAnchors();

    // // Add listeners
    this.addDragAndRotateListener(graphContainer);
    this.addSelectGraphListener(graphContainer, this.graph, rotationPoint, graphCornorPoints);
    // this.addRulerRotateListener(this.rulerAnchorA);
  }



  addDragAndRotateListener(obj: PIXI.Graphics, isRotate?: boolean, rotatePoint?: PIXI.Graphics) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    let isRotateDragging = false;
    let initialAngle = 0;
    obj.cursor = 'grab';
    obj.interactive = true;
    if(isRotate) {
      rotatePoint.cursor = 'ew-resize'
      rotatePoint.interactive = true;
    }


    const onDragStart = (e) => {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragging = true;
        initialDiffX = mousePosition.x - obj.x
        initialDiffY = mousePosition.y - obj.y
        obj.cursor = 'grabbing';
        console.log('drag start')
    }
    const onDragEnd = (e) => {
        isDragging = false;
        obj.cursor = 'grab';
    }
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(!this.isDragExpanding && isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging) {
            this.isGlobalDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            obj.x = mousePosition.x - initialDiffX; // obj x = 3
            obj.y = mousePosition.y - initialDiffY;

            this.snapToNearestGrid_x(obj);
            this.snapToNearestGrid_y(obj);
            this.render();
        } else if(!isDragging) {
          this.isGlobalDragging = false;
        }
        // console.log('drag moving...')
    }

    if(isRotate) {
      initialAngle = Math.atan2(obj.pivot.y - rotatePoint.y, obj.pivot.x - rotatePoint.x) + Math.PI;
    }
    const onRotateStart = (e) => {
      isRotateDragging = true;
      this.isGlobalRotating = true;
      console.log('rotate start')
    }
    const onRotateEnd = (e) => {
      isRotateDragging = false;
      this.isGlobalRotating = false;
    }
    const onRotate = (e: PIXI.InteractionEvent) => {
        if(isRotateDragging) {
          const mousePosition = e.data.getLocalPosition(this.stage);

          const mouseAngle = Math.atan2(obj.y - mousePosition.y, obj.x - mousePosition.x) + Math.PI;
          
          obj.rotation = mouseAngle - initialAngle;

          this.render();
        }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove);

    if(isRotate) {
      rotatePoint.on('pointerdown', onRotateStart)
      .on('pointerup', onRotateEnd)
      .on('pointerupoutside', onRotateEnd)
      .on('pointermove', onRotate);
    }
  }

  addSelectGraphListener(obj: PIXI.Graphics, container: PIXI.Graphics, rotationPoint: PIXI.Graphics, points: IPoint[]) {
    let selected = false;
    let dragging = false;
    let outline;
    let outterBorder;
    let menu;
    let dragPoints;

    const onClick = (e) => {
      dragging = false;
    }
    const checkDragging = (e) => {
      dragging = this.isGlobalDragging;
    }
    const toggleMenu = (e) => {
      if(!dragging) {
        selected = !selected;
        if(selected) {
          // outline = this.drawSelectedOutline(obj, container, points);
          outterBorder = this.drawSelectedBorder(container, points);
          dragPoints = this.drawDragPoints(container, points);
          // outline.alpha = 1;
          outterBorder.alpha = 1;
          dragPoints.alpha = 1
          // menu.alpha = 1;
          rotationPoint.alpha = 1;
        } else {
          outterBorder.destroy();
          dragPoints.destroy();
          rotationPoint.alpha = 0;
        }

        this.render();
      }
    }

    obj.on('pointerdown', onClick).on('pointermove', checkDragging).on('pointerup', toggleMenu);
  }


  snapToNearestGrid_x(obj: PIXI.Graphics){
    const gridGap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;
    let index = Math.floor(obj.x / gridGap)
    let remainder = obj.x % 10
    console.log(obj.x.toFixed(2), index, remainder)
    if ((index + 1) * gridGap < this.element.canvasWidth && remainder > gridGap / 2){
      index = index + 1;
    }
    obj.x = index * gridGap;
  }

  snapToNearestGrid_y(obj: PIXI.Graphics){
    const gridGap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;
    let index = Math.floor(obj.y / gridGap)
    let remainder = obj.x % 10
    // console.log(obj.y.toFixed(2), index, remainder)
    if ((index + 1) * gridGap < this.element.canvasHeight && remainder > gridGap / 2){
      index = index + 1;
    }
    obj.y = index * gridGap;
  }

  drawDragPoints(container: PIXI.Graphics, points: IPoint[]){
    const center = this.getShapeCenter(points);
    const dragPoints = new PIXI.Graphics();

    dragPoints.beginFill(0x0000, 0.9);
    dragPoints.drawCircle(center.x, 0, 6);
    dragPoints.drawCircle(0, center.y, 6);
    dragPoints.drawCircle(center.x, center.maxY, 6);
    dragPoints.drawCircle(center.maxX, center.y, 6);


    dragPoints.endFill();

    dragPoints.alpha = 0
    dragPoints.interactive = true;

    container.addChild(dragPoints);

    this.addDragExpandListener(container, true, dragPoints);

    return dragPoints;
  }


  isDragExpanding = false;
  addDragExpandListener(obj: PIXI.Graphics, isRotate?: boolean, dragPoint?: PIXI.Graphics) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    let isRotateDragging = false;
    let initialAngle = 0;
    obj.cursor = 'grab';
    obj.interactive = true;

    dragPoint.cursor = 'ew-resize'
    dragPoint.interactive = true;

    const onDragStart = (e) => {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragging = true;
        initialDiffX = mousePosition.x - dragPoint.x
        initialDiffY = mousePosition.y - dragPoint.y
        obj.cursor = 'grabbing';
        console.log('drag start')
    }
    const onDragEnd = (e) => {
        isDragging = false;
        this.isDragExpanding = false;
        obj.cursor = 'grab';
    }
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging) {
            this.isGlobalDragging = true;
            this.isDragExpanding = true
            const mousePosition = e.data.getLocalPosition(this.stage);
            const xDiff = mousePosition.x - initialDiffX;
            const yDiff = mousePosition.y - initialDiffY;
            console.log(xDiff.toFixed(2), yDiff.toFixed(2))
            console.log('drag moving...')

        } else if(!isDragging) {
          this.isGlobalDragging = false;
          this.isDragExpanding = false;
        }
        // console.log('drag moving...')
    }

    if(isRotate) {
      initialAngle = Math.atan2(obj.pivot.y - dragPoint.y, obj.pivot.x - dragPoint.x) + Math.PI;
    }
    const onRotateStart = (e) => {
      isRotateDragging = true;
      this.isGlobalRotating = true;
      console.log('rotate start')
    }
    const onRotateEnd = (e) => {
      isRotateDragging = false;
      this.isGlobalRotating = false;
    }
    const onRotate = (e: PIXI.InteractionEvent) => {
        if(isRotateDragging) {
          const mousePosition = e.data.getLocalPosition(this.stage);

          const mouseAngle = Math.atan2(obj.y - mousePosition.y, obj.x - mousePosition.x) + Math.PI;
          
          obj.rotation = mouseAngle - initialAngle;

          this.render();
        }
    }

    dragPoint.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove);
  }
}
