import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ChatService} from '../../chat/chat.service';

@Component({
  selector: 'test-taker',
  templateUrl: './test-taker.component.html',
  styleUrls: ['./test-taker.component.scss']
})
export class TestTakerComponent implements OnInit, AfterViewInit {

  @Input() markingPoolId;
  @Input() uid;
  @Input() supervisorName;
  @Input() chatService;
  @Input() instit_group_id;

  @ViewChild('chathistory') chatDiv: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.chatService.removeUnread();
  }

  ngAfterViewInit() {
    this.chatService.elem = this.chatDiv;
    this.chatService.scrollToBottom();
  }

  sendMessage(message) {

    // if the message is empty
    if (!message && message !== '0') {
      console.log('empty message, returning');
      return;
    }

    const newMessage = {
      date: Date(),
      isBroadcast: false,
      msg: message,
      sender: this.uid
    };

    if (this.chatService.socket) {

      const messageData = {
        uid: this.uid,
        message: message,
        gameId: this.markingPoolId + '-' + this.instit_group_id
      };

      this.chatService.emit('messageFromPlayer', messageData);

    }

    this.chatService.currentChat.push(newMessage);
    this.chatService.currentMessage = '';
    this.chatService.scrollToBottom();

  }

}
