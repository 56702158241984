import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CaptureImageUploadService {
  newUpload: EventEmitter<any> = new EventEmitter();

  uploadedNewVideo() {
    this.newUpload.emit();
  }
}