import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IPoint } from "./virtual-tools";
import { IExtendedTools } from "../element-render-virtual-tools.component";
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from "@angular/material/tooltip";

enum ACTIONS {
  DUPLICATE = "DUPLICATE",
  DELETE = "DELETE"
}

export interface IPointCenter {
    x: number;
    y: number;
    maxX: number;
    maxY: number;
    minX: number;
    minY: number;
    height: number;
    width: number;
}
export class FreehandRectangle extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  rectangle: PIXI.Graphics;
  vertices: PIXI.Graphics;
  lines: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  vFlipSprite: PIXI.Sprite;
  hFlipSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  deleteSprite: PIXI.Sprite;
  rectangles: PIXI.Graphics[] = [];
  rectangleColor: string;
  rectangleTools: IExtendedTools;
  points: IPoint[] = [];
  isSelecting: boolean;
  isGlobalResizing = false;


  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.element = element;
    this.spriteLoader = new SpriteLoader();
    this.backgroundSprite = backgroundSprite;
    this.vFlipSprite = new PIXI.Sprite();
    this.hFlipSprite = new PIXI.Sprite();
    this.dupeSprite = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();
    this.rectangleColor = this.element.fhRectangleColor || '#03DAC6';
    this.loadAssets().then(this.initSprites);

    this.addDrawVertexListener();

    this.isSelecting = false;
    this.resizePoints = [];
    this.vertices = new PIXI.Graphics();
    this.rectangleContainersOnResizing = new PIXI.Graphics;
    this.addGraphic(this.vertices);
    this.addGraphic(this.rectangleContainersOnResizing);


    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.rectangleTools = {
      parent: 'rectangle',
      colors: [
        this.element.fhRectangleColor,
        '#c43f2e',
        '#2a91d6'
      ],        
      colorHandler: (color: string) => {
        this.rectangleColor = color;
      },
      tools: [
      {
        id: 'delete',
        action: () => this.clearRectangles(),
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
      },
      {
        id: 'colorPicker',
        isColorPick: true,
        colorController: this.rectangleColor,
        action: () => {},
      },
      {
        id: 'select',
        iconUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/select-arrow/1686932347476/select-arrow.png',
        action: () => this.toggleSelectTool(),
      },
    ]}

    // Render scene
    this.render();
  }

  initSprites = ({resources}) => {
    this.vFlipSprite.texture = resources.vflip.texture;
    this.hFlipSprite.texture = resources.hflip.texture;
    this.dupeSprite.texture = resources.duplicate.texture;
    this.deleteSprite.texture = resources.delete.texture;
  }

  drawSelectedOutline(obj: PIXI.Graphics, container: PIXI.Graphics, points: IPoint[]) {
    let outline = new PIXI.Graphics();
    outline.beginFill(0x0000, 0);
    outline.lineStyle(2, 0x0000, 1);
    for(let i=0; i<points.length; i++) {
      if(i==0) {
        outline.moveTo(points[i].x, points[i].y);
      } else {
        outline.lineTo(points[i].x, points[i].y);
        if(!points[i+1]) { 
          outline.lineTo(points[0].x, points[0].y);
        }
      }
    }
    outline.closePath();
    outline.endFill();
    outline.alpha = 0;

    obj.addChild(outline);

    return outline;
  }

  // Draws (adds) a single vertice on the stage.
  drawRectangleVertices(vertices: PIXI.Graphics, point: IPoint, isHover?: boolean) {


    const vertice = new PIXI.Graphics();
    const fillAlpha = isHover ? 0.5 : 1;
    vertice.beginFill(0x0000, fillAlpha);
    vertice.drawCircle(point.x, point.y, 4);
    vertice.endFill();
    vertice.interactive = true;
    vertice.zIndex = 6;
    vertices.addChild(vertice);

    // If both diagonal corner points are set, draw a rectangle
    if (this.currentRectanglePoints.length == 2){
      if (this.rectangleVisulizer) this.rectangleVisulizer.destroy();
      const topLeftPoint = this.currentRectanglePoints[0];
      const bottomRightPoint = this.currentRectanglePoints[1];
      const width = bottomRightPoint.x - topLeftPoint.x;
      const height = bottomRightPoint.y - topLeftPoint.y;
      const rectangle = new PIXI.Graphics();
      rectangle.lineStyle(2, 0x0000, fillAlpha);
      rectangle.drawRect(topLeftPoint.x, topLeftPoint.y, width, height);
      this.rectangleVisulizer = rectangle
      vertices.addChild(rectangle);
    }

    this.render();

    return vertice;
  }

  isDrawingRectangle: boolean;
  rectangleAndPoints: [{ points: [{x: any, y:any}], rectangle: PIXI.Graphics}];
  currentRectanglePoints
  hoverVertice: PIXI.Graphics;
  rectangleVisulizer: PIXI.Graphics;
  addDrawVertexListener() {
    // Three listeners, mose down to start drawing, up for finishing, plus a hover one.
    const addVerticeMouseDown = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && !this.isDrawingRectangle && !this.isSelecting) {
        this.isDrawingRectangle = true;
        this.currentRectanglePoints = [];
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        this.currentRectanglePoints.push(point);
        this.drawRectangleVertices(this.vertices, point);
      }
    }

    const addVerticeMouseUP = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && this.isDrawingRectangle && !this.isSelecting) {
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        if (this.currentRectanglePoints.length > 1) this.currentRectanglePoints.pop();
        this.currentRectanglePoints.push(point);
        this.drawRectangleVertices(this.vertices, point);
        this.isDrawingRectangle = false;

        this.completeRectangle(this.currentRectanglePoints);
        this.currentRectanglePoints = [];
      }
    }

    const addHoverVertice = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && this.isDrawingRectangle && !this.isSelecting) {
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        if (this.currentRectanglePoints.length > 1) this.currentRectanglePoints.pop();
        this.currentRectanglePoints.push(point);

        if (this.hoverVertice) this.hoverVertice.destroy();
        this.hoverVertice = this.drawRectangleVertices(this.vertices, point, true);
      }
    }

    this.stage.on('pointerdown', addVerticeMouseDown);
    this.stage.on('pointerup', addVerticeMouseUP);
    this.stage.on('pointermove', addHoverVertice)
  }

  // this method makes sure the draw points of the rectangle
  ensureDiagonalPoints(points: IPoint[]){
    const pointA = points[0];
    const pointB = points[1];
    const b_a_x = pointB.x - pointA.x;
    const b_a_y = pointB.y - pointA.y;
    const width =  Math.abs(b_a_x);
    const height =  Math.abs(b_a_y);
    const newPoints = [];
    let resizePointPos;

    // B is to the right of A
    if (pointB.x > pointA.x){
      // A is top left, B is bottom right
      if (pointB.y > pointA.y){
        newPoints.push({x: pointA.x, y: pointA.y, isRotationPoint: false});
        newPoints.push({x: pointB.x, y: pointB.y, isRotationPoint: false});
        resizePointPos = {x: width, y: height, isRotationPoint: false};
      }
      // A is bottom left, B is top right
      else {
        newPoints.push({x: pointA.x, y: pointA.y - height, isRotationPoint: false});
        newPoints.push({x: pointB.x, y: pointB.y + height, isRotationPoint: false});
      }
    } 

    else {
      // B is bottom left, A is top right
      if (pointB.y > pointA.y){
        newPoints.push({x: pointA.x - width, y: pointA.y, isRotationPoint: false});
        newPoints.push({x: pointB.x + width, y: pointB.y, isRotationPoint: false});  
        resizePointPos = {x: 0, y: height, isRotationPoint: false};
      }
      // B is top left, A is bottom right
      else {
        newPoints.push({x: pointB.x, y: pointB.y, isRotationPoint: false});
        newPoints.push({x: pointA.x, y: pointA.y, isRotationPoint: false});  
      }
    }
    return [newPoints, resizePointPos];

  }
  
  // isFromRefresh is a flag to indicate the rectangle to be drawn is duruing a dragging,
  // resizing etc, that should not have any event listeners attatched.

  // Holds the list of all resize points of all rectangles
  resizePoints: PIXI.Graphics[];
  completeRectangle(points: IPoint[], isFromResize?: boolean) {
    this.vertices.removeChildren();
    // Translate points to 0,0
    let transX = 0;
    let transY = 0;

    const rectangleDrawPoints = this.ensureDiagonalPoints(points)[0];

    for(let i = 0; i< points.length; i++) {
      if(i == 0) {
          transX = points[0].x;
          transY = points[0].y;
          points[0].x = 0;
          points[0].y = 0;
      } else {
          points[i].x -= transX;
          points[i].y -= transY;
      }
    }

    for(let i = 0; i< rectangleDrawPoints.length; i++) {
      if(i == 0) {
        transX = rectangleDrawPoints[0].x;
        transY = rectangleDrawPoints[0].y;
        rectangleDrawPoints[0].x = 0;
        rectangleDrawPoints[0].y = 0;
      } else {
        rectangleDrawPoints[i].x -= transX;
        rectangleDrawPoints[i].y -= transY;
      }
    }

    // const dot = new PIXI.Graphics
    // dot.beginFill(0x0000, 1);
    // dot.drawCircle(0, 0, 4);
    // dot.beginFill(0xc43f2e, 1)
    // dot.drawCircle(points[0].x, points[0].y, 4);
    // dot.beginFill(0x0000ff, 1)
    // dot.drawCircle(points[1].x, points[1].y, 4);
    // dot.endFill();

    const rectangleContainer = new PIXI.Graphics();
    const fill = {color: this.rectangleColor, opacity: this.element.fhRectangleOpacity * 0.01 || 0.8};
    const rectangle = this.drawRectangle(0, 0, fill, rectangleDrawPoints, false);
    rectangle.pivot.set(0.5);
    rectangle.interactive = true;
    rectangle.name = 'rectangle';
    rectangleContainer.addChild(rectangle);
    rectangleContainer.zIndex = 7;
    const center = this.getShapeCenter(rectangleDrawPoints);

    rectangleContainer.pivot.set(center.x,center.y);
    rectangleContainer.position.set(transX + center.x, transY + center.y);
    // rectangleContainer.addChild(dot)

    if (!isFromResize){
      this.addGraphic(rectangleContainer);
      this.rectangles.push(rectangleContainer);
      
      const resizePoint = this.drawResizePoint(center, rectangleContainer, true, false, rectangleDrawPoints[1]);
      this.resizePoints.push(resizePoint)
      if (!this.isSelecting){
          resizePoint.alpha = 0;
      }
      this.addSelectRectangleListener(rectangle, rectangleContainer, resizePoint, points)
    } else {
      const resizePoint = this.drawResizePoint(center, rectangleContainer, true, true, rectangleDrawPoints[1]);
      this.rectangleContainersOnResizing.addChild(rectangleContainer);
    }    

    this.render();
    return rectangleContainer;
  }

  drawRectangle(x: number, y: number, fill: {color: string, opacity: number}, points: IPoint[], isInteractive: boolean) {
    const rectangle = new PIXI.Graphics;
    rectangle.beginFill(this.getParsedColor(fill.color), fill.opacity);
    // rectangle.lineStyle(2, this.getParsedColor(fill.color), 1);

    const transformedPoints = []
    points.map(point => {
      transformedPoints.push({x: point.x + x, y: point.y + y});
    })

    const topLeftPoint = transformedPoints[0];
    const bottomRightPoint = transformedPoints[1];
    const width = bottomRightPoint.x - topLeftPoint.x;
    const height = bottomRightPoint.y - topLeftPoint.y;
    rectangle.drawRect(transformedPoints[0].x, transformedPoints[0].y, width, height);
    rectangle.endFill();

    rectangle.x += x;
    rectangle.y += y;
    rectangle.pivot.set(x, y);
    rectangle.zIndex = 50;

    return rectangle;
  }

  drawResizePoint(center: IPointCenter, parent: PIXI.Graphics, showByDefault?: boolean, noListener?: boolean, resizePointPoxition?: any) {
    const resizePoint = new PIXI.Graphics();
    resizePoint.name = 'resizeAnchor';
    resizePoint.beginFill(0x0000, 0.9);
    // resizePoint.drawCircle(center.minX, center.minY, 4);
    // resizePoint.drawCircle(center.maxX, center.maxY, 4);
    if (resizePointPoxition){
      resizePoint.beginFill(this.getParsedColor('#011d4a'), 0.9);
      resizePoint.drawCircle(0, 0, 4);
      resizePoint.position.set(resizePointPoxition.x, resizePointPoxition.y)
    }
    resizePoint.endFill();
    resizePoint.pivot.set(0, 0);
    // resizePoint.rotation = -0.785398;
    if (!showByDefault) resizePoint.alpha = 0;
    parent.addChild(resizePoint);

    if(!noListener) this.addDragAndResizeListener(parent, true, resizePoint);

    return resizePoint;
  }

  previousCircleContainerAnchor: PIXI.DisplayObject;
  rectangleContainersOnResizing: PIXI.Graphics;
  deltaX: number;
  deltaY: number;
  rectangleContainersDragStatus = new Map();
  addDragAndResizeListener(rectangleContainer: PIXI.Graphics, isResize?: boolean, resizePoint?: PIXI.Graphics) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    let isResizeDragging = false
    rectangleContainer.cursor = 'grab';
    rectangleContainer.interactive = true;
    rectangleContainer.name = Math.floor(Math.random() * 100) + '';
    this.rectangleContainersDragStatus.set(rectangleContainer, false); // Map to record if the rectangle is being dragged

    if (isResize){
      resizePoint.cursor = 'ew-resize'
      resizePoint.interactive = true;
    }

    // const a = new PIXI.Graphics();
    // a.beginFill(0xffd700, 0.9);
    // a.drawCircle(rectangleContainer.x, rectangleContainer.y, 4);
    // a.zIndex = 99;
    // this.addGraphic(a)

    const onDragStart = (e) => {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragging = true;
        initialDiffX = mousePosition.x - rectangleContainer.x
        initialDiffY = mousePosition.y - rectangleContainer.y
        rectangleContainer.cursor = 'grabbing';
    }
    const onDragEnd = (e) => {
      isDragging = false;
      rectangleContainer.cursor = 'grab';
      this.rectangleContainersDragStatus.set(rectangleContainer, false);
    }


    const onDragMove = (e: PIXI.InteractionEvent) => {
        if (e.currentTarget && !e.currentTarget.name || e.currentTarget.name != rectangleContainer.name){
          return;
        }
        if(this.isSelecting && isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging && !this.isGlobalResizing) {
          this.isGlobalDragging = true;
          this.rectangleContainersDragStatus.set(rectangleContainer, true);

          const mousePosition = e.data.getLocalPosition(this.stage);
          rectangleContainer.x = mousePosition.x - initialDiffX;
          rectangleContainer.y = mousePosition.y - initialDiffY;
          this.render();
          console.log('setting true by id: ', rectangleContainer.name)
        } else if(!isDragging) {
          console.log('setting false by id: ', rectangleContainer.name)
          this.isGlobalDragging = false;
        }
    }

    const onResizeStart = (e) => {
      isResizeDragging = true;
      this.isGlobalResizing = true;
      rectangleContainer.cursor = 'grabbing';
      rectangleContainer.removeAllListeners();
    }
    const onResizeEnd = (e) => {
      isResizeDragging = false;
      this.isGlobalResizing = false;
      rectangleContainer.cursor = 'grab';
      if (this.previousCircleContainerAnchor) this.previousCircleContainerAnchor.destroy();
      this.rectangleContainersOnResizing.removeChildren();
      this.previousCircleContainerAnchor = rectangleContainer;
      rectangleContainer.children.forEach((child, i) => {
        rectangleContainer.getChildAt(i).removeAllListeners();
        rectangleContainer.getChildAt(i).destroy();
      })
      this.previousCircleContainerAnchor.alpha = 0;
      const pivot = rectangleContainer.pivot


      console.log("width: ", Math.abs((rectangleContainer.x - pivot.x) - (rectangleContainer.x + this.deltaX)))
      this.completeRectangle([{x: rectangleContainer.x - pivot.x, y:rectangleContainer.y - pivot.y}, {x: rectangleContainer.x + this.deltaX, y:rectangleContainer.y + this.deltaY}]);
      this.render();
    }
    
    const onResizeMove = (e: PIXI.InteractionEvent) => {
        if(isResizeDragging) {
          this.isGlobalResizing = true;
          this.rectangleContainersOnResizing.removeChildren();
          resizePoint.alpha = 0;

          const mousePosition = e.data.getLocalPosition(this.stage);
          this.deltaX = mousePosition.x - rectangleContainer.x; 
          this.deltaY = mousePosition.y - rectangleContainer.y;


          // console.log(this.deltaX, this.deltaY)
          console.log(resizePoint.x, rectangleContainer.x)

          rectangleContainer.children.forEach((child, i) => {
            if (child.name != 'resizeAnchor'){
                rectangleContainer.getChildAt(i).destroy()
            } else {
                this.previousCircleContainerAnchor = child;
                const {x,y} = e.data.getLocalPosition(this.stage);
                const point = {x, y};
                this.points.push(point);
            }
          })
          //   this.previousCircleContainerAnchor?.alpha = 0;
          const pivot = rectangleContainer.pivot
        
          const newRectanglePoints = [{x: rectangleContainer.x - pivot.x, y:rectangleContainer.y - pivot.y}, {x: rectangleContainer.x + this.deltaX, y:rectangleContainer.y + this.deltaY}];
          this.completeRectangle(newRectanglePoints, true);
          this.render();
          //   console.log('dragging', id)
        } else if(!isResizeDragging) {
          this.isGlobalResizing = false;
        }
    }

    // const rec = rectangleContainer.getChildByName('rectangle');
    // rec.on('pointerdown', onDragStart)
    // .on('pointerup', onDragEnd)
    // .on('pointerupoutside', onDragEnd)
    // .on('pointermove', onDragMove);

    rectangleContainer.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove);

    if(isResize) {
      resizePoint.on('pointerdown', onResizeStart)
      .on('pointerup', onResizeEnd)
      .on('pointerupoutside', onResizeEnd)
      .on('pointermove', onResizeMove);
    }
  }

  drawMenu(obj: PIXI.Graphics, container: PIXI.Graphics, points: IPoint[]) {
    const center = this.getShapeCenter(points);
    const menu = new PIXI.Graphics();
    const menuYOffset = 15;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset
    const menuHeight = 30;
    const menuWidth = 55;
    menu.beginFill(0x333c42);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);

    const menuPosX = points[1].x < 0 ? 0 : center.minX;
    const menuPoxY = points[1].y < 0 ? menuY + (center.maxY - center.minY) : menuY;
    menu.position.set(menuPosX, menuPoxY);
    menu.endFill();

    menu.alpha = 0;

    // Menu icons
    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    dupe.y = menuHeight / 2;
    dupe.x = 10;
    dupe.interactive = true;

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = dupe.x + dupe.width + 5;
    del.interactive = true;

    menu.addChild(dupe);
    menu.addChild(del);
    this.addMenuListeners(obj, container, ACTIONS.DUPLICATE, dupe, points);
    this.addMenuListeners(container, container, ACTIONS.DELETE, del, points);
    
    container.addChild(menu);

    return menu;
  }

  addMenuListeners(rectangle: PIXI.Graphics, rectangleContainerSource: PIXI.Graphics,  action: ACTIONS, option: PIXI.Sprite, points: IPoint[]) {
    let func;
    option.cursor = 'pointer';
    if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        const rectangleContainer = this.completeRectangle([...points]);
        rectangleContainer.position.set(rectangleContainerSource.x + 20, rectangleContainerSource.y + 20);
        this.render();
      }
    } else if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.deleteObject(rectangle);
        this.render();
      }
    }
    option.on('pointerup', func);
  }

  deleteObject(obj: PIXI.Graphics) {
    obj.removeChildren();
    obj.clear();
  }


  isDrawMode = false;
  toggleDrawMode(mode?: boolean) {
    this.isDrawMode = mode == null ? !this.isDrawMode : mode;
    if(!this.isDrawMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
    }
    this.points = [];


    this.render(); 

    return this.isDrawMode;
  }

  clearRectangles() {
    this.rectangles.forEach((rectangle) => {
      this.deleteObject(rectangle);
      rectangle.destroy();
    });
    this.rectangles = [];
    this.render();
  }

  toggleSelectTool() {
    this.isSelecting = !this.isSelecting;


    this.resizePoints.forEach(resizePoint => {
        if (this.isSelecting) resizePoint.alpha = 1;
        else resizePoint.alpha = 0;
    })

    this.render();
  }

  getRectangleTools() {
    return this.rectangleTools;
  }

  rectangleBorder: PIXI.Graphics;
  rectanglesSelectStatus = new Map();
  addSelectRectangleListener(obj: PIXI.Graphics, container: PIXI.Graphics, resizePoint: PIXI.Graphics, points: IPoint[]) {
    let selected = false;
    let dragging = false;
    let resizing = false;
    let outterBorder;
    let border;
    let rotationPoint;
    let menu;

    this.rectanglesSelectStatus.set(container, selected);

    const onClick = (e) => {
      dragging = false;
      this.isSelecting = true;

    }
    const checkDragging = (e) => {
      dragging = this.isGlobalDragging;
      resizing = this.isGlobalResizing;
    }
    const toggleMenu = (e) => {
      console.log('toggling menu, isGlobalDragging: ', this.isGlobalDragging)
      if(!dragging && !this.isGlobalDragging && !resizing) {
        selected = !selected;
        if(selected) {
          border = this.drawSelectedCircleBorder(container, points);
          outterBorder = border[0];
          rotationPoint = border[1];
          this.rectangleBorder = outterBorder;
          menu = this.drawMenu(obj, container, points);
          outterBorder.alpha = 1;
          menu.alpha = 1;
          resizePoint.alpha = 1;
        } else {
          outterBorder.destroy();
          rotationPoint.destroy();
          this.rectangleBorder = undefined;
          menu.destroy();
          resizePoint.alpha = 0;
          this.isSelecting = false;
        }

        this.rectanglesSelectStatus.set(container, selected);
        this.render();
      }
    }
    obj.on('pointerdown', onClick).on('pointermove', checkDragging).on('pointerup', toggleMenu);
  }


  drawSelectedCircleBorder(container: PIXI.Graphics, points: IPoint[]) {
    const center = this.getShapeCenter(points);
    const border = new PIXI.Graphics();
    const width = center.maxX - center.minX;
    const height = center.maxY - center.minY;
    let offsetX = center.x - width/2;
    let offsetY = center.y - height/2;

    if (points[1].x < 0)  offsetX = 0;
    if (points[1].y < 0)  offsetY = 0;

    // border.moveTo(offsetX, offsetY);
    border.lineStyle(3, this.getParsedColor('#011d4a'), 0.5);
    border.drawRect( offsetX, offsetY, width, height);
    border.moveTo(offsetX + width/2, offsetY);
    border.lineStyle(3, this.getParsedColor('#011d4a'), 0.5);
    border.lineTo(offsetX + width/2, offsetY - 20);
    border.alpha = 1;


    const rotationPoint = new PIXI.Graphics();
    rotationPoint.beginFill(this.getParsedColor('#011d4a'), 1);
    rotationPoint.drawCircle(0, 0, 4);
    rotationPoint.position.set(offsetX + width/2, offsetY - 20);
    rotationPoint.alpha = 1;

    this.addRotateListener(container, rotationPoint);
    container.addChild(rotationPoint);
    container.addChild(border);
    return [border, rotationPoint];
  }

  addRotateListener(obj, rotatePoint){
    this.isGlobalDragging = false;
    let isRotateDragging = false;

    rotatePoint.cursor = 'ew-resize'
    rotatePoint.interactive = true;

    let initialAngle = Math.atan2(obj.pivot.y - rotatePoint.y, obj.pivot.x - rotatePoint.x) + Math.PI;
    const onRotateStart = (e) => {
      isRotateDragging = true;
      this.isGlobalRotating = true;
      console.log('rotate start')
    }
    const onRotateEnd = (e) => {
      isRotateDragging = false;
      this.isGlobalRotating = false;
    }
    const onRotate = (e: PIXI.InteractionEvent) => {
        if(isRotateDragging) {
          const mousePosition = e.data.getLocalPosition(this.stage);
          const mouseAngle = Math.atan2(obj.y - mousePosition.y, obj.x - mousePosition.x) + Math.PI;
          obj.rotation = mouseAngle - initialAngle;
          this.render();
        }
    }

    rotatePoint.on('pointerdown', onRotateStart)
    .on('pointerup', onRotateEnd)
    .on('pointerupoutside', onRotateEnd)
    .on('pointermove', onRotate);
  }

  loadAssets() {
    let assets = [];
    assets.push({
        name: "hflip", 
        path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/flip-vertical/1684187809679/flip-vertical.png"
      }
    );
    assets.push({
      name: "vflip", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/flip-horizontal/1684187792111/flip-horizontal.png"
    });
    assets.push({
      name: "duplicate", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/duplicate-2/1684187819438/duplicate-2.png"
    });
    assets.push({
      name: "delete", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png"
    });

    this.spriteLoader.addSpritestoLoader(assets);
    return this.spriteLoader.loadSprites()
  }

  drawPoint(x, y, color){
    const a = new PIXI.Graphics();
    a.beginFill(color, 0.9);
    a.drawCircle(x, y, 4);
    a.zIndex = 99;
    this.addGraphic(a)
  }
}
