import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BcAccountsService, Filter, FilterCondition, PaginatedRows, Pagination } from 'src/app/bc-accounts/bc-accounts.service';
import { sum } from 'lodash';

export interface IColumnHeading {
  heading: string;
  subheading?: string;
  sortBy: string;
  isSelect?: boolean;
  options?: {
    label: string;
    value: any;
  }[]
  sortDisabled?: boolean;
  filterDisabled?: boolean;
  isLink?: boolean;

  [key: string]: any,
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Data {
  [key: string]: any,
}

export interface Tag {
  text: string,
  color: string,
}

export interface LinkClickEvent<T> {
  row: T,
  rowIndex: number,
  by: string,
}

export type Style = { [key: string]: string };

export interface Toggle {
  title?: string,
  tra?: string,
}

export interface TextDisplayElement {
  text: string,
  isLink?: boolean,
  linkId?: string,
  disabled?: boolean,
  style: { [key: string]: string },
}

export enum TextDisplayDirection {
  HORIZONTAL,
  VERTICAL,
}

export interface TextDisplay {
  elements: TextDisplayElement[],
  direction: TextDisplayDirection,
}

@Component({
  selector: 'bc-paginated-table',
  templateUrl: './bc-paginated-table.component.html',
  styleUrls: ['./bc-paginated-table.component.scss'],
})
export class BcPaginatedTableComponent<T extends Data> implements OnInit {

  @Input() columnHeadings: IColumnHeading[] = [];
  @Input() pagination: Pagination = null;
  @Input() defaultFilterCondition: FilterCondition = FilterCondition.MATCH;
  @Input() filterUpdater: (by: string, value: string | number, filters: Map<string, Filter>) => void = null;
  @Input() filterCleanup: (by: string) => string[];
  @Input() getFilterInitValue: (filter: Filter) => string | number = null;
  @Input() getAnyValue: (by: string) => string = null;
  @Input() getRows: (pagination: Pagination) => Promise<PaginatedRows<T>>;
  @Input() getDisplay: (by: string, row: T) => string | TextDisplay;
  @Input() getTag: (by: string, row: T) => Tag;
  @Input() getRowStyle: (row: T, rowIndex: number) => Style = null;
  @Input() columnWidths: number[] = [];
  @Input() addCheckbox: boolean = false;
  @Input() withExport: boolean = false;
  @Input() zebra: boolean = false;
  @Input() zebraField: string = null;

  @Input() extraToggle: Toggle = null;

  @Output() update = new EventEmitter();
  @Output() loading = new EventEmitter();
  @Output() select = new EventEmitter();
  @Output() link = new EventEmitter();
  @Output() export = new EventEmitter();
  @Output() toggle = new EventEmitter();

  visibleFilters: Set<string> = new Set();
  filters: Map<string, Filter> = new Map();
  filterThrottle: NodeJS.Timeout | null;

  rows: T[] = [];
  displays: (string | TextDisplay)[][];

  isLoading: boolean = false;

  OrderDirection = OrderDirection;

  selectAll: boolean = false;

  toggleValue: boolean = false;

  constructor(
    private bcAccounts: BcAccountsService,
  ) { }

  ngOnInit(): void {
    if (!this.pagination) {
      this.pagination = this.bcAccounts.getInitialPagination();
    }
    this.updateTable();
  }

  updateTable() {
    this.isLoading = true;
    this.loading.emit(this.isLoading);

    this.update.emit();

    if (this.getRows) {
      this.getRows(this.pagination).then(({ data, count }) => {
        this.rows = data;
        this.pagination.count = count;

        if (this.zebra) {
          this.processZebraStyling();
        }

        this.displays = this.rows.map(r => {
          return this.columnHeadings.map(h => {
            if (this.isHeadingValueComplex(h.sortBy, r)) {
              return this.getDisplay(h.sortBy, r) || '';
            }
            return this.getHeadingValueForRow(h.sortBy, r);
          })
        });

        this.isLoading = false;
        this.loading.emit(this.isLoading);
      });
      return;
    }

    this.rows = []
    this.pagination.count = 0;
    this.isLoading = false;
    this.loading.emit(this.isLoading);
  }

  onLinkClicked(row: T, index: number, by: string) {
    if (!by) return;

    let event: LinkClickEvent<T> = {
      row,
      by,
      rowIndex: index,
    }
    this.link.emit(event);
  }

  changeOrderBy(by: string) {
    if (this.pagination.orderBy === by) {
      this.pagination.orderDirection = this.pagination.orderDirection === OrderDirection.ASC ? OrderDirection.DESC : OrderDirection.ASC;
    } else {
      this.pagination.orderBy = by;
      this.pagination.orderDirection = OrderDirection.ASC;
    }
    this.pagination.skip = 0;

    this.updateTable();
  }

  isSortedBy(by: string, direction: OrderDirection): boolean {
    return this.pagination.orderBy === by && this.pagination.orderDirection === direction;
  }

  toggleShowFilter(by: string): void {
    if (this.visibleFilters.has(by)) {
      this.visibleFilters.delete(by);
    } else {
      this.visibleFilters.add(by);
    }
  }

  isFilterVisible(by: string): boolean {
    return this.visibleFilters.has(by);
  }

  updateFilter(event, by: string) {
    let heading = this.columnHeadings.find(h => h.sortBy === by);
    if (!heading) return;

    let anyValue = [''];
    if (heading.isSelect && this.getAnyValue) {
      let customAnyValue = this.getAnyValue(by);
      if (customAnyValue) {
        anyValue.push(customAnyValue);
      }
    }

    if (anyValue.includes(event.target.value)) {
      this.filters.delete(by);
      if (this.filterCleanup) {
        let fieldsToDelete = this.filterCleanup(by);
        fieldsToDelete.map(field => {
          this.filters.delete(field);
        })
      }
    } else {
      let heading = this.columnHeadings.find(h => h.sortBy === by);
      if (heading) {
        this.filters.set(heading.sortBy, {
          field: heading.sortBy,
          condition: this.defaultFilterCondition,
          value: event.target.value,
        });
      }
      if (this.filterUpdater) {
        this.filterUpdater(by, event.target.value, this.filters);
      }
    }

    if (this.filterThrottle !== null) {
      clearTimeout(this.filterThrottle);
    }
    this.filterThrottle = setTimeout(() => {
      this.pagination.filters = [...this.filters.values()];
      this.pagination.count = undefined;
      this.pagination.skip = 0;
      this.updateTable();
      this.filterThrottle = null;
    }, 500);

  }

  getFilterValue(field: string): string {
    const filter = this.filters.get(field) as Filter;
    let value = '';
    if (filter) {
      if (Array.isArray(filter)) {
        value = filter[0].value;
      } else {
        value = String(filter.value);
      }
    }
    return value;
  }

  isHeadingValueComplex(sortBy: string, row: T): boolean {
    if (!this.getDisplay) return false;
    let display = this.getDisplay(sortBy, row);
    return this.isDisplayComplex(display);
  }

  isDisplayComplex(display: string | TextDisplay): boolean {
    return display != null && typeof display !== 'string' && typeof display != 'number';
  }

  getHeadingValueForRow(sortBy: string, row: T): string {
    if (this.getDisplay) {
      let display = this.getDisplay(sortBy, row);

      if (display == null || display == undefined) return '';

      return display.toString();
    }
    return '';
  }

  getComplexHeadingValuesForRowCol(row: number, col: number): TextDisplay {
    return this.displays[row][col] as TextDisplay;
  }

  getTagForRow(sortBy: string, row: T): Tag {
    if (this.getTag) {
      return this.getTag(sortBy, row);
    }
    return null;
  }

  getTagColorForRow(sortBy: string, row: T): string {
    let tag = this.getTagForRow(sortBy, row);
    if (tag) {
      return tag.color;
    }
    return `white`;
  }

  getTagTextForRow(sortBy: string, row: T): string {
    let tag = this.getTagForRow(sortBy, row);
    if (tag) {
      return tag.text;
    }
    return '';
  }

  getColumnWidth(column: number): string {
    if (column >= this.columnWidths.length) {
      return '100px';
    }
    return `${this.columnWidths[column]}px`;
  }

  getTotalWidth(): string {
    if (this.columnWidths.length === 0) return '0px';
    return `${sum(this.columnWidths)}px`;
  }

  onPaginationChange() {
    this.updateTable();
  }

  filterInitValue(field: string): string | number {
    let filter = this.filters.get(field);
    if (filter) {
      if (this.getFilterInitValue) {
        return this.getFilterInitValue(filter);
      } else {
        return filter.value;
      }
    } else {
      return ''
    }
  }

  onSelectAllRowsChange() {
    this.rows.map(r => (r as Data)._checked = this.selectAll);
    this.emitSelectedRows();
  }

  onSelectedRowChange(row: T) {
    this.emitSelectedRows();
  }

  private emitSelectedRows() {
    let selectedRows = this.rows.filter(r => r._checked);
    this.select.emit(selectedRows);
  }

  getInputClass(by: string): string {
    return `input-${by.replace(' ', '-')}`;
  }

  forceFilter(by: string, value: string) {
    let heading = this.columnHeadings.find(h => h.sortBy === by);
    if (!heading) return;

    let inputClass = this.getInputClass(by);
    let elementCollection = document.getElementsByClassName(inputClass);
    if (elementCollection.length === 0) return;

    let element: HTMLInputElement | HTMLSelectElement;
    if (heading.isSelect) {
      element = elementCollection.item(0) as HTMLSelectElement;
    } else {
      element = elementCollection.item(0) as HTMLInputElement;
    }

    element.value = value;
  }

  onExport() {
    this.export.emit();
  }

  getStyleForRow(row: T, index: number): Style {
    if (this.getRowStyle) {
      return this.getRowStyle(row, index);
    }
    return {};
  }

  onToggleChange() {
    this.toggleValue = !this.toggleValue;
    this.toggle.emit(this.toggleValue);
  }

  getCurrentRows(): T[] {
    return this.rows;
  }

  getCurrentRow(rowIndex?: number): T {
    return this.rows[rowIndex];
  }

  private processZebraStyling() {
    let isOdd: boolean;
    let currValue: any;
    let currIndex: number = 0;
    return this.rows.map(row => {
      if (row[this.zebraField] !== currValue) {
        currValue = row[this.zebraField];
        currIndex = 0;
        isOdd = !isOdd;
      } else {
        ++currIndex;
      }
      (row as Data)._isOdd = !isOdd;
      (row as Data)._isFirstRow = currIndex === 0
      return row
    })
  }

}
