import { PubSubTypes } from "../element-render-frame/pubsub/types";
import { IContentElementImage } from "../element-render-image/model";
import { McqDisplay } from "../element-render-mcq/model";
import { Direction, IContentElement, IEntryState, IEntryStateScored, IScoredResponse } from "../models";


export enum CustomInteractionType {
    GRID_DND          = "GRID_DND",
    GRID_FILL         = 'GRID_FILL',
    GRID_SCATTER_PLOT = 'GRID_SCATTER_PLOT',
    GROUPING          = 'GROUPING',
    PIE_CHART         = 'PIE_CHART',
    ROTATE_SPRITE     = "ROTATE_SPRITE",
    SCALE_RADIUS      = "SCALE_RADIUS",
    SLIDER            = "SLIDER",
  }

export interface IContentElementCustomInteraction extends IContentElement, IScoredResponse {
    interactionType: CustomInteractionType;
    canvasWidth: number;
    canvasHeight: number;
}
  
export interface IContentElementScaleRadius extends IContentElementCustomInteraction {
    scale: number;
    img: IContentElementImage;
    decimalRound: number;
    answer: number;
    // initialRadius: number,
    // minRadius: number,
    // maxRadius: number,
    // scale: number,
}
export interface IContentElementRotateSprite extends IContentElementCustomInteraction {
    rotateSpriteanswer;
    rotateSpriteBgImage?: IContentElementImage;
    rotateSpriteImage?: IContentElementImage;
    scale: number;
    scaleBg: number;
    spriteAnchorPoint: string;
    spriteBgAnchorPoint: string;
    spriteBgHeight: number;
    spriteBgWidth: number;
    spriteBgX: number;
    spriteBgY: number;
    spriteHeight: number;
    spriteWidth: number;
    spriteX: number;
    spriteY: number;
}
  
export interface IContentElementSlider extends IContentElementCustomInteraction {
    bigNotchMod?: number;
    bigNotchScale?: number;
    fontSize: number;
    frameRotate?: number;
    frameScale?: number;
    frameType?: PubSubTypes
    isAllowMcqInteraction: boolean;
    isAllowTargetFrame?:boolean;
    isVertical?: boolean;
    mapRangetoMcqOptionArray?: number[]
    maxCorrectValueRange?: number[]
    maxValue: number;
    mcqId?: number;
    mcqType?: McqDisplay;
    minCorrectValueRange?: number[]
    minValue: number;
    notchLength: number;
    notchThickness: number;
    numNotches: number;
    padding: number;
    showBigNotches: boolean;
    skipFirstText: boolean;
    sliderImage?: IContentElementImage;
    sliderOffset: number; //x offset for vertcal, y offset for horizontal
    sliderScale: number;
    snapAmount: number;
    startingValue: number;
    switchTextSide: boolean;
    targetFrameId?:number;
    textMod: number;
    textPadding: number;
    unit?: string;
}
  
export interface IContentElementCartesianPlane extends IContentElementCustomInteraction {
    axisLabelFontSize: number;
    displayCoordsForDrags?: boolean;
    draggableSide?: Direction;
    enableDrags?: boolean;
    incDistanceBetweenLabelsX?: number;
    incDistanceBetweenLabelsY?: number;
    maxX?: number;
    maxY?: number;
    minX?: number;
    minY?: number;
    options?: IContentElementCartesianPlaneOption[];
    snapDragsOnGrid?: boolean;
    thicknessOfAxis?: number;
    thicknessOfLines?: number
    xAxisLabel: string;
    xGap: number;
    xInc: number;
    xLength: number;
    yAxisLabel: string;
    yGap: number;
    yInc: number;
    yLength: number;
}
  
export interface IContentElementCartesianPlaneOption {
    correctX?: number;
    correctY?: number;
    fontSize?: number;
    gap?: number;
    id: string;
    img: IContentElementImage;
    label?: string;
    length?: number;
    width?: number;
}


export interface IEntryStateCustomInteraction extends IEntryState, IEntryStateScored {
    subtype: CustomInteractionType;
}

export interface IEntryStateScaleRadius extends IEntryStateCustomInteraction {
    radius: number;
    xCursor: number;
    yCursor: number;
    userInteracted: boolean;
    userScaledRadius: boolean;
}
  
export interface IEntryStateSlider extends IEntryStateCustomInteraction {
    value: number;
    userInteracted?:boolean;
    userMovedSlider?: boolean;
}
  
export interface IEntryStateRotateSprite extends IEntryStateCustomInteraction {
    value: number;
    rotation: number;
}
export interface IEntryStateCartesianPlane extends IEntryStateCustomInteraction {
    optionStates: any[]
}

export const customInteractionEditInfo = {
    editExcludeFields: {}
}

customInteractionEditInfo.editExcludeFields[CustomInteractionType.SLIDER] = ['sliderImage'];
customInteractionEditInfo.editExcludeFields[CustomInteractionType.SCALE_RADIUS] = ['img'];
customInteractionEditInfo.editExcludeFields[CustomInteractionType.ROTATE_SPRITE] = ['rotateSpriteImage', 'rotateSpriteBgImage'];
customInteractionEditInfo.editExcludeFields[CustomInteractionType.GRID_DND] = ['options'];
