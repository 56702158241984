<fieldset [disabled]="isReadOnly()">
    <label>
        <input type="checkbox" [(ngModel)]="element.isIconDisabled">
        <tra slug="auth_hide_icon"></tra>
    </label>
    <div>
        <tra slug="auth_caption"></tra> <input type="text" class="input is-small" [formControl]="caption">
    </div>
</fieldset>
<div *ngIf="element.thumbNail" >
    <tra slug="auth_thumbnail"></tra> <capture-image [element]="element.thumbNail"></capture-image>
    <asset-library-link [element]="element.thumbNail"></asset-library-link>
</div>
<fieldset>
    <div>
        <tra slug="auth_reading_passage"></tra> <input type="text" class="input is-small" [formControl]="readerId">
    </div>
    <div>
        <tra slug="auth_element_target"></tra> <input type="text" class="input is-small" [formControl]="readerElementId">
    </div>
</fieldset>