<div class="block-container"
  [class.is-horizontal]="isHorizontal"
  [class.is-vertical]="!isHorizontal"
>
  <div 
    *ngFor="let option of arr" 
    class="block" 
    [class.is-selected]="isOptionSelected(option)"
    (click)="selectOption(option)"
  >
    <div *ngIf="option.imageUrl" class="block-image" [class.is-large-image]="isLargeImage">
      <img src="{{option.imageUrl}}">
    </div>
    <div class="block-content">
      <div class="block-caption">
        {{option.caption}}
      </div>
      <div class="block-body">
        {{option.body}}
      </div>
    </div>
    <div class="block-checkmark scale-in-bl">
      <i class="fa fa-check-circle" aria-hidden="true"></i>
    </div>
  </div>
</div>
