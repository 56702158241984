import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { District } from 'src/app/bc-accounts/bc-accounts.service';

@Component({
  selector: 'bc-search-dropdown',
  templateUrl: './bc-search-dropdown.component.html',
  styleUrls: ['./bc-search-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BcSearchDropdownComponent implements OnInit {
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() inputValue: any;
  @Input() itemDisplayFn: (item: any) => string;
  @Input() filterFn: (value: any) => any[];
  @Output() itemSelected = new EventEmitter();
  autoCompleteFC = new FormControl();
  filteredItems: Observable<District[]>;

  private searchInputAttached = false;

  constructor() {
    this.autoCompleteFC.setValue(this.inputValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputValue) {
      // Must do this twice for ngx-autosize-input to pick up the change
      this.autoCompleteFC.setValue(this.inputValue);
      setTimeout(() => {
        this.autoCompleteFC.setValue(this.inputValue);
      }, 0);
    }
    if (changes.disabled) {
      changes.disabled.currentValue ? this.autoCompleteFC.disable() : this.autoCompleteFC.enable();
    }
  }

  private attachSearchInput() {
    if (!this.searchInputAttached) {
      this.filteredItems = this.autoCompleteFC.valueChanges.pipe(
        startWith(''),
        map(value => this.filterItems(value))
      );
      this.searchInputAttached = true;
    }
  }

  private detachSearchInput() {
    this.filteredItems = new BehaviorSubject('').pipe(
      startWith(''),
      map(value => this.filterItems(value))
    );
    this.searchInputAttached = false;
  }

  ngOnInit(): void {
    this.autoCompleteFC.valueChanges.subscribe(() => {
      this.attachSearchInput()
    })
  }

  public selectItem = (event) => {
    this.itemSelected.emit(event)
  }

  private filterItems(value: any): any[] {
    return this.filterFn(value);
  }

  public searchPopupOpened() {
    this.detachSearchInput()
  }

}
