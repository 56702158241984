import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { DEFAULT_TICK_MEASURE, TICK_LEVEL, GAP_THRESHOLDS } from './constants'

export enum TOOLS {
  RULER = "RULER",
  PROTRACTOR = "PROTRACTOR"
}

export class MeasuringTools extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  canvasGr: PIXI.Graphics;
  ruler: PIXI.Graphics;
  rulerAnchorA: PIXI.Graphics;
  rulerAnchorB: PIXI.Graphics;
  protractor: PIXI.Graphics;
  protractorAnchor: PIXI.Graphics;
  protractorTick: PIXI.Graphics;
  tick: PIXI.Graphics;
  rotateSprite: PIXI.Sprite;
  dragPosOffset: PIXI.Point;
  isDragging: boolean;
  value: number;
  rotation: number;
  speed: number;
  maxRadius: number;
  cX: number;
  cY: number;
  radius: number;
  dx: number;
  dy: number;
  isResponded: boolean;

  // Initialize config variables
  rectRadius = 3;
  rulerPadding = 10;
  toolColor = 0xd6d5dc;
  // toolDetailColor = 0x8c8b95;
  toolDetailColor = 0x0000;
  toolDetailColorLight = 0x777777;

  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,    
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.element = element;
    this.spriteLoader = new SpriteLoader();
    
    // Initialize config variables

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    //setup stage
    PIXI.settings.ROUND_PIXELS = true;

    // Initialize objects
    this.protractor = new PIXI.Graphics();
    this.protractorTick = new PIXI.Graphics();
    this.protractorAnchor = new PIXI.Graphics();

    // Render objects
    this.stage.addChild(this.protractor);
    this.protractor.addChild(this.protractorTick);
    this.protractor.addChild(this.protractorAnchor);

    // Separated listeners
    this.addDragAndRotateListener(this.protractor);
    this.addProtRotateListener(this.protractorAnchor);

    // Render scene
    this.render();
  }

  protractorRadius: number;
  renderProtractor(x = this.element.protractorX, y = this.element.protractorY, radius = this.protractorRadius) {
    this.protractorRadius = this.element.protractorRad;

    this.protractor.beginFill(this.toolColor, 0.75);
    this.protractor.arc(x, y, radius,Math.PI, 0); // cx, cy, radius, startAngle, endAngle
    this.protractor.drawRect(x-radius, y, (radius*2), 8);
    this.protractor.endFill();
    this.protractor.interactive = true;
    this.protractor.cursor = 'grab';
    this.protractor.pivot.set(x, y);
    this.protractor.position.set(x, y);
    this.protractor.zIndex = 10;

    // Render details
    this.renderProtractorTicks(x, y, radius)
    this.renderProtRotationAnchor(x, y, radius)
  }
  renderProtractorTicks(x: number, y: number, radius: number) {
    const tickOffset = 10
    const tickThickness = 1;
    const angleXOffset = 20;
    const oppAngleXOffset = 30;
    const textMargin = 10;
    const mediumLongLineLength = radius - tickOffset - oppAngleXOffset - textMargin
    const smallLongLineLength = radius - tickOffset - angleXOffset - textMargin
    const shortLineX = x + radius - angleXOffset + 2;
    const shortLineLength = (x+radius) - shortLineX
    const small = 75
    const medium = 120 

    this.protractorTick.pivot.set(x,y-1+(tickThickness/2));
    this.protractorTick.position.set(x,y-1);

    for(let i = 0; i<=180; i++) {
      let tick = new PIXI.Graphics();
      tick.beginFill(this.toolDetailColor, 0.9);
      
      if(i%10 == 0) { // 10ths
        if(radius >= medium || (radius < medium && i%30 == 0)) {
          const angle = new PIXI.Text((i).toString(), {fontSize: 8});
          angle.x = x + radius - angleXOffset
          angle.y = y-(angle.width / 2)
          angle.style.fill = this.toolDetailColor
          angle.rotation = this.getRadians(90);

          if(radius >= small) {
            const oppAngle = new PIXI.Text((180-i).toString(), {fontSize: 6});
            oppAngle.x = x + radius - oppAngleXOffset
            oppAngle.y = y-(oppAngle.width / 2)
            oppAngle.style.fill = this.toolDetailColor
            oppAngle.rotation = this.getRadians(90);
            tick.addChild(oppAngle);
          }

          tick.addChild(angle);
        }
        if(i%90 == 0) {
          tick.drawRect(x,y-1, (radius >= small ? mediumLongLineLength : smallLongLineLength) + tickOffset, tickThickness);
        } else {
          tick.drawRect(x + tickOffset,y-1, (radius >= small ? mediumLongLineLength : smallLongLineLength), tickThickness);
        }
        tick.drawRect(shortLineX, y-1, shortLineLength, tickThickness);
      } else if(i%5 == 0) {
        tick.drawRect(x + radius - (shortLineLength / 1.25), y-1, shortLineLength / 1.25, tickThickness);
      } else if(radius >= small) {
        tick.drawRect(x + radius - (shortLineLength / 2), y-1, shortLineLength / 2, tickThickness);
      }

      tick.endFill();
      tick.pivot.set(x,y-1+(tickThickness/2));
      tick.position.set(x,y);
      tick.rotation = -(this.getRadians(i))

      this.protractorTick.addChild(tick);
      
    }
  }

  renderProtRotationAnchor(x: number, y: number, radius: number) {
    this.protractorAnchor.beginFill(0x0000, 0.9);
    this.protractorAnchor.drawCircle(x + (radius), y, 6);
    this.protractorAnchor.endFill();
    this.protractorAnchor.position.set(x + (radius), y);
    this.protractorAnchor.pivot.set(x + (radius), y);
    this.protractorAnchor.cursor = 'ew-resize'
    this.protractorAnchor.interactive = true;
  }

  clearProtractor() {
    this.protractor.clear();
    this.protractorAnchor.clear();
    while(this.protractorTick.children[0]) { 
      this.protractorTick.removeChild(this.protractorTick.children[0]);
    }
  }

  initRuler() {
    this.ruler = new PIXI.Graphics();
    this.rulerAnchorA = new PIXI.Graphics();
    this.rulerAnchorB = new PIXI.Graphics();
    this.tick = new PIXI.Graphics();
  }

  rulerOutterBounds: PIXI.Graphics;
  renderRuler() {
    this.initRuler()
    // Ruler graphic
    this.ruler.beginFill(this.toolColor, 0.75);
    this.ruler.drawRoundedRect(this.element.rulerX, this.element.rulerY, (100*10), 46 * (this.element.scale || 1), this.rectRadius);
    this.ruler.pivot.set(this.element.rulerX + this.rulerPadding, this.element.rulerY);
    this.ruler.position.set(this.element.rulerX, this.element.rulerY);
    this.ruler.endFill();
    this.ruler.interactive = true;
    this.ruler.cursor = 'grab';
    this.ruler.zIndex = 10;
    this.stage.addChild(this.ruler)

    // Mask
    this.rulerOutterBounds = new PIXI.Graphics();
    this.rulerOutterBounds.beginFill(this.toolDetailColor, 0.5);
    this.rulerOutterBounds.drawRoundedRect(this.element.rulerX, this.element.rulerY-this.pivotRadius, this.minRulerWidth, this.ruler.height+this.pivotRadius, this.rectRadius);
    this.rulerOutterBounds.endFill();
    this.rulerOutterBounds.pivot.set(this.element.rulerX, this.element.rulerY);
    this.rulerOutterBounds.position.set(this.element.rulerX, this.element.rulerY);
    this.ruler.addChild(this.rulerOutterBounds);
    this.ruler.mask = this.rulerOutterBounds;

    // Render details
    this.renderTicks();
    this.renderRulerRotationAnchors();

    // Add listeners
    this.addDragAndRotateListener(this.ruler);
    this.addRulerRotateListener(this.rulerAnchorA);
  }

  shouldDrawTick(i:number, gap:number, tickLevel:TICK_LEVEL){
    if (tickLevel === TICK_LEVEL.MAJOR){
      if ((i%50 != 0) && (gap < GAP_THRESHOLDS.B)){
        return false;
      }
    }
    if (tickLevel === TICK_LEVEL.MID){
      if (gap < GAP_THRESHOLDS.B){
        return false;
      }
    }
    if (tickLevel === TICK_LEVEL.MINOR){
      if (gap < GAP_THRESHOLDS.B){
        return false;
      }
    }
    return true;
  }

  renderTicks() {
    const gap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;
    const isShortGap = (gap <= GAP_THRESHOLDS.A)
    const isShortGap_lvl2 = (gap <= 1)
    let mmHeight = 10;
    let halfCmHeight = 20;
    let cmHeight = 25;
    const maxMeasure = this.element.rulerWidth; //cm
    if (isShortGap){
      mmHeight = 5;
      halfCmHeight = 12;
    }

    this.ruler.addChild(this.tick);
    this.tick.beginFill(this.toolDetailColor);

    for(let i=0; i<=maxMeasure * (10); i++) {
      if(i%10 == 0) {
        if (this.shouldDrawTick(i, gap, TICK_LEVEL.MAJOR)){
          const number = new PIXI.Text((i/10).toString(), {fontSize: 10});
          number.x = this.element.rulerX + i*gap + this.rulerPadding - 2
          number.y = this.element.rulerY + 30
          number.style.fill = this.toolDetailColor
          this.ruler.addChild(number);
          this.tick.drawRect(this.element.rulerX + i*gap + this.rulerPadding, this.element.rulerY, 1, cmHeight);
        }
      } 
      else if(i%5 == 0) {
        if (this.shouldDrawTick(i, gap, TICK_LEVEL.MID)){
          this.tick.drawRect(this.element.rulerX + i*gap + this.rulerPadding, this.element.rulerY, 1, halfCmHeight);
        }
      } 
      else {
        if (this.shouldDrawTick(i, gap, TICK_LEVEL.MINOR)){
          if (isShortGap){
            this.tick.beginFill(this.toolDetailColorLight);
          }
          this.tick.drawRect(this.element.rulerX + i*gap + this.rulerPadding, this.element.rulerY, 1, mmHeight);
        }
      }
      this.tick.beginFill(this.toolDetailColor);
    }
    this.tick.endFill();
  }

  pivotRadius = 6
  renderRulerRotationAnchors() {
    const pivotRadius = 6
    this.ruler.addChild(this.rulerAnchorA);
    this.rulerAnchorA.beginFill(0x0000);
    this.rulerAnchorA.drawCircle(this.rulerOutterBounds.getLocalBounds().right-(pivotRadius * 2), this.element.rulerY, pivotRadius);
    this.rulerAnchorA.interactive = true;
    this.rulerAnchorA.cursor = 'ew-resize';
    this.rulerAnchorA.endFill();
  }

  // Listeners
  addProtRotateListener(obj: PIXI.Graphics, isStretch = true) {
    const minRadius = 65;
    const onDragStart = (e) => {
      this.isProtRotateDragging = true;
    }
    const onDragEnd = (e) => {
        this.isProtRotateDragging = false;
    }
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(this.isProtRotateDragging) {
          const mousePosition = e.data.getLocalPosition(this.stage);
          const tempX = this.protractor.x;
          const tempY = this.protractor.y;

          if(isStretch) {
            const distanceX = Math.pow(mousePosition.x - this.protractor.x, 2);
            const distanceY = Math.pow(mousePosition.y - this.protractor.y, 2);
            const radius = Math.sqrt(distanceX + distanceY)
            this.protractorRadius = radius >= minRadius ? radius : minRadius;
            this.clearProtractor();
            this.renderProtractor(tempX, tempY, this.protractorRadius);
          }
          
          const rotate = Math.atan2(this.protractor.y - mousePosition.y, this.protractor.x - mousePosition.x) + Math.PI;
          if(this.getDegrees(rotate) <= 2 || this.getDegrees(rotate) >= 358) {
            this.protractor.rotation = -this.getRadians(0);
          } else if (this.getDegrees(rotate) <= 182 && this.getDegrees(rotate) >= 178) {
            this.protractor.rotation = -this.getRadians(180);
          } else {
            this.protractor.rotation = rotate;
          }

          this.render();
        }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove)
  }

  minRulerWidth = 125;
  
  addRulerRotateListener(obj: PIXI.Graphics, isStretch = true) {  
    const onDragStart = (e) => {
      this.isRulerRotateDragging = true;
    }
    const onDragEnd = (e) => {
        this.isRulerRotateDragging = false;
    }
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(this.isRulerRotateDragging) {
          const mousePosition = e.data.getLocalPosition(this.stage);
          const rotation = Math.atan2(this.ruler.y - mousePosition.y, this.ruler.x - mousePosition.x) + Math.PI;

          if(this.getDegrees(rotation) <= 1 || this.getDegrees(rotation) >= 359) {
            this.ruler.rotation = this.getRadians(0);
          } else {
            this.ruler.rotation = rotation;
          }

          if(isStretch) {
            const distanceX = Math.pow(mousePosition.x - this.ruler.x, 2);
            const distanceY = Math.pow(mousePosition.y - this.ruler.y, 2);
            const rightBounds = this.tick.width + (this.rulerPadding * 2)

            if(this.rulerOutterBounds.width >= this.minRulerWidth && this.rulerOutterBounds.width <= rightBounds) {
              this.rulerOutterBounds.width = Math.sqrt(distanceX + distanceY) + (this.pivotRadius * 4)
            } 
            if(this.rulerOutterBounds.width < this.minRulerWidth) {
              this.rulerOutterBounds.width = this.minRulerWidth;
            }
            if(this.rulerOutterBounds.width > rightBounds) {
              this.rulerOutterBounds.width = rightBounds;
            }
            this.rulerAnchorA.x = this.rulerOutterBounds.width - this.minRulerWidth
          }

          this.render();
        }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove)
  }

  isRulerMode = false;
  toggleRuler(mode?: boolean) {
    this.isRulerMode = mode == null ? !this.isRulerMode : mode;

    if(this.isRulerMode) {
      this.renderRuler();
      this.render();
    } else{
      this.ruler.destroy();
      this.render();
    }

    return this.isRulerMode;
  }

  isProtractorMode = false;
  toggleProtractor(mode?: boolean) {
    this.isProtractorMode = mode == null ? !this.isProtractorMode : mode;
    if(this.isProtractorMode) {
      this.renderProtractor(this.element.protractorX, this.element.protractorY, this.element.protractorRad)
      this.render();
    } else{
      this.clearProtractor();
      this.render();
    }
    
    return this.isProtractorMode;
  }


  loadAssets = () => {
    return null;
  }
}
