import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

export type FilterToggles = IFilterToggle[]

export interface IFilterToggle {
  id: string,
  caption: string,
  value: boolean,
  assessmentDefId?: number
}

@Component({
  selector: 'filter-toggles',
  templateUrl: './filter-toggles.component.html',
  styleUrls: ['./filter-toggles.component.scss']
})
export class FilterTogglesComponent implements OnInit {

  @Input() state:FilterToggles;
  @Input() caption = 'lbl_filter_to';
  @Output() id = new EventEmitter();
  @Output() assessmentDefId = new EventEmitter();
  @Output() change = new EventEmitter();

  constructor() { }


  ngOnInit(): void {
    if (this.state){
      this.id.emit( this.getActiveToggleId() );
    }
  }

  isSelected(toggle:IFilterToggle){
    let activeToggle = this.getActiveToggle();
    if (activeToggle === toggle){
      return true;
    }
    return false;
  }

  getActiveToggleId(){
    let activeToggle = this.getActiveToggle();
    if (activeToggle){
      return activeToggle.id;
    }
  }

  getActiveToggleAssessmentDefId(){
    const activeToggle = this.getActiveToggle();
    if (activeToggle){
      return activeToggle.assessmentDefId;
    }
  }

  getActiveToggle(){
    let activeToggle:IFilterToggle;
    this.state.forEach(toggle => {
      if (toggle.value){
        activeToggle = toggle;
      }
    })
    return activeToggle;
  }

  selectToggle(toggle:IFilterToggle){
    let activeToggle = this.getActiveToggle();
    if (activeToggle === toggle){
      toggle.value = false;
    }
    else{
      if (activeToggle){
        activeToggle.value = false;
      }
      toggle.value = true;
    }
    this.id.emit( this.getActiveToggleId() );
    this.assessmentDefId.emit( this.getActiveToggleAssessmentDefId() );
  }

}
