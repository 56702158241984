import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { DEFAULT_TICK_MEASURE } from './constants'
import { OnInit } from "@angular/core";
import { IExtendedTools } from "../element-render-virtual-tools.component";
import { ACTIONS } from "./table";

export interface ITableData {
    id: number,
    input: string,
    isInited: boolean,
    x?: number,
    y?: number,
    width?: number,
    height?: number,
    isRevoked?: boolean
  }
export class TextBox extends VirtualTools{
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  hcPrevState: boolean;
  staticBg: PIXI.Graphics;  
  textBoxOutlines: PIXI.Graphics[][] = [];
  isTextBoxSelected: boolean = false;
  activeTextBoxData: ITableData = null;
  activeText: PIXI.Text = null;
  cellHeight = 30;
  cellWidth = 60;
  tableMenus: PIXI.Graphics[] = [];
  textBoxesContainer: PIXI.Graphics;
  singleTextBoxData: ITableData[] = [];
  singleTextboxes: PIXI.Graphics[] = [];
  deleteSprite: PIXI.Sprite;
  cellIdCounter: number;

  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()
    this.hcPrevState = this.textToSpeech.isHiContrast;  

    this.element = element;
    this.isGlobalRotating = isGlobalRotating;
    this.isGlobalDragging = isGlobalDragging;

    this.spriteLoader = new SpriteLoader();

    this.deleteSprite = new PIXI.Sprite();
    this.textBoxesContainer = new PIXI.Graphics();

    this.deselectAllTextBoxListener();

    this.addTextEventListener();

    this.addGraphic(this.textBoxesContainer);

    this.loadAssets().then(this.initSprites);
  }

  initSprites = ({resources}) => {
    this.deleteSprite.texture = resources.delete.texture;
  }

  createTextBox(x: number, y: number, color: number, tableContainer: PIXI.Graphics, textBoxData: ITableData, isStandAlone?: boolean) {
    const createOutline = (outline: PIXI.Graphics, color: number, width: number, height: number) => {
      outline.lineStyle(1, color, 1);
      outline.lineTo(width, 0);
      outline.lineTo(width, height);
      outline.lineTo(0, height);
      outline.lineTo(0, 0);
      outline.closePath();
    }
    const cell = new PIXI.Graphics();
    const outline = new PIXI.Graphics();
    const selectOutline = new PIXI.Graphics();

    if(isStandAlone && (!textBoxData || textBoxData == null)) {
        const defaultData = {id: this.singleTextBoxData.length, active: false, input: '', x, y, width: this.cellWidth, height: this.cellHeight, isRevoked: false};
        this.singleTextBoxData.push(JSON.parse(JSON.stringify(defaultData)));
        textBoxData = this.singleTextBoxData[this.singleTextBoxData.length-1];
    }

    const text = new PIXI.Text(textBoxData.input, {fontSize: 14});
    text.style.fill = 0x0000;
    const padding = (this.cellHeight - 14)/2
    text.y = padding;
    text.x = padding;

    if(text.width < this.cellWidth-padding){
      textBoxData.width = this.cellWidth;
    } else if(text.width > textBoxData.width-(padding * 2) || 
      (text.width > this.cellWidth && text.width < textBoxData.width-(padding * 2))
    ) {
      textBoxData.width = text.width + (padding * 2);
    }

    if(text.height > (textBoxData.height-(padding*2)) || 
      (text.width > this.cellHeight && text.height < textBoxData.height-(padding * 2))
    ) {
      textBoxData.height = text.height + (padding * 2);
    }

    cell.beginFill(color, 0.000001);
    cell.drawRect(0,0, textBoxData.width || this.cellWidth, textBoxData.height || this.cellHeight);
    cell.interactive = true;
    cell.cursor = 'text';
    cell.endFill();

    cell.addChild(text);
    cell.x = x;
    cell.y = y;

    createOutline(outline, 0xbbbbbb, textBoxData.width || this.cellWidth, textBoxData.height || this.cellHeight);
    createOutline(selectOutline, 0x000000, textBoxData.width || this.cellWidth, textBoxData.height || this.cellHeight);
    outline.alpha = isStandAlone && textBoxData.input.length > 0 ? 0 : 1;
    selectOutline.alpha = 0;

    cell.addChild(outline);
    cell.addChild(selectOutline);
    this.textBoxOutlines.push([selectOutline]);

    this.selectTextBoxListener(cell, text, textBoxData, this.textBoxOutlines.length-1);

    if(isStandAlone) {
        this.TextBoxMenuListener(cell, textBoxData.id);
        this.textBoxDragListener(cell, textBoxData.id);
        this.textBoxesContainer.addChild(cell);
        this.singleTextboxes.push(cell);
        // outline.alpha = 0;
        this.render();
    } else {
        tableContainer.addChild(cell);
    }
  }

  drawTextBoxes() {
    this.singleTextboxes.forEach((textbox, i) => {
        this.singleTextboxes[i].destroy();
    });
    this.singleTextboxes = [];

    this.singleTextBoxData.forEach((textbox) => {
      if(!textbox.isRevoked){
        this.createTextBox(textbox.x, textbox.y, 0xffffff, this.textBoxesContainer, textbox, true);
      }
    })

    this.render();
  }

  addTextEventListener() {
    // global active text and global actiev textboxdata
    const keyDown = (e) => {
      if(this.activeTextBoxData != null && this.activeText != null) {
        if(e.keyCode == 32 && e.target == document.body) {
          e.preventDefault();
        } 
        
        if(e.keyCode == 8) {
          this.activeTextBoxData.input = this.activeTextBoxData.input.slice(0, this.activeTextBoxData.input.length - 1);
        } else if(e.keyCode == 13) {
          this.activeTextBoxData.input += '\n';
        } else {
          this.activeTextBoxData.input += e.key.length <= 1 ? e.key : '';
        }

        this.drawTextBoxes();
        this.drawText(this.activeText, this.activeTextBoxData.input)
      }
    }

    window.addEventListener("keydown", keyDown.bind(this));
  }

  drawText(text: PIXI.Text, input: string) {
    text.text = input;
    this.render();
  }

  drawTextboxMenu(container: PIXI.Graphics, textBoxDataIndex: number) {
    const menu = new PIXI.Graphics();
    const menuYOffset = 10;
    const menuHeight = 30;
    const menuWidth = 35;
    menu.beginFill(0x333c42);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.y = container.height + menuYOffset;
    menu.endFill();
    menu.interactive = false;

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = 10;
    del.interactive = true;

    menu.addChild(del);
    this.addMenuListeners(container, ACTIONS.DELETE, del, textBoxDataIndex);
    
    this.tableMenus.push(menu);
    container.addChild(menu);
    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, textBoxDataIndex?: number) {
    let func;
    option.cursor = 'pointer';
  
    if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.singleTextBoxData[textBoxDataIndex].isRevoked = true;
        this.drawTextBoxes();
      }
    }
    option.on('pointerup', func);
  }

  TextBoxMenuListener(textBox: PIXI.Graphics, textBoxDataIndex: number) {
    const clearMenus = () => {
      this.tableMenus.forEach((v, i) => {
        this.tableMenus[i].destroy();
      })
      this.tableMenus = [];
    }
    let menu: PIXI.Graphics;
    const selectTable = () => {
        clearMenus();
        menu = this.drawTextboxMenu(textBox, textBoxDataIndex);
        this.render();
    }

    textBox.on('pointerdown', selectTable)
  }

  selectTextBoxListener(obj: PIXI.Graphics, text: PIXI.Text, textBoxData: ITableData, outlineIndex: number) {
    const deselect = () => {
      this.activeText = null;
      this.activeTextBoxData = null;
      this.textBoxOutlines.forEach((v, i) => {
        this.textBoxOutlines[i][0].alpha = 0;
      })
    }
    const selectText = () => {
      this.isTextBoxSelected = true;
      deselect();
      this.activeText = text;
      this.activeTextBoxData = textBoxData;
      this.textBoxOutlines[outlineIndex][0].alpha = 1;
      this.render();
    }
    obj.on('pointerdown', selectText);
  }

  deselectAllTextBoxListener() {
    const deselectAll = () => {
      if(!this.isTextBoxSelected) {
        this.activeText = null;
        this.activeTextBoxData = null;

        this.textBoxOutlines.forEach((v, i) => {
          this.textBoxOutlines[i][0].alpha = 0;
        })
        this.tableMenus.forEach((v, i) => {
          this.tableMenus[i].clear();
          this.tableMenus[i].removeChildren();
        })
        this.tableMenus = [];
        this.render();
      } else {
        this.isTextBoxSelected = false;
      }
    }

    this.stage.on('pointerup', deselectAll);
  }

  textBoxDragListener(obj: PIXI.Graphics, textboxIndex: number) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    obj.cursor = 'grab';
    obj.interactive = true;
    
    const onDragStart = (e) => {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragging = true;
        initialDiffX = mousePosition.x - obj.x
        initialDiffY = mousePosition.y - obj.y
        obj.cursor = 'grabbing';
    }
    const onDragEnd = (e) => {
        isDragging = false;
        obj.cursor = 'grab';

        this.singleTextBoxData[textboxIndex].x = obj.x;
        this.singleTextBoxData[textboxIndex].y = obj.y;
    }
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging) {
            this.isGlobalDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            obj.x = mousePosition.x - initialDiffX; // obj x = 3
            obj.y = mousePosition.y - initialDiffY;

            this.render();
        } else if(!isDragging) {
          this.isGlobalDragging = false;
        }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove);
  }

  loadAssets = () => {
    let assets = [];
    assets.push({
      name: "delete", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png"
    });

    this.spriteLoader.addSpritestoLoader(assets);
    return this.spriteLoader.loadSprites()
  }
}