import { IContentElement } from "../models";

export interface IContentElementMath extends IContentElement {
    latex: string;
    paragraphStyle: string;
  }

  export const mathEditInfo = {
    editExcludeFields: [],
    editTextFields: ['latex']
  }