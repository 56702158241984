import { AfterViewInit, Component, Input, OnChanges, OnInit, Renderer2, SimpleChange, SimpleChanges, ViewChild } from "@angular/core";
import { QuestionPubSub } from "../question-runner/pubsub/question-pubsub";
import * as PIXI from "pixi.js-legacy";
import { LangService } from "../../core/lang.service";
import { ZoomService } from "../zoom.service";
import { TextToSpeechService } from "../text-to-speech.service";
import { CustomInteractionType, IContentElementVirtualTools} from "./model";
import { MeasuringTools } from "./controllers/measuring-tools";
import { Counter } from "./controllers/counter";
import { Background } from "./controllers/background";
import { Polygon } from "./controllers/polygon";
import { FreehandPolygon } from "./controllers/freehand-polygon";
import { Table } from "./controllers/table";
import { Graph } from "./controllers/graph";
import { FreehandLine } from "./controllers/freehand-line";
import { FreehandPencil } from "./controllers/freehand-pencil";
import { FreehandCircle } from "./controllers/freehand-circle";
import { FreehandRectangle } from "./controllers/freehand-rectangle";

export interface IExtendedTools {
  parent?: string;
  tools?: {
    id: string, 
    action?: () => void,
    isColorPick?: boolean,
    iconUrl?: string,
    colorController?: string,
  }[],
  colors?: string[],
  colorHandler?: (color: string) => void,
}
@Component({
  selector: "element-render-virtual-tools",
  templateUrl: "./element-render-virtual-tools.component.html",
  styleUrls: ["./element-render-virtual-tools.component.scss"]
})
export class ElementRenderVirtualToolsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() element: IContentElementVirtualTools;
  @Input() isLocked: boolean;
  @Input() questionState: any;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() changeCounter: number;
  hcPrevState: boolean;

  @ViewChild("pixiDiv") pixiDiv;

  app: PIXI.Application;

  CustomInteractionType = CustomInteractionType;
  constructor(private renderer2: Renderer2, private lang: LangService, private zoom: ZoomService, private textToSpeech: TextToSpeechService) {
    PIXI.settings.ROUND_PIXELS = true;
    this.backgroundSprite = new PIXI.Sprite();
    this.staticBg = new PIXI.Graphics();
    this.backgroundSprite.interactive = true;
    this.zoom.zoomSub.subscribe(val => {
      if (this.element) {
        this.refresh(true);
      }
    });

    this.zoom.screenShrinkSub.subscribe(val => {
      if(this.element) {
        this.refresh(true);
      } 
    })

    this.hcPrevState = this.textToSpeech.isHiContrast
    this.textToSpeech.isHighContrastSub.subscribe(val => {
      if(this.hcPrevState !== val){
        this.refresh(true);
        this.hcPrevState = val
      }
    })
  }

  renderer; //: PIXI.Renderer;
  stage: PIXI.Container;
  stageBorder: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  staticBg: PIXI.Graphics;

  MeasuringTools: MeasuringTools;
  Background: Background;
  Counter: Counter;
  Polygon: Polygon;
  FreehandPolygon: FreehandPolygon;
  Table: Table;
  Graph: Graph;
  FreehandLine: FreehandLine;
  FreehandPencil: FreehandPencil;
  FreehandCircle: FreehandCircle;
  Rectangle: FreehandRectangle;

  extendedTools:IExtendedTools = {};

  toolbarTools = [
    {
      id: 'pencil',
      toggle: () => this.toggleFreehandPencil(),
      isSelected: () => this.FreehandPencil.isDrawMode,
      isDisabled: () => false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/pencil/1686838975207/pencil.png'
    },
    {
      id: 'text',
      toggle: () => this.createTextbox(),
      isSelected: () => false,
      isDisabled: () => false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/text/1686836133001/text.png'
      //https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/table/1686779324291/table.png
    },
    {
      id: 'table',
      toggle: () => this.createTable(),
      isSelected: () => false,
      isDisabled: () => false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/table-2/1686779242810/table-2.png'
      //https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/table/1686779324291/table.png
    },
   {
      id: 'protractor',
      toggle: () => this.toggleProtractor(),
      isSelected: () => this.MeasuringTools.isProtractorMode,
      isDisabled: () => this.element.isProtractorDisabled,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/protractor-5/1684340267918/protractor-5.png',
    },
    {
      id: 'ruler',
      toggle: () => this.toggleRuler(),
      isSelected: () => this.MeasuringTools.isRulerMode,
      isDisabled: () => this.element.isRulerDisabled,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ruler-5/1684340292133/ruler-5.png',
    },
    {
      id: 'counter',
      toggle: () => this.toggleCounter(),
      isSelected: () => this.Counter.isCounterMode,
      isDisabled: () => this.element.isCounterDisabled,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/tally-2/1684340305117/tally-2.png',
    },
    {
      id: 'polygon',
      toggle: () => this.toggleFreehandPolygon(),
      isSelected: () => this.FreehandPolygon.isDrawMode,
      isDisabled: () => this.element.fhPolygonDisabled,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/polygon-3/1684188795969/polygon-3.png',
    },
    {
      id: 'line',
      toggle: () => this.toggleFreehandLine(),
      isSelected: () => this.FreehandLine.isDrawMode,
      isDisabled: () => this.element.fhLineDisabled,
      iconUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/line/1686862423245/line.png',
    },
    {
      id: 'circle',
      toggle: () => this.toggleFreehandCircle(),
      isSelected: () => this.FreehandCircle.isDrawMode,
      isDisabled: () => this.element.fhCircleDisabled,
      iconUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/circle/1686837012800/circle.png',
    },
    {
      id: 'rectangle',
      toggle: () => this.toggleRectangle(),
      isSelected: () => this.Rectangle.isDrawMode,
      isDisabled: () => this.element.fhRectangleDisabled,
      iconUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/rectangle/1686926968103/rectangle.png',
    },
    {
      id: 'graph',
      toggle: () => this.toggleGraph(),
      isSelected: () => this.Graph.isGraphMode,
      isDisabled: () => this.element.graphDisabled,
      iconUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/graph/1686862515106/graph.png',
    },
  ]

  ngOnInit(): void {


    this.app = new PIXI.Application({ 
      autoStart: false,
      antialias: true,
      autoDensity: true,
      resolution: window.devicePixelRatio * 2
    });
    // #Below code is experimental : deal with the sluggish performance after firing so many events
    // It has something to do with Ticker and System ticker in PIXI
    // this.app.ticker.autoStart =false
    // this.app.ticker.stop()

    // this.stage = new PIXI.Container();
    // this.renderVirtualTools();
    this.app.renderer.plugins.interaction.useSystemTicker = false
  }

  intervals = []
  ngOnDestroy() {
    this.intervals.forEach((interval)=>{
      clearInterval(interval)
    })
  }

  ngAfterViewInit() {
    this.renderer2.appendChild(this.pixiDiv.nativeElement, this.renderer.view);
  }
  canvasWidth: number
  canvasHeight: number
  refresh(isZoomChange: boolean = false) {
    const zoom = !this.zoom.getScreenShrinkZoom() ? 1 : this.zoom.getScreenShrinkZoom();
    this.canvasWidth = zoom * (this.element.canvasWidth || 500);
    this.canvasHeight= zoom * (this.element.canvasHeight || 500);
    // console.log(this.element.canvasWidth, this.element.canvasHeight)
    if (!this.renderer) {
      this.initRenderer();
    } else {
      this.renderer.resize(this.canvasWidth, this.canvasHeight);
    }

    if(!isZoomChange) {
      this.initStage();
    }

    this.stage.scale.set(zoom);

    if(!isZoomChange) {
      this.stageBorder.beginFill(0x0000, 0);
      this.stageBorder.lineStyle(1, 0xe3e3e3);
      this.stageBorder.drawRect(0,0,this.element.canvasWidth || 500,this.element.canvasHeight || 500)
      this.stageBorder.endFill();
      this.stageBorder.zIndex = 11;
      this.initVTCtrl();
    } else {
    this.render();
    }
  }

  render() {
    this.stage.sortableChildren = true;
    this.stage.sortChildren();
    this.renderer.render(this.stage);
  }

  initRenderer() {
    this.renderer = PIXI.autoDetectRenderer({ 
      width: this.canvasWidth, 
      height: this.canvasHeight, 
      antialias: true,
      autoDensity: true,
      transparent: true,
      resolution: window.devicePixelRatio * 2
    });
  }

  initStage() {
    this.stage = new PIXI.Container();
    this.stage.interactive = true;
    this.stageBorder = new PIXI.Graphics();
    this.stage.addChild(this.stageBorder);
  }

  isGlobalRotating = false;
  isGlobalDragging = false;
  initVTCtrl() {
    const render = () => {
      this.stage.sortableChildren = true;
      this.stage.sortChildren();
      this.renderer.render(this.stage);
    }

    const addGraphic = (gr: PIXI.DisplayObject) => {
      this.stage.addChild(gr);
    };
    
    this.Background = new Background(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.staticBg);
    this.MeasuringTools = new MeasuringTools(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging);
    this.Counter = new Counter(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite);
    this.Polygon = new Polygon(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging);
    this.FreehandPolygon = new FreehandPolygon(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite);
    this.Table = new Table(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite);
    this.Graph = new Graph(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite);
    this.FreehandLine = new FreehandLine(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite);
    this.FreehandPencil = new FreehandPencil(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.staticBg);
    this.FreehandCircle = new FreehandCircle(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite);
    this.Rectangle = new FreehandRectangle(<IContentElementVirtualTools>this.element, addGraphic, render, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite);
  }

  toggleRuler() {
    let toggled = false;
    if(!this.element.isRulerDisabled){
      toggled = this.MeasuringTools.toggleRuler();
    }

    if(toggled) {
      this.Counter.toggleCounterMode(false);
      this.FreehandPolygon.toggleDrawMode(false)
      this.FreehandPencil.toggleDrawMode(false);
      this.extendedTools = {};
    }
  }

  toggleProtractor() {
    let toggled = false;
    if(!this.element.isProtractorDisabled){
      toggled = this.MeasuringTools.toggleProtractor();
    }

    if(toggled) {
      this.Counter.toggleCounterMode(false);
      this.FreehandPolygon.toggleDrawMode(false)
      this.FreehandPencil.toggleDrawMode(false);
      this.extendedTools = {};
    }
  }

  toggleCounter() {
    this.showColorPalette = false;
    this.FreehandPolygon.toggleDrawMode(false);
    this.MeasuringTools.toggleProtractor(false);
    this.FreehandPencil.toggleDrawMode(false);
    if(this.MeasuringTools.isRulerMode) {
      this.MeasuringTools.toggleRuler(false);
    }

    if(!this.element.isCounterDisabled) {
      this.extendedTools = this.Counter.toggleCounterMode() ? this.Counter.getCounterTools() : {};
    }
  }

  toggleFreehandPolygon() {
    this.showColorPalette = false;
    this.Counter.toggleCounterMode(false);
    this.MeasuringTools.toggleProtractor(false);
    this.FreehandPencil.toggleDrawMode(false);
    if(this.MeasuringTools.isRulerMode) {
      this.MeasuringTools.toggleRuler(false);
    }

    if(!this.element.fhPolygonDisabled) {
      this.extendedTools = this.FreehandPolygon.toggleDrawMode() ? this.FreehandPolygon.getPolygonTools() : {};
    }
  }

  showColorPalette = false;
  toggleColorPalette(){
      this.showColorPalette = !this.showColorPalette;
  }

  toggleFreehandLine() {
    this.showColorPalette = false;
    this.Counter.toggleCounterMode(false);
    this.MeasuringTools.toggleProtractor(false);
    this.FreehandPencil.toggleDrawMode(false);
    if(this.MeasuringTools.isRulerMode) {
      this.MeasuringTools.toggleRuler(false);
    }

    if(!this.element.fhLineDisabled) {
      this.extendedTools = this.FreehandLine.toggleDrawMode() ? this.FreehandLine.getLineTools() : {};
    }
  }

  toggleFreehandCircle() {
    this.showColorPalette = false;
    this.Counter.toggleCounterMode(false);
    this.MeasuringTools.toggleProtractor(false);
    if(this.MeasuringTools.isRulerMode) {
      this.MeasuringTools.toggleRuler(false);
    }

    if(!this.element.fhCircleDisabled) {
      this.extendedTools = this.FreehandCircle.toggleDrawMode() ? this.FreehandCircle.getCircleTools() : {};
    }
  }

  toggleRectangle() {
    this.showColorPalette = false;
    this.Counter.toggleCounterMode(false);
    this.MeasuringTools.toggleProtractor(false);
    if(this.MeasuringTools.isRulerMode) {
      this.MeasuringTools.toggleRuler(false);
    }

    if(!this.element.fhRectangleDisabled) {
      this.extendedTools = this.Rectangle.toggleDrawMode() ? this.Rectangle.getRectangleTools() : {};
    }
  }

  toggleGraph() {
    let toggled = false;
    if(!this.element.isGraphDisabled){
      toggled = this.Graph.toggleGraph();
    }

    if(toggled) {
      this.Counter.toggleCounterMode(false);
      this.FreehandPolygon.toggleDrawMode(false);
      this.FreehandPencil.toggleDrawMode(false);
      this.extendedTools = {};
    }
  }

  toggleFreehandPencil() {
    const toggled = this.FreehandPencil.toggleDrawMode();

    if(toggled) {
      this.Counter.toggleCounterMode(false);
      this.FreehandPolygon.toggleDrawMode(false);
      this.FreehandLine.toggleDrawMode(false);
    }

    this.extendedTools = toggled ? this.FreehandPencil.getPencilTools() : {};
  }

  createTextbox() {
    this.Counter.toggleCounterMode(false);
    this.FreehandPolygon.toggleDrawMode(false);
    this.FreehandPencil.toggleDrawMode(false);
    this.extendedTools = {};

    this.Table.newStandaloneTextbox();
  }

  createTable() {
    this.Counter.toggleCounterMode(false);
    this.FreehandPolygon.toggleDrawMode(false);
    this.FreehandPencil.toggleDrawMode(false);
    this.extendedTools = {};

    this.Table.newTable()
  }



  isObjectEmpty(obj) {
    return Object.keys(obj).length == 0
  }

  disableHC() {
    const style = {}
    if (this.textToSpeech.isHiContrast) {
      style["filter"]="invert(0)";
    }
    return style;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.changeCounter && changes.changeCounter.currentValue !== undefined && !changes.changeCounter.firstChange) {
      this.questionState[this.element.entryId] = undefined; //(when we are not essentially performing an ngOnInit)
    }

    if(changes.changeCounter?.firstChange) {
      this.initRenderer();
      this.initStage();
      this.initVTCtrl();
    }

    this.refresh();
  }
}
