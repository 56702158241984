import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bc-export-button',
  templateUrl: './bc-export-button.component.html',
  styleUrls: ['./bc-export-button.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class BcExportButtonComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() title: string = 'Export';
  @Input() large: boolean = false;

  @Output() export = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  _export(event) {
    this.export.emit(event);
  }

}
