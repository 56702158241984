import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementDocLink } from './model';



@Component({
  selector: 'element-render-doc-link',
  templateUrl: './element-render-doc-link.component.html',
  styleUrls: ['./element-render-doc-link.component.scss']
})
export class ElementRenderDocLinkComponent implements OnInit {

  @Input() element:IContentElementDocLink;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;
  
  constructor() { }

  ngOnInit(): void {
  }

}
