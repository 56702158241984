import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IContentElementTextLink } from 'src/app/ui-testrunner/element-render-text-link/model';
import { ElementType } from "src/app/ui-testrunner/models";
import { EditingDisabledService } from '../editing-disabled.service';
import { generateDefaultElementImage } from '../item-set-editor/models';
import { bindFormControls } from '../services/data-bind';

@Component({
  selector: 'element-config-text-link',
  templateUrl: './element-config-text-link.component.html',
  styleUrls: ['./element-config-text-link.component.scss']
})
export class ElementConfigTextLinkComponent implements OnInit {

  @Input() element:IContentElementTextLink;
  
  constructor(
    private editingDisabled: EditingDisabledService
  ) { }

  isShowAdvancedOptions = new FormControl(false);
  caption = new FormControl();
  readerId = new FormControl();
  readerElementId = new FormControl();

  ngOnInit() {
    const formControls = [
      {f: this.isShowAdvancedOptions, p:'isShowAdvancedOptions'},
      {f: this.caption, p:'caption'},
      {f: this.readerId, p:'readerId'},
      {f: this.readerElementId, p:'readerElementId'},
    ];
    bindFormControls(this.element, formControls);

    if (!this.element.thumbNail) this.element.thumbNail = generateDefaultElementImage(ElementType.IMAGE);
    console.log(this.element.thumbNail)
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }
}
