
<twiddle caption="Config" [state]="twiddleConfig"></twiddle>
<div  *ngIf="twiddleConfig.value">
    <fieldset [disabled]="isReadOnly()">
        <div class="simple-form-container">
            <label>   
                ID  
                <span  *ngIf="!isEditingEntryId">{{element.entryId}}</span>
                <input *ngIf="isEditingEntryId" type="number" [(ngModel)]="element.entryId" /> 
            </label>
            <button *ngIf="!isEditingEntryId && !isCurrQTrackChanges()" (click)="startEditingEntryId()">Edit</button>
            <button *ngIf="isEditingEntryId" (click)="isEditingEntryId = false">Save</button>
        </div>

        <label>
            <input type="checkbox" [(ngModel)]="element.isNoInvertOnHiContrast"/>
            &nbsp;<tra slug="auth_avoid_inversion_on_contrast"></tra>
        </label>
    </fieldset>

    
    <div class="simple-form-header">button actions</div>
    
    <fieldset [disabled]="isReadOnly()">
        <div class="simple-form-container" style="color:rebeccapurple">
            <label> Animate?  <input type="checkbox" [(ngModel)]="element.isAnimatable" /> </label>
            <label> Click?  <input type="checkbox" [(ngModel)]="element.isClickable" /> </label>
            <label> Capture State?  <input type="checkbox" [(ngModel)]="element.isStateEnabled" /> </label>
            <label title="Check this off on elements that you want to have start not only hidden but not rendered to start with"> Off?  <input type="checkbox" [(ngModel)]="element.isOff" /> </label>
            <ng-container *ngIf="element.isClickable">
              <label>Target  <input type="text" [(ngModel)]="element.toggleTargetId" /> </label>
              <label *ngIf="element.toggleTargetId ">   TGroup  <input type="text" [(ngModel)]="element.toggleTargetGroupId" /> </label>
          
              <div style="display: flex; flex-direction: row; width: 100%; margin-top: 0.5em">
                <label style="width:100%">
                    Event
                    <div [class.is-disabled]="isReadOnly()" class="select is-fullwidth" [style.margin-left.em]="0.5">
                        <select [(ngModel)]="element.eventType">
                            <option value="">None</option>
                            <option *ngFor="let type of eventTypes" [value]="type.id">
                                {{type.caption}}
                            </option>
                        </select>
                    </div>
                </label>
              </div>
          
              <div style="margin-top: 0.5em">
                  <ng-container *ngIf="element.eventType === PubSubTypes.ROTATE">
                      <label>
                          Angle
                          <input type="number" [style.width.em]="4" max="359" min="-359" [(ngModel)]="element.eventData.angle"/>
                      </label><br>
                  </ng-container>
                  <ng-container *ngIf="element.eventType === PubSubTypes.INC_HEIGHT">
                      <label>
                          Change In Height
                          <input type="number" [style.width.em]="4" min="0" [(ngModel)]="element.eventData.heightChange"/>
                      </label><br>
                      <label>
                        Maximum Height
                        <input type="number" [style.width.em]="4" min="0" [(ngModel)]="element.eventData.maxHeight"/>
                    </label><br>
                  </ng-container>
              </div>
            </ng-container>
        </div>
    
        <div class="simple-form-header">style</div>
        <div class="simple-form-container">
            <label>  W  <num-scrub [el]="element" prop="width" [max]="100"></num-scrub></label>
            <label>  H  <num-scrub [el]="element" prop="height" [max]="100"></num-scrub></label>
        </div>
        <div *ngFor="let styleProp of element.activatedStyles" style="margin-bottom:0.2em;">
            <label >  
                <span class="prop-text"> {{styleProp}}: </span>
                <input type="text" style="border:none" [(ngModel)]="element.styleRaw[styleProp]" />
            </label>
        </div>
    </fieldset>
    
    <div style="display:flex; flex-direction:row;">
        <fieldset [disabled]="isReadOnly()">
            <input type="text" style="width:8em" placeholder="New CSS Style Prop" [(ngModel)]="newStyleProp">
            <button (click)="addStyleProp()">Add</button>
            <button style="margin-left:1em" (click)="importStyles()">Import</button>
        </fieldset>
        <button (click)="exportStyles()">Export</button>
    </div>
</div>


<!-- <twiddle caption="Display Elements" [state]="twiddleDisplayElements"></twiddle>
<div  *ngIf="twiddleDisplayElements.value">
</div> -->

    <div class="simple-form-header">Display Elements</div>

<fieldset [disabled]="isReadOnly()">
    <div class="simple-form-container" style="color:rebeccapurple">
        <label> Animate?  <input type="checkbox" [(ngModel)]="element.isAnimatable" /> </label>
        <label> Click?  <input type="checkbox" [(ngModel)]="element.isClickable" /> </label>
        <label> Capture State?  <input type="checkbox" [(ngModel)]="element.isStateEnabled" /> </label>
        <label title="Check this off on elements that you want to have start not only hidden but not rendered to start with"> Off?  <input type="checkbox" [(ngModel)]="element.isOff" /> </label>
        <ng-container *ngIf="element.isClickable">
          <label>Target  <input type="text" [(ngModel)]="element.toggleTargetId" /> </label>
          <label *ngIf="element.toggleTargetId ">   TGroup  <input type="text" [(ngModel)]="element.toggleTargetGroupId" /> </label>
      
          <div style="display: flex; flex-direction: row; width: 100%; margin-top: 0.5em">
            <label style="width:100%">
                Event
                <div [class.is-disabled]="isReadOnly()" class="select is-fullwidth" [style.margin-left.em]="0.5">
                    <select [(ngModel)]="element.eventType">
                        <option value="">None</option>
                        <option *ngFor="let type of eventTypes" [value]="type.id">
                            {{type.caption}}
                        </option>
                    </select>
                </div>
            </label>
          </div>
      
          <div style="margin-top: 0.5em">
              <ng-container *ngIf="element.eventType === PubSubTypes.ROTATE">
                  <label>
                      Angle
                      <input type="number" [style.width.em]="4" max="359" min="-359" [(ngModel)]="element.eventData.angle"/>
                  </label><br>
              </ng-container>
              <ng-container *ngIf="element.eventType === PubSubTypes.INC_HEIGHT">
                  <label>
                      Change In Height
                      <input type="number" [style.width.em]="4" min="0" [(ngModel)]="element.eventData.heightChange"/>
                  </label><br>
                  <label>
                    Maximum Height
                    <input type="number" [style.width.em]="4" min="0" [(ngModel)]="element.eventData.maxHeight"/>
                </label><br>
                <label>
                    Rounding Tolerance: 
                    <input type="number" [style.width.em]="4" min="0"  [(ngModel)]="element.eventData.roundingTolerance"/>
                </label><br>
              </ng-container>
          </div>
        </ng-container>
    </div>

    <div>
        <!-- <div class="simple-form-container" style="color:rebeccapurple">
            <label>   Portal  <button (click)="activatePortal()">Activate</button> </label>
        </div> -->
        <div cdkDropList [cdkDropListDisabled]="isReadOnly()" (cdkDropListDropped)="drop(element.content, $event);">
            <div cdkDrag *ngFor="let contentElement of element.content" class="nested-element">
              <div [class.no-pointer-events]="isReadOnly()" class="nested-element-header" cdkDragHandle>
                <button (click)="contentElement._isCollapsed = !contentElement._isCollapsed">
                    <i class="fa" [ngClass]="getIconByElementTypeId(contentElement.elementType)" aria-hidden="true"></i>
                </button>
                <a [class.is-disabled]="isReadOnly()" class="button">
                  <i class="fas fa-trash" (click)="removeElement(element.content, contentElement);" aria-hidden="true"></i>
                </a>                  
              </div>
              <div class="nested-element-content" *ngIf="!contentElement._isCollapsed">
                <element-config [contentElement]="contentElement"></element-config>
              </div>
            </div>
          </div>
        <select-element 
          [elementTypes]="elementTypes"
          (insert)="insertDisplayListEntry($event)"
          style="margin-top:1em;" 
        ></select-element>
    </div>
    <div>
        <fieldset [disabled]="isReadOnly()">
            <button (click)="importContent()">Import Elements</button>
        </fieldset>
        <button (click)="exportContent()">Export Elements</button>
    </div>



