import { Component, Input, OnInit } from '@angular/core';
import { IContentElementAnnotatedText } from 'src/app/ui-testrunner/element-render-annotation/model';
import { ElementType } from "src/app/ui-testrunner/models";
import { EditingDisabledService } from '../editing-disabled.service';
import { generateDefaultElementText } from '../item-set-editor/models/index';

@Component({
  selector: 'element-config-annotation',
  templateUrl: './element-config-annotation.component.html',
  styleUrls: ['./element-config-annotation.component.scss']
})
export class ElementConfigAnnotation implements OnInit {

  @Input() element:IContentElementAnnotatedText;
  
  constructor(private editingDisabled: EditingDisabledService) { }

  ngOnInit(): void {
    if (!this.element.text) this.element.text = generateDefaultElementText(ElementType.TEXT);
    if (!this.element.annotation) this.element.annotation = "";
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

}