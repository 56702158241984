import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IPoint } from "./virtual-tools";
import { IExtendedTools } from "../element-render-virtual-tools.component";

enum ACTIONS {
  FLIPH = "FLIPH",
  FLIPV = "FLIPV",
  DUPLICATE = "DUPLICATE",
  DELETE = "DELETE"
}
export class FreehandLine extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  polygon: PIXI.Graphics;
  vertices: PIXI.Graphics;
  lines: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  vFlipSprite: PIXI.Sprite;
  hFlipSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  deleteSprite: PIXI.Sprite;
  drawnLines: PIXI.Graphics[] = [];
  lineColor: string;
  lineTools: IExtendedTools;

  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.element = element;
    this.spriteLoader = new SpriteLoader();
    this.backgroundSprite = backgroundSprite;
    this.vFlipSprite = new PIXI.Sprite();
    this.hFlipSprite = new PIXI.Sprite();
    this.dupeSprite = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();
    this.lineColor = this.element.fhPolygonColor || '#03DAC6';

    this.loadAssets().then(this.initSprites);

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.addDrawVertexListener();

    this.vertices = new PIXI.Graphics();
    this.lines = new PIXI.Graphics();
    this.lines.zIndex = 1;
    this.vertices.zIndex = 8;
    this.addGraphic(this.vertices);
    this.addGraphic(this.lines);

    this.lineTools = {
      parent: 'polygon',
      colors: [
        this.element.fhPolygonColor,
        '#c43f2e',
        '#2a91d6'
      ],        
      colorHandler: (color: string) => {
        this.lineColor = color;
      },
      tools: [
      {
        id: 'delete',
        action: () => this.clearDrawnLines(),
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
      },
      {
        id: 'colorPicker',
        isColorPick: true,
        colorController: this.lineColor,
        action: () => {},
      },
    ]}

    // Render scene
    this.render();
  }

  initSprites = ({resources}) => {
    this.vFlipSprite.texture = resources.vflip.texture;
    this.hFlipSprite.texture = resources.hflip.texture;
    this.dupeSprite.texture = resources.duplicate.texture;
    this.deleteSprite.texture = resources.delete.texture;
  }
  
  lineContainer: PIXI.Graphics
  completeLine(points: IPoint[]) {
    // Translate points to 0,0
    let transX = 0;
    let transY = 0;
    for(let i = 0; i< points.length; i++) {
      if(i == 0) {
        transX = points[0].x;
        transY = points[0].y;
        points[0].x = 0;
        points[0].y = 0;
      } else {
        points[i].x -= transX;
        points[i].y -= transY;
      }
    }

    this.vertices.removeChildren();
    this.lines.removeChildren();

    const lineContainer = new PIXI.Graphics();
    const fill = {color: this.lineColor, opacity: this.element.fhPolygonOpacity * 0.01 || 0.8};
    const line = this.drawLine(0, 0, fill, points, false, false);
    line.pivot.set(0.5);
    line.interactive = true;
    lineContainer.addChild(line);
    lineContainer.zIndex = 7;
    const center = this.getShapeCenter(points);

    if (this.element.fhLineHeadAsPivotPoint) {
      lineContainer.pivot.set(0,0);
      lineContainer.position.set(transX, transY);

    } else {
      lineContainer.pivot.set(center.x,center.y);
      lineContainer.position.set(transX+center.x, transY+center.y);
    }
    const rotationPoint = this.drawRotationPoint({x: center.maxX, y: center.minY}, lineContainer);
    rotationPoint.alpha = 0;

    this.addGraphic(lineContainer);
    this.addSelectLineListener(line, lineContainer, rotationPoint, [...points]);
    this.drawnLines.push(lineContainer);
    return lineContainer;
  }

  drawMenu(obj: PIXI.Graphics, container: PIXI.Graphics, points: IPoint[]) {
    const center = this.getShapeCenter(points);
    const menu = new PIXI.Graphics();
    const menuYOffset = 15;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset
    const menuHeight = 30;
    const menuWidth = 95;
    menu.beginFill(0x645f73);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.position.set(center.minX, menuY);
    menu.endFill();

    menu.alpha = 0;

    // Menu icons
    const hFlip = new PIXI.Sprite();
    hFlip.texture = this.hFlipSprite.texture;
    hFlip.scale.set(0.03);
    hFlip.anchor.set(0,0.5);
    hFlip.y = menuHeight / 2;
    hFlip.x = 10;
    hFlip.interactive = true;

    const vFlip = new PIXI.Sprite();
    vFlip.texture = this.vFlipSprite.texture;
    vFlip.scale.set(0.03);
    vFlip.anchor.set(0,0.5);
    vFlip.y = menuHeight / 2;
    vFlip.x = hFlip.x + hFlip.width + 5;
    vFlip.interactive = true;

    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    dupe.y = menuHeight / 2;
    dupe.x = vFlip.x + vFlip.width + 5;
    dupe.interactive = true;

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = dupe.x + dupe.width + 5;
    del.interactive = true;

    menu.addChild(hFlip);
    menu.addChild(vFlip);
    menu.addChild(dupe);
    menu.addChild(del);
    this.addMenuListeners(obj, ACTIONS.FLIPH, hFlip, points);
    this.addMenuListeners(obj, ACTIONS.FLIPV, vFlip, points);
    this.addMenuListeners(obj, ACTIONS.DUPLICATE, dupe, points);
    this.addMenuListeners(container, ACTIONS.DELETE, del, points);
    
    container.addChild(menu);

    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, points: IPoint[]) {
    const center = this.getShapeCenter(points);
    let func;
    let flipped = false;
    option.cursor = 'pointer';
  
    if(action == ACTIONS.FLIPH) {
      func = (e) => {
        if(!flipped) {
          obj.scale.y = -1;
          obj.y = center.y * 2;
        } else {
          obj.scale.y = 1;
          obj.y = 0;
        }
        flipped = !flipped

        this.render();
      }
    } else if(action == ACTIONS.FLIPV) {
      func = (e) => {
        if(!flipped) {
          obj.scale.x = -1;
          obj.x = center.x * 2;
          this.render();
        } else {
          obj.scale.x = 1;
          obj.x = 0;
          this.render();
        }

        flipped = !flipped
      }
    } else if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        const polygonContainer = this.completeLine([...points]);
        polygonContainer.position.set(this.lineContainer.x + 20, this.lineContainer.y + 20);
        this.render();
      }
    } else if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.deleteObject(obj);
        this.render();
      }
    }
    option.on('pointerup', func);
  }

  deleteObject(obj: PIXI.Graphics) {
    obj.removeChildren();
    obj.clear();
  }

  addSelectLineListener(obj: PIXI.Graphics, container: PIXI.Graphics, rotationPoint: PIXI.Graphics, points: IPoint[]) {
    let selected = false;
    let dragging = false;
    let outline;
    let outterBorder;
    let menu;

    const onClick = (e) => {
      dragging = false;
    }
    const checkDragging = (e) => {
      dragging = this.isGlobalDragging;
    }
    const toggleMenu = (e) => {
      if(!this.isDrawMode && !dragging) {
        selected = !selected;
        if(selected) {
          outterBorder = this.drawSelectedBorder(container, points);
          menu = this.drawMenu(obj, container, points);
          outterBorder.alpha = 1;
          menu.alpha = 1;
          rotationPoint.alpha = 1;
        } else {
          outterBorder.destroy();
          menu.destroy();
          rotationPoint.alpha = 0;
        }

        this.render();
      }
    }
    obj.on('pointerdown', onClick).on('pointermove', checkDragging).on('pointerup', toggleMenu);
  }

  points: IPoint[] = [];
  addDrawVertexListener() {
    const addVertice = (e) => {
        if(this.isDrawMode && !this.isVertexDragging && !this.isGlobalRotating) {
            const {x,y} = e.data.getLocalPosition(this.stage);
            const point = {x, y};
            this.points.push(point);
            this.drawShapeVertices(this.vertices, this.lines, this.points, this.addMoveVertexListener.bind(this));
          }
        if(this.points.length >= 2){
            this.lineContainer = this.completeLine(this.points);
            this.points = [];

            this.isDrawMode = false;
            this.stage.cursor = 'default';
            this.backgroundSprite.cursor = 'default';
            this.render();
        }
    }

    this.stage.on('pointerup', addVertice);
  }

  isVertexDragging = false;
  addMoveVertexListener(obj, index) {
    let isClicked = false;
    let initialDiffX = 0;
    let initialDiffY = 0;
    const onDragStart = (e) => {
      if(this.isDrawMode){
        const mousePosition = e.data.getLocalPosition(this.stage);
        initialDiffX = mousePosition.x - obj.x
        initialDiffY = mousePosition.y - obj.y
        isClicked = true;
        obj.cursor = 'grabbing';
      }
    }
    const onDragMove = (e) => {
        if(isClicked && this.isDrawMode) {
            this.isVertexDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            obj.x = mousePosition.x - initialDiffX;
            obj.y = mousePosition.y - initialDiffY;
            this.render();
        }
    }
    const onDragEnd = (e) => {
      if(this.isDrawMode){
        if(!this.isVertexDragging && index == 0 && this.points.length >= 3) {
          this.completeLine(this.points);
          
          this.points = [];

          this.isDrawMode = false;
          this.stage.cursor = 'default';
          this.backgroundSprite.cursor = 'default';
          this.render();
        } else if(!this.isVertexDragging && index != 0) {
          // delete counter
          this.points.splice(index, 1);
          this.drawShapeVertices(this.vertices, this.lines, this.points, this.addMoveVertexListener.bind(this));
        } else {
          isClicked = false;
          this.isVertexDragging = false;
          obj.cursor = 'grab';
          this.points[index].x += obj.x;
          this.points[index].y += obj.y;
          this.drawShapeVertices(this.vertices, this.lines, this.points, this.addMoveVertexListener.bind(this));
        }
      }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointermove', onDragMove)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
  }

  isDrawMode = false;
  toggleDrawMode(mode?: boolean) {
    this.isDrawMode = mode == null ? !this.isDrawMode : mode;
    if(!this.isDrawMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
    }
    this.vertices.removeChildren();
    this.lines.removeChildren();
    this.points = [];

    this.render(); 

    return this.isDrawMode;
  }

  clearDrawnLines() {
    this.drawnLines.forEach((polygon) => {
      this.deleteObject(polygon);
      polygon.destroy();
    });
    this.drawnLines = [];
    this.render();
  }

  getLineTools() {
    return this.lineTools;
  }

  loadAssets() {
    let assets = [];
    assets.push({
        name: "hflip", 
        path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/flip-vertical/1684187809679/flip-vertical.png"
      }
    );
    assets.push({
      name: "vflip", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/flip-horizontal/1684187792111/flip-horizontal.png"
    });
    assets.push({
      name: "duplicate", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/duplicate-2/1684187819438/duplicate-2.png"
    });
    assets.push({
      name: "delete", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png"
    });

    this.spriteLoader.addSpritestoLoader(assets);
    return this.spriteLoader.loadSprites()
  }
}
