import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { ContrastMode, IContentElementImage } from 'src/app/ui-testrunner/element-render-image/model';
import { AuthService } from '../../api/auth.service';
import { LangService } from '../../core/lang.service';
import { memo } from '../../ui-testrunner/element-render-video/element-render-video.component';
import { AltTextService } from '../alt-text.service';
import { AssetField } from '../asset-library/types';
import { EditingDisabledService } from '../editing-disabled.service';
import { CaptureImageUploadService } from './capture-image-upload.service';
import { generateDefaultElementImage } from '../item-set-editor/models/index';
import { AssetLibraryService } from '../services/asset-library.service';

@Component({
  selector: 'capture-image',
  templateUrl: './capture-image.component.html',
  styleUrls: ['./capture-image.component.scss']
})
export class CaptureImageComponent implements OnInit, OnDestroy {

  @Input() element:IContentElementImage;
  @Input() urlProp:string = 'url';
  @Input() urlStr?:string;
  @Input() fileType:string = 'image';
  @Input() isNoScale:boolean;
  @Input() isCondensed:boolean;
  @Input() allowReupload:boolean;
  @Input() uploadCaption:string = this.lang.tra("auth_select_file_to_upload");
  @Input() parentElement?:IContentElementImage;
  @Input() displayAltText?:boolean = true;
  @Input() isUploadsDisabled?:boolean = false;
  @Input() inverseContrastImg?:IContentElementImage;
  @Input() displayImageControls?:boolean = true;
  @Input() ignoreDisablingService?:boolean;
  @Output() onClear = new EventEmitter();
  @Output() onUploaded = new EventEmitter();

  @ViewChild("player") player:ElementRef;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  isHovering: boolean;
  isRequestingReupload:boolean;
  altText = new FormControl('');
  url = new FormControl('');
  uploadedFileType: string;

  private altTextSub:Subscription;
  private userUploadSub:Subscription;
  private storageSub:Subscription;
  private urlSub:Subscription;

  constructor(
    // private storage: AngularFireStorage,
    // private db: AngularFirestore,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    private editingDisabled: EditingDisabledService,
    private assetLibraryService: AssetLibraryService,
    private uploadService: CaptureImageUploadService,
    private altTextService: AltTextService,
    public lang: LangService  
  ) {}
  
  modes = [
    {id: ContrastMode.NORMAL, caption: "Normal"},
    {id: ContrastMode.HIGH_CONTRAST, caption: "High Contrast"}
  ]

  baseElement:IContentElementImage;
  currentElement:IContentElementImage;

  ngOnInit() {    
    this.modelChange()
    if (!this.currentElement.scale){
      this.currentElement.scale = 100;
    }
    this.updateAltText();
    this.altTextService.registerFormControl(this.currentElement.entryId, this.altText);
    this.altTextSub = this.altText.valueChanges.subscribe( e => {
      this.currentElement.altText = this.altText.value
    })
    if (this.getUrl()){ this.url.setValue(this.getUrl()); }
    this.urlSub = this.url.valueChanges.subscribe( e => {
      this.currentElement[this.urlProp] = this.url.value
    })
    // console.log(this.currentElement)
  }

  modelChange() {
    if (this.parentElement) {
      this.baseElement = this.parentElement
      this.currentElement = this.element
    } else {
      this.baseElement = this.element
      if (!this.element.hiContrastImg) {
        this.element.hiContrastImg = generateDefaultElementImage('image')
      }
      if (this.baseElement.mode == ContrastMode.HIGH_CONTRAST && this.element.hiContrastImg) {
        this.currentElement = this.element.hiContrastImg
      } else {
        this.currentElement = this.element
      }
    }
  }

  updateAltText() {
    if (this.currentElement.altText){ this.altText.setValue(this.currentElement.altText); }
  }

  ngOnDestroy(){
    if (this.altTextSub){ this.altTextSub.unsubscribe(); }
    if (this.userUploadSub){ this.userUploadSub.unsubscribe(); }
    if (this.storageSub){ this.storageSub.unsubscribe(); }
    if (this.urlSub){ this.urlSub.unsubscribe(); }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  getUrl(){
    if (this.currentElement && !this.urlStr) {
      return this.currentElement[this.urlProp];
    } else if (this.urlStr) {
      return this.urlStr
    }
    return undefined
  }
  clearUrl(){
    if (confirm('Are you sure you want to clear this upload?')){
      this.currentElement[this.urlProp] = undefined;
      this.currentElement.assetId = undefined
      if (this.currentElement.hiContrastImg) {
        this.currentElement.hiContrastImg[this.urlProp] = undefined
      }
      if (this.baseElement) {
        this.baseElement[this.urlProp] = undefined
        this.baseElement.assetId = undefined
        this.baseElement.mode = ContrastMode.NORMAL
      } 
      if (this.inverseContrastImg) {
        this.inverseContrastImg[this.urlProp] = undefined;
        this.inverseContrastImg.assetId = undefined
      }
      this.modelChange()
      this.onClear.emit();
    }
  }

  promptImgUrl(){
    const url = prompt('Paste image link here (only images from within the authoring system can be used)');
    if (url){
      if (url.indexOf('https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/') === -1){
        alert('Invalid image link')
        return 
      }
      this.element[this.urlProp] = url;
    }
  }

  imageUrls = new Map();
  getImageUrl(){
    return this.getSanitizedUrl(this.getUrl());
  }

  getAFileType(url:string) {
    if (url) {
      const fileExt = url.split('.').pop();
      return fileExt.toUpperCase();
    }
    
  }

  getFileType(){
    return this.getAFileType(this.getUrl())
  }

  getParentFileType(){
    if (!this.baseElement) return
    return this.getAFileType(this.baseElement.url)
  }

  getInverseFileType(){
    if (!this.inverseContrastImg) return
    return this.getAFileType(this.inverseContrastImg.url)
  }
 
  supportedImageExtensions = ['JPG', 'JPEG', 'PNG', 'GIF'];

  isImage() {
    return this.supportedImageExtensions.indexOf(this.getFileType()) !== -1;
    return false;
  }

  isThisOrInverseImage() {
    if (this.getUrl()) {
      if (this.supportedImageExtensions.indexOf(this.getFileType()) != -1) {
        return true
      }
    }
    if (this.baseElement && this.baseElement.url) {
      if (this.supportedImageExtensions.indexOf(this.getParentFileType()) != -1) {
        return true
      }
    }
    if (this.inverseContrastImg && this.inverseContrastImg.url) {
      if (this.supportedImageExtensions.indexOf(this.getInverseFileType()) != -1) {
        return true
      }
    }
    return false
  }
  
  getSanitizedUrl(url){
    return memo(this.imageUrls, url, url => {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  uploadedMeta = new Map();
  getUploadedMeta(){
    return memo(this.uploadedMeta, this.getUrl(), (url) => {
      if (url){
        const path = url.split('/');
        const filename = path.pop().split('?')[0];
        const ext = filename.split('.').pop();
        return {filename, ext}
      }
    })
  }
  
  isWaitingForUpload(){
    return this.isRequestingReupload || !this.getUrl();
  }

  startUpload(event: FileList) {
    let allowedFileTypes = this.fileType.split(',');
    if (this.fileType === 'audio'){
      allowedFileTypes = ['mp3', 'wav']
    }
    // alert('Image uploads are currently disabled.')
    // The File object
    const file = event.item(0);
    // Client-side validation example
    const fileTypeFull = file.type;
    const fileType = fileTypeFull.split('/')[0];
    const fileExt = file.name.split('.').pop();
    //had to add filetype override for vtt because it was not being recognized
    console.log(fileExt, allowedFileTypes)
    if (this.fileType !== 'any'){
      if (!(_.includes(allowedFileTypes, fileType) || _.includes(allowedFileTypes, fileExt.toLowerCase()) || (allowedFileTypes.indexOf('Graphics') > -1 && file.type === 'image/svg+xml'))) {  
        alert('Unsupported File Type ' + fileType);
        return;
      }
    }
    this.auth
    .uploadFile(file, file.name, 'authoring', true)
    .then(res => {
      this.currentElement.fileType = fileTypeFull;
      this.currentElement[this.urlProp] = res.url;
      if (res.urls && this.urlProp == 'url') {
        this.currentElement.urls = res.urls;
        this.uploadService.uploadedNewVideo();
      }
      this.onUploaded.emit(file);
      this.isRequestingReupload = false;
      if(this.currentElement.assetId && this.urlProp=='url'){
        delete(this.currentElement.assetId)
        delete(this.currentElement.assetVersionId)
      }
    })
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  isReadOnly() {
    return  (!this.ignoreDisablingService) && this.editingDisabled.isReadOnly();
  }

  editAltText() {
    //Open up the asset library and edit it directly
    this.assetLibraryService.update(this.currentElement, true, AssetField.ALT_TEXT);
  }
}
