import { IContentElement, IScoredResponse } from "src/app/ui-testrunner/models";
import { IContentElementDynamicImage, IContentElementImage, IContentElementImageSubText } from "../element-render-image/model";

export const passageEditInfo = {
    editExcludeFields: []
}
 

export const PASSAGE_LIN_COUNT_INTERVAL_DEFAULT_SYS = 5;

export enum PassageCounterType { 
    LINE = 'LINE',
    PARAGRAPH = 'PARAGRAPH',
    NONE = 'NONE',
}

export interface IContentElementPassage extends IContentElement {
    text: string,
    images?: {
        id:number, 
        el:IContentElementDynamicImage,
        alignment?:'left' | 'right' | 'center' | 'none',
        subTexts?: IContentElementImageSubText,
    }[],
    paragraphStyles?: {
        id:number, 
        tabs:{sizeEm:number}[]
    }[],
    counterType: PassageCounterType,
    counterAlignment?: 'left' | 'right',
    lineCountInterval: number,
    isLineCountSkipBlank?: boolean,
}
