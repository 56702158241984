import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {QuestionState} from '../models'

@Component({
  selector: 'mcq-option',
  templateUrl: './mcq-option.component.html',
  styleUrls: ['./mcq-option.component.scss']
})
export class McqOption implements OnInit {

  @Input() optionElement:any;
  @Input() alignLeft?: boolean;
  @Input() questionState?: QuestionState;
  @Input() doNotInvertTextColorOnHC?: boolean;

  constructor() { }

  ngOnInit(): void {
    
  }

  isAdvTextEl(el){
    if (el.paragraphStyle){
      return true;
    }
    return false;
  }

  isBasicImg(el) {
    return !el.images
  }
}
