export const ENS_TOPICS = {
    code: 'T',
    tags: [
        {
        "code": "NUM",
        "name": "Business Numeracy Concepts"
        },
        {
        "code": "RP",
        "name": "Ratios and Percentages"
        },
        {
        "code": "ALG",
        "name": "Basic Algebra"
        },
        {
        "code": "B",
        "name": "Budgeting"
        },
        {
        "code": "CM",
        "name": "Cash Management"
        },
        {
        "code": "DM",
        "name": "Debt Management"
        },
        {
        "code": "T",
        "name": "Taxes"
        },
        {
        "code": "BS",
        "name": "Business Statistics"
        },
        {
        "code": "BID",
        "name": "Business Investment Decisions"
        }
    ]
}