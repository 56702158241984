import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription, Subject } from 'rxjs';
import { QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementGraphics } from './model';




@Component({
  selector: 'element-render-graphics',
  templateUrl: './element-render-graphics.component.html',
  styleUrls: ['./element-render-graphics.component.scss']
})
export class ElementRenderGraphicsComponent implements OnInit {

  @Input() questionState:QuestionState;
  @Input() element:IContentElementGraphics;
  @Input() isLocked:boolean;
  @Input() questionPubSub?: QuestionPubSub;

  private postMessageSubCancel: Function;
  private isInited:boolean = false;
  
  private urlSub:Subscription;
  
  lastTrackedQuestionState;


  constructor(
    private sanitizer: DomSanitizer,
  ) { 
    
  }

  ngOnInit(): void { 
    
  }

  getUrl(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.element.url);
  }

}
