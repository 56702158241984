import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'custom-nav-button',
  templateUrl: './custom-nav-button.component.html',
  styleUrls: ['./custom-nav-button.component.scss']
})
export class CustomNavButtonComponent implements OnInit {
  
  @Input() fgColor;
  @Input() bgColor;
  @Input() isBold;
  @Input() isPrev;
  @Input() text;
  @Input() showStar;
  
  constructor() { }

  ngOnInit(): void {
  }

  getFgStyle() {
    return {
      'color': this.fgColor
    }
  }
  
  getBgStyle() {
    return {
      'background-color': this.bgColor
    }
  }

}
