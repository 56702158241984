<div class="time-input">
  <input type="number" min="0" max="23" maxlength="2" [placeholder]="getHourplaceholder()" class="input is-small" [formControl]="hour"  >
  <span class="time-seperator">:</span>
  <input type="number" min="0" max="59" maxlength="2" placeholder="Min."  class="input is-small"  [formControl]="minutes"  >
  <div class="select is-small">
    <select [formControl]="period" >
      <option value=""></option>    
      <option [value]="Periods.AM"><tra slug="lbl_am"></tra></option>    
      <option [value]="Periods.PM"><tra slug="lbl_pm"></tra></option>    
    </select>
  </div>
</div>
