import { FormControl } from '@angular/forms';
import { DBT } from 'src/app/api/models';
import {DATETIME} from "../../api/models/db-types";
import { AssetTypes } from "../asset-library/types";

export enum EStatus {
  RESEARCHING,
  COPY_REQ_READY,
  COPY_REQ_APP,
  COPY_PROC_READY,
  MINISTRY_APP_READY,
  COMPLETED
}

export enum ESitting {
  VRETTA_COPY,
  BC,
  VRETTA_DESIGN,
  COMPLETE
}

export enum EAsmtType {
  SAMPLE,
  SECURE
}

export enum EAsmtReleaseStatus {
  PRE_ADMIN ,
  ADMINISTERED,
  REJECTED
}

export enum ELang {
  EN_ONLY,
  FR_ONLY,
  BOTH_EN_FR
}

/*export enum ECopyrightAcq {
  YES,
  NO
}*/

export enum EYesNo {
  YES,
  NO
}

export enum EMedium {
  PRINT_ONLY,
  WEB_ONLY,
  PRINT_WEB,
  NOT_SPECIFIED,
  ANY
}

export const ASSET_TYPE_OPTIONS = [
  {id: AssetTypes.IMAGE, caption: 'Image'}, 
  {id: AssetTypes.TEXT, caption: 'Text'}, 
  {id: AssetTypes.VIDEO, caption: 'Video'}, 
  {id: AssetTypes.AUDIO, caption: 'Audio'},
  {id: AssetTypes.ITEM, caption: 'Item'}];

export const ASSESSMENT_TYPE_OPTIONS = [
  {id: EAsmtType.SAMPLE, caption: 'Sample'}, 
  {id: EAsmtType.SECURE, caption: 'Secure'}];

export const ASSESSMENT_RELEASE_STATUS_OPTIONS = [
  {id: EAsmtReleaseStatus.PRE_ADMIN, caption: 'Pre-administration'},
  {id: EAsmtReleaseStatus.ADMINISTERED, caption: 'Administered'},
  {id: EAsmtReleaseStatus.REJECTED, caption: 'Rejected'}]

export const YES_NO_OPTIONS = [ /*COPYRIGHT_ACQ_OPTIONS*/
  {id: EYesNo.YES, caption: 'Yes'}, 
  {id: EYesNo.NO, caption: 'No'}];

export const SITTING_OPTIONS = [
  {id: ESitting.VRETTA_COPY, caption: 'Vretta Copyright'}, 
  {id: ESitting.BC, caption: 'BC'}, 
  {id: ESitting.VRETTA_DESIGN, caption: 'Vretta Design'}, 
  {id: ESitting.COMPLETE, caption: 'Complete'}]

export const STATUS_OPTIONS = [
  {id: EStatus.RESEARCHING, caption: 'Researching'},
  {id: EStatus.COPY_REQ_READY, caption: 'Copyright request ready'},
  {id: EStatus.COPY_REQ_APP, caption: 'Copyright request approved'},
  {id: EStatus.COPY_PROC_READY, caption: 'Ready for copyright procurement'},
  {id: EStatus.MINISTRY_APP_READY,caption: 'Ready for Ministry Approval'},
  {id: EStatus.COMPLETED,caption: 'Completed'}];

export const LANG_OPTIONS = [ 
  {id: ELang.EN_ONLY, caption: 'EN Only'},
  {id: ELang.FR_ONLY, caption: 'FR Only'},
  {id: ELang.BOTH_EN_FR, caption: 'EN & FR'} ];

export const MEDIUM_OPTIONS = [ 
  {id: EMedium.PRINT_ONLY, caption: 'Print Only'},
  {id: EMedium.WEB_ONLY, caption: 'Web Only'},
  {id: EMedium.PRINT_WEB, caption: 'Print & Web'},
  {id: EMedium.NOT_SPECIFIED, caption: 'Not Specified'},
  {id: EMedium.ANY, caption: 'Any'}]

export enum EAssetField {
  // "Asset Description" Group
  ASSET_NAME = 'asset_name',
  ASSET_FILE = 'url',
  ASSET_FILE_FR = 'url_fr',
  ASSET_FILE_HC = 'hc_url',
  ASSET_FILE_FR_HC = 'hc_url_fr',
  SUBMITTED_BY = 'submitted_by',
  DATE_OF_SUBMISSION = 'submitted_on',
  ASSET_TYPE = 'asset_type',
  SNAPSHOTS = 'snapshots',
  TOTAL_TEST_TAKERS = 'totalTestTakers',
  ESTIMATED_TEST_TAKERS = 'estimatedTestTakers',
  TAGS = 'tags',
  ORIGINAL_TITLE = 'original_title',
  AUTHOR_NAME = 'author_name',
  PUBLICATION_NAME = 'publication_name',
  PUBLICATION_AUTHOR = 'publication_author',
  PUBLICATION_DATE = 'publication_date',
  PUBLISHER_NAME = 'publisher_name',
  DECORATOR = 'is_decorator',
  PUBLISHER_ADDRESS = 'publisher_address',
  WEB_ADDRESS = 'web_address',
  DATE_ACCESSED = 'date_accessed',
  CAPTIONS = 'captions',
  // "Use in Assessments" Group
  ITEMS = 'items',
  ASSESSMENTS = 'assessments',
  ASSESSMENT_RELEASE_STATUS = 'assessment_release_status',
  ASSESSMENT_RELEASE_YEAR = 'assessment_release_year',
  ASSESSMENT_TYPE = 'assessment_type',
  LANGUAGE = 'language',
  DESCRIPTION = 'description',
  // "Copyright Info" Group
  POINT_OF_CONTACT = 'point_of_contact',
  COPYRIGHT_ACQUISITION = 'copyright_acquisition',
  PRICE_PAID_PREVIOUS_YEARS = 'price_paid_prev_year',
  PRICE_MOST_RECENT = 'price_most_recent',
  BOOK_FRONT_COVER = 'book_front_cover',
  BOOK_COPYRIGHT = 'book_copyright',
  BOOK_ACKNOWLEDGEMENT = 'book_acknowledgement',
  // "License Details" Group
  LICENSE_NOTES = 'license_notes',
  MEDIUM = 'medium',
  NUM_PRINT_IMPRESSIONS = 'num_print_impressions',
  VALID_FROM = 'valid_from',
  VALID_UNTIL = 'valid_until',
  ATTRIBUTION = 'attribution',
  STATUS_NOTES = 'status_notes',
  AMOUNT = 'amount',
  TAX = 'tax',
  TOTAL = 'total',
  // "Metadata Checklist" Group
  ASSET_COPY_ATTACHED = 'asset_copy_attached',
  FORM_FULLY_COMPLETED = 'form_fully_completed',
  BOOK_COVER_COPIED_ATTACHED = 'book_cover_copied_attached',
  BOOK_COPYRIGHT_COPIED_ATTACHED = 'book_copyright_copied_attached',
  BOOK_ACKNOWLEDGEMENT_COPIED_ATTACHED = 'book_acknow_copied_attached',
  WEB_ADDRESS_PROVIDED = 'web_address_provided',
  // "Status" Group
  METADATA_COMPLETE = 'metadata_complete',
  SENT_FOR_APPROVAL = 'sent_for_approval',
  BC_MINISTRY_APPROVED = 'bc_ministry_approved',
  LICENSE_HOLDER_PAID = 'license_holder_paid',
  REIMBURSEMENT_INVOICE_SENT = 'reimbursement_invoice_sent',
  SITTING_IN = 'sitting_in',
  STATUS = 'status',
  // "Vretta Internal" Group
  GITLAB = 'gitlab',
  // "Accessibility" Group
  ALT_TEXT_EN = 'alt_text',
  ALT_TEXT_FR = 'alt_text_fr',
  SUBTITLES_EN = 'subtitles_en',
  SUBTITLES_FR = 'subtitles_fr',
  AUDIO_TRANSCRIPT_EN = 'transcript_en',
  AUDIO_TRANSCRIPT_FR = 'transcript_fr',
  SPEAKER_NOTES_EN = 'speaker_notes_en',
  SPEAKER_NOTES_FR = 'speaker_notes_fr',
  // Asset Library table specific fields
  IS_COPYRIGHT_CHECKED = 'is_copyright_checked',
  IS_PLAGIARISM_CHECKED = 'is_plagiarism_checked',
  IS_REQUEST_FORM_COMPLETE = 'is_request_form_complete'
}

export const FIELD_OPTIONS = {
  [EAssetField.ASSET_TYPE]: ASSET_TYPE_OPTIONS,
  [EAssetField.ASSESSMENT_TYPE]: ASSESSMENT_TYPE_OPTIONS,
  [EAssetField.ASSESSMENT_RELEASE_STATUS]: ASSESSMENT_RELEASE_STATUS_OPTIONS,
  [EAssetField.LANGUAGE]: LANG_OPTIONS,
  [EAssetField.COPYRIGHT_ACQUISITION]: YES_NO_OPTIONS,
  [EAssetField.MEDIUM]: MEDIUM_OPTIONS,
  [EAssetField.SITTING_IN]: SITTING_OPTIONS,
  [EAssetField.STATUS]: STATUS_OPTIONS
}

export enum AssetFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  FILE_UPLOAD = 'FILE_UPLOAD',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  TEXTAREA = 'TEXTAREA',
  CHECKBOX = 'CHECKBOX',
  CUSTOM = 'CUSTOM',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD' //used only for creating new param - alias for a restricted FILE_UPLOAD
}

export interface IAssetField {
  id: EAssetField;
  assetProp: string;
  caption: string;
  type: AssetFieldType;
  isEditing?: boolean;
  formControl?: FormControl;
  config?: IAssetFieldConfig; 
  secure?: boolean;
  readOnly?: boolean;

  //Custom field props:
  customId?: number; //id from the db entry holding the custom field
  groupSlug?: AssetFieldGroup; //the group it's in
}

export interface IAssetFieldConfig {
  placeholder?: string;
  captureImage?: boolean;
  preview?: boolean;
  assetType?: AssetTypes;
  fileTypes?: string;
  min?: number;
  max?: number;
  options?: {id: any, caption: string}[]
  getDisplayValue?: (asset: ILibraryAsset) => any; 
}

export enum AssetFieldGroup {
  ASSET_DESCRIPTION = 'ASSET_DESCRIPTION',
  ACCESSIBILITY = 'ACCESSIBILITY',
  USE_IN_ASSESSMENTS = 'USE_IN_ASSESSMENTS',
  COPYRIGHT_INFO = 'COPYRIGHT_INFO',
  LICENSE_DETAILS = 'LICENSE_DETAILS',
  METADATA_CHECKLIST = 'METADATA_CHECKLIST',
  STATUS = 'STATUS',
  VRETTA_INTERNAL = 'VRETTA_INTERNAL'
}

export interface IAssetFieldGroup {
  id: AssetFieldGroup;
  caption: string;
  fields: IAssetField[];
  isExpanded: boolean;
}

export interface IAssetFieldType {
  id: AssetFieldType;
  caption: string;
}

export interface ILibraryAsset {
  asset_id: number;
  asset_library_id: number;
  current_version_id: number;
  url: string;
  url_fr?: string;
  alt_text?: string;
  description?: string;
  group_id: number;
  id: number;
  library_id: number;
  library_name: string;
  license_end: DATETIME;
  license_holder: string;
  license_information: string;
  license_start: DATETIME;
  max_impressions: number;
  tags: string;
  asset_type: AssetTypes;
  uid: number;
  updated_on: DATETIME;
  is_archived?: boolean;
  is_decorator?: boolean;
  is_plagiarism_checked?: 1|0;
  is_copyright_checked?: 1|0;
  captions?: string;
  _tags?: string[];
  assessments?: string[];
  submitted_by?: string;
  submitted_on?: DATETIME;
  status?: EStatus;
  location?: ESitting;
  is_request_form_complete?: 1|0;

  // Asset info fields:
  //item_ids: string[];
  items: {id: number, label: string}[];
  asset_name: string;
  snapshots: string,
  original_title: string;
  author_name: string;
  publication_name: string;
  publication_author: string;
  publication_date: DATETIME;
  publisher_name: string;
  publisher_address: string;
  web_address: string;
  alt_text_fr: string
  subtitles_en: string
  subtitles_fr: string;
  transcript_en: string;
  transcript_fr: string;
  assessment_release_year: string;
  assessment_type: number;
  language: number;
  point_of_contact: string;
  copyright_acquisition: boolean;
  price_paid_prev_year: string;
  price_most_recent: string;
  book_front_cover: string;
  book_copyright: string;
  book_acknowledgement: string;
  license_notes: string;
  attribution: string;
  status_notes: string;
  amount: string;
  tax: string;
  total: string;
  date_accessed?: DATETIME;
  audio_transcript?: string;
  num_print_impressions?: string;
  valid_from?: DATETIME;
  valid_until?: DATETIME;
  asset_copy_attached: boolean;
  form_fully_completed: boolean;
  book_cover_copied_attached: boolean;
  book_copyright_copied_attached: boolean;
  book_acknow_copied_attached: boolean;
  web_address_provided: boolean;
  metadata_complete: boolean;
  sent_for_approval: boolean;
  bc_ministry_approved: boolean;
  license_holder_paid: boolean;
  reimbursement_invoice_sent: boolean;
  sitting_in: number;
  medium: number;
  gitlab: string;
  speaker_notes_en: string;
  speaker_notes_fr: string;
  assessment_release_status: number;
  is_locked: boolean;
  custom_fields: any;
  totalTestTakers?: number;
  item_id?: number;
  // selection-only, not persisted
  _isSelected?: boolean;
}

export interface IAssetLibrary {
  id: number,
  name?: string,
  description?: string,
  group_id?: number,
  created_on: DBT.DATETIME,
  created_by_uid: number  
}
