import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { WhitelabelService } from '../domain/whitelabel.service';

@Directive({
  selector: '[wlCtx]'
})
export class WlCtxDirective {

  private isShowing = false;

  constructor(
    private whitelabel: WhitelabelService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input() set wlCtx(wlFlag: string) {
    const shouldHide = (wlFlag && !this.whitelabel.getSiteFlag(wlFlag));
    if (this.isShowing && shouldHide){
      this.viewContainer.clear()
    }
    if (!this.isShowing && !shouldHide){
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    this.isShowing = !shouldHide;
  }

}
