import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IExtendedTools } from "../element-render-virtual-tools.component";

interface ICounter {
  x: number,
  y: number,
  color: string,
}
export class Counter extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  backgroundSprite: PIXI.Sprite
  counters: PIXI.Container;
  counterColor: string;
  counterTools: IExtendedTools

  // // 512
  protractorPng = "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/protractor/1681409765420/protractor.png";
  rulerPng = "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ruler/1681409782957/ruler.png"
  // // 64
  // protractorPng = "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/protractor-3/1681412875326/protractor-3.png";
  // rulerPng = "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ruler-3/1681412986079/ruler-3.png"

  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.element = element;
    this.backgroundSprite = backgroundSprite;
    this.spriteLoader = new SpriteLoader();
    this.counters = new PIXI.Graphics();   
    this.addGraphic(this.counters);
    this.counters.zIndex=9;
    this.counterColor = this.element.counterColor || '#ffce18';
    
    // Initialize config variables

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    // Add stage listener
    this.addCounterListener();

    this.counterTools = {
      parent: 'counter',
      colors: [
        this.element.counterColor || '#ffce18',
        '#c43f2e',
        '#2a91d6'
      ],        
      colorHandler: (color: string) => {
        this.counterColor = color;
      },
      tools: [
      {
        id: 'delete',
        action: () => this.clearCounters(),
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
      },
      {
        id: 'colorPicker',
        isColorPick: true,
        colorController: this.counterColor,
        action: () => {},
      },
    ]}

    this.initCounterManager();

    // Render scene
    this.render();
  }

  countersManager: ICounter[][] = [];
  initCounterManager() {
    this.counterTools.colors.forEach((color) => {
      this.countersManager.push([]);
    })
  }

  insertCounter(x: number, y:number) {
    const counter = {
        x, 
        y,
        color: this.counterColor
    }

    // Find the corresponding countersManager array and insert it there
    const counterGroup = this.counterTools.colors.findIndex(color => color === this.counterColor);
    this.countersManager[counterGroup].push(counter);
  }

  drawCounters() {
    const counterNumberColor = this.getParsedColor(this.element.counterNumberColor || '#000000');
    this.counters.removeChildren();
    this.countersManager.forEach((counterGroup, group) => {
      for(let i=0; i<counterGroup.length; i++) {
        const counterColor = this.getParsedColor(counterGroup[i].color);
        const newCounter = new PIXI.Graphics();
        const number = new PIXI.Text((i+1).toString(), {
            fontSize: this.element.counterRadius * 0.75,
            fill: counterNumberColor,
            opacity: this.element.counterOpacity * 0.01 || 0.8
        });
        number.x = counterGroup[i].x - (number.width/2);
        number.y = counterGroup[i].y - (number.height/2);
        newCounter.addChild(number);
        
        newCounter.beginFill(counterColor, this.element.counterOpacity * 0.01 || 0.8);
        newCounter.drawCircle(counterGroup[i].x, counterGroup[i].y, this.element.counterRadius);
        newCounter.endFill();
        newCounter.cursor = 'pointer';
        newCounter.interactive = true;
        this.addDragListener(newCounter, i, group);
        this.counters.addChild(newCounter);
      }
    })
    this.render();
  }

  isCounterMode = false;
  toggleCounterMode(mode?: boolean) {
    this.isCounterMode = mode == null ? !this.isCounterMode : mode;
    if(!this.isCounterMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
    }
    this.render(); 
  
    return this.isCounterMode;
  }

  addCounterListener() {
    const addCounter = (e) => {
        if(this.isCounterMode) {
            const mousePosition = e.data.getLocalPosition(this.stage);
            this.insertCounter(mousePosition.x, mousePosition.y);
            this.drawCounters();
        }
    }

    this.stage.on('pointerup', addCounter);
  }

  isAnyDragging = false;
  addDragListener(obj: PIXI.Graphics, index: number, group: number) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let endingX = 0;
    let endingY = 0;
    let isDragStart = false;
    let isDragging = false;

    const onDragStart = (e) => {
      if(this.isCounterMode) {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragStart = true;
        initialDiffX = mousePosition.x - obj.x
        initialDiffY = mousePosition.y - obj.y
        obj.cursor = 'grabbing';
      }
    }

    const onDragEnd = (e) => {
      if(this.isCounterMode) {
        if(!isDragging && !this.isAnyDragging) {
          // delete counter
          this.countersManager[group].splice(index, 1);
          this.drawCounters();
        } else {
          // Update location
          const originalX = this.countersManager[group][index].x
          const originalY = this.countersManager[group][index].y
          this.countersManager[group][index].x += obj.x;
          this.countersManager[group][index].y += obj.y;

          // Collision detection
          for(let i=0; i<this.countersManager[group].length; i++) {
            const distanceX = Math.pow(this.countersManager[group][index].x - this.countersManager[group][i].x, 2);
            const distanceY = Math.pow(this.countersManager[group][index].y - this.countersManager[group][i].y, 2);
            const distance = Math.sqrt(distanceX + distanceY)

            if(distance <= this.element.counterRadius * 2 && i != index) {
              this.countersManager[group][index].x = originalX;
              this.countersManager[group][index].y = originalY;
            }
          }
          if(this.countersManager[group][index].x < 0) {
            this.countersManager[group][index].x = originalX;
          }
          if(this.countersManager[group][index].y < 0) {
            this.countersManager[group][index].y = originalY;
          }
          if(this.countersManager[group][index].x > this.element.canvasWidth) {
            this.countersManager[group][index].x = originalX;
          }
          if(this.countersManager[group][index].y > this.element.canvasHeight) {
            this.countersManager[group][index].y = originalY;
          }
        }

        isDragging = false;
        isDragStart = false;
        this.isAnyDragging = false;
        obj.cursor = 'grab';
        this.drawCounters();
      }
    }
    
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(isDragStart && this.isCounterMode) {
            this.isAnyDragging = true;
            isDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            obj.x = endingX = mousePosition.x - initialDiffX;
            obj.y = endingY = mousePosition.y - initialDiffY;
            this.render();
        }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove)
  }
  
  clearCounters() {
    this.counters.removeChildren();
    this.countersManager.forEach((group, index) => {
      this.countersManager[index] = [];
    });
    this.render();
  }

  getCounterTools() {
    return this.counterTools
  }


  loadAssets() {
      return null;
  }
}
