import { AssessmentCode } from 'src/app/ui-schooladmin/data/mappings/eqao-g9';
import { IStudentAccount, IStudentTestSession } from '../../ui-schooladmin/data/types';
import { IMappedList } from '../../ui-schooladmin/data/util';

export interface IClassroomArchivedTeachers {
    __id?: string,
    classroomId: string,
    timestamp: any,
    uid:string
  }
  export interface IUserInfo {
    uid: string,
    displayName: string,
  }
  export interface IClassroomSlot {
    id: string, 
    archiveId?: string, 
    name: string,
    isAssigned?:boolean
    curricShort?: string,
    classCode?: string,
    numStudents?: number,
    numTeachers?: number,
    is_fi?: number,
  }
   export interface IClassroom {
    id: string, 
    group_id: number, 
    name:string;
    classCode?:string;
    isAssigned?:boolean
    curricShort?: AssessmentCode,
    owner?: string;
    enableClassListingByCC?: boolean;
    currentStudents?: IMappedList<IStudentAccount>,
    currentTeachers?: string[],
    openAssessments?: any[],
    scheduledAssessments?: any[],
    recentAssessments?: any[],
    timeCreated?: string;
    timeLastTouched?: string;
    is_invigilating?:boolean,
    is_fi?: number,
    allow_ISR?:number
  }
  export interface IStudent {
    oen: string,
    firstname: string,
    lastname: string,
    accommodation:string
  }
  export interface IStudentList {
    studentAccount:IStudentAccount,
    is_online?: boolean,
    session_a?:ISession,
    session_b?:ISession
    
  }
  export interface ISession{
    is_active?:boolean,
    is_session_active:boolean,
    time_spent?:string
  }
  
  export interface ISessionBegin{
    slug: string,
    isScheduled:boolean,
    scheduled_time?:string[]
  }

  export enum ASSESSMENT {
    G9_SAMPLE = 'G9_SAMPLE',
    G9_OPERATIONAL = 'G9_OPERATIONAL',
    OSSLT_SAMPLE = 'OSSLT_SAMPLE',
    OSSLT_OPERATIONAL = 'OSSLT_OPERATIONAL',
    PRIMARY_SAMPLE = 'PRIMARY_SAMPLE',
    PRIMARY_OPERATIONAL = 'PRIMARY_OPERATIONAL',
    JUNIOR_SAMPLE = 'JUNIOR_SAMPLE',
    JUNIOR_OPERATIONAL = 'JUNIOR_OPERATIONAL',
    TCLE_SAMPLE = 'TCLE_SAMPLE',
    TCLE_OPERATIONAL = 'TCLE_OPERATIONAL',
    TCN_SAMPLE ='TCN_SAMPLE',
    TCN_OPERATIONAL ='TCN_OPERATIONAL',
    SCIENCES8_SAMPLE = 'SCIENCES8_SAMPLE',
    SCIENCES8_OPERATIONAL = 'SCIENCES8_OPERATIONAL',
    LECTURE2_OPERATIONAL = 'LECTURE2_OPERATIONAL',
    LECTURE7_OPERATIONAL = 'LECTURE7_OPERATIONAL',
    MATH8_SAMPLE = 'MATH8_SAMPLE',
    MATH8_OPERATIONAL = 'MATH8_OPERATIONAL',
    MBED_SAMPLE = 'MBED_SAMPLE',
    SMCS_EN_G7 = 'SMCS_EN_G7',
    MATH6_OPERATIONAL = 'MATH6_OPERATIONAL',
    ELPA_SAMPLE = 'ELPA_SAMPLE',
    ELPA_OPERATIONAL = 'ELPA_OPERATIONAL',
    DEMO_SAMPLE = 'DEMO_SAMPLE',
    NBED_ANGLO_EN6_SAMPLE = 'NBED_ANGLO_EN6_SAMPLE',
    NBED_ANGLO_SCI6_SAMPLE = 'NBED_ANGLO_SCI6_SAMPLE',
    NBED_ANGLO_MATH7_SAMPLE = 'NBED_ANGLO_MATH7_SAMPLE',
    NBED_ANGLO_FI_READ7_SAMPLE = 'NBED_ANGLO_FI_READ7_SAMPLE',
    NBED_ANGLO_SCI8_SAMPLE = 'NBED_ANGLO_SCI8_SAMPLE',
    NBED_ANGLO_FR12_SAMPLE = 'NBED_ANGLO_FR12_SAMPLE',
    NBED_ANGLO_SCI4_SAMPLE = 'NBED_ANGLO_SCI4_SAMPLE',
    NBED_ANGLO_EN4_SAMPLE = 'NBED_ANGLO_EN4_SAMPLE',
    NBED_ANGLO_FR5_SAMPLE = 'NBED_ANGLO_FR5_SAMPLE',
    NBED_ANGLO_FI_READ10_SAMPLE = 'NBED_ANGLO_FI_READ10_SAMPLE',

    NBED_ANGLO_EN6_OPERATIONAL = 'NBED_ANGLO_EN6_OPERATIONAL',
    NBED_ANGLO_SCI6_OPERATIONAL = 'NBED_ANGLO_SCI6_OPERATIONAL',
    NBED_ANGLO_MATH7_OPERATIONAL = 'NBED_ANGLO_MATH7_OPERATIONAL',
    NBED_ANGLO_FI_READ7_OPERATIONAL = 'NBED_ANGLO_FI_READ7_OPERATIONAL',
    NBED_ANGLO_SCI8_OPERATIONAL = 'NBED_ANGLO_SCI8_OPERATIONAL',
    NBED_ANGLO_FR12_OPERATIONAL = 'NBED_ANGLO_FR12_OPERATIONAL',
    NBED_ANGLO_SCI4_OPERATIONAL = 'NBED_ANGLO_SCI4_OPERATIONAL',
    NBED_ANGLO_EN4_OPERATIONAL = 'NBED_ANGLO_EN4_OPERATIONAL',
    NBED_ANGLO_FR5_OPERATIONAL = 'NBED_ANGLO_FR5_OPERATIONAL',
    NBED_ANGLO_FI_READ10_OPERATIONAL = 'NBED_ANGLO_FI_READ10_OPERATIONAL',

    NBED_ANGLO_MATH5_OPERATIONAL = 'NBED_ANGLO_MATH5_OPERATIONAL',
    NBED_ANGLO_FI_READ5_OPERATIONAL = 'NBED_ANGLO_FI_READ5_OPERATIONAL',
    NBED_ANGLO_PIF_READ7_OPERATIONAL = 'NBED_ANGLO_PIF_READ7_OPERATIONAL',
    NBED_ANGLO_PIF_READ10_OPERATIONAL = 'NBED_ANGLO_PIF_READ10_OPERATIONAL',

    NBED_ANGLO_MATH5_SAMPLE = 'NBED_ANGLO_MATH5_SAMPLE',
    NBED_ANGLO_FI_READ5_SAMPLE = 'NBED_ANGLO_FI_READ5_SAMPLE',
    NBED_ANGLO_PIF_READ7_SAMPLE = 'NBED_ANGLO_PIF_READ7_SAMPLE',
    NBED_ANGLO_PIF_READ10_SAMPLE = 'NBED_ANGLO_PIF_READ10_SAMPLE',

    NBED_ECRITURE7_SAMPLE = 'NBED_ECRITURE7_SAMPLE',
    NBED_ECRITURE7_OPERATIONAL = 'NBED_ECRITURE7_OPERATIONAL',

    NBED_FRANCO_DEMO_SAMPLE = 'NBED_FRANCO_DEMO_SAMPLE',
    NBED_FRANCO_DEMO_OPERATIONAL = 'NBED_FRANCO_DEMO_OPERATIONAL',
    // OSSLT = 'Ontario Secondary School Literacy Test',
    // OSSLT_PRACTICE ='Sample Test (OSSLT)',
    // G9 = 'Grade 9 Assessment of Mathematics',
    // G9_PRACTICE = 'Sample Assessment (Grade 9)'
  }
  export enum SCHEDULER {
    NOW = 'NOW',
    LATER = 'LATER'
  }
export enum TeacherModal {
  SAMPLE_MODAL = 'SAMPLE_MODAL',
  EDIT_MODAL = 'EDIT_MODAL',
  SAMPLE_MODAL_OTHER = 'SAMPLE_MODAL_OTHER',
  REPORT_MODAL = "REPORT_MODAL",
  ALREADY_EXIST = "ALREADY_EXIST",
  REVIEW_SUBM_MODAL = 'REVIEW_SUBM_MODAL',
  REVIEW_CURR_SS_STATUS_MODAL = 'REVIEW_CURR_SS_STATUS_MODAL',
  UNSUBMIT_STUDENT_MODAL = 'UNSUBMIT_STUDENT_MODAL',
}

export const ASSESSION_SLUG_MAPPING = () => [
  {target_1:'L-A', target_2:'pj_lbl_lang_session_a', source: 'lang_session_a'},
  {target_1:'L-B', target_2:'pj_lbl_lang_session_b', source: 'lang_session_b'},
  {target_1:'L-C', target_2:'pj_lbl_lang_session_c', source: 'lang_session_c'},
  {target_1:'L-D', target_2:'pj_lbl_lang_session_d', source: 'lang_session_d'},
  {target_1:'M-1', target_2:'pj_lbl_math_stage_1', source: 'math_stage_1'},
  {target_1:'M-2', target_2:'pj_lbl_math_stage_2', source: 'math_stage_2'},
  {target_1:'M-3', target_2:'pj_lbl_math_stage_3', source: 'math_stage_3'},
  {target_1:'M-4', target_2:'pj_lbl_math_stage_4', source: 'math_stage_4'},
  {target_1:'Q', target_2:'session_q', source: 'session_q'},
]; 

export enum PrintScanModal {
  STUDENT_INFO = 'STUDENT_INFO',
  INSTRUCTION_MODAL = 'INSTRUCTION_MODAL',
};
