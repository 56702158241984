
<div class="view-login-entry">
    <div class="header-container">
        <header [breadcrumbPath]="breadcrumb" [isLoginPage]="true"></header>
    </div>
    <ng-container *ngIf="isLoaded">
        <section *ngIf="!EntryWorks"  class="container fx fx-sb" >
            <a [routerLink]="getLoginAngloLink()[0]" [queryParams]="getLoginAngloLink()[1]" class="link text-center">
                <h2 class="content-title fc-white fw-700"><tra slug="nbed_anglo_choose_sector"></tra></h2>
                <div class="card card-square fx fx-column fx-ch">
                    <h3 class="content-text-sm fw-700 fc-green"><tra slug="nbed_anglo_schools"></tra></h3>
                    <p class="content-text-sm fw-400 fc-green"><tra-md slug="nbed_anglo_schools_categories"></tra-md></p>
                </div>
            </a>
            <a [routerLink]="getLoginFrancoLink()[0]" [queryParams]="getLoginFrancoLink()[1]" class="link text-center">
                <h2 class="content-title fc-white fw-700"><tra slug="nbed_franco_choose_sector"></tra></h2>
                <div class="card card-square fx fx-column fx-ch ">
                    <h3 class="content-text-sm fw-700 fc-green "><tra slug="nbed_franco_schools"></tra></h3>
                    <p class="content-text-sm fw-400 fc-green"><tra-md [slug]="getFrancoSchoolCategory()"></tra-md></p>
                </div>
            </a>
        </section>
        <section *ngIf="EntryWorks === 'anglo' || EntryWorks === 'franco'" class="container fx fx-column" >
            <h2 class="content-title fc-white fw-700"><tra slug="nbed_login_sector_entry_title"></tra></h2>
            <a routerLink="/{{lang.c()}}/{{EntryWorks}}/public-practice" class="card content-text-sm card-button text-center"><tra slug="nbed_login_practice_assmt_txt"></tra></a>
            <a href="{{ssoAddress}}/signin?domain={{EntryWorks}}&lang={{lang.c()}}" referrerpolicy="origin" class="card content-text-sm card-button text-center"><tra slug="nbed_signin"></tra></a>
            <!-- <button disabled [attr.data-text]="getDisabledText()" class="card content-text-sm card-button button-disabled text-center fw-700"><tra slug="nbed_signin"></tra></button> -->
        </section>
    </ng-container>
</div>
  