import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'list-trav-arrows',
  templateUrl: './list-trav-arrows.component.html',
  styleUrls: ['./list-trav-arrows.component.scss']
})
export class ListTravArrowsComponent implements OnInit {

  @Input()  current : number;
  @Input()  max : number;
  @Output() prev = new EventEmitter();
  @Output() next = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  isMin(){
    return this.current === 1;
  }

  isMax(){
    return this.current === this.max;
  }
  

}
