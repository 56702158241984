import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { WhitelabelService } from '../domain/whitelabel.service';
import { AuthRolesService } from "./auth-roles.service";
import { IItemAuthNote } from './element-notes/element-notes.component';
import { PARAM_SPECIAL_FLAGS } from './framework-dimension-editor/model';

export enum AuthScopeSetting {
  ENABLE_SCORE_WEIGHT = 'ENABLE_SCORE_WEIGHT', // should be conditionally enabled for BC (but false for EQAO MPT/Grade9)
  DISABLE_EDITING = 'DISABLE_EDITING', //based on role type
  ASSET_LIB_TAG_FILTER = 'ASSET_LIB_TAG_FILTER', // to preserve search
  APPLY_NUM_FORMATS = 'APPLY_NUM_FORMATS', // should be conditionally enabled for ENS (through the framework)
  Q_FADE_IN_ENTRANCE = 'Q_FADE_IN_ENTRANCE', // would likely be set by the assessment, with override by the item
  ENABLE_Q_LOCK = 'ENABLE_Q_LOCK',
  ENABLE_COMMENT_DEL = 'ENABLE_COMMENT_DEL',
  ENABLE_COMMENT_SEE_ALL = 'ENABLE_COMMENT_SEE_ALL',
  ENABLE_COMMENT_SHARE = 'ENABLE_COMMENT_SHARE',
  ENABLE_COMMENT_EDIT = 'ENABLE_COMMENT_EDIT',
  ENABLE_COMMENT_IMPORT = 'ENABLE_COMMENT_IMPORT',
  ENABLE_PSYCHO_PARAM_EDIT = 'ENABLE_PSYCHO_FLAG_EDIT',
  ENABLE_SUGGESTIONS = 'ENABLE_SUGGESTIONS',
  DISABLE_TRACKED_CHANGES_CTRL = 'DISABLE_TRACKED_CHANGES_CTRL',
  USE_EDITING_MODE = 'USE_EDITING_MODE',
  DISABLE_ITEM_PARAMS = 'DISABLE_ITEM_PARAMS', 
  IS_EDITOR = 'IS_EDITOR',
  DISABLE_REVIEW_EDIT_TAB = 'DISABLE_REVIEW_EDIT_TAB'
}

@Injectable({
  providedIn: 'root'
})
export class AuthScopeSettingsService {
  
  // scope should eventually be defined by the following hierarchy
  /*

    > System Defaults
      > White Label Defaults
        > Auth Group Defaults
          > Item Bank Defaults
            > Current Item Setting

  */
  // this can be used to provide all of the display customization which might be relevant for the authoring experience

  private state:Map<AuthScopeSetting, boolean | number | any> = new Map();

  constructor(    private auth:AuthService,
                  private authRole:AuthRolesService,
                  private whitelabel:WhitelabelService,
    ) {
    this.state.set(AuthScopeSetting.Q_FADE_IN_ENTRANCE, true)
    this.state.set(AuthScopeSetting.ENABLE_SCORE_WEIGHT, true)
  }
  
  public getSetting(settingId: AuthScopeSetting) {
    return this.state.get(settingId);
  }

  public setSetting(settingId: AuthScopeSetting, val:any) {
    this.state.set(settingId, val);
  }

  isAllCommentsViewable() {
    return this.getSetting(AuthScopeSetting.ENABLE_COMMENT_SEE_ALL);
  }

  isAssignedToThisUser(com) {
    const user = com.assigned_uid;
    if (this.auth.user() && this.auth.user().value && user == this.auth.user().value.uid || this.authRole.hasRole(com.assigned_access_level) || (com.parent && this.isAssignedToThisUser(com.parent))) {
      return true;
    }
    return false;
  }

  hasChildAssignedToThisUser(com) {
    const user = com.assigned_uid;
    const role = com.assigned_access_level
    if (this.authRole.hasRole(role) || ((this.auth.user() && this.auth.user().value && user == this.auth.user().value.uid))) {
      return true;
    } else if (com.sub) {
      let assigned = false
      com.sub.forEach(element => {
        if (this.hasChildAssignedToThisUser(element)) {
          assigned = true
        }
      });
      return assigned
    }
  }

  isCreatedBy(com) {
    const user = com.created_by_uid;
    if (this.auth.user() && this.auth.user().value && user == this.auth.user().value.uid || (com.parent && this.isCreatedBy(com.parent))) {
      return true;
    }
    return false;
  }

  hasCreatedChild(com) {
    const user = com.created_by_uid;
    if (this.auth.user() && this.auth.user().value && user == this.auth.user().value.uid) {
      return true;
    } else if (com.sub) {
      let assigned = false
      com.sub.forEach(element => {
        if (this.hasCreatedChild(element)) {
          assigned = true
        }
      });
      return assigned
    }
  }

  isCommentViewable(comment:IItemAuthNote) {
    if (this.isAllCommentsViewable() ||
    this.isAssignedToThisUser(comment) || 
    this.isCreatedBy(comment) || 
    this.isShared(comment) || 
    this.hasChildAssignedToThisUser(comment) ||
    this.hasCreatedChild(comment)) {
      return true;
    }
    return false;
  }

  isShared(com) {
    if (!com) return false;
    if (com.parent) {
      return this.isShared(com.parent)
    }
    return com.is_shared;
  }
  
  isItemParamViewable(param) {
    if (this.whitelabel.getSiteFlag('IS_EQAO')) {
      return  !this.getSetting(AuthScopeSetting.DISABLE_ITEM_PARAMS) || 
              (this.getSetting(AuthScopeSetting.DISABLE_ITEM_PARAMS) && 
              this.getSetting(AuthScopeSetting.IS_EDITOR) &&
              param?.config?.special && param.config.special[PARAM_SPECIAL_FLAGS.VISIBLE_TO_EDITOR])
    }
    return true    
  }
}
