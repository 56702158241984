<div class="page-body">
    <div>
      <header [breadcrumbPath]="breadcrumb" [hasSidebar]="true"></header>
      
      <div class="page-content is-fullpage " *ngIf="!hasAccess">
        Loading...
        <div>
            <a routerLink="/en/test-ctrl/asmt-item-analysis">Access Item Analysis</a>
        </div>
      </div>
      <div class="page-content is-fullpage " *ngIf="hasAccess">
        <div class="pagelet-container">
            <div class="space-between">
                <h2>Grade 12 Literacy Assessments - 2021 Field Test</h2>
                <div>
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="isShowSubmissionsOnly" (change)="processSummaryData()">
                            Show submissions only?
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox"  [(ngModel)]="isIncludeSampleAccounts" (change)="processSummaryData()">
                            Include Sample Accounts?
                        </label>
                    </div>
                </div>
            </div>
            <div>
                <a routerLink="/en/test-ctrl/asmt-item-analysis">Access Item Analysis</a>
            </div>
            <div>
                <h4>Provincial Totals</h4>
            </div>
            <table>
                <tr>
                    <th></th>
                    <th>Total</th>
                    <th *ngFor="let asmtComponent of assessmentComponents" (click)="selectGroup(asmtComponent.group)" [class.is-selected]="isActiveGroup(asmtComponent.group)">
                        {{asmtComponent.code}}
                    </th>
                </tr>
                <tr>
                    <th>Student {{renderLoginOrSubmission()}}s:</th>
                    <td>
                        {{getSummaryValue(createProvTotalKey(DataTranche.TOTAL, DataTranche.STUDENT))}}
                    </td>
                    <td *ngFor="let asmtComponent of assessmentComponents" (click)="selectGroup(asmtComponent.group)" [class.is-selected]="isActiveGroup(asmtComponent.group)">
                        {{getSummaryValue(createProvTotalKey(asmtComponent.code, DataTranche.STUDENT))}}
                    </td>
                </tr>
                <tr>
                    <th>Teacher/Admin {{renderLoginOrSubmission()}}s:</th>
                    <td>
                        {{getSummaryValue(createProvTotalKey(DataTranche.TOTAL, DataTranche.TEACHER))}}
                    </td>
                    <td *ngFor="let asmtComponent of assessmentComponents" (click)="selectGroup(asmtComponent.group)" [class.is-selected]="isActiveGroup(asmtComponent.group)">
                        {{getSummaryValue(createProvTotalKey(asmtComponent.code, DataTranche.TEACHER))}}
                    </td>
                </tr>
            </table>
            
            <div style="margin-top:2em;"></div>

            <div class="space-between">
                <h4>{{renderLoginOrSubmission(true)}}s Counts by District and Independent Schools</h4>
                <div>
                    <div>                        
                        <label>
                            <input type="checkbox" checked disabled>
                            Only show schools/districts with at least 1 {{renderLoginOrSubmission()}}?
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" [(ngModel)]="isDistSchTeachers" (change)="processSummaryData()">
                            View teacher {{renderLoginOrSubmission()}}s
                        </label>
                    </div>
                </div>
            </div>
            <table>
                <tr>
                    <th>Name</th>
                    <th>Category</th>
                    <th *ngFor="let asmtComponent of assessmentComponents" (click)="selectGroup(asmtComponent.group)"  [class.is-selected]="isActiveGroup(asmtComponent.group)">
                        {{asmtComponent.code}}
                    </th>
                </tr>
                <tr *ngFor="let schoolDist of schoolsAndDistricts">
                    <td>{{schoolDist.schoolDistId}}</td>
                    <td><span class="tag" [class.is-warning]="schoolDist.isDistrict">{{schoolDist.cat}}</span></td>
                    <td *ngFor="let asmtComponent of assessmentComponents" (click)="selectGroup(asmtComponent.group)" [class.is-selected]="isActiveGroup(asmtComponent.group)">
                        {{getSummaryValue(createDistSchKey(schoolDist.cat, schoolDist.schoolDistId, asmtComponent.code))}}
                    </td>
                </tr>

            </table>
            <!-- <div style="margin-top:0.5em;">
                Page 1 of X <button disabled>Prev</button>&nbsp;<button>Next</button>
            </div> -->

            <div style="margin-top:2em;"></div>
            <div class="align-center" style=" align-items: baseline;">
                <h4> Most Recent Student/Teacher {{renderLoginOrSubmission(true)}}s </h4>
                <button class="button is-small has-icon" style="margin-left:1em; " [ngSwitch]="!!isShowingRecent" (click)="isShowingRecent = !isShowingRecent">
                    <span *ngSwitchCase="true" class="icon"><i  class="fa fa-eye"></i></span>
                    <span *ngSwitchCase="false" class="icon"><i  class="fa fa-eye-slash"></i></span>
                    <span *ngSwitchCase="false">Show</span>
                    <span *ngSwitchCase="true">Hide</span>
                </button> 
            </div>
            <div *ngIf="isShowingRecent">
                <table>
                    <tr>
                        <th>ID</th>
                        <th>Logged In On</th>
                        <th>School/District</th>
                        <th>Category</th>
                        <th>Student/Teacher</th>
                        <th>Assessment Component</th>
                        <th>Pages Accessed</th>
                        <th>Submitted On</th>
                        <th>OS/Browser</th>
                    </tr>
                            
                    <ng-container *ngFor="let record of recentLogins" >
                        <tr *ngIf="(isIncludeSampleAccounts || !record.isTester) && ((!isShowSubmissionsOnly || record.closed_on))" >
                            <td>{{record.test_attempt_id}}</td>
                            <td>{{record.created_on}}</td>
                            <td>{{record.schoolDistId}}</td>
                            <td><span class="tag" [class.is-warning]="record.isDistrict">{{record.schoolDistCat}}</span></td>
                            <td>{{record.isTester ? 'Sample ' : ''}}{{record.isTeacher ? 'Teacher' : 'Student'}}</td>
                            <td (click)="selectGroup(record.asmtComponent.group)"  [class.is-selected]="isActiveGroup(record.asmtComponent.group)">{{record.asmtComponent.code}}</td>
                            <td>{{record.num_questions}}</td>
                            <td>{{record.closed_on}}</td>
                            <td>
                                {{record.browser_name}}
                                {{record.browser_version}}
                                on {{record.operating_system}}
                            </td>
                        </tr>
                    </ng-container>

                </table>
            </div>
        </div>
      </div>
    </div>
    <footer [hasLinks]="true"></footer>
  </div>