import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService, IUserInfo } from '../auth.service';
import { RoutesService } from '../routes.service';
import { NotificationCentre } from './data/types';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notifications: NotificationCentre.IUserMessage[] = [];
  public user: IUserInfo;
  public onNotificationsLoaded: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentGroupId: string | number;
  public currentRoleId: string | number;
  private userSub: Subscription;

  constructor(
    private auth: AuthService,
    private routes: RoutesService
  ) {
    this.userSub = this.auth.userSub.subscribe(user => {
      if(user) {
        this.user = user;
        console.log('notificationUser', user)
        this.loadAllNotifications();
      }
    });

    if(this.auth.currentAccountType) {
      this.currentGroupId = this.auth.currentAccountType.group_id;
      this.currentRoleId = this.auth.currentAccountType.ur_id;
      console.log('filteringToRole', this.currentRoleId, this.currentGroupId);
    }

    this.auth.accountTypeSub.subscribe((accountType) => {
      this.currentGroupId = accountType.group_id;
      this.currentRoleId = accountType.ur_id;
    });
  }

  // destructor
  ngOnDestroy() {
    if(this.userSub) this.userSub.unsubscribe();
  }

  loadAllNotifications() {
    this.auth.apiFind(this.routes.NTF_USER_NOTIFICATIONS,)
      .then((res: any) => {
        this.notifications = res;
        console.log('loadedNotifications', res)
        this.onNotificationsLoaded.next(this.notifications);
      })
  }

  // DEPRECATED: Using getNotificationsByRole instead
  getNotificationsByGroup(group_id?: string | number) {
    if(!group_id) {
      group_id = this.currentGroupId;
    }
    return this.notifications.filter(notification => notification.recipient_group_id === group_id);
  }

  getNotificationsByRole(role_id?: string | number, group_id?: string | number) {
    if(!role_id) {
      role_id = this.currentRoleId;
    }
    if(!group_id) {
      group_id = this.currentGroupId;
    }
    return this.notifications.filter(notification => {
      // if no role or group is specified, it is system wide
      if(!notification.recipient_role_id && !notification.recipient_group_id) {
        // console.log('System wide notification', notification)
        return true;
      }
      // if only group is specified, it is group specific
      if(notification.recipient_group_id && !notification.recipient_role_id) {
        // console.log('Group specific notification', notification.recipient_group_id, notification)
        return notification.recipient_group_id == group_id;
      }

      // in all other cases, it is role specific
      return notification.recipient_role_id == role_id
    });
  }

  unreadNotifications(pipeline: NotificationCentre.IUserMessage[] = undefined) {
    let filter = this.getNotificationsByRole();
    if(pipeline) {
      filter = pipeline
    }

    return filter.filter(notification => notification.read == NotificationCentre.ReadStatus.UNREAD);
  }

  markAsRead(notification_id: string | number) {
    let notification = this.notifications.find(notification => notification.id === notification_id);
    if(!notification || notification.read) return;
    notification.read = NotificationCentre.ReadStatus.READ;
    
    this.auth.apiPatch(this.routes.NTF_USER_NOTIFICATIONS, notification.id, {read: true})
      .then((res: any) => {
        console.log('markAsRead', res)
      })
  }

  markAsArchived(notification_id: string | number, status =  NotificationCentre.ArchiveStatus.ARCHIVED) {
    let notification = this.notifications.find(notification => notification.id === notification_id);
    if(!notification) return;

    notification.archived = status;
    
    this.auth.apiPatch(this.routes.NTF_USER_NOTIFICATIONS, notification.id, {archived: status})
      .then((res: any) => {
        console.log('markAsArchived', res)
      })
  }

  async getMessageDetails(notification_id: string | number) {
    let notification = this.notifications.find(notification => notification.id === notification_id);
    if(!notification) return;

    const message = await this.auth.apiGet(this.routes.NTF_USER_NOTIFICATIONS, notification.id)
    console.log('getMessageDetails', message)
    return message;
  }
}
