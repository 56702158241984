import { IPanelLink } from "./type";
import { BC_FSA_MINISTRY_ADMIN_VIEWS } from '../../../ui-ministryadmin/view-ministryadmin-dashboard/data/views' 

const items: IPanelLink[] = BC_FSA_MINISTRY_ADMIN_VIEWS.map(view => {
  return {
    caption: view.caption,
    routerLink: `/en/ministry-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled
  }
}) 

export default items