<div class="dont-print" *ngIf="!hasSidebar && !isLoginPage"> 
  <div class="slab-lang">
    <div>
      <button 
        *ngFor="let lang of langs"
        (click)="setLang(lang.code)" 
        class="button is-small"
        [class.is-light]="!isLangActive(lang.code)"
        [class.is-info]="isLangActive(lang.code)"
      >{{lang.caption}}</button>
      <button
        *ngIf="langService.isUsingLiveTranslations()"
        class="button is-small is-danger"
        (click)="langService.deactivateLiveTranslations()"
      >LIVE UPDATE</button>
    </div>
    <div style="display: flex; flex-direction: row;">
      <div style="display: flex; flex-direction: row; align-items: center; margin-inline: 1em;" *ngIf="isLoggedIn()  && false">
        <a routerLink="/en/test-auth/notifications">
          <div style="display: flex; align-items: center">
            <i style="font-size: 1.25em"class="fas fa-bell" aria-hidden="true"></i>&nbsp;{{getNotificationsText()}}
          </div>
        </a>
        <!-- <i *ngIf="notifications.hasUnreadPersonal" class="fas fa-circle" style="color: red; font-size: 0.4em; margin-top: -2em"></i> -->
      </div>
      <div style="display: flex; flex-direction: row; align-items: center" *ngIf="isLoggedIn()">
        <a routerLink="/{{langService.c()}}/message-centre">
          <div style="display: flex; align-items: center">
            <i style="font-size: 1.25em"class="fas fa-bell" aria-hidden="true"></i>&nbsp;
            <tra slug="lbl_centre_messag"></tra>
            <span *ngIf="notifications.unreadNotifications().length > 0">&nbsp;({{notifications.unreadNotifications().length}})</span>
          </div>
        </a>
        <i *ngIf="notifications.unreadNotifications().length > 0" class="fas fa-circle" style="color: red; font-size: 0.4em; margin-top: -2em"></i>
      </div>
    </div>
  </div>
  <div class="slab-site-account" *ngIf="!hasSidebar">
    <div class="site-title">
      <a *ngIf="!isLoginHidden" class="is-title" [routerLink]="getHomeScreenRoute()">
        <tra [slug]="getBranding()"></tra>
      </a> 
      <!-- <a routerLink="/en/test-auth/dashboard">Authoring</a>
      <a routerLink="/en/test-ctrl/dashboard">Test Control</a> -->
    </div>
    <div class="profile-formlet">
      <div *ngIf="!userInfo">
        <a [routerLink]="getLoginRoute()" >
          <tra slug="title_login"></tra>
        </a>
      </div>
      <div *ngIf="userInfo">
        <div [ngSwitch]="!!accountInfo" class="user-display-name">

          <span *ngIf="newMessage" (click)="onClickNewMessage()">
            <a [routerLink]="'/en/chat/' + messagePoolId"> <i class="fa fa-envelope" aria-hidden="true"></i> </a>|
          </span>

          <span *ngSwitchCase="true"> <a [routerLink]="accountInfo">{{getUserDisplayName()}}</a>  </span>
          <span *ngSwitchCase="false">{{getUserDisplayName()}}</span>
        </div>
        <div style="margin-bottom: 1em; opacity:0.8; font-size:0.7em;">
          <div class="timezone-indicator"><b>Timezone: </b>{{timeZone}}</div>
        </div>
        <div>
          <button class="button is-small is-outlined is-danger" style="margin-right:0em ;" (click)="logout()">
              <tra slug="title_logout"></tra>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="color-strip" *ngIf="!hasSidebar" [ngStyle]="{'background-color':getStripColor()}"></div>
  <div class="slab-breadcrumb">
    <div class="crumb-element">
      <a *ngIf="!isBreadcrumbMenuEnabled()" [routerLink]="getDashboardRoute()"><i class="fa fa-home"></i></a>
      <a class="crumb" (click)="toggleMenu()" style="font-weight:600" *ngIf="isBreadcrumbMenuEnabled()">Menu</a>
      <span *ngIf="breadcrumbPath && breadcrumbPath.length" class="crumb-delim">/</span>
    </div>
    <div class="crumb-element" *ngFor="let crumb of breadcrumbPath; let isLast = last">
      <a class="crumb" [routerLink]="crumb.route" [queryParams]="crumb.params">{{langService.tra(crumb.caption)}}</a>
      <span *ngIf="!isLast" class="crumb-delim">/</span>
    </div>
  </div>
  <div *ngIf="isMenuRevealed" class="slab-breadcrumb buttons" style="border-top:1px solid #ccc;">
    <a class="button is-small" routerLink="/{{langService.c()}}/applicant/landing"><tra slug="tab_test_app"></tra></a>
    <a class="button is-small" routerLink="/{{langService.c()}}/testadmin/landing"><tra slug="tab_test_admin"></tra></a>
  </div>
</div>

<div class="dont-print simple-header has-sidebar" [class.is-opaque]="isOpaque" *ngIf="hasSidebar">
  <div class="slab-breadcrumb">
    <div class="crumb-element">
      <a (click)="goBackHome()" role="link"  [attr.aria-label]="langService.tra('lbl_home')" tabindex="0"><i class="fa fa-home"></i></a>
      <span *ngIf="breadcrumbPath && breadcrumbPath.length" class="crumb-delim">/</span>
    </div>
    <div class="crumb-element" *ngFor="let crumb of breadcrumbPath; let isLast = last">
      <a class="crumb" [routerLink]="crumb.route" [queryParams]="crumb.params">{{langService.tra(crumb.caption)}}</a>
      <span *ngIf="!isLast" class="crumb-delim">/</span>
    </div>
  </div>
  <div style="display: flex; flex-direction: row; align-items: center">
    <button *ngIf="!hideSupportBtn" (click)="supportReqActivate()" class="support-link">
      <tra whitelabel="tech_support" slug="title_feedback"></tra>
    </button>
    <ng-content></ng-content>
    <div style="display: flex; flex-direction: row; align-items: center; margin-inline: 1em;" *ngIf="isLoggedIn() && !hideMessageCentre">
      <a routerLink="/{{langService.c()}}/message-centre">
        <div style="display: flex; align-items: center">
          <i style="font-size: 1.25em"class="fas fa-bell" aria-hidden="true"></i>&nbsp;
          <tra slug="lbl_centre_messag"></tra>
          <span *ngIf="notifications.unreadNotifications().length > 0">&nbsp;({{notifications.unreadNotifications().length}})</span>
        </div>
      </a>
      <i *ngIf="notifications.unreadNotifications().length > 0" class="fas fa-circle" style="color: red; font-size: 0.4em; margin-top: -2em"></i>
    </div>
    <!-- <div *ngIf="!isEnglish()" class="mutable-lang-select">
      <a (click)="setLang('en')" style="margin-left:0.3em;">English</a>
    </div>
    <div *ngIf="isEnglish()" class="mutable-lang-select">
      <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/France_flag%201/1601053949477/France_flag%201.png">
      <a (click)="setLang('fr')">Français</a>
    </div> -->
  </div>
</div>

<div class="dont-print simple-header is-light" *ngIf="isLoginPage">
  <div>
    <div class="slab-breadcrumb">
      <div class="crumb-element">
        <a [routerLink]="getDashboardRoute()" ><i class="fa fa-home"></i></a>
        <span *ngIf="breadcrumbPath && breadcrumbPath.length" class="crumb-delim">/</span>
      </div>
      <div class="crumb-element" *ngFor="let crumb of breadcrumbPath; let isLast = last">
        <a class="crumb" [routerLink]="crumb.route" [queryParams]="crumb.params">{{langService.tra(crumb.caption)}}</a>
        <span *ngIf="!isLast" class="crumb-delim">/</span>
      </div>
    </div>
    <div>
      <img *ngIf="isEnglish()"  style="max-width: 9em;" [src]="getWhitelabelUrl('header_logo_en')">
      <img *ngIf="!isEnglish()" style="max-width: 9em;" [src]="getWhitelabelUrl('header_logo_fr')">
    </div>
  </div>
  <div *wlCtx="'IS_LANG_HEADER_SWITCH'">
    <div *ngIf="!isEnglish()" class="mutable-lang-select">
      <a (click)="setLang('en')" style="margin-left:0.3em;">English</a>
    </div>
    <div *ngIf="isEnglish()" class="mutable-lang-select">
      <!-- <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/France_flag%201/1601053949477/France_flag%201.png"> -->
      <a (click)="setLang('fr')">Français</a>
    </div>
  </div>
</div>