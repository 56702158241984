<!-- <capture-image></capture-image> -->
<!-- <input type="file" /> -->

<input *ngIf="!isLocked" type="file" (change)="startUpload($event.target.files)">

<div *ngIf="getImgUrl() && !isRequestingReupload" style="margin-top:1em; font-size:0.8em;">
    <p *ngIf="!isLocked">This is what you uploaded:</p>
    <div >
        <img [src]="getSanitizedImageUrl()">
    </div>
</div>
<div *ngIf="isLocked && !getImgUrl()">Student did not upload the file</div>

