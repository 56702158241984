import { Injectable } from '@angular/core';
import { AssessmentCode } from '../ui-schooladmin/data/mappings/eqao-g9';

export interface IStuWritingConfig {
  [uid: number]: boolean
}
@Injectable({
  providedIn: 'root'
})
export class OnlineOrPaperService {
  public isPaper: boolean = false;

  public paperStudents = new Set();
  public onlineStudents = new Set();

  constructor() { }

  initStudentsWritingType(stuWriteConfig: IStuWritingConfig) {
    this.paperStudents = new Set();
    this.onlineStudents = new Set();
    for (const uid in stuWriteConfig) {
      const isPaper = stuWriteConfig[uid];
      isPaper ? !this.paperStudents.has(uid) && this.paperStudents.add(+uid) : !this.onlineStudents.has(uid) && this.onlineStudents.add(+uid);
    }
  }

  addStudentWritingType(studentUid: number, isPaper: boolean) {
    isPaper ? this.paperStudents.add(studentUid) : this.onlineStudents.add(studentUid);
  }

  toggleStudentWritingType(toPaper: boolean, uid: number) {
    if (toPaper) {
      this.onlineStudents.delete(uid);
      this.paperStudents.add(uid);
    } else {
      this.paperStudents.delete(uid);
      this.onlineStudents.add(uid);
    }
  }

  toggle() {
    this.isPaper = !this.isPaper;
  }

  getPaperVal(IsCrScanDefault, curricShort: AssessmentCode) {
    if(IsCrScanDefault != null) {
      return IsCrScanDefault == '1'
    } else {
      return this.getDefaultIsPaperValue(curricShort);
    }
  }

  private getStudentUids(isPaper: boolean) {
    const uids = [];
    let studentsSet = isPaper ? this.paperStudents : this.onlineStudents;
    for (const uid of studentsSet.values()) {
      uids.push(uid);
    }
    return uids;
  }

  getPaperStudents() {
    return this.getStudentUids(true);
  }

  getOnlineStudents() {
    return this.getStudentUids(false);
  }

  setIsPaper(IsCrScanDefault, curricShort: AssessmentCode) {
    this.isPaper = this.getPaperVal(IsCrScanDefault, curricShort);
  }

  getDefaultIsPaperValue(curricShort: AssessmentCode) {
    switch(curricShort) {
      case AssessmentCode.EQAO_G3:
      case AssessmentCode.NBED_MATH8:
      case AssessmentCode.NBED_MATH6:
        return true;
      case AssessmentCode.EQAO_G6:
      default:
        return false;
    }
  }
}
