<div class="fsa-schools-not-started-report">
    <bc-export-button [disabled]="pagination.isLoading" [large]="true" (export)="export()"></bc-export-button>
    <div class="report-title-row">
        <div class="report-title">Schools Not Started the Online FSA and CR Score Entry</div>
        <div class="horizontal-grow"></div>
        <a [routerLink]="getBackRoute()" class="back-to-reports">Back to Report List</a>
    </div>
    <div class="report-description">
        Use this table to view schools who have not yet started any of FSA components (SR for Numeracy and Literacy, and
        CR score entry). You can view schools of a specific district and filter to specific grades or languages using
        the drop down menus.
    </div>
    <div class="dropdown-selectors">
        <div class="grade-selector dropdown-selector">
            <span class="selector-label">Viewing:</span>
            <select [(ngModel)]="selectedGradeOption" (change)="onSelectedGradeChange($event)"
                [disabled]="pagination.isLoading">
                <option *ngFor="let grade of gradeOptions" [ngValue]="grade">{{ grade.caption }}
                </option>
            </select>
        </div>
    </div>
    <div class="cover-with-loading">
        <div *ngIf="pagination.isLoading" class="reports-loader">
            <div></div>
        </div>
        <bc-paginator [paginator]="pagination" (refresh)="updateTable()"></bc-paginator>
        <div class="table-section">
            <table>
                <col>
                <col>
                <col>
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <thead>
                    <tr>
                        <th *ngFor="let headingToSortBy of getHeadingToSortBy()" rowspan="2" class="no-border">
                            <div>
                                <div (click)="changeOrderBy(headingToSortBy.sortBy)"
                                    *ngIf="!headingToSortBy.sortDisabled">
                                    <span>{{ headingToSortBy.heading }}</span>
                                    <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'asc')" class="fa fa-caret-up"></i>
                                    <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'desc')" class="fa fa-caret-down"></i>
                                </div>
                                <div *ngIf="headingToSortBy.sortDisabled" class="sort-disabled">
                                    <span>{{ headingToSortBy.heading }}</span>
                                </div>
                                <i *ngIf="!headingToSortBy.filterDisabled" class="fa fa-filter" aria-hidden="true"
                                    (click)="toggleShowFilter(headingToSortBy.sortBy)"></i>
                            </div>
                            <div *ngIf="isFilterVisible(headingToSortBy.sortBy)">
                                <input type="text" (input)="updateFilter($event, headingToSortBy.sortBy)"
                                    [value]="getFilterValue(headingToSortBy.sortBy)">
                            </div>
                        </th>
                        <th colspan="2" scope="colgroup" *ngFor="let headingToSortBy of getComponentsHeadingToSortBy()">
                            <div>
                                <div>
                                    <span>{{headingToSortBy.heading}}</span><br><span>{{headingToSortBy.subheading}}</span>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">Status</span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">Submission Progress</span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">Status</span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">Submission Progress</span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">Status</span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">Submission Progress</span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">Status</span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">Submission Progress</span></div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of reportTable, index as i" [class.odd]="row._isOdd">
                        <td><span *ngIf="row._isInfo">{{row.school_name}}</span></td>
                        <td><span *ngIf="row._isInfo">{{formatSchoolCode(row.school_foreign_id)}}</span></td>
                        <td><span *ngIf="row._isInfo">{{row.school_type}}</span></td>
                        <td>Grade {{row.grade}}</td>
                        <td>
                            <div [class.not-started]="getStatus(row.sr_literacy, row.enrollment) == 'Not Started'">
                                {{getStatus(row.sr_literacy, row.enrollment)}}
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="getStatus(row.sr_literacy, row.enrollment) != 'Not Started'">
                                ({{getDivisionPercentageDisplay(row.sr_literacy,
                                row.enrollment)}}%)
                            </ng-container>
                        </td>


                        <td>
                            <div [class.not-started]="getStatus(row.sr_numeracy, row.enrollment) == 'Not Started'">
                                {{getStatus(row.sr_numeracy, row.enrollment)}}
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="getStatus(row.sr_numeracy, row.enrollment) != 'Not Started'">
                                ({{getDivisionPercentageDisplay(row.sr_numeracy,
                                row.enrollment)}}%)
                            </ng-container>
                        </td>


                        <td>
                            <div [class.not-started]="getStatus(row.cr_literacy, row.enrollment) == 'Not Started'">
                                {{getStatus(row.cr_score_entry, row.enrollment)}}
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="getStatus(row.cr_literacy, row.enrollment) != 'Not Started'">
                                ({{getDivisionPercentageDisplay(row.cr_literacy,
                                row.enrollment)}}%)
                            </ng-container>
                        </td>

                        <td>
                            <div [class.not-started]="getStatus(row.cr_numeracy, row.enrollment) == 'Not Started'">
                                {{getStatus(row.cr_numeracy, row.enrollment)}}
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="getStatus(row.cr_numeracy, row.enrollment) != 'Not Started'">
                                ({{getDivisionPercentageDisplay(row.cr_numeracy,
                                row.enrollment)}}%)
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>