import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IContentElementMcqOption } from '../../ui-testrunner/element-render-mcq/model';
import { EditingDisabledService } from '../editing-disabled.service';
import { bindFormControls } from '../services/data-bind';

@Component({
  selector: 'element-config-mcq-option-text',
  templateUrl: './element-config-mcq-option-text.component.html',
  styleUrls: ['./element-config-mcq-option-text.component.scss']
})
export class ElementConfigMcqOptionTextComponent implements OnInit {

  @Input() mcqOption:IContentElementMcqOption;
  @Output() change =  new EventEmitter<string>();

  public content = new FormControl();

  constructor(private editingDisabled: EditingDisabledService) { }

  ngOnInit() {

    const formControls =  [ {f: this.content, p:'content'},];
    this.content.valueChanges.subscribe(()=>{
      this.change.emit(this.content.value)
    })

    bindFormControls(this.mcqOption, formControls)
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

}
