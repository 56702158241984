import { Component, Input, OnInit } from '@angular/core';
import { IAccessibilitySetting } from 'src/app/ui-item-maker/item-set-editor/models/assessment-framework';
import { AccessibilityService } from '../accessibility.service';

@Component({
  selector: 'accessibility-settings-modal',
  templateUrl: './accessibility-settings-modal.component.html',
  styleUrls: ['./accessibility-settings-modal.component.scss']
})
export class AccessibilitySettingsModalComponent implements OnInit {

  
  @Input() accessibilitySettings : { [key:string] : IAccessibilitySetting}
  
  Object = Object;
  constructor(public accessibility: AccessibilityService) { }

  ngOnInit(): void {
  }

}
