import { IContentElementMcq } from "../ui-testrunner/element-render-mcq/model";
import { IQuestionConfig } from "../ui-testrunner/models";

export const objectToNumMap = ( obj:{[key:number]: any}) => {
    const map = new Map();
    Object.keys(obj).forEach(key => {
      map.set(+key, obj[key]);
    })
    return map;
}

const isEResCorrect = (eRes, eAns) => {
  if (eRes && eRes.selections) {
    if (eRes.selections[0] && eRes.selections[0].i === eAns.optionIndex) {
      return true;
    }
  }
  return false;
}

const getQuestionById = (id: number, questions:IQuestionConfig[]) => {
  let question: IQuestionConfig;
  questions.forEach(_question => {
    if (_question.id === id) {
      question = _question;
    }
  });
  return question;
}

export const feedAnswersToQuestions = (testForm: any, answerKey: any, questions:IQuestionConfig[]) => {
  testForm.currentTestDesign.sections.forEach(section => {
    section.questions.forEach(qId => {
      const qAns = answerKey[qId];
      const q = getQuestionById(qId, questions);
      // to do : this is fixed to MCQ
      Object.keys(qAns).forEach(entryId => {
        const eAns = qAns[entryId];
        // to do: assuming flat question structures
        q.content.forEach((el:IContentElementMcq) => {
          if (el.entryId === +entryId) {
            el.options.forEach( (option, optionIndex) => {
              if (eAns.optionIndex === optionIndex) {
                option.isCorrect = true;
              }
            });
          }
        });
      });
    });
  }); 
}

export const scoreQuestions = (testForm:any, answerKey:any) => {
  let questionScores = new Map<number, boolean>();

  const states = testForm.questionStates;
  testForm.currentTestDesign.sections.forEach(section => {
    section.questions.forEach(qId => {
      const qAns = answerKey[qId];
      const qRes = states[qId];
      let isAllCorrect = true;

      if (!qRes) {
        isAllCorrect = false;
      } else {
        Object.keys(qAns).forEach(entryId => {
          const eAns = qAns[entryId];
          const eRes = qRes[entryId];
          isAllCorrect = isAllCorrect && isEResCorrect(eRes, eAns);
        });
      }
      const isCorrect = isAllCorrect;
      questionScores.set(qId, isCorrect);
    });
  });

  return questionScores;
}