import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IContentElementInput, InputFormat } from 'src/app/ui-testrunner/element-render-input/model';
import { IContentElementMath } from 'src/app/ui-testrunner/element-render-math/model';
import { TextParagraphStyle } from 'src/app/ui-testrunner/element-render-text/model';
import { ElementType } from "src/app/ui-testrunner/models";
import { EditingDisabledService } from '../editing-disabled.service';
import { bindFormControls } from '../services/data-bind';

@Component({
  selector: 'element-config-math',
  templateUrl: './element-config-math.component.html',
  styleUrls: ['./element-config-math.component.scss']
})
export class ElementConfigMathComponent implements OnInit {

  @Input() element:IContentElementMath;

  paragraphStyle = new FormControl('');
  isShowAdvancedOptions = new FormControl(false);

  paragraphStyles = [
    {id: TextParagraphStyle.HEADLINE, caption:'Extra Large'},
    {id: TextParagraphStyle.HEADLINE_SMALL, caption:'Large'},
    {id: TextParagraphStyle.REGULAR, caption:'Regular'},
    {id: TextParagraphStyle.SMALL, caption:'Small'},
  ]

  constructor(private editingDisabled: EditingDisabledService) { }

  ngOnInit() {
    // console.log('mathquill in angular', (window as any).MathQuill);

    const formControls = [
      {f: this.isShowAdvancedOptions, p:'isShowAdvancedOptions'},
      {f: this.paragraphStyle, p:'paragraphStyle'},
    ];

    bindFormControls(this.element, formControls)
  }

  convertToInput(){
    let element:IContentElementInput = <any>this.element;
    element.isShowAdvancedOptions = false;
    element.elementType = ElementType.INPUT
    element.format = InputFormat.ALGEBRA;
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

}
