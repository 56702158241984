import { Injectable } from '@angular/core';
import { IContentElement,IQuestionConfig } from '../../ui-testrunner/models/index';
import { Subject, BehaviorSubject } from 'rxjs';
import { ItemMakerService } from '../item-maker.service';
import {
  AssetFieldGroup,
  EAssetField,
  IAssetFieldGroup,
  ILibraryAsset
} from '../asset-details/types';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { MyInstitutionService } from 'src/app/ui-testadmin/my-institution.service';
import {ItemBankCtrl} from "../item-set-editor/controllers/item-bank";
import { filter } from 'rxjs/operators';

export interface IAssetLibraryConfig {
  element?: IContentElement,
  initEnabled?: boolean,
  focusedField?: string
  itemElement?: IQuestionConfig;
  assetId?: number;
}

export enum AssetTypeFilter {
  EXCLUDE_ITEMS = "exclude item",
  ONLY_ITEMS = "only item",
  INCLUDE_ALL = "include all"
}

@Injectable({
  providedIn: 'root'
})
export class AssetLibraryService {

  public observer:BehaviorSubject<any> = new BehaviorSubject({});

  public assetIdFilter: number[];

  public typeFilter: AssetTypeFilter

  public isSaving: {[key:number] : boolean} = {};

  fieldGroups: IAssetFieldGroup[];

  constructor(private myItems: ItemMakerService,
    private auth: AuthService,
    private routes: RoutesService,
              private myInst: MyInstitutionService,
              private itemBankCtrl: ItemBankCtrl
  ) {
    this.fieldGroups =  [{
        id: AssetFieldGroup.ASSET_DESCRIPTION,
        caption: 'auth_asset_desc',
        fields: [], //populated in asset-details
        isExpanded: false
      }, {
        id: AssetFieldGroup.ACCESSIBILITY,
        caption: 'auth_accessibility',
        fields: [],
        isExpanded: false
      },{
        id: AssetFieldGroup.USE_IN_ASSESSMENTS,
        caption: 'auth_use_in_assessments',
        fields: [],
        isExpanded: false
      }, {
        id: AssetFieldGroup.COPYRIGHT_INFO,
        caption: 'auth_copyright_info',
        fields: [],
        isExpanded: false
      }, {
        id: AssetFieldGroup.LICENSE_DETAILS,
        caption: 'auth_license_details',
        fields: [],
        isExpanded: false
      }, {
        id: AssetFieldGroup.METADATA_CHECKLIST,
        caption: 'auth_metadata_checklist',
        fields: [],
        isExpanded: false
      }, {
        id: AssetFieldGroup.STATUS,
        caption: 'auth_status_col',
        fields: [],
        isExpanded: false
      }, {
        id: AssetFieldGroup.VRETTA_INTERNAL,
        caption: 'auth_vretta_internal',
        fields: [],
        isExpanded: false
      }];
  }

  update(contentElement:IContentElement, initEnabled: boolean, focusedField?: string){    
    const config:IAssetLibraryConfig = {
      element:contentElement,
      initEnabled,
      focusedField,
    }
    this.typeFilter = AssetTypeFilter.EXCLUDE_ITEMS
    this.observer.next(config);
  }

  updateAsset(assetId: number){
    const config: IAssetLibraryConfig = { assetId }
    this.typeFilter = AssetTypeFilter.EXCLUDE_ITEMS
    this.observer.next(config);
  }

  create(item: IQuestionConfig, initEnabled: boolean, focusedField?: string){
    const config: IAssetLibraryConfig = {
      itemElement: item,
      initEnabled,
      focusedField,
    }
    this.typeFilter = AssetTypeFilter.ONLY_ITEMS
    this.observer.next(config);
  }

  importItem(currItem: IQuestionConfig) {
    const config: IAssetLibraryConfig = {
      itemElement: currItem,
      initEnabled: false
    }
    this.typeFilter = AssetTypeFilter.ONLY_ITEMS
    this.observer.next(config)
  }

  clearAssetIdFilter() {
    this.assetIdFilter = undefined;
  }
  
  isLockAvail() {
    return this.myItems.hasGroupsAsSuper;
  }

  isSavingAsset(asset) {
    return this.isSaving[asset.id];
  }
  
  saveAsset(asset: ILibraryAsset) {
    this.isSaving[asset.id] = true;

    let {custom_fields, ...data} = <any>asset;
    
    if(custom_fields) {
      custom_fields = JSON.stringify(custom_fields);
    } else {
      custom_fields = null;
    }

    data.custom_fields = custom_fields;
    console.log(data)
    return this.auth.apiPatch(this.routes.TEST_AUTH_ASSET, asset.id, data, this.myInst.constructPermissionsParams()).then((res) => {
      this.isSaving[asset.id] = false;
      return res;
    });
  }

  toggleLock(asset: ILibraryAsset) {
    asset.is_locked = !asset.is_locked;
    this.saveAsset(asset);
  }

  getAssetPreviewField(asset: ILibraryAsset): EAssetField {
    let field: EAssetField = null;
    if (asset.snapshots) {
      field = EAssetField.SNAPSHOTS;
    } else {
      const firstProp: EAssetField = this.itemBankCtrl.isLang('en') ? EAssetField.ASSET_FILE : EAssetField.ASSET_FILE_FR;
      let propOrder: EAssetField[] = [firstProp, firstProp === EAssetField.ASSET_FILE ? EAssetField.ASSET_FILE_FR : EAssetField.ASSET_FILE];
      if (asset[propOrder[0]]) {
        field = propOrder[0]
      } else if (asset[propOrder[1]]) {
        field = propOrder[1];
      }
    }
    return field;
  }

}
