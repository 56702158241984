import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IPoint } from "./virtual-tools";
import { IExtendedTools } from "../element-render-virtual-tools.component";

enum ACTIONS {
  FLIPH = "FLIPH",
  FLIPV = "FLIPV",
  DUPLICATE = "DUPLICATE",
  DELETE = "DELETE"
}
export class FreehandCircle extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  polygon: PIXI.Graphics;
  vertices: PIXI.Graphics;
  lines: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  vFlipSprite: PIXI.Sprite;
  hFlipSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  deleteSprite: PIXI.Sprite;
  drawnCircle: PIXI.Graphics;
  circleColor: string;
  lineTools: IExtendedTools;
  circleRadius: number;
  isGlobalResizing: boolean;
  isGlobalDragging: boolean;

  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.element = element;
    this.spriteLoader = new SpriteLoader();
    this.backgroundSprite = backgroundSprite;
    this.vFlipSprite = new PIXI.Sprite();
    this.hFlipSprite = new PIXI.Sprite();
    this.dupeSprite = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();
    this.circleColor = this.element.fhPolygonColor || '#03DAC6';

    this.loadAssets().then(this.initSprites);

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.addDrawVertexListener();

    this.vertices = new PIXI.Graphics();
    this.lines = new PIXI.Graphics();
    this.lines.zIndex = 1;
    this.vertices.zIndex = 8;
    this.circleRadius = 12;
    this.addGraphic(this.vertices);
    this.addGraphic(this.lines);

    this.lineTools = {
      parent: 'polygon',
      colors: [
        this.element.fhPolygonColor,
        '#c43f2e',
        '#2a91d6'
      ],        
      colorHandler: (color: string) => {
        this.circleColor = color;
      },
      tools: [
      {
        id: 'delete',
        action: () => this.clearDrawnCircles(),
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
      },
      {
        id: 'colorPicker',
        isColorPick: true,
        colorController: this.circleColor,
        action: () => {},
      },
    ]}

    // Render scene
    this.render();
  }

  initSprites = ({resources}) => {
    this.vFlipSprite.texture = resources.vflip.texture;
    this.hFlipSprite.texture = resources.hflip.texture;
    this.dupeSprite.texture = resources.duplicate.texture;
    this.deleteSprite.texture = resources.delete.texture;
  }
  
  circleContainer: PIXI.Graphics
  completeCircle(points: IPoint[], isFromResize?: boolean) {
    // Translate points to 0,0
    let transX = 0;
    let transY = 0;
    for(let i = 0; i< points.length; i++) {
      if(i == 0) {
        transX = points[0].x;
        transY = points[0].y;
        points[0].x = 0;
        points[0].y = 0;
      } else {
        points[i].x -= transX;
        points[i].y -= transY;
      }
    }

    this.vertices.removeChildren();

    const circleContainer = new PIXI.Graphics();
    const fill = {color: this.circleColor, opacity: this.element.fhCircleOpacity * 0.01 || 0.8};
    const circle = this.drawCircle(0, 0, fill, points, false, this.circleRadius);
    circle.pivot.set(0.5);
    circle.interactive = true;
    circleContainer.addChild(circle);
    circleContainer.zIndex = 7;
    const center = this.getShapeCenter(points);

    circleContainer.pivot.set(center.x,center.y);
    circleContainer.position.set(transX+center.x, transY+center.y);
    

    this.addGraphic(circleContainer);
    this.drawnCircle = circleContainer;

    if (!isFromResize){
      const resizePoint = this.drawResizePoint({x: center.maxX + this.circleRadius, y: 0}, circleContainer);
      resizePoint.alpha = 0;
      this.addSelectCircleListener(circle, circleContainer, resizePoint, [...points]);
      // this.addDragAndResizeListener(circleContainer);
    }
    

    return circleContainer;
  }

  drawMenu(obj: PIXI.Graphics, container: PIXI.Graphics, points: IPoint[]) {
    const center = this.getShapeCenter(points);
    const menu = new PIXI.Graphics();
    const menuYOffset = 15;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset
    const menuHeight = 30;
    const menuWidth = 95;
    menu.beginFill(0x645f73);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.position.set(center.minX, menuY);
    menu.endFill();

    menu.alpha = 0;

    // Menu icons
    const hFlip = new PIXI.Sprite();
    hFlip.texture = this.hFlipSprite.texture;
    hFlip.scale.set(0.03);
    hFlip.anchor.set(0,0.5);
    hFlip.y = menuHeight / 2;
    hFlip.x = 10;
    hFlip.interactive = true;

    const vFlip = new PIXI.Sprite();
    vFlip.texture = this.vFlipSprite.texture;
    vFlip.scale.set(0.03);
    vFlip.anchor.set(0,0.5);
    vFlip.y = menuHeight / 2;
    vFlip.x = hFlip.x + hFlip.width + 5;
    vFlip.interactive = true;

    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    dupe.y = menuHeight / 2;
    dupe.x = vFlip.x + vFlip.width + 5;
    dupe.interactive = true;

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = dupe.x + dupe.width + 5;
    del.interactive = true;

    menu.addChild(hFlip);
    menu.addChild(vFlip);
    menu.addChild(dupe);
    menu.addChild(del);
    this.addMenuListeners(obj, ACTIONS.FLIPH, hFlip, points);
    this.addMenuListeners(obj, ACTIONS.FLIPV, vFlip, points);
    this.addMenuListeners(obj, ACTIONS.DUPLICATE, dupe, points);
    this.addMenuListeners(container, ACTIONS.DELETE, del, points);
    
    container.addChild(menu);

    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, points: IPoint[]) {
    const center = this.getShapeCenter(points);
    let func;
    let flipped = false;
    option.cursor = 'pointer';
  
    if(action == ACTIONS.FLIPH) {
      func = (e) => {
        if(!flipped) {
          obj.scale.y = -1;
          obj.y = center.y * 2;
        } else {
          obj.scale.y = 1;
          obj.y = 0;
        }
        flipped = !flipped

        this.render();
      }
    } else if(action == ACTIONS.FLIPV) {
      func = (e) => {
        if(!flipped) {
          obj.scale.x = -1;
          obj.x = center.x * 2;
          this.render();
        } else {
          obj.scale.x = 1;
          obj.x = 0;
          this.render();
        }

        flipped = !flipped
      }
    } else if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        const circleContainer = this.completeCircle([...points]);
        circleContainer.position.set(this.circleContainer.x + 20, this.circleContainer.y + 20);
        this.render();
      }
    } else if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.deleteObject(obj);
        this.render();
      }
    }
    option.on('pointerup', func);
  }

  deleteObject(obj: PIXI.Graphics) {
    obj.removeChildren();
    obj.clear();
  }

  circleBorder: PIXI.Graphics
  addSelectCircleListener(obj: PIXI.Graphics, container: PIXI.Graphics, resizePoint: PIXI.Graphics, points: IPoint[]) {
    let selected = false;
    let dragging = false;
    let resizing = false;
    let outline;
    let outterBorder;
    let menu;

    const onClick = (e) => {
      dragging = false;
    }
    const checkDragging = (e) => {
      dragging = this.isGlobalDragging;
      resizing = this.isGlobalResizing;
    }
    const toggleMenu = (e) => {
      console.log('toggling menu, isGlobalDragging: ', this.isGlobalDragging)
      if(!this.isDrawMode && !dragging && !this.isGlobalDragging && !resizing) {
        selected = !selected;
        if(selected) {
          outterBorder = this.drawSelectedCircleBorder(container, points);
          this.circleBorder = outterBorder;
          menu = this.drawMenu(obj, container, points);
          outterBorder.alpha = 1;
          menu.alpha = 1;
          resizePoint.alpha = 1;
        } else {
          outterBorder.destroy();
          this.circleBorder = undefined;
          menu.destroy();
          resizePoint.alpha = 0;
        }

        this.render();
      }
    }
    obj.on('pointerdown', onClick).on('pointermove', checkDragging).on('pointerup', toggleMenu);
  }

  points: IPoint[] = [];
  addDrawVertexListener() {
    const addVertice = (e) => {
        if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && !this.isGlobalResizing) {
            const {x,y} = e.data.getLocalPosition(this.stage);
            const point = {x, y};
            this.points.push(point);
            this.drawShapeVertices(this.vertices, this.lines, this.points, () => {});
          
            if(this.points.length >= 1){
                this.circleContainer = this.completeCircle(this.points);
                // this.points = [];

                this.isDrawMode = false;
                this.stage.cursor = 'default';
                this.backgroundSprite.cursor = 'default';
                this.render();
            }
        }
    }

    this.stage.on('pointerup', addVertice);
  }

  drawResizePoint(point: {x: number, y: number}, parent: PIXI.Graphics) {
    const resizePoint = new PIXI.Graphics();
    resizePoint.name = 'resizeAnchor';
    resizePoint.beginFill(0x0000, 0.9);
    resizePoint.drawCircle(point.x, point.y, 4);
    resizePoint.endFill();
    resizePoint.pivot.set(0, 0);
    resizePoint.rotation = -0.785398;

    parent.addChild(resizePoint);

    this.addDragAndResizeListener(parent, true, resizePoint);

    return resizePoint;
  }

  previousCircleContainerAnchor: PIXI.DisplayObject;
  addDragAndResizeListener(obj: PIXI.Graphics, isResize?: boolean, resizePoint?: PIXI.Graphics) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    let isResizeDragging = false
    obj.cursor = 'grab';
    obj.interactive = true;
    if (isResize){
      resizePoint.cursor = 'ew-resize'
      resizePoint.interactive = true;
    }

    const onDragStart = (e) => {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragging = true;
        initialDiffX = mousePosition.x - obj.x
        initialDiffY = mousePosition.y - obj.y
        obj.cursor = 'grabbing';
    }
    const onDragEnd = (e) => {
        isDragging = false;
        obj.cursor = 'grab';
    }
    const id = Math.floor(Math.random() * 100)

    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging && !this.isGlobalResizing) {
            this.isGlobalDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            obj.x = mousePosition.x - initialDiffX; // obj x = 3
            obj.y = mousePosition.y - initialDiffY;
            this.render();
        } else if(!isDragging) {
          this.isGlobalDragging = false;
        }
    }

    const onResizeStart = (e) => {
      isResizeDragging = true;
      this.isGlobalResizing = true;
      obj.cursor = 'grabbing';
      this.drawnCircle.removeAllListeners();
    }
    const onResizeEnd = (e) => {
      isResizeDragging = false;
      this.isGlobalResizing = false;
      obj.cursor = 'grab';
      this.previousCircleContainerAnchor.destroy();
      this.previousCircleContainerAnchor = this.drawnCircle;
      this.drawnCircle.children.forEach((child, i) => {
        this.drawnCircle.getChildAt(i).removeAllListeners();
        this.drawnCircle.getChildAt(i).destroy();
      })
      this.drawnCircle.destroy();
      this.previousCircleContainerAnchor.alpha = 0;
      this.completeCircle([{x: obj.x, y:obj.y}]);
      this.render();

    }
    const onResizeMove = (e: PIXI.InteractionEvent) => {
        if(isResizeDragging) {
          this.isGlobalResizing = true;
          const mousePosition = e.data.getLocalPosition(this.stage);
          const deltaX = mousePosition.x - obj.x; 
          const deltaY = mousePosition.y - obj.y;
          const newRadius = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
          console.log(deltaX)
          this.circleRadius = newRadius;

          this.previousCircleContainerAnchor = this.drawnCircle;
          this.drawnCircle.children.forEach((child, i) => {
            if (child.name != 'resizeAnchor'){
              this.drawnCircle.getChildAt(i).destroy()
            } else {
                this.previousCircleContainerAnchor = child;
            }
          })
          this.previousCircleContainerAnchor.alpha = 0;
          this.completeCircle([{x: obj.x, y:obj.y}], true);
          this.render();
          console.log('dragging', id)
        } else if(!isResizeDragging) {
          this.isGlobalResizing = false;
        }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove);

    if(isResize) {
      resizePoint.on('pointerdown', onResizeStart)
      .on('pointerup', onResizeEnd)
      .on('pointerupoutside', onResizeEnd)
      .on('pointermove', onResizeMove);
    }
  }

  drawSelectedCircleBorder(container: PIXI.Graphics, points: IPoint[]) {
    const center = this.getShapeCenter(points);
    const border = new PIXI.Graphics();
    border.lineStyle(2, 0x0000, 1);
    border.drawCircle(center.x, center.y, this.circleRadius);
    border.endFill();
    border.alpha = 0;

    container.addChild(border);

    return border;
  }

  isDrawMode = false;
  toggleDrawMode(mode?: boolean) {
    this.isDrawMode = mode == null ? !this.isDrawMode : mode;
    if(!this.isDrawMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
    }
    this.vertices.removeChildren();
    this.lines.removeChildren();
    this.points = [];

    this.render(); 

    return this.isDrawMode;
  }

  clearDrawnCircles() {
    this.deleteObject(this.drawnCircle);
    this.drawnCircle.destroy();
    this.drawnCircle = undefined;
    this.render();
  }

  getCircleTools() {
    return this.lineTools;
  }

  loadAssets() {
    let assets = [];
    assets.push({
        name: "hflip", 
        path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/flip-vertical/1684187809679/flip-vertical.png"
      }
    );
    assets.push({
      name: "vflip", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/flip-horizontal/1684187792111/flip-horizontal.png"
    });
    assets.push({
      name: "duplicate", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/duplicate-2/1684187819438/duplicate-2.png"
    });
    assets.push({
      name: "delete", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png"
    });

    this.spriteLoader.addSpritestoLoader(assets);
    return this.spriteLoader.loadSprites()
  }
}
