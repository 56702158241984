
<div
#elPosContainer
class="pos-container"
>
  <div cdkDropListGroup class="">
    <div class="drag-drop-containe">
      <div
        style="display: flex;
        flex-direction: row;
        flex: 1 1 0%;
        align-items: flex-start;
        flex-wrap: wrap;
        border: unset;
        height: 100%;"
        cdkDropList
        [cdkDropListData]="draggables"
        class="drag-drop-list mb-3"
        #mainDraggables
        cdkDropListSortingDisabled
        (cdkDropListDropped)="drop($event)">
        <div class="drag-el" *ngFor="let elPos of draggables" cdkDracripg style="margin-right: 10px;">
            <element-render
                *ngIf="elPos.ref.element"
                [contentElement]="elPos.ref.element"
                [isLocked]="true"
                [questionState]="{}"
            ></element-render>
        </div>
      </div>
    </div>

    <div class="painting-sculpting-list">
      <div *ngIf="!element.isInstructionsDisabled" class="question-block-label mb-3" style="text-align: center; font-size:0.8em;"  > 
        <tra-md slug="txt_default_darg_match_instr"></tra-md>
      </div>
      <div class="drag-drop-container matching" *ngFor="let elPos of targets; index as idx">
        <div class="group-title">
          <element-render
              *ngIf="elPos.ref.element"
              [contentElement]="elPos.ref.element"
              [isLocked]="true"
              [questionState]="{}"
          ></element-render>
        </div>

        <div
          cdkDropList
          [cdkDropListData]="element.targets[idx].groups"
          class="drag-drop-list"
          (cdkDropListDropped)="drop($event)">
          <div class="drag-el" *ngFor="let elPos of element.targets[idx].groups" cdkDrag style="margin: 10px 0;">
              <element-render
                  *ngIf="elPos.ref.element"
                  [contentElement]="elPos.ref.element"
                  [isLocked]="true"
                  [questionState]="{}"
              ></element-render>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>