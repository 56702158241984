<table class="results-table" [ngStyle]="getStyle()" [class.is-hi-contrast]="isHiContrast()">
    <tr>
        <th colspan="3"><tra slug="tr_results_sr_questions"></tra></th>
    </tr>
    <tr *ngIf="showScore">
        <td>
            <tra slug="tr_results_score"></tra>
        </td>
        <td>
            {{getCorrectSRScore()}}/{{totalSRScore}}
        </td>
        <td>
            ({{getSRPercentage()}}%)
        </td>
    </tr>
    <tr *ngIf="showScore"><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
    <tr>
        <td>
            <tra slug="tr_results_total_questions"></tra>
        </td>
        <td>
            {{numSRQuestions}}
        </td>
        <td>
        </td>
    </tr>
    <tr>
        <td>
            <tra slug="tr_results_correct_ans"></tra>
        </td>
        <td>
            {{numCorrectSRQuestions}}
        </td>
        <td>
        </td>
    </tr>
    <tr>
        <td>
            <tra slug="tr_results_incorrect_ans"></tra>
        </td>
        <td>
            {{getNumIncorrectSRQuestions()}}
        </td>
        <td>
        </td>
    </tr>
</table>
<div *ngIf="isCustomResultPageNote" style="margin-top: 0.1em; margin-bottom:1em; font-style: italic; font-size: 0.8em;">
    <!-- <tra style="font-style: italic;" slug="note_title"></tra> -->
    <span *ngIf="lang.c() === 'en'" [innerHTML]="customResultPageNoteEN"></span>
    <span *ngIf="lang.c() === 'fr'" [innerHTML]="customResultPageNoteFR"></span>
</div>