export const tcDataDlApiPrefix = 'public/test-ctrl/schools/data-dl/';

export interface ICodebook {
  sections: {
    id: number,
    caption: string,
    link?: string,
    dataFrames?: {
      id: number|string,
      isEnabled?:boolean,
      apiPath:string,
      caption: string,
      description: string,
      sampleDescription?: string,
    }[],
  }[]
}

export interface IDataDownloadFilters {
  dateStart: string,
  dateEnd: string,
  allowed: {
    schoolDistrictForeignId?: string,
  }
}

export const CODEBOOK_G9:ICodebook = {
  sections: [
    {
      id: 1,
      caption:'Overview and Changelog Available in Codebook',
      link:'https://docs.google.com/spreadsheets/d/1JUn2wQ4ZW3EucttQLUASi2eBHwZ1WLcHKLxVjRHvn6o/edit#gid=1222699256',
      dataFrames: [
        {
          id: 2,
          isEnabled: true,
          apiPath:'asmt-frmk/scope-groups',
          caption: 'Scope Groups',
          description: 'Identifies the top level projects managed in the Vretta system.'
        }
      ]
    },
    {
      id: 2,
      caption: 'Assessment Framework',
      dataFrames: [
        { id: 1,  isEnabled:true, apiPath:'asmt-frmk/test-designs', caption: 'Test Designs', description: 'This view contains a listing of test designs with their associated blueprint and versions.', },
        { id: 2,  isEnabled:true, apiPath:'asmt-frmk/test-design-versions', caption: 'Test Design Versions', description: 'This view contains further information on each test design version created.', },
        { id: 3,  isEnabled:true, apiPath:'asmt-frmk/reporting-components', caption: 'Reporting Components', description: 'This view contains a list of all reporting components in the test.', },
        { id: 4,  isEnabled:true, apiPath:'asmt-frmk/reporting-component-types', caption: 'Reporting Component Types', description: 'This view contains a list of all reporting component types in the test.', },
        { id: 5,  isEnabled:true, apiPath:'asmt-frmk/reporting-component-scoring-types', caption: 'Reporting Component Scoring Types', description: 'This view contains a list of all reporting component scoring types in the test.', },
        { id: 6,  isEnabled:true, apiPath:'asmt-frmk/sessions', caption: 'Sessions', description: 'This view contains a list of all the sections in the test.', },
        { id: 7,  isEnabled:true, apiPath:'asmt-frmk/stages', caption: 'Stages', description: 'This view contains a list of all the stages in the test.', },
        { id: 8,  isEnabled:true, apiPath:'asmt-frmk/modules', caption: 'Modules', description: 'This view contains a list of all the modules in the test.', },
        { id: 9,  isEnabled:true, apiPath:'asmt-frmk/routing', caption: 'Routing', description: 'This view contains a list of all the routing rules and connections in the test.', },
        // {
        //   id: '13.1',
        //   isEnabled: true,
        //   apiPath: 'asmt-frmk/score-options',
        //   caption: 'Score Options',
        //   description: 'Catalogues the available scoring options that are used for various items. Both scoring value codes and flag options are detailed here along with relevant on-screen texts.'
        // },
        // {
        //   id: '13.2',
        //   isEnabled: true,
        //   apiPath: 'asmt-frmk/item-scoring-profiles',
        //   caption: 'Item Scoring Profiles',
        //   description: 'A scoring profile is assigned to each item that is brought into a scoring window. Each scoring profile is associated with a particular item type (in the case of OSSLT, this includes Long Writing - Conventions, Long Writing - Topic Development, etc.), has associated documents with an introduction to scoring, and is used as a linking table for the scoring options and flagging options that are applicable to the item type.'
        // },
        // {
        //   id: '13.3',
        //   isEnabled: true,
        //   apiPath: 'asmt-frmk/item-scoring-profile-options',
        //   caption: 'Item Scoring Profile Options',
        //   description: 'Links the scoring options to scoring profiles (and determines order in which it is presented for that scoring profile).'
        // },
        // {
        //   id: '13.4',
        //   isEnabled: true,
        //   apiPath: 'asmt-frmk/item-scoring-profile-flags',
        //   caption: 'Item Scoring Profile Flags',
        //   description: 'Links the reporting options to scoring profiles (and determines order in which it is presented for that scoring profile).'
        // },
        // {
        //   id: '13.5',
        //   isEnabled: true,
        //   apiPath: 'asmt-frmk/batch-allocation-policies',
        //   caption: 'Batch Allocation Policies',
        //   description: 'Catalogues all information relevant to batch allocation, including the claiming a batch, calculating routing rules, determining access based on validity threshold.'
        // },
        // {
        //   id: '13.6',
        //   isEnabled: true,
        //   apiPath: 'asmt-frmk/task-component-types',
        //   caption: 'Task Component Types',
        //   description: 'Catalogues the types of tasks which scorers can complete with respect to an assigned item.'
        // },
        // {
        //   id: '13.7',
        //   isEnabled: true,
        //   apiPath: 'asmt-frmk/response-set-types',
        //   caption: 'Response Set Types',
        //   description: 'Catalogues the response set types used on the platform (validity items, qualifying tests, practice tests, etc.)'
        // },
      ]
    },
    {
      id: 3,
      caption: 'Question Bank',
      dataFrames: [
        { id: 1, isEnabled:true, apiPath:'item-bank/items', caption: 'Items', description: 'This view contains a listing of each question, with associated detail such as version, label, and psychometric variables.', },
        {
          id: '1.1',
          isEnabled: true,
          apiPath:'',
          caption: 'Item IDs',
          description: 'This view contains a listing of each question, with associated detail such as version, label, and psychometric variables.'
        },
        // { id: 2, isEnabled:true, apiPath:'item-bank/flat-item-bank', caption: 'Flattened Question Bank Export', description: 'Customization. Combines "Questions" and "Answer Key" tables into a flattened reference for the EQAO psychometric team.', },
        { id: 3, isEnabled:true, apiPath:'item-bank/test-panels', caption: 'Test Panels', description: 'This view contains a list of all test panels created.', },
        { id: 4, isEnabled:true, apiPath:'item-bank/test-panel-items', caption: 'Test Panel Items', description: 'This view contains an association between an item and its location on one more many test panels.', },
        { id: 5, isEnabled:true, apiPath:'item-bank/questionnaire', caption: 'Questionnaire', description: 'Contains a text extract of the survey questions.', },
        { id: 6, isEnabled:true, apiPath:'item-bank/questionnaire-resp-codes', caption: 'Questionnaire Response Codes', description: 'Contains a text extract of the available survey responses.', },
        {
          id: '7.1',
          isEnabled: true,
          apiPath: 'item-bank/scoring-window-items',
          caption: 'Scoring Window Items',
          description: 'Catalogues all of the Constructed Response items that are non-machine scored, and to which scorers are assigned. Additional scoring meta data is also specified here.'
        },
      ]
    },
    {
      id: 4,
      caption: 'Users, Roles, and Bookings',
      dataFrames: [
        { id: 1, isEnabled:true, apiPath:'registrations/test-windows', caption: 'Test Windows', description: 'This view contains description of all test windows on the system, including their start time and test session constraints.', },
        { id: 2, isEnabled:true, apiPath:'registrations/test-window-design-alloc', caption: 'Test Windows - Associated Designs', description: 'This view contains the associated and activated test designs within each test window.', },
        { id: 3, isEnabled:true, apiPath:'registrations/test-sessions', caption: 'Test Sessions', description: 'This view contains a list of all test sessions on the system and their relevant information.', },
        { id: 4, isEnabled:true, apiPath:'registrations/students', caption: 'Test Taker Registration Info', description: 'Includes test taker information only provided at time of registration.', },
        { id: 5, isEnabled:true, apiPath:'registrations/schools', caption: 'School Info', description: 'Includes school and board information.', },
        { id: 6, isEnabled:true, apiPath:'registrations/class', caption: 'Class Info', description: 'Includes class information aside from teacher id.', },
        { id: 7, isEnabled:true, apiPath:'registrations/teachers', caption: 'Teachers Info', description: 'Includes teacher information.', },
        { id: 8, isEnabled:true, apiPath:'registrations/school-districts', caption: 'School Board Info', description: 'Includes teacher information.', },
        {
          id: '9.1',
          isEnabled: true,
          apiPath: 'registrations/scoring-windows',
          caption: 'Scoring Windows',
          description: 'Defines the group in which scoring takes place for a certain set of items. Scorers are assigned to the test window and access is assigned by date for each scoring window here.'
        },
        {
          id: '9.2',
          isEnabled: true,
          apiPath: 'registrations/scoring-window-test-window',
          caption: 'Scoring Window Test Window',
          description: 'Defines test windows whose responses are to be included in the scoring window. This also includes special test windows which are used to store exemplars and historical validity/practice/qualifying response set material.'
        },
        {
          id: '9.3',
          isEnabled: true,
          apiPath: 'registrations/scorers',
          caption: 'Scorers',
          description: 'Composed view which lists all user accounts that have ever been provided a scoring role.'
        },
        {
          id: '9.4',
          isEnabled: true,
          apiPath: 'registrations/scorer-item-tasks',
          caption: 'Scorer Item Tasks',
          description: 'Tracks access and progress of scorers on individual tasks associated with scoring an item (this includes access of training materials, completion of practice test, successful completion of qualifying test, and scoring).'
        },
        {
          id: '9.5',
          isEnabled: true,
          apiPath: 'registrations/scoring-leaders',
          caption: 'Scoring Leaders',
          description: 'Composed view which lists all user accounts that have ever been provided a scoring leader role.'
        },
        {
          id: '9.6',
          isEnabled: true,
          apiPath: 'registrations/scoring-supervisors',
          caption: 'Scoring Supervisors',
          description: 'Composed view which lists all user accounts that have ever been provided a scoring supervisor role.'
        },
        {
          id: '9.7',
          isEnabled: true,
          apiPath: 'registrations/range-finders',
          caption: 'Range Finders',
          description: 'Composed view which lists all user accounts that have ever been provided a range finder role.'
        },
        {
          id: '9.8',
          isEnabled: true,
          apiPath: 'NODOWNLOAD',
          caption: 'Profile IDs',
          description: 'Contains the latest profile ID associated with the scorer.'
        },
      ]
    },
    {
      id: 5,
      caption: 'Responses & Results',
      dataFrames: [
        { id: 1, isEnabled:true, apiPath:'responses/subm-test-att', caption: 'Submitted Test Attempts', description: 'This view contains a list of all submitted test attempts. ', sampleDescription:'The extracted sample contains all applicant submission data for 2 test sessions (including no-show attempts).' },
        { id: '1.1', isEnabled:true, apiPath:'responses/test-attempt-isrs', caption: 'Test Attempt ISRs', description: 'This view contains all the ISR data found elsewhere in the codebook.Composed view which provides core statistics on a scorers progress in scoring an item.' },
        { id: '1.2', isEnabled:true, apiPath:'', caption: 'Submitted Test Attempts - No Responses', description: 'This view contains a list of submitted test attempts that have been excluded from 5.1 because they contain no responses.' },
        { id: 2,   isEnabled:true, apiPath:'responses/subm-item-resp', caption: 'Submitted Questions Responses', description: 'This view contains a list of all related questions that have been submitted for the relevant test attempts.', sampleDescription:'In the extracted sample, questionnaire responses are excluded.' },
        // { id: 3,   isEnabled:true, apiPath:'responses/flat-test-att-resp', caption: 'Flattened Test Attempts & Responses', description: 'Customization. Combines Test Attempts table and answer key into a flattened reference for the EQAO psychometric team.							', },
        { id: 4,   isEnabled:true, apiPath:'responses/stu-att', caption: 'Student Attempt Records', description: 'Customization. Combines the Test Attempts table and student registration info.							', },
        { id: 5,   isEnabled:true, apiPath:'responses/item-summ', caption: 'Question Summary', description: 'This view contains a summary of all questions as answered by test takers.							', },
        { id: 6,   isEnabled:true, apiPath:'responses/resp-summ', caption: 'Response Summary', description: 'This view contains a summary of all responses as answered by test takers.							', },
        { id: 7,   isEnabled:true, apiPath:'responses/reported-issues', caption: 'Reported Issues', description: 'This view provides a listing of all reported issues coming in to the system through support.							', },
        // { id: 8.1, isEnabled:true, apiPath:'responses/process-data/items', caption: 'Process Data - Items', description: 'Includes granular-level process data within the item.							', },
        // { id: 8.2, isEnabled:true, apiPath:'responses/process-data/screens', caption: 'Process Data - Screens', description: 'Includes granular-level process data within the item and response concerning individual screens.							', },
        // { id: 8.3, isEnabled:true, apiPath:'responses/process-data/recordings', caption: 'Process Data - Recordings', description: 'Includes granular-level process data within the item and response concerning individual recordings.							', },
        // { id: 8.4, isEnabled:true, apiPath:'responses/process-data/text-to-speech', caption: 'Process Data - TTS', description: 'Includes granular-level process data within the item and response concerning text to speech usage.							', },
        // { id: 8.5, isEnabled:true, apiPath:'responses/process-data/teacher-responses', caption: 'Process Data - Teacher Response', description: 'Includes granular-level process data within the item and response concerning occurrences of teachers entering the responses for the student.							', },
        // { id: 8.6, isEnabled:true, apiPath:'responses/process-data/drawing-tools', caption: 'Process Data - Graphics', description: 'Includes granular-level process data within the item and response concerning usage of graphical tools.							', },
        {
          id: '9.1',
          isEnabled: true,
          apiPath: 'scoring/scorer-item-summary',
          caption: 'Scorer Item Summary',
          description: 'Composed view which provides core statistics on a scorers progress in scoring an item.'
        },
        {
          id: '9.2',
          isEnabled: true,
          apiPath: 'scoring/scorer-claimed-batches',
          caption: 'Scorer Claimed Batches',
          description: 'A record of each response batch claimed by scorers, along with it\'s current marking and expiry (i.e., revocation) status. These batches include training tasks such as Practice Test and Qualifying Tests, which are flagged accordingly to distinguish these scores from those used for determine student scores.'
        },
        {
          id: '9.3',
          isEnabled: true,
          apiPath: 'scoring/scorer-claimed-responses',
          caption: 'Scorer Claimed Responses',
          description: 'A record of each response contained in batches claimed by scorers. This includes validity responses (indicated in the is_validity column), as well as responses contained in "Training" sets (PT / QT).'
        },
        {
          id: '9.4',
          isEnabled: true,
          apiPath: 'scoring/responses-score',
          caption: 'Responses Score',
          description: 'Composed view which summarizes the final score assigned to each student response, as will be used in computing the student\'s overall result on the test and for the computation of psychometric statistics.\n\n- the first valid score assigned to a response is the one that is to be used (this includes expert scores applied during range finding - excluding those that were not put into a set)\n- this excludes scores given in a batch that was marked as invalid due to low rolling validity\n- in the case of expert scores that are used, the last score applied is taken'
        },
        {
          id: '9.5.a',
          isEnabled: true,
          apiPath: 'NODOWNLOAD',
          caption: 'Item Psychometrics Equating Sample',
          description: 'Composed view which aggregates item psychometrics computed on the basis of response scores (and impacted by machine-scored items as they impact the overall test score). Computed using the equating sample'
        },
        {
          id: '9.5.b',
          isEnabled: true,
          apiPath: 'NODOWNLOAD',
          caption: 'Item Psychometrics Whole Sample',
          description: 'Composed view which aggregates item psychometrics computed on the basis of response scores (and impacted by machine-scored items as they impact the overall test score). Computed using the whole sample.'
        },
        {
          id: '9.6',
          isEnabled: true,
          apiPath: 'scoring/scorings',
          caption: 'Scorings',
          description: 'A record of each score assigned by a scorer on any response (i.e., outside of range finding), as well as CAS and "Send to Principal" reporting flags and accompanying messages.'
        },
        {
          id: '9.7',
          isEnabled: true,
          apiPath: 'scoring/range-finding-selections',
          caption: 'Range Finding Selections',
          description: 'A record of each student response that was selected for at least one stage of the range-finding process. The expert assigned score is tracked here.'
        },
        {
          id: '9.8',
          isEnabled: true,
          apiPath: 'scoring/response-sets',
          caption: 'Response Sets',
          description: 'A record of every response set (used for validity, practice test, qualifying tests, and more) created during the range finding process. These sets are created anytime the user clicks on the "Make Available to Scorers" button.'
        },
        {
          id: '9.9',
          isEnabled: true,
          apiPath: 'scoring/response-set-responses',
          caption: 'Response Set Responses',
          description: 'A record every response contained within response sets.'
        },
        {
          id: '9.10',
          isEnabled: true,
          apiPath: 'scoring/validity-response-summary',
          caption: 'Validity Response Summary',
          description: 'Composed view with scorer statistics for each response used for validity.'
        },
        {
          id: '9.11',
          isEnabled: true,
          apiPath: 'scoring/qualifying-test-response-summary',
          caption: 'Qualifying Test Response Summary',
          description: 'Composed view with scorer statistics for each response used in qualifying test.'
        },
        {
          id: '9.13',
          isEnabled: true,
          apiPath: 'NODOWNLOAD',
          caption: 'Polytomous Items',
          description: 'Composed view which aggregates item statistics segregated by possible score.'
        }
      ]
    },
  ]
}
