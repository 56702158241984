import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { AuthService } from '../../api/auth.service';

@Component({
  selector: 'ens-congratulations',
  templateUrl: './ens-congratulations.component.html',
  styleUrls: ['./ens-congratulations.component.scss']
})
export class EnsCongratulationsComponent implements OnInit {

  @Output() congratulationsSubmit = new EventEmitter();

  nameFc = new FormControl(null, [Validators.required]);
  emailFc = new FormControl(null, [Validators.required, Validators.email]);
  accessCodeFc = new FormControl(null, [Validators.required, this.accessCodeValidator(/^1234$/)]);

  purchaseClicked = false;
  invalidAccessCode = false;

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    
  }

  onPurchaseClick() {
    this.purchaseClicked=true;
  }

  accessCodeValidator(accessCodeRe: RegExp) : ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const correct = accessCodeRe.test(control.value);
      return correct ? null : {forbiddenAccessCode: {value: control.value}};
    };
  }

  onSubmitClick() {
    if(this.accessCodeFc.invalid && this.accessCodeFc.errors.forbiddenAccessCode) {
      this.invalidAccessCode = true;
      return;
    }

    this.congratulationsSubmit.emit({
      name: this.nameFc.value, 
      email: this.emailFc.value,
      accessCode: this.accessCodeFc.value});
  }

  submitIsDisabled() {
    return this.nameFc.invalid || this.emailFc.invalid || (this.accessCodeFc.invalid && this.accessCodeFc.errors.required);
  }
}
