import { Component, OnInit, Input } from '@angular/core';
import { QuestionState } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementAnnotatedText } from './model';


@Component({
  selector: 'element-render-annotation',
  templateUrl: './element-render-annotation.component.html',
  styleUrls: ['./element-render-annotation.component.scss']
})
export class ElementRenderAnnotationComponent implements OnInit {

  @Input() element:IContentElementAnnotatedText;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState; // not used
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
  ) { }

  ngOnInit() {
    // console.log('element', this.element)
  }

  isRightAlign(){
    return !this.isLeftAlign();
  }
  isLeftAlign(){
    return this.element.isLeftAligned;
  }

}