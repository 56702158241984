import { IContentElementMcqOption } from "../element-render-mcq/model";
import { ElementType, IContentElement } from "../models";

export interface IContentElementTextSelection extends IContentElement {
    texts: IContentElementSelectableText[];
    hasOutline?: boolean;
    isSelectionsLimited?: boolean;
    maxSelections?: number;
    width?: number;
    isParagraphMode?: boolean;
    paragraphs?: IContentElementSelectableText[][]
    spaceAfterParagrapgh?: number
}

export const selectTextEditInfo = {
    editExcludeFields: ['texts','paragraphs']
}

export interface IContentElementSelectableText extends IContentElementMcqOption {}

export const selectableTextEditInfo = {
    editExcludeFields: [],
    superType: ElementType.MCQ_OPTION
}