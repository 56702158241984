import * as moment from 'moment-timezone';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ScrollService } from '../../core/scroll.service';
import { LangService } from '../../core/lang.service';
import { AccountType } from '../../constants/account-types';
import { LoginGuardService } from '../../api/login-guard.service';
import { IInstitutionInfo, MyInstitutionService } from '../my-institution.service';
import { Subscription } from 'rxjs';
import { MyTestSessionsSetupService, ITestSessionSetupInfo } from '../my-test-sessions-setup.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { DemoDataService, IAvailableSession } from '../demo-data.service';
import { AuthService } from '../../api/auth.service';
import { AvailableSessionsService } from '../available-sessions.service';
import enLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr-ca';
import { WhitelabelService } from '../../domain/whitelabel.service';

enum SETUP_STATES {
  LOADING      = 'LOADING',
  FAILED       = 'FAILED',
  NEW_SETUP    = 'NEW_SETUP',
  RESUME_SETUP = 'RESUME_SETUP',
  SETUP_PENDING   = 'SETUP_PENDING',
  SETUP_DONE   = 'SETUP_DONE',
}

@Component({
  selector: 'view-dashboard',
  templateUrl: './view-dashboard.component.html',
  styleUrls: ['./view-dashboard.component.scss']
})
export class ViewDashboardComponent implements OnInit, OnDestroy {

  public breadcrumb = [];
  public instInfo:IInstitutionInfo;
  public setupInfo:ITestSessionSetupInfo;
  public SETUP_STATES = SETUP_STATES;
  public activeSessions:IAvailableSession[] = [];
  public upcomingSessions:IAvailableSession[] = [];
  public unclosedTestSessions = [];
  public isInited:boolean;
  public isRoleFailed:boolean;
  public showUnclosedTestSessions:boolean;
  public sessionCount:number = 0;
  public registrationCount:number = 0;
  
  private subs:Subscription[] = [];


  // calendar
  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  calendarPlugins = [dayGridPlugin]; 
  eventTimeFormat: { // like '14:30:00'
    hour: '2-digit',
    minute: '2-digit',
    meridiem: true
  };
  calendarEvents = [ 
    // { title: 'L#1', date: '2020-01-10 09:00' }, 
  ];
  constructor(
    private auth: AuthService,
    private availSessService: AvailableSessionsService,
    private myInst: MyInstitutionService,
    private mySetup: MyTestSessionsSetupService,
    private loginGuard: LoginGuardService, // 
    private breadcrumbsService: BreadcrumbsService,
    private scrollService: ScrollService,
    public lang: LangService,
    private whitelabel:WhitelabelService,
    private router:Router,
  ) { }
  
  ngOnInit() {
    this.loginGuard.activate([AccountType.TEST_ADMIN]);
    this.scrollService.scrollToTop();
    this.breadcrumb = [
      this.breadcrumbsService.TESTADMIN_LANDING(),
      this.breadcrumbsService.TESTADMIN_DASHBOARD(),
    ];
    this.initRouteView();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe() );
  }
  
  initRouteView(){
    this.subs = this.subs.concat([
      this.myInst.sub().subscribe(this.updateInstInfo),
      this.myInst.fail().subscribe(this.onRoleFail),
      this.mySetup.sub().subscribe(this.updateSetupInfo)
    ]);
  }
  updateInstInfo = (instInfo:IInstitutionInfo) => {
    if (instInfo && !this.isInited){
      this.isInited = true;
      this.instInfo = instInfo;
      this.loadAvailableSessions();
    }
  }
  getMsAsDays = (ms:number) =>{
    let days = Math.floor(moment.duration(ms, 'milliseconds').asDays());
    return days || ' < 1';
  }

  onRoleFail = (failState:boolean) => {
    this.isRoleFailed = failState;
  }

  isShowBookingStats(){
    return this.isSessionCreator() && this.whitelabel.getSiteFlag('BOOKING_POLICY_STATS');
  }

  loadPrior() {
    this.auth.apiFind('public/test-admin/test-sessions/prior', {
      query: {
        instit_group_id: this.instInfo.groupId
      }
    }).then(res => {
      let list = res;
      if(list.data) list = list.data;
      list.forEach((session) => {
        const start = moment(session.date_time_start);
        const now = moment();
        if (start.isBefore(now) && !session.is_closed) {
          this.unclosedTestSessions.push(session);
        }

      });
      console.log(this.unclosedTestSessions);
    });
  }

  isSessionsLoaded:boolean;
  loadAvailableSessions() {
    const div = document.getElementsByClassName('today-sessions');
    setTimeout(() => {
      let today_session = div.item(0);
      today_session?.setAttribute('class', "today-sessions full-size");
    }, 500)
    this.availSessService.refresh();
    this.subs = this.subs.concat(this.availSessService.sub().subscribe(this.captureAvailableSessions));
    this.subs.concat(this.availSessService.sub().subscribe(this.captureAvailableSessions));
    this.loadPrior();
  }

  isSMCS(){
    return this.whitelabel.getSiteFlag('IS_SMCS');
  }

  captureAvailableSessions = (availableSessions:IAvailableSession[]) => {
    // console.log('captureAvailableSessions', availableSessions, this.isSessionsLoaded)
    if (availableSessions && !this.isSessionsLoaded){
      this.isSessionsLoaded = true;
      const sessions = availableSessions;
      sessions.forEach(session => {
        this.sessionCount ++;
        console.log('session', session)
        this.registrationCount += session.bookingsCount || 0;
        if (session.__isStartingSoon){
          this.activeSessions.push(session);
        }
        this.upcomingSessions.push(session);
        this.calendarEvents.push({
          title: this.lang.tra('cts_location_room_lbl') + ' ' + session.room,
          date: session.dateTimeStart.format('YYYY-MM-DD HH:mm')
        })
      })
      this.calendarEvents = [].concat(this.calendarEvents);
      // console.log('update calendarEvents', this.calendarEvents)
    }
  }

  getPrintRoute(sessionId:number){
    return  `/${this.lang.c()}/${AccountType.TEST_ADMIN}/print-session/${sessionId}`
  }
  
  renderLongTimestamp(m:moment.Moment){
    return m.format( this.lang.tra('datefmt_dashboard_long'));
    // 4:30pm, Sept. 22, 2020 (Wednesday)
  }

  convertDateToMoment(date){
    return moment.tz(date, moment.tz.guess());
  }

  renderSMCSpastSessionName(sessionName){
    if(typeof(sessionName) == 'string') sessionName = JSON.parse(sessionName);
    if(this.lang.c() == 'en') return sessionName.en;
    else return sessionName.fr;
  }

  renderShortTimestamp(m:moment.Moment){
    return m.format( this.lang.tra('datefmt_dashboard_short'));
  }

  renderCapacityProportion(session:IAvailableSession){
    return Math.round(100 * session.bookingsCount / session.capacity);
  }

  updateSetupInfo = (setupInfo:ITestSessionSetupInfo) => {
    this.setupInfo = setupInfo;
  }

  getInvigilDisplay(session:IAvailableSession){
    return this.availSessService.renderInvigDisplayFromSession(session);
  }

  getCalendarLocale(){
    if (this.lang.c() === 'fr'){
      return frLocale;
    }
    else{
      return enLocale;
    }
  }



  getSetupState(){
    // console.log('getSetupState', this.isRoleFailed, this.SETUP_STATES.FAILED)
    if (this.isRoleFailed){
      return this.SETUP_STATES.FAILED;
    }
    else if (this.myInst.isInstMngr()){
      if (this.setupInfo){
        // console.log('has setup info')
        if (this.myInst.hasSessions()){
          return SETUP_STATES.SETUP_DONE
        }
        if (this.myInst.isInstMngr()){
          if (this.setupInfo.__reqNew){
            return this.SETUP_STATES.NEW_SETUP;
          }
          else{
            return this.SETUP_STATES.RESUME_SETUP;
          }
        }
        else{
          return this.SETUP_STATES.SETUP_PENDING;
        }
      }
    }
    else {
      return SETUP_STATES.SETUP_DONE
    }
    return this.SETUP_STATES.LOADING;
  }
  createNewTestSessionSetup(){
    this.mySetup.createNewTestSessionSetup();
  }

  isSessionsActive(){
    return false;
  }

  isSessionsUpcoming(){
    return true;
  }

  isSessionCreator(){
    return this.myInst.isInstMngr(); // accomm coord can do it in the pending accomm view
  }

  resumeCreateNewTestSessions(){
    this.mySetup.navToLastVisitedCS(this.setupInfo);
  }

  getInvigRoute(sessionId:number) {
    return `/${this.lang.c()}/${AccountType.TEST_ADMIN}/invigilate/${sessionId}`;
  }

  getBookingsRoute(sessionId:number) {
    return `/${this.lang.c()}/${AccountType.TEST_ADMIN}/manage-session/${sessionId}`;
  }

  getInvigilationsRoute(sessionId: number) {
    return `/${this.lang.c()}/${AccountType.TEST_ADMIN}/invigilate/${sessionId}`;
  }

}
