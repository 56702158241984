import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { TranslatorService } from '../translator.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ITranslateLanguages } from './types';

@Component({
  selector: 'widget-translator',
  templateUrl: './widget-translator.component.html',
  styleUrls: ['./widget-translator.component.scss']
})
export class WidgetTranslatorComponent implements OnInit {

  // Inputs
  @Input() languages?: ITranslateLanguages[]

  // data
  sourceLanguageCode: string;
  sourceText: string = '';
  targetLanguageCode: string;
  translatedText: string = '';

  // UI
  showTranslatedFullScreen: boolean;

  // Meta
  __transMeta = new FormGroup({
    isInitializing: new FormControl(true),
    isTranslating: new FormControl(false),
  })

  constructor(
    private translate: TranslatorService,
    private lang: LangService
  ) { }


  ngOnInit(): void {
    this.init();
  }

  // Inits 

  async init() { 

    if(!this.languages) {
      if(!this.translate.supportedLangs) {
        await this.translate.fetchSupportedLangs();        
      } 
      this.languages = this.translate.supportedLangs
    }

    this.sourceLanguageCode = this.lang.c(); // defaults to the current lang

    this.setMetaControls('isInitializing', false);
  }

  // Handle
  
  handleTranslateBtn = async () => {
    this.setMetaControls('isTranslating', true);

    const res = await this.translate.translate({
      sourceLanguageCode: this.sourceLanguageCode,
      targetLanguageCode: this.targetLanguageCode,
      text: this.sourceText     
    });

    
    this.translatedText = res;    
    this.setMetaControls('isTranslating', false);
  }

  // controls

  getMetaControls(metaFormControl: string) {
    return this.__transMeta.get([metaFormControl]);
  }

  getMetaControlsVal(metaFormControl: string){
    return this.getMetaControls(metaFormControl).value;
  }

  setMetaControls(metaFormControl: string, val: any) {
    this.__transMeta.get([metaFormControl]).setValue(val);
  }

  toggleTranslatedFullScreen () {
    this.showTranslatedFullScreen = !this.showTranslatedFullScreen;
  }

  // style

  get translationWidgetTitle() {
    return this.lang.tra('lbl_accomm_translator')
  }

  get translateFromPlaceholder() {
    return this.lang.tra('auth_translate_placeholder');
  }

  get translateToPlaceholder() {
    return this.lang.tra('auth_translated_placeholder');
  }

  get translateBtnLabel() {
    return this.lang.tra('auth_translate_btn');
  }

  get selectLanguageLabel() {
    return this.lang.tra('auth_tlang_select_dropdown')
  }

  get isToTranslateTextBoxVisible() {
    return !this.showTranslatedFullScreen;
  }

  get isTranslateBtnVisible() {
    return !this.showTranslatedFullScreen;
  }

  get isLangSelectPlaceholder() {
    return !this.targetLanguageCode
  }

  get sourceLangLabel() {
    return this.lang.getKnownLangName(this.sourceLanguageCode); 
  }

}
