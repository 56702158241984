import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'twiddle',
  templateUrl: './twiddle.component.html',
  styleUrls: ['./twiddle.component.scss']
})
export class TwiddleComponent implements OnInit {

  @Input() state:TwiddleState;
  @Input() caption:string;
  @Input() defaultValue?:boolean;
  @Input() isSectionHeader: boolean;
  @Input() icon: string;
  @Output() change = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (!this.state){
      this.state = new TwiddleState(!!this.defaultValue);
    }
  }

  toggle(){
    this.state.value = !this.state.value;
    this.change.emit(this.state.value);
  }

}

export class TwiddleState {

  constructor(
    public value:boolean,
  ){

  }

}