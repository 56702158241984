import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { DEFAULT_TICK_MEASURE } from './constants'

export class Background extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  staticBg: PIXI.Graphics;
  hcPrevState: boolean;

  constructor(
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    staticBg: PIXI.Graphics
  ) {
    super(addGraphic, render, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.element = element;
    this.spriteLoader = new SpriteLoader();
    this.isGlobalRotating = isGlobalRotating;
    this.isGlobalDragging = isGlobalDragging;
    
    // Initialize config variables

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    // Initialize objects
    this.backgroundSprite = backgroundSprite;
    this.staticBg = staticBg;

    this.hcPrevState = this.textToSpeech.isHiContrast;
    this.textToSpeech.isHighContrastSub.subscribe(val => {
      if(this.hcPrevState !== val){
        if(val){
          this.drawStaticBg(1);
          console.log('1');
        } else {
          this.drawStaticBg();
          console.log('2');
        }
        this.render();
        this.hcPrevState = val
      }
    })

    //load Sprites
    this.loadAssets().then(this.init)

    // Draw bg
    this.drawBg()
    this.drawStaticBg();
    this.drawGrid();

    // Render scene
    this.render();
  }

  init = ({resources}) => {
    if (resources['background']) this.backgroundSprite.texture = resources.background.texture;
    this.render();
  }

  drawGrid() {
    if(this.element.showGrid) {
      const grid = new PIXI.Graphics();
      const gap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;

      const setFill = (counter:number) => {
        if (counter % 10 == 0){
          grid.beginFill(0x000, 0.4);
        }
        else {
          grid.beginFill(0xe3e3e3, 0.4);
        }
      }
      let counter = 0;
      for(let i = gap; i<this.element.canvasWidth; i+=gap) {
        setFill(counter++);
        grid.drawRect(i, 0, 1, this.element.canvasHeight);
      }
      counter = 0;
      for(let i = gap; i<this.element.canvasHeight; i+=gap) {
        setFill(counter++);
        grid.drawRect(0, i, this.element.canvasWidth, 1);
      }
      grid.endFill();
      grid.zIndex = -1; // todo:CONFIG this should be configurable

      this.addGraphic(grid);
    }
  }

  drawStaticBg(opacity: number = 0.00000000001 ) {
    if (this.textToSpeech.isHiContrast) {
      opacity = 1;
    }
    this.staticBg.clear();
    this.staticBg.beginFill(0xffffff, opacity);
    this.staticBg.drawRect(0, 0, this.element.canvasWidth || 500, this.element.canvasHeight || 500);
    this.staticBg.endFill();
    this.staticBg.zIndex = -2;
    this.staticBg.interactive = true;
    this.addGraphic(this.staticBg);
  }

  drawBg() {
    if (this.isBgImg()) {
      this.backgroundSprite.anchor.set(0);
      this.backgroundSprite.scale.set(this.element.scaleBg || 1);
      // this.backgroundSprite.height = this.element.spriteBgHeight * (this.element.scaleBg || 1); //sRadius * 2;
      // this.backgroundSprite.width = this.element.spriteBgWidth * (this.element.scaleBg || 1); //sRadius * 2;
      this.backgroundSprite.x = this.element.spriteBgX;
      this.backgroundSprite.y = this.element.spriteBgY;
      this.backgroundSprite.zIndex = -1;
      this.backgroundSprite.cursor = 'default';
      this.stage.addChild(this.backgroundSprite);
    }
  }

  loadAssets = () => {
    let assets = []
    if (this.isBgImg()) assets.push({name: "background", path: this.element.bgImage.url})
    this.spriteLoader.addSpritestoLoader(assets);
    return this.spriteLoader.loadSprites()
  }

  isBgImg() {
    return this.element.bgImage && this.element.bgImage.url;
  }
}
