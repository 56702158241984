import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { LoginCtrl } from './login-ctrl';

@Injectable({
  providedIn: 'root'
})

export class LoginValidationService {

  constructor(
    private auth: AuthService,
    private routes: RoutesService,
  ) {

  }
  initCtrl(){
   return new LoginCtrl(this.auth, this.routes);
  }
 
}
