import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IHotspotConfig, QuestionState } from 'src/app/ui-testrunner/models';
import { bindFormControls } from '../services/data-bind';

@Component({
  selector: 'element-config-hotspot',
  templateUrl: './element-config-hotspot.component.html',
  styleUrls: ['./element-config-hotspot.component.scss']
})
export class ElementConfigHotspotComponent implements OnInit {  

  @Input() element:IHotspotConfig;

  public hspHeight: FormControl = new FormControl();
  public hspWidth: FormControl = new FormControl();
  public hspOpacity: FormControl = new FormControl();
  public hspColor: FormControl = new FormControl();
  public useUniqueColor: FormControl = new FormControl();

  constructor() { }

  ngOnInit() {
    const formControls = [
      // Canvas
      {f: this.hspHeight, p:'hspHeight'},
      {f: this.hspWidth, p:'hspWidth'},
      {f: this.hspColor, p:'hspColor'},
      {f: this.hspOpacity, p:'hspOpacity'},
      {f: this.useUniqueColor, p:'useUniqueColor'}
    ];
    if(this.element.hspHeight == null) { // only init defaults if on block initialization
      this.initDefaults();
    }

    bindFormControls(this.element, formControls, true);
  }

  initDefaults() {
    if(this.element.hspHeight == null) {
      this.element.hspHeight = 50;
    }
    if(this.element.hspWidth == null) {
      this.element.hspWidth = 50;
    }
    if(this.element.hspColor == null) {
      this.element.hspColor = "#000000";
    }
    if(this.element.hspOpacity == null) {
      this.element.hspOpacity = 50;
    }
  }
}
