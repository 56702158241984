import { IContentElement, IScoredResponse } from "src/app/ui-testrunner/models";

export const templateEditInfo = {
  editExcludeFields: ['content']
}

export interface IContentElementTemplate extends IContentElement, IScoredResponse {
    templateName?: string,
    templateId?: number,
    templateVersion?: number,
     // `content` stores the current inner representation of the elements as shaped by `contentConfig`, user-modified `templateConfig`, and `metaAdjusters`
    content: IContentElement[],
    configs: {
        [id:string]: TemplateConfigRow,
    },
}
export interface IBlockTemplateConfig {
    templateId: number, // from db
    templateVersion: number, // from db
    // `contentConfig` determines the elements that will exist at base in the template (all elements that need to exist and be controlled by the template should be defined here, and turned off as needed). In all cases, you should be able to render this in the `content` prop of an item and you would see some of the basic elements. It is encouraged to set some of the sizing constants here (if it is not more appropriate to make them visible using the meta adjusters)
    contentConfig: IContentElement[],
    // `templateConfig`
    templateConfig: TemplateConfigRow[],
    // `metaAdjusters`
    metaAdjusters: TemplateMetaAdjust[],
    // `migrationOptions`
    migrationOptions: TemplateMigrationOption[],
}

// Meta Adjusters
export interface TemplateMigrationOption {
    target_slug: string, // 
    templateConfigTransforms: TemplateMigrationTransform[]
}
export interface TemplateMigrationTransform {
    transformation: 'move' | 'split',
    pathSource: string,
    pathTarget: string,
}


// Meta Adjusters
export interface TemplateMetaAdjust {
    metaAdjusterType: 'const' | 'var' | 'listener',
}
export interface TemplateMetaAdjustConst extends TemplateMetaAdjust {

}
export interface TemplateMetaAdjustListener extends TemplateMetaAdjust {

}


// Template Config Rows are used to dynamically specificy the aspects of a template that an author can edit.
export interface TemplateConfigDefRow {
    configType: 'el' | 'label' | 'text' | 'text-list' | 'number',
    id?: string, // not needed for 'label'
    label: string,
    elementRef: string,
}

// easier to carry over some aspects that are in the def
export interface TemplateConfigRow {
    id: string, // not needed for 'label'
    elementRef: string,
}

export interface TemplateConfigRowEl extends TemplateConfigRow {
    config: IContentElement,
}

export interface TemplateConfigRowText  extends TemplateConfigRow {
    text: string,
    isTextArea: boolean,
}

export interface TemplateConfigRowTextList  extends TemplateConfigRow {
    text_els: {text: string}[],
}

export interface TemplateConfigRowMcqOptions  extends TemplateConfigRow {
    text_els: {text: string}[],
}

export interface TemplateConfigRowTargets  extends TemplateConfigRow {
    targets: GroupingTarget[],
    defaultOptions: GroupingOption[],
    displayOrder: string[],
}
export interface GroupingTarget {
    isDefaultGroup: boolean,
    label: string,
}
export interface GroupingOption {
    label: string,
}
