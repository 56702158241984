<div>
    <table>
        <tr *ngFor="let row of selected; let i = index" class="graph-row">
            <td>
                <div class="select is-small" style="margin-top:1em;">
                    <select [(ngModel)]="selected[i].group" (input)="changeGroupDropdown($event, i)">
                        <option *ngFor="let type of graphGroupKeys" [value]="type">{{ type }}</option>
                    </select>
                </div>
                <div class="select is-small" style="margin-top:1em;">
                    <select [(ngModel)]="selected[i].id" (input)="changeIdDropdown($event, i)">
                        <option *ngFor="let id of districtSchoolKeys[row.group]" [value]="id">{{ id }}</option>
                    </select>
                </div>
                <div class="select is-small" style="margin-top:1em;">
                    <select [(ngModel)]="selected[i].year" (input)="changeYearDropdown($event, i)">
                        <option *ngFor="let year of yearKeys[row.group][row.id]" [value]="year">{{ year }}</option>
                    </select>
                </div>
                <div class="select is-small" style="margin-top:1em;">
                    <select [(ngModel)]="selected[i].primary" (input)="changePrimaryDropdown($event, i)">
                        <option *ngFor="let year of studentTypeKeys" [value]="year">{{ year }}</option>
                    </select>
                </div>
                <br><br>

                <pie-graph [data]="selected[i].numeracy" [legendTitle]="'Numeracy'"></pie-graph>
                <pie-graph [data]="selected[i].reading" [legendTitle]="'Reading'"></pie-graph>
                <pie-graph [data]="selected[i].writing" [legendTitle]="'Writing'"></pie-graph>
                <button *ngIf="i != 0" (click)="removeComparison(i)" class="button is-danger remove-button">Remove</button>
            </td>
        </tr>
    </table>
    <button (click)="addComparison()" class="button is-success">Add Comparison</button>
</div>
