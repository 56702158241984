import { Subject } from "rxjs";

export interface IQPubSubPayload {
    entryId?: number,
    type: number | string,
    data: any,
}

export class QuestionPubSub {

    constructor(
        public isPrintMode: boolean,
        public selectedEntry: any
    ){
    }
    contextSubject:Subject<IQPubSubPayload> = new Subject();
    elementSubjects:Map<number, Subject<IQPubSubPayload>> = new Map();

    public destroy(){
        this.elementSubjects = null; // todo: may need to iterate and unsubscribe
        this.contextSubject = null;
    }

    public initElementSub(entryId:number){
        // console.log('initElementSub', entryId)
        const sub:Subject<IQPubSubPayload> = new Subject();
        this.elementSubjects.set(entryId, sub);
        return sub;
    }

    public elementPub(entryId:number, type:number | string, data?:any){
        const sub = this.elementSubjects.get(+entryId);
        if (sub){
            sub.next({
                type,
                data
            });
        }
        else{
            console.error('entryId', entryId, 'not initialized')
        }
    }

    public allPub(payload:IQPubSubPayload){
        this.contextSubject.next(payload)
    }

    public allSub(){
        return this.contextSubject;
    }


} 