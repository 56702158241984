import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AltTextService {

  constructor() { }

  private elementIdToAltText: Map<number, FormControl> = new Map<number, FormControl>();

  registerFormControl(entryId: number, fc: FormControl) {
    this.elementIdToAltText.set(entryId, fc);
  }

  updateAltText(entryId: number, newAltText: string) {
    const fc = this.elementIdToAltText.get(entryId);
    if(fc) {
      fc.setValue(newAltText);
    } 
  }
}
