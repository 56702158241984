export const DEFAULT_TICK_MEASURE = 5;

export enum TICK_LEVEL {
    MAJOR,
    MID,
    MINOR
}

export enum GAP_THRESHOLDS {
    A = 2,
    B = 1,
}